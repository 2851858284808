import { Box, SxProps, Typography } from "@mui/material";
import { _Logo, PAGE } from "./Theme";
import _Texts from "./Texts";
import Metadata from "./Metadata";
import { useGlobalState } from "./State";

import _2021 from './Charts/2021.jpg';
import _2020 from './Charts/2020.jpg';
import _2019 from './Charts/2019.jpg';
import _2018 from './Charts/2018.jpg';
import _2017 from './Charts/2017.jpg';
import _2016 from './Charts/2016.jpg';
import _2015 from './Charts/2015.jpg';
import _2010 from './Charts/2010.jpg';
import _2011 from './Charts/2011.jpg';
import _2012 from './Charts/2012.jpg';
import _2013 from './Charts/2013.jpg';
import _2014 from './Charts/2014.jpg';
import { Fragment } from "react/jsx-runtime";
import { Antoshka } from "./images/Images";

const Returns = [
    [ 2021, 'Oberon Flagship',  53,  15280  ],
    [ 2021, 'US Benchmark',     4,   10436  ],
    [ 2021, 'AU Benchmark',     -0,  9945   ],
    [ 2021, 'CN Benchmark',     -45, 5485   ],
    [ 2020, 'Oberon Flagship',  137, 23713  ],
    [ 2020, 'US Benchmark',     11,  11110  ],
    [ 2020, 'AU Benchmark',     14,  11491  ],
    [ 2020, 'CN Benchmark',     -21, 7809   ],
    [ 2019, 'Oberon Flagship',  255, 35515  ],
    [ 2019, 'US Benchmark',     80,  18081  ],
    [ 2019, 'AU Benchmark',     9,   10994  ],
    [ 2019, 'CN Benchmark',     36,  13675  ],
    [ 2018, 'Oberon Flagship',  76,  17560  ],
    [ 2018, 'US Benchmark',     40,  14004  ],
    [ 2018, 'AU Benchmark',     -7,  9275   ],
    [ 2018, 'CN Benchmark',     -1,  9838   ],
    [ 2017, 'Oberon Flagship',  92,  19179  ],
    [ 2017, 'US Benchmark',     33,  13356  ],
    [ 2017, 'AU Benchmark',     14,  11486  ],
    [ 2017, 'CN Benchmark',     7,   10723  ],
    [ 2016, 'Oberon Flagship',  158, 25796  ],
    [ 2016, 'US Benchmark',     66,  16613  ],
    [ 2016, 'AU Benchmark',     19,  11938  ],
    [ 2016, 'CN Benchmark',     67,  16710  ],
    [ 2015, 'Oberon Flagship',  167, 26686  ],
    [ 2015, 'US Benchmark',     26,  12606  ],
    [ 2015, 'AU Benchmark',     2,   10259  ],
    [ 2015, 'CN Benchmark',     -6,  9367   ],
    // [ 2010, 'Oberon Flagship',  63,  16320  ],
    // [ 2010, 'US Benchmark',     37,  13784  ],
    // [ 2010, 'AU Benchmark',     -7,  9229   ],
    // [ 2011, 'Oberon Flagship',  10,  10982  ],
    // [ 2011, 'US Benchmark',     30,  13057  ],
    // [ 2011, 'AU Benchmark',     5,   10536  ],
    // [ 2012, 'Oberon Flagship',  209, 30946  ],
    // [ 2012, 'US Benchmark',     47,  14754  ],
    // [ 2012, 'AU Benchmark',     35,  13524  ],
    // [ 2012, 'CN Benchmark',     19,  11947  ],
    // [ 2013, 'Oberon Flagship',  170, 26973  ],
    // [ 2013, 'US Benchmark',     55,  15554  ],
    // [ 2013, 'AU Benchmark',     18,  11890  ],
    // [ 2013, 'CN Benchmark',     42,  14250  ],
    // [ 2014, 'Oberon Flagship',  18,  11790  ],
    // [ 2014, 'US Benchmark',     16,  11626  ],
    // [ 2014, 'AU Benchmark',     -5,  9420   ],
    // [ 2014, 'CN Benchmark',     -10, 8967   ],
];

const translated = {
    'Oberon Flagship': { en: 'Oberon Flagship', zh: '奥布朗战略' },
    'US Benchmark': { en: 'US Benchmark', zh: '美国基准' },
    'AU Benchmark': { en: 'AU Benchmark', zh: '澳大利亚基准' },
    'CN Benchmark': { en: 'CN Benchmark', zh: '中国基准' },
};

const toPct = v => `${(v as any) > 0 ? '+' : ''}${v}%`;

const H2 = ({mt=4, children}) => <Typography component='h2' sx={{
    fontWeight: `800`, display: 'block', mt: mt, fontSize: `1.1rem`,
}}>
    {children}
</Typography>

const P = ({sx={} as SxProps, component=undefined, children}) => <Typography component={component} sx={{
    fontFamily: `sans-serif`,
    ...sx,
}}>
    {children}
</Typography>;

const UL = ({sx={}, children}) => <Box component='ul' sx={{
    display: `flex`,
    flexDirection: `column`,
    alignItems: `stretch`,
    maxWidth: `min(500px, 100%)`,
    gap: 1,
    ...sx,
}}>
    {children}
</Box>

const WARN = ({children}) => <Typography component='h3' sx={{
    fontWeight: `bold`,
    display: 'block',
    mt: 2,
    color: `orangered`,
}}>
    {children}
</Typography>

const CELL = ({row, isTitle=false}) => {
    const [year, title, percentReturn, _10k] = row;
    const isOber = title.startsWith('Ober') || title.startsWith('奥布朗');
    const isUS   = title.startsWith('US') || title.startsWith('美国');
    const isAU   = title.startsWith('AU') || title.startsWith('澳大');
    const isZH   = title.startsWith('CN') || title.startsWith('中国');
    const bg = isOber ? '#ffd700' : isUS ? '#ffd700a8' : isAU ? '#ffd70080' : '#ff850085';
    const sx: SxProps = {
        py: 1,
        px: 1.5,
        mt: isOber ? 2 : 0,
        background: bg,
        display: 'flex',
        textAlign: `center`,
        alignItems: `center`,
        justifyContent: `center`,
        fontWeight: isOber || isTitle ? 'bold' : 'normal',
        '@media print': {
            background: 'white',
            borderBottom: `solid 1px grey`,
        }
    };
    return <Box sx={{display: `contents`}}>
        <Box sx={sx}>{year}</Box>
        <Box sx={sx}>{title}</Box>
        <Box sx={sx}>{isTitle ? percentReturn : toPct(percentReturn)}</Box>
        <Box sx={sx}>{`${!isTitle ? '$' : ''}${!isTitle ? new Intl.NumberFormat('en-US').format(_10k) : _10k}`}</Box>
    </Box>;
}

const CHART = ({year, lang, vals, chart}) => {
    const [oberon, us, au, cn] = vals;
    return <Box sx={{
        borderTop: `solid 2px grey`,
        pageBreakInside: `avoid`,
        mt: 3,
        '@media print': {
            borderTop: `solid 0px grey`,
        }
    }}>
        <H2>{{en: `2 Years Starting ${year}`, zh: `2 年开始 ${year}`}[lang]}</H2>
        <Box sx={{
            display: `flex`, flexWrap: `wrap`, gap: 3,
            alignItems: `center`,
        }}>
            {[['blue', 'Oberon Flagship', oberon], ['orange', 'US Benchmark', us], ['green', 'AU Benchmark', au], ['red', 'CN Benchmark', cn],].map(([clr, title, pct], i) => <Box key={i} sx={{
                display: `flex`, alignItems: `center`, gap: 1,
            }}>
                <Box sx={{
                    background: clr,
                    width: `2ch`,
                    height: `2ch`
                }}/>
                    <P>{translated[title][lang]}</P>
                    <P>{toPct(pct)}</P>
                </Box>)}
        </Box>
        <Box component='img' src={chart} sx={{
            width: `100%`,
            mx: `auto`,
        }} />
    </Box>;
}

const Proposal = () => {
    const [ WebsiteFlags ] = useGlobalState(`WebsiteFlags`);

    const desc1 = {
        en: [
            'Oberon Research is starting a new Investment Strategy in US Equities. This Strategy will pursue:',
            'Utilize High Volatility, High Risk, High Leverage Strategy,',
            'FANG Technology, Semiconductor, and BitCoin Equities,',
            'Exposure to Asset Classes through Derivatives.',
        ],
        zh: [
            '奥伯龙研究公司正在启动一项新的美国股票投资策略。该策略将追求：',
            '利用高波动性、高风险、高杠杆的策略。',
            'FANG科技公司、半导体和比特币股票。',
            '通过衍生品投资资产类别。',
        ],
    }

    const taxes = {
        en: `
            Taxes
            We cannot provide tax advice specific to your situation; however, we offer the following general tax information:
            U.S. Capital Gains Tax: The United States taxes capital gains on assets located within the U.S. or on income sourced from the U.S.,
            U.S. Federal Capital Gains and Ordinary Dividends/Interest: These are taxed at ordinary income tax rates (10% to 37%),
            FICA and Medicare Taxes: These do not apply to investment income such as capital gains, dividends, or interest,
            Australian Foreign Income Tax Offset: This offset reduces your Australian tax liability by the amount of tax you've already paid in the U.S.
        `.trim().split('\n').map(item => item.trim()),
        zh: `
            税务
            我们无法为您的具体情况提供税务建议，但我们提供以下一般税务信息：
            美国资本利得税：美国对位于美国境内的资产或源自美国的收入征收资本利得税。
            美国联邦短期资本利得和普通股息/利息：这些收入按普通收入税率征税（10% 至 37%）。
            FICA 和 Medicare 税：这些税不适用于资本利得、股息或利息等投资收入。
            澳大利亚外国收入税抵免：此抵免可减少您在澳大利亚的税务负担，抵消您已在美国缴纳的税款。
        `.trim().split('\n').map(item => item.trim()),
    }

    const fees = {
        en: `
            Fees and Redemptions
            Client fees are 30% of the annualized profits based on the cost basis, deducted on a monthly basis.
            In most cases, clients may redeem assets at any time. In times of extraordinary market behavior, redemptions may be restricted for an unspecified amount of time.
        `.trim().split('\n').map(item => item.trim()),
        zh: `
            费用
            客户费用为基于成本基础的年度化利润的30%，按月扣除。
        `.trim().split('\n').map(item => item.trim()),
    }

    const strategy = {
        en: `
            Investment Strategy & Risk Management
            Our trading strategy is driven by proprietary Python and C++ algorithms that analyze various indexes and companies, enabling us to target higher returns.
            We only invest when our algorithms indicate a strong potential for profitability and meet our internal thresholds for risk.
            We employ robust risk management techniques, including Stop Loss orders to protect against large losses in rising markets, and Short positions in order to protect profits in falling markets.
            We utilize market data dating back to 1985 for simulations and analysis, and we study historical market performance from as early as 1910 to gain insights into how markets have reacted to different political and economic conditions.
        `.trim().split('\n').map(item => item.trim()),
        zh: `
            投资策略与风险管理
            我们的交易策略由专有的 Python 和 C++ 算法驱动，这些算法分析各种指数和公司，使我们能够瞄准更高的回报。我们只在算法显示出强大的盈利潜力并符合我们的内部风险标准时进行投资。
            我们采用强大的风险管理技术，包括止损订单，以防止在上涨市场中遭受大额损失，并通过空头头寸在下跌市场中保护利润。
            我们利用自 1985 年以来的市场数据进行模拟和分析，并研究自 1910 年以来的历史市场表现，以深入了解市场在不同政治和经济条件下的反应。
        `.trim().split('\n').map(item => item.trim()),
    }

    const chiefStrategist = {
        en: `
            Chief Strategist
            Antoshka Milton (安东尼·米尔顿)
            Engineer - Real Time Data - Bloomberg
            Vice President - High Frequency Trading - JP Morgan
            Expert in High Frequency Trading, Algorithms, C++, Python
        `.trim().split('\n').map(item => item.trim()),
        zh: `
            首席策略师
            安东尼·米尔顿 (Antoshka Milton)
            工程师 - 实时数据 - 彭博社，纽约市
            副总裁 - 高频交易 - 摩根大通，纽约市
            高频交易、算法、C++、Python 专家
        `.trim().split('\n').map(item => item.trim()),
    }

    const company = {
        en: `
            Company
            Oberon Research & Investment, LLC
            1309 Coffeen Avenue STE 10017
            Sheridan Wyoming 82801
            Registered with the Wyoming Secretary of State.
            We follow all applicable State and Federal Regulations.
        `.trim().split('\n').map(item => item.trim()),
        zh: `
            公司
            Oberon Research & Investment, LLC
            1309 Coffeen Avenue STE 10017
            Sheridan Wyoming 82801
            已在怀俄明州务卿处注册。
            我们遵守所有适用的州和联邦政府法规。
        `.trim().split('\n').map(item => item.trim()),
    };

    const custodian = {
        en: `
            Custodians
            We accept client funds denominated in USD via Wire transfer or Wise international transfer.
            We store client funds at Capital One Bank and Morgan Stanley Bank.
            We deploy and invest client funds in an individual accounts at Morgan Stanley Trading Services.
            Client funds are never co-mingled with company or other client funds.
            We provide trading statements on a weekly and monthly basis.
        `.trim().split('\n').map(item => item.trim()),
        zh: `
            托管人
            我们接受通过电汇或Wise国际转账以美元计价的客户资金。
            我们将客户资金存储在Capital One银行和摩根士丹利银行。
            我们将客户资金部署并投资于摩根士丹利交易服务的个人账户。
            客户资金绝不会与公司或其他客户的资金混合。
            我们每周和每月提供交易报表。
        `.trim().split('\n').map(item => item.trim()),
    };

    const estimates = {
        en: [
            'These are estimated historical returns for the years 2010 to 2022:',
            'The returns are estimated based on a combination of actual historical data (where available) and simulated historical prices,',
            'The estimates are generated using a Buy/Hold/Sell Python algorithm designed to minimize losses while outperforming comparable benchmarks,',
            'Benchmarks are provided for comparative purposes across strategies,',
            'Please note that taxes and fees are not included in these estimates.',
        ],
        zh: [
            '以下是2010年至2022年的历史回报估算：',
            '回报估算基于实际历史数据（如有）和模拟历史价格的组合。',
            '这些估算是通过一个买入/持有/卖出Python算法生成的，旨在在避免损失的同时，超越可比基准。',
            '提供基准以便在不同策略之间进行比较。',
            '请注意，这些估算未包含税费。',
        ],
    }

    const Chart = () => <Box component='article' sx={{
        width: `100%`,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
        gap: 1,
    }}>
        <_Logo />
        <Box sx={{
            textAlign: `center`,
            display: `flex`,
            gap: 1,
            flexDirection: `column`,
            alignItems: `center`,
            width: `min(80svw, 900px)`,
        }}>
            <PAGE center sx={{gap: 1}}>
                <H2>{{en: 'Flagship Investment Strategy Proposal', zh: '新的投资策略建议'}[WebsiteFlags.local]}</H2>
                <P>{desc1[WebsiteFlags.local][0]}</P>
                <UL>
                    {desc1[WebsiteFlags.local].slice(1).map((x, i) => <P key={i} component='li'>{x}</P>)}
                </UL>

                <H2>{strategy[WebsiteFlags.local].slice(0, 1)}</H2>
                <UL>{strategy[WebsiteFlags.local].slice(1).map((x, i) => <P sx={{}} key={i} component='li'>{x}</P>)}</UL>

                <H2>{chiefStrategist[WebsiteFlags.local].slice(0, 1)}</H2>
                <Box component='img' src={Antoshka} sx={{
                    width: `min(150px, 80svw)`,
                    height: `auto`,
                }}/>
                <P sx={{fontWeight: `bold`}}>{chiefStrategist[WebsiteFlags.local].slice(1, 2)}</P>
                <UL sx={{listStyleType: `none`}}>{chiefStrategist[WebsiteFlags.local].slice(2).map((x, i) => <P sx={{listStyleType: `none`}} key={i} component='li'>{x}</P>)}</UL>
            </PAGE>

            <PAGE center sx={{gap: 1}}>
                <H2>{custodian[WebsiteFlags.local].slice(0, 1)}</H2>
                <UL>{custodian[WebsiteFlags.local].slice(1).map((x, i) => <P sx={{}} key={i} component='li'>{x}</P>)}</UL>

                <H2>{taxes[WebsiteFlags.local].slice(0, 1)}</H2>
                <P>{taxes[WebsiteFlags.local].slice(1, 2)}</P>
                <UL>{taxes[WebsiteFlags.local].slice(2).map((x, i) => <P sx={{}} key={i} component='li'>{x}</P>)}</UL>

                <H2>{fees[WebsiteFlags.local].slice(0, 1)}</H2>
                <UL>{fees[WebsiteFlags.local].slice(1).map((x, i) => <P sx={{}} key={i} component='li'>{x}</P>)}</UL>

                <H2>{company[WebsiteFlags.local].slice(0, 1)}</H2>
                <P sx={{fontWeight: `bold`}}>{company[WebsiteFlags.local].slice(1, 2)}</P>
                <UL sx={{listStyleType: `none`}}>{company[WebsiteFlags.local].slice(2).map((x, i) => <P sx={{listStyleType: `none`}} key={i} component='li'>{x}</P>)}</UL>
            </PAGE>

            <PAGE center sx={{gap: 1}}>
                <H2>{{en: 'Estimated Historical Returns', zh: '预计历史收益'}[WebsiteFlags.local]}</H2>
                <P>{estimates[WebsiteFlags.local][0]}</P>
                <UL>{estimates[WebsiteFlags.local].slice(1).map((x, i) => <P sx={{}} key={i} component='li'>{x}</P>)}</UL>
            </PAGE>

            {[[0, 16], [17, undefined]].map(([s, e], i) => <Fragment key={i}>
                <PAGE sx={{gap: 1, mb: `auto`}}>
                    <WARN>{{en: 'Investment is High Risk - You May Lose All Your Capital - Historical Performance / Estimated Performance Is No Guarantee of Future Performance', zh: '投资具有高风险 - 您可能会损失全部本金 - 过去表现或估算表现不能保证未来表现。'}[WebsiteFlags.local]}</WARN>
                    <P>Quoted simulated returns are before taxes and fees.</P>
                    <Box sx={{
                        display: `grid`,
                        mt: 3,
                        gridTemplateColumns: `auto auto auto auto`,
                        gridTemplateRows: `repeat(${Returns.length}, auto)`,
                        width: `100%`,
                    }}>
                        <CELL isTitle row={
                            {
                                en: ['Year', 'Benchmark', 'Percent Return over 2 Years', 'A $10,000 Investment Returns'],
                                zh: ['年份', '基准', '两年回报率', '一笔$10,000的投资回报']
                            }[WebsiteFlags.local]
                        } />
                        {Returns.slice(s, e).map(([year, title, percentReturn, _10k], i) => <Fragment key={i}>
                            <CELL row={[year, translated[title][WebsiteFlags.local], percentReturn, _10k]} />
                        </Fragment>)}
                    </Box>
                </PAGE>
            </Fragment>)}

            <PAGE sx={{gap: 1}}>
                <H2>
                    <Box sx={{mt: 5}} />
                    {{en: 'Estimated Historical Returns Charts', zh: '预计历史收益图表'}[WebsiteFlags.local]}
                </H2>
                <WARN>{{en: 'Returns Exclude Fees and Taxes - Returns are Estimated Based on Historical Equity Values', zh: '回报不包括费用和税款 - 回报是基于历史股票价值估算的。'}[WebsiteFlags.local]}</WARN>
                {[
                    [2021, _2021, [53,  4,  -0, -45,],],
                    [2020, _2020, [137, 11, 14, -21,],],
                    [2019, _2019, [255, 80, 9,  36, ],],
                    [2018, _2018, [76,  40, -7, -1,],],
                    [2017, _2017, [92,  33, 14, 7, ],],
                    [2016, _2016, [158, 66, 19, 67, ],],
                    [2015, _2015, [167, 26, 2,  -6, ],],
                ].map(([year, chart, vals], i) => <Fragment key={i}><CHART lang={WebsiteFlags.local} year={year} vals={vals} chart={chart}/></Fragment>)}
            </PAGE>
        </Box>
    </Box>;

    return <Box sx={{
        minHeight: `100svh`,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
        justifyContent: `center`,
        boxSizing: 'border-box',
        p: 3,
        mx: 'auto',
        width: '100%',
    }}>
        <Chart />
    </Box>;
}

export default () => <>
    <Metadata title={{en: 'Proposal', zh: '投资方案'}} />
    <Proposal />
</>;

