import { Box, SxProps } from "@mui/material";
import { useGlobalState } from "./State";
import { MONOSPACE, Span, XButton, assert, lineClamp } from "./Theme";
import { useEffect, useRef, useState } from "react";
import { Game_T } from "./Interfaces";

const STATE = {
    CLOSED: 0,
    OPENING: 1,
    OPEN: 2,
    CLOSING: 4
};

const useTitleDrawer = (game: Game_T, symbol: string) => {
    const [height, setHeight] = useState('0px');
    const ref = useRef(null);
    const [expand, setExpand] = useState(STATE.CLOSED);

    useEffect(() => {
        if (expand != STATE.CLOSED) {
          setHeight(`${ref.current.scrollHeight}px`);
        } else {
          setHeight('0px');
        }
    }, [expand]);

    const toggleExpand = () => {
        const willOpen = expand == STATE.CLOSED;
        setExpand(willOpen ? STATE.OPENING : STATE.CLOSING);
        setTimeout(() => {
            setExpand(willOpen ? STATE.OPEN : STATE.CLOSED);
        }, 500);
    };

    return [
        <>
            <XButton
            id={`${symbol}-title`}
            disabled={![STATE.OPEN, STATE.CLOSED].includes(expand)}
            onClick={toggleExpand} noStyle
            sx={{
                fontWeight: `bold`,
                color: game.view_symbolColor[symbol],
                width: {xs: `4ch`, md: `10ch`},
                ...lineClamp(1)
            }} disabledSx={{
                textDecoration: `none`,
            }}>
                {symbol}
            </XButton>
        </>,
        <>
            <Box ref={ref} sx={{
                height: height,
                mt: expand == STATE.CLOSED ? -1 : 0,
                overflow: `hidden`,
                transition: `height 0.5s cubic-bezier(0.18, 0.89, 0.32, 1.28)`,
            }}>
                <Box sx={{flex: `1`, ...lineClamp(3)}}>
                    {game.view_symbolDesc[symbol]}
                </Box>
            </Box>
        </>
    ];
};

export default ({game, symbolData, symbol, onBuy, onSell, sx={} as SxProps}) => {
    const [ flags ] = useGlobalState(`flags`);
    const [ title, drawer ] = useTitleDrawer(game, symbol);

    const canSell = (sym) => {
        if (flags && flags.restricted === false) {
            return true;
        }
        const passed = game.day - game.mostRecentPurchaseIndexes[sym];
        const ret = 25 - passed;
        return ret <= 0;
    };

    const [ avgPrice, ownedQty ] = game.portfolioAssets[symbol] || [0, 0];
    const price = symbolData[symbol].marketTicks[game.day];
    const symbolColor = game.view_symbolColor[symbol];
    const clr = avgPrice < price ? 'green' : 'red';

    const toPct = (val: number) => {
        return `${val > 0 ? '+' : ''}` + val.toFixed(1) + '%'
    };

    return <Box component='li' sx={{
        display: `flex`,
        flexDirection: `column`,
        gap: 1,
        p: 1,
        border: `solid 1px ${symbolColor}`,
        background: `${symbolColor}10`,
        borderRadius: `5px`,
        position: `relative`,
        ...sx,
    }}>
        <Box sx={{
            fontFamily: MONOSPACE,
            display: `flex`,
            alignItems: `center`,
            flexWrap: `wrap`,
            gap: 1,
        }}>
            {title}
            <Box sx={{}}>
                ${price.toFixed(0)}
            </Box>
            {[
                [toPct(symbolData[symbol]._ytdChange[game.day]), 'YTD'],
                [toPct(symbolData[symbol]._30dayChange[game.day]), '30D'],
            ].map(([val, time], i) => <Box key={i}>
                        <Span sx={{color: val.startsWith('-') ? 'red' : 'green', minWidth: `4ch`, display: `inline-block`, textAlign: `right`}}>
                            {val}
                        </Span>
                        <Span sx={{fontSize: `0.8em`, display: `inline-block`, transform: `translate(5px, -10px)`}}>
                            {time}
                        </Span>
                    </Box>
                )}
        </Box>
        {drawer}
        <Box sx={{
            display: `flex`,
            gap: 1,
            alignItems: `center`,
        }}>
            {onBuy && <XButton id={`${symbol}-buy`} onClick={onBuy}>Buy</XButton>}
            {onSell && ownedQty != 0 && <XButton id={`${symbol}-sell`} disabled={!canSell(symbol)} onClick={onSell}>Sell</XButton>}
            {ownedQty != 0 && <Box sx={{color: clr, ml: 'auto'}}>
                <Span sx={{}}>{ownedQty.toFixed(1)}</Span>
                <Span sx={{fontSize: `0.8em`}}>@</Span>
                <Span sx={{}}>${avgPrice.toFixed(1)}</Span>
            </Box>}
        </Box>
    </Box>;
}
