import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, ToggleButton, Typography } from "@mui/material";
import { assert_eq, _Logo, Span, MONOSPACE, genClipPath, toGridArea } from "./Theme";
import _Texts from "./Texts";
import Metadata from "./Metadata";
import { useNavigate } from "react-router";
import { Fragment, useEffect, useState } from "react";
import Data from './benchmarks.json';
import { Add, Remove } from "@mui/icons-material";

// const soxl_high_sell = (
//     stopLongOn: number,
//     sellLongOn: number,
//     startLongOn: number,
//     padding: number,
//     weeklyContri: number,
//     initialCash: number,
//     canSell: boolean,
//     soxl_opens: number[],
//     qqq_opens: number[],
//     a200_opens: number[],
// ) => {
//     if (sellLongOn < stopLongOn) return;
//     if (startLongOn < 0) return;

//     const percentChange = (a, b) => (a && b) ? ((b - a) / a) * 100 : 0;

//     let soxlAShares = 0;
//     let soxlPShares = 0;
//     let qqqShares = 0;
//     let a200Shares = 0;
//     let soxlAAssets = [];
//     let soxlPAssets = [];
//     let qqqAssets = [];
//     let a200Assets = [];
//     let soxlACost = 0;
//     let soxlPCost = 0;
//     let qqqCost = 0;
//     let a200Cost = 0;
//     let unspentCashHistory = [];
//     let contributionHistory = [];
//     let unspentCash = 0;
//     let unspentInitialCash = initialCash;
  
//     let isBuying = true;
//     let daysSinceBuy = 0;
  
//     const startLongOn0 = -startLongOn;
//     const startLongOn1 = -startLongOn / 2;
//     const startLongOn2 = -startLongOn / 4;
  
//     for (let i = 0; i < soxl_opens.length; i++) {
//       const soxlOpen = soxl_opens[i];
//       const qqqOpen = qqq_opens[i];
//       const a200Open = a200_opens[i];
  
//       if (i < padding) continue;
//       daysSinceBuy += 1;
  
//       const recentLow = Math.min(...soxl_opens.slice(i - padding, i));
//       const recentHigh = Math.max(...soxl_opens.slice(i - padding, i));
//       const recentRise = percentChange(recentLow, soxlOpen);
//       const recentFall = percentChange(recentHigh, soxlOpen);
  
//       if (isBuying && recentRise > stopLongOn) {
//         isBuying = false;
//       } else if (!isBuying && daysSinceBuy > 30 && recentRise > sellLongOn && canSell) {
//         unspentCash += soxlAShares * soxlOpen;
//         soxlAShares = 0;
//         soxlACost = 0;
//       } else if (!isBuying && recentFall < startLongOn2) {
//         isBuying = true;
//       }
  
//       if (i % 5 === 0) {
//         unspentCash += weeklyContri;
//         let toSpend = weeklyContri;
//         if (qqqCost === 0) toSpend += initialCash;
//         qqqShares += +(toSpend / qqqOpen).toFixed(3);
//         qqqCost += toSpend;
//         a200Shares += +(toSpend / a200Open).toFixed(3);
//         a200Cost += toSpend;
//         soxlPShares += +(toSpend / soxlOpen).toFixed(3);
//         soxlPCost += toSpend;
//       }
  
//       if (isBuying && unspentCash) {
//         let toSpendPct = 1;
//         if (recentFall < startLongOn0) {
//           toSpendPct = 1;
//         } else if (recentFall < startLongOn1) {
//           toSpendPct = 0.4;
//         } else if (recentFall < startLongOn2) {
//           toSpendPct = 0.1;
//         } else {
//           toSpendPct = 0;
//         }
  
//         let toSpend = toSpendPct * unspentCash;
//         unspentCash -= toSpend;
//         let toSpendInitial = toSpendPct * unspentInitialCash;
//         unspentInitialCash -= toSpendInitial;
//         toSpend += toSpendInitial;
//         daysSinceBuy = 0;
//         soxlAShares += +(toSpend / soxlOpen).toFixed(3);
//         soxlACost += toSpend;
//       }
  
//       if (soxlAShares < 0 || soxlOpen < 0 || unspentCash < 0) {
//         throw new Error('Invalid state');
//       }
  
//       soxlAAssets.push(+(soxlAShares * soxlOpen + unspentCash).toFixed(3));
//       soxlPAssets.push(+(soxlPShares * soxlOpen).toFixed(3));
//       qqqAssets.push(+(qqqShares * qqqOpen).toFixed(3));
//       a200Assets.push(+(a200Shares * a200Open).toFixed(3));
//       unspentCashHistory.push(unspentCash);
//       contributionHistory.push(qqqCost);
//     }
  
//     const logQQQ = qqqAssets.map(x => x ? Math.log(x) : 0);
//     const logA200 = a200Assets.map(x => x ? Math.log(x) : 0);
//     const logSoxlA = soxlAAssets.map(x => x ? Math.log(x) : 0);
//     const logSoxlP = soxlPAssets.map(x => x ? Math.log(x) : 0);
//     const logContribution = contributionHistory.map(x => Math.log(x));
  
//     const toSave = {
//       cash: unspentCashHistory,
//       totalCost: qqqCost,
//       standard: {
//         'Oberon Benchmark': soxlAAssets,
//         'US Benchmark': qqqAssets,
//         'AU Benchmark': a200Assets,
//         'Bank Account': contributionHistory,
//       },
//       log: {
//         'Oberon Benchmark': logSoxlA,
//         'US Benchmark': logQQQ,
//         'AU Benchmark': logA200,
//         'Bank Account': logContribution,
//       }
//     };
//     return toSave;
// };

const getData = (date) => {
    /*
    {
        Flagship: number[];
        "US Benchmark": number[];
        "AU Benchmark": number[];
        "CN Benchmark": number[];
        "AU Property Benchmark": number[];
    }
    */
    const data = Data[date];
    const log = Object.fromEntries(Object.entries(data).map(([key, value]) => [key, (value as []).map(Math.log)]));
    const toSave = {
        open: data['Flagship'][0],
        close: data['Flagship'][data['Flagship'].length - 1],
        standard: data,
        log: log,
    };
    return toSave
};

const formatNum = num => new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
}).format(num);

function getYearRange(dateStr) {
    const [year, month] = dateStr.split('-').map(Number);
    const date = new Date(year, month - 1); // month - 1 because months are 0-indexed
    const formatDate = (date) => {
        const options = { year: 'numeric', month: 'short' };
        return new Intl.DateTimeFormat('en-US', options as any).format(date);
    };
    const startDateStr = formatDate(date);
    const endDate = new Date(date);
    endDate.setFullYear(date.getFullYear() + 2);
    const endDateStr = formatDate(endDate);
    return [startDateStr, endDateStr];
}
  
const useOptions = () => {

    const [ isLog,      setIsLog      ] = useState(false);
    const [ dates,      setDates      ] = useState(Object.keys(Data));
    const [ date,       setDate       ] = useState(dates[0]);
    const [ benchmarks, setBenchmarks ] = useState([]);
    const [ benchmark,  setBenchmark  ] = useState('');
    const [ data,       setData ] = useState<any>();
    const [ standardClip, setSC ] = useState<string>();
    const [ benchClip,    setBC ] = useState<string>();

    // const data = get_benchmarks(
    //     /* stopLongOn  */  40,
    //     /* sellLongOn  */  150,
    //     /* startLongOn */  20,
    //     /* padding     */  120,
    //     /* weeklyContri*/  income,
    //     /* initialCash */  initialCash,
    //     /* canSell     */  true,
    //     /* soxl_opens  */  Data[scenario].opens['Oberon Benchmark'],
    //     /* qqq_opens   */  Data[scenario].opens['US Benchmark'    ],
    //     /* a200_opens  */  Data[scenario].opens['AU Benchmark'    ],
    // );

    useEffect(() => {
        const nextData = getData(date);
        const nextBenchmarks = Object.keys(nextData.standard);
        setBenchmarks(nextBenchmarks.slice(1));
    }, [ date ]);

    useEffect(() => {
        if (!date) {
            return;
        }
        const nextData = getData(date);
        const nextBenchmarks = Object.keys(nextData.standard);
        setBenchmarks(nextBenchmarks.slice(1));
        setBenchmark(nextBenchmarks.slice(1)[0]);
        setData(nextData);
    }, [ date ]);

    useEffect(() => {
        if (!benchmark || !date) {
            return;
        }
        const logVals = Object.values(data.log as object).flat();
        const stdVals = Object.values(data.standard as object).flat();
        const logsMax = Math.max(...logVals);
        const logsMin = Math.min(...logVals);
        const stdsMax = Math.max(...stdVals);
        const stdsMin = Math.min(...stdVals);

        const [min, max]    = isLog ? [logsMax, logsMin]: [stdsMax, stdsMin];
        const benchmarkVals = data[isLog ? 'log' : 'standard'][benchmark];
        const oberonVals    = data[isLog ? 'log' : 'standard']['Flagship'];

        const benchmarkClipPath = genClipPath(benchmarkVals, false, min, max);
        const oberonClipPath    = genClipPath(oberonVals, false,    min, max);

        console.log(`Setting chart for date:${date} benchmark:${benchmark}`)
        setSC(oberonClipPath);
        setBC(benchmarkClipPath);
    }, [ benchmark, date, isLog ]);

    return [
        <Fragment>
            {dates && <FormControl sx={{
                width: `25ch`,
            }} fullWidth>
                <InputLabel id="scenario-select-label">Date</InputLabel>
                <Select
                value={date}
                label="Date"
                onChange={e => setDate(e.target.value)}
                >
                    {dates.map((b, i) => <MenuItem key={i} value={b}>{b}</MenuItem>)}
                </Select>
            </FormControl>}
            <FormControl sx={{
                width: `25ch`,
            }} fullWidth>
                <InputLabel id="benchmark-select-label">Benchmark</InputLabel>
                <Select
                value={benchmark}
                label="Benchmark"
                onChange={e => setBenchmark(e.target.value)}
                >
                    {benchmarks.map((b, i) => <MenuItem key={i} value={b}>{b}</MenuItem>)}
                </Select>
            </FormControl>
            <ToggleButton value="check" selected={isLog} onChange={() => setIsLog(!isLog)}>
                Logarithmic
            </ToggleButton>
        </Fragment>,
        [
            benchClip,
            formatNum(Number((10000).toFixed(0))),
            benchClip ? benchClip.split(' ').pop() : '',
            benchmark,
        ],
        [
            standardClip,
            formatNum(Number((90000).toFixed(0))),
            standardClip ? standardClip.split(' ').pop() : '',
        ],
        dates,
    ] as any;
}

const Calculator = () => {
    const [ height, setHeight ] = useState(400);

    const dates = Object.keys(Data).sort();
    const [ date,       setDate  ] = useState(dates[0]);
    const benchmarks = Object.keys(Data[date]).slice(1).sort();
    const [ bm, setBM   ] = useState(benchmarks[0]);
    const [ log, setLog ] = useState(false);
    const [ flagClip,  setFC ] = useState<string>();
    const [ benchClip, setBC ] = useState<string>();
    const [ lastFVal, setLFV ] = useState();
    const [ lastBVal, setBFV ] = useState();

    const [sd, ed] = getYearRange(date);

    useEffect(() => {
        const rawData = Data[date];
        const allVal = log ? Object.fromEntries(Object.entries(rawData).map(([key, value]) => [key, (value as []).map(x => x ? Math.log(x) : 0)])) : rawData;
        const allValsFlat = Object.values(allVal).flat() as number[];

        setLFV(rawData['Flagship'][rawData['Flagship'].length - 1])
        setBFV(rawData[bm][rawData[bm].length - 1])

        const [min, max]    = [Math.max(...allValsFlat), Math.min(...allValsFlat)];
        const benchmarkVals = allVal[bm];
        const flagship      = allVal['Flagship'];

        const benchmarkClipPath = genClipPath(benchmarkVals, false, min, max);
        const flagshipClip      = genClipPath(flagship, false, min, max);

        console.log(`Setting chart for date:${date} benchmark:${bm} log:${log}`)
        setFC(flagshipClip);
        setBC(benchmarkClipPath);
    }, [ bm, date, log ]);

    const Chart = () => <Box sx={{
        width: `100%`,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
        gap: 1,
    }}>
        <_Logo />
        <Typography sx={{
            textAlign: `center`,
            mb: 1,
            width: `min(80svw, 900px)`,
        }}>
            <Span sx={{
                fontWeight: `bold`,
                textDecoration: `underline`,
                display: 'block',
            }}>Flagship Strategy Simulation</Span>
                <Span sx={{display: `block`}}>This a is close approximation of Oberon's Flagship Strategy compared to common indexes.</Span>
                <Span sx={{display: `block`}}>Taxes and Management Fees are not included.</Span>
                <Span sx={{display: `block`}}>This is based on historical data and should not be relied upon for investment decisions.</Span>
            </Typography>
        <Box sx={{
            display: `flex`,
            flexWrap: 'wrap',
            gap: 1.5,
            justifyContent: `center`,
            alignItems: `center`,
            m: 1,
        }}>
            <FormControl sx={{
                width: `25ch`,
            }} fullWidth>
                <InputLabel id="scenario-select-label">Date</InputLabel>
                <Select
                value={date}
                label="Date"
                onChange={e => setDate(e.target.value)}
                >
                    {dates.map((b, i) => <MenuItem key={i} value={b}>{b}</MenuItem>)}
                </Select>
            </FormControl>
            <FormControl sx={{
                width: `25ch`,
            }} fullWidth>
                <InputLabel id="benchmark-select-label">Benchmark</InputLabel>
                <Select
                    value={bm}
                    label="Benchmark"
                    onChange={e => setBM(e.target.value)}
                >
                    {benchmarks.map((b, i) => <MenuItem key={i} value={b}>{b}</MenuItem>)}
                </Select>
            </FormControl>
        </Box>
        <Box sx={{
            display: `grid`,
            gridTemplateColumns: `auto 1fr auto`,
            gridTemplateRows: `1fr auto`,
            width: `100%`,
        }}>
            {flagClip && <Box sx={{
                gridArea: toGridArea(1, 1),
                width: `7ch`,
                fontFamily: MONOSPACE,
                position: `relative`,
            }}>
                <Typography sx={{
                    fontFamily: MONOSPACE,
                    position: 'absolute',
                    left: 0,
                    top: flagClip.split(' ')[5].replace(',', ''), color: '#009688',
                    fontWeight: `bold`,
                    borderTop: `solid 2px #009688`,
                }}>$10,000</Typography>
            </Box>}
            <Box sx={{
                display: `flex`,
                alignItems: `center`,
                justifyContent: `center`,
                flexGrow: 1,
                width: `100%`,
                position: `relative`,
                gridArea: toGridArea(2, 1),
                height: `max(60svh, 400px)`,
            }}>
                {flagClip && <Box id='oberon-chart' sx={{
                    position: `absolute`,
                    top: 0, left: 0, right: 0, bottom: 0,
                    width: `100%`,
                    clipPath: `polygon(${flagClip})`,
                    transition: `clip-path 1s`,
                    backgroundColor: `#009688`,
                }} />}
                {benchClip && <Box id='benchmark-chart' sx={{
                    position: `absolute`,
                    top: 0, left: 0, right: 0, bottom: 0,
                    width: `100%`,
                    clipPath: `polygon(${benchClip})`,
                    transition: `clip-path 1s`,
                    backgroundColor: `#ffa500bf`,
                }} />}
                <Box sx={{
                    display: `flex`, gap: 1, flexWrap: `wrap`,
                    maxWidth: {xs: '80%', sm: '50%'},
                    position: `absolute`, top: 20, left: 20,
                }}>
                    {[
                        ['#009688', 'Oberon Flagship'  ],
                        ['#ffa500', bm ],
                    ].map(([color, title], i) => 
                        <Box key={i} sx={{
                            display: `flex`,
                            alignItems: `center`,
                        }}>
                            <Span sx={{p: '.8ch', m: 0.8, backgroundColor: color, display: `inline-block`}}/>
                            <Typography sx={{
                            }}>
                                {`${title}`}
                            </Typography>
                        </Box>)}
                </Box>
            </Box>
            <Box sx={{
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `space-between`,
                gridArea: toGridArea(3, 1),
                position: `relative`,
            }}>
                {flagClip && [
                    [formatNum(lastFVal), '#009688', flagClip.split(' ').pop()],
                    [formatNum(lastBVal), '#ffa500', benchClip.split(' ').pop()],
                ].map(([x, color, Y], i) => 
                    <Fragment key={i}>
                        <Typography sx={{
                            fontFamily: MONOSPACE,
                            fontWeight: `bold`,
                            width: `${x.length + 1}ch`,
                        }} />
                        <Typography sx={{
                            fontFamily: MONOSPACE,
                            position: 'absolute',
                            top: Y, color: color,
                            fontWeight: `bold`,
                            borderTop: `solid 1px ${color}`,
                        }}>
                            {`$${x}`}
                        </Typography>
                    </Fragment>)}
            </Box>
            <Box sx={{
                display: `flex`,
                justifyContent: `space-between`,
                gridArea: toGridArea(2, 2),
                mx: 6,
            }}>
                <Typography sx={{fontWeight: `bold`}}>{sd}</Typography>
                <Typography sx={{fontWeight: `bold`}}>{ed}</Typography>
            </Box>
        </Box>
    </Box>;

    const skillBoxes = [
        { content: <_Logo />,            gridArea: {xs: toGridArea(1, 1, 1, 6), sm: toGridArea(1, 1, 1, 4), md: toGridArea(1, 1, 1, 3)} },
        { content: '', color: "#009688", gridArea: {xs: toGridArea(6, 5)      , sm: toGridArea(4, 6)      } },
    ];

    return <Box sx={{
        minHeight: `100svh`,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
        justifyContent: `center`,
        boxSizing: 'border-box',
        p: 3,
        mx: 'auto',
        width: '100%',
    }}>
        <Chart />
    </Box>;
}

export default () => <>
    <Metadata title={{en: 'Simulator', zh: '模拟器'}} />
    <Calculator />
</>;
