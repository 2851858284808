
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, SxProps, Typography } from "@mui/material"
import { useNavigate } from "react-router";
import { Fragment } from "react/jsx-runtime";
import { useState } from "react";
import { PieChart as _PieChart } from 'react-minimal-pie-chart';
import { useGlobalState } from "./State";
import { Logo_Img } from "./images/Images";

export const MONOSPACE = "Menlo, Consolas, 'DejaVu Sans Mono', 'Liberation Mono', 'Courier New', monospace";
export const CURSIVE = `"Allura", cursive`;
export const PARAGRAPH = `DM Sans,Arial,sans-serif,-apple-system,blinkmacsystemfont,Segoe UI,roboto,oxygen-sans,ubuntu,cantarell,Helvetica Neue`;

export const lineClamp = (lines: number): SxProps => ({
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: lines,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
});

export const assert = (condition, message='') => {
    if (!condition) {
        throw new Error(message || 'Assertion failed');
    }
}

export const assert_eq = (a, b) => {
    if (JSON.stringify(a) !== JSON.stringify(b)) {
        throw new Error(`${JSON.stringify(a)} !== ${JSON.stringify(b)}`);
    }
}

export const assert_is_object = obj => {
    const isObject = obj !== null && typeof obj === 'object' && !Array.isArray(obj);
    if (!isObject) {
        throw new Error(`Expected object: ${obj}`);
    }
}

export const assert_is_num = obj => {
    if (typeof obj !== 'number') {
        throw new Error(`Expected number, got ${typeof obj}`);
    }
}

export const Span = ({sx={} as SxProps, children=<></> as any}) => <Box component='span' sx={sx}>{children}</Box>;

const color = {
    backgroundColor: 'white',
    '&:hover': {
        backgroundColor: 'white'
    },
};

export const XButton = ({
    id,
    url=null as string | null,
    sx={} as SxProps,
    onClick=undefined,
    disabled=false,
    blank=false,
    attrs={},
    noStyle=false,
    disabledSx={} as SxProps,
    children,
}) => {
    const navigate = useNavigate();

    const noStyleSx = {
        border: `inherit`,
        color: `inherit`,
        textTransform: `none`,
        p: 0, px: 0, py: 0,
        "&:hover": {
            border: `inherit`,
            filter: `brightness(70%)`,
        },
        '&.Mui-disabled': {
            color: `white`,
            filter: `brightness(70%)`,
            textDecoration: `underline`,
            ...disabledSx,
        },
    };

    const buttonSx: SxProps = {
        // color: `orangered`,
        // backgroundColor: `yellow`,
        // boxShadow: `8px 5px 1px 0px rgb(101 75 0)`,
        // outline: `solid 1px orangered`,
        // fontSize: `1.2em`,
        // py: {xs: 0.6, sm: 1},
        // px: {xs: 0.8, sm: 1.2},
        // "&:hover": {
        //     backgroundColor: `yellow`,
        // }
    };

    let href = undefined;
    if (id && !(id as string).endsWith('-button')) {
        id += '-button';
    }
    if (url) assert(!onClick);
    if (url && (url.startsWith('https://') || blank)) {
        onClick = e => {
            e.preventDefault();
            window.open(url, '_blank');
        };
        href = url;
    }
    if (url && (url.startsWith('/') && !blank)) {
        onClick = e => {
            e.preventDefault();
            navigate(url);
        };
        href = url;
    }
    sx = {...(noStyle ? noStyleSx : buttonSx), ...sx};
    return <Button {...attrs} disabled={disabled} id={id} sx={sx} variant='outlined' href={href} onClick={onClick}>
        {children}
    </Button>;
}

export const ChinawinLogo = ({size=1.5 as 1.5 | 2 | 3, sx={} as SxProps, only_in='zh', exclusive=false}) => {
    const [ WebsiteFlags, setWebsiteFlags ] = useGlobalState(`WebsiteFlags`);
    return <Box sx={{
        display: (!only_in || (only_in && WebsiteFlags.local == only_in)) ? `flex` : `none`,
        flexDirection: `column`,
        alignItems: `flex-start`,
        fontWeight: `normal`,
        gap: 0.5,
        ...sx,
    }}>
        {exclusive && <Typography component='span' sx={{
            fontFamily: '"Noto Sans", sans-serif',
            fontSize: `${size * 0.6}rem`,

        }}>本文件专供</Typography>}
        <Box component='span' sx={{
            fontSize: `${size * 0.7}rem`,
            color: `royalblue`,
            fontFamily: '"Noto Sans", sans-serif',
            userSelect: `none`,
            textTransform: `none`,
            display: `block`,
            px: 1, py: 0.5, border: `solid 1px royalblue`, borderRadius: `4px`,
        }}>
            Chinawin International
        </Box>
    </Box>
}

export const Logo = ({size='s' as 's' | 'm' | 'l'}) => {
    const [ WebsiteFlags, setWebsiteFlags ] = useGlobalState(`WebsiteFlags`);
    const text = {
        en: [`Oberon`, `Research`],
        zh: [`奥布朗`, `研究`],
    };
    console.log(`lang:`, WebsiteFlags.local)
    const _size = size == 's' ? 1.5 : size == 'm' ? 2 : 3;
    return <Typography component='h1' sx={{
        fontSize: `${_size}rem`,
        color: `green`,
        display: `flex`,
        alignItems: `flex-start`,
        gap: 0.5,
        fontFamily: '"Noto Sans", sans-serif',
        userSelect: `none`,
        flexDirection: `column`,
    }}>
        <Box component='span' sx={{
            fontSize: `${_size}rem`,
            color: `green`,
            display: `flex`,
            alignItems: `flex-start`,
            gap: 0.5,
            fontFamily: '"Noto Sans", sans-serif',
            userSelect: `none`,
        }}>
            <Box sx={{
                background: `url(${Logo_Img}) center center/cover no-repeat local`,
                width: `${_size}em`,
                height: `${_size}em`,
                userSelect: `none`,
            }} />
            <Span sx={{fontWeight: 800}}>
                {text[WebsiteFlags.local][0]}
            </Span>
            <Span sx={{fontWeight: 300}}>
                {text[WebsiteFlags.local][1]}
            </Span>
        </Box>
    </Typography>
}

export const FormattedDate = ({pre=undefined, timestamp=undefined}) => {
    const [ WebsiteFlags ] = useGlobalState(`WebsiteFlags`);
    const generater = {
        'zh': () => {
            const daysOfWeek = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];
            const today = timestamp ? new Date(timestamp * 1000) : new Date();
            const year = today.getFullYear();
            const month = today.getMonth() + 1;
            const day = today.getDate();
            const dayOfWeek = daysOfWeek[today.getDay()];
            return `${year}年${month}月${day}日，${dayOfWeek}`;
        },
        'en': () => {
            const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const today = timestamp ? new Date(timestamp * 1000) : new Date();
            const dayOfWeek = daysOfWeek[today.getDay()];
            const day = today.getDate();
            const month = months[today.getMonth()];
            const year = today.getFullYear();
            return `${dayOfWeek} ${day} ${month}, ${year}`;
        }
    };
    return <Box component='span' sx={{display: `flex`, gap: 1}}>
        {pre && <Box component='span' sx={{display: `block`}}>{`${pre[WebsiteFlags.local]}:`}</Box>}
        {generater[WebsiteFlags.local]()}
    </Box>;
}

export const FormattedURL = () => {
    const { hostname, pathname } = window.location;
    const formattedHostname = hostname.replace(/^www\./, '');
    const formattedUrl = `${formattedHostname}${pathname}`;
    return <Fragment>{formattedUrl}</Fragment>;
};

const langs = [['中', 'zh'], ['En', 'en']];
const LangIcon = ({i}) => <Box component='span' sx={{ fontSize: '3ch', fontWeight: 'bold' }}>{i}</Box>;
export const _Logo = () => {
    const [ WebsiteFlags, setWebsiteFlags ] = useGlobalState(`WebsiteFlags`);
    const [ nextLangI, setNextLangI ] = useState(WebsiteFlags.local == 'en' ? 0 : 1);
    const navigate = useNavigate();
    const onLangChange = e => {
        e.preventDefault();
        const tmpNextLangI = (nextLangI + 1) % langs.length;
        const nextLang = langs[nextLangI][1] as any;
        console.log('nextLang', nextLang);
        setWebsiteFlags(f => ({...f, local: nextLang}));
        setNextLangI(tmpNextLangI);
    };
    const onHome = e => { e.preventDefault(); navigate(`/`); };
    return <Box sx={{
        display: `flex`,
        flexBasis: `column`,
        width: `100%`,
        overflow: `hidden`,
        '@media print': {
            display: `none`,
        },    
    }}>
        <Button onClick={onLangChange} sx={{
        }}>
            {langs[nextLangI][0]}
        </Button>
        <Button href='/' onClick={onHome} disableRipple sx={{
            display: `flex`,
            alignItems: `center`,
            justifyContent: `center`,
            p: 1,
            '&:hover': {
                backgroundColor: `white`,
            }
        }}>
            <Box sx={{display: {xs: `none`, sm: `block`}}}><Logo size='m' /></Box>
            <Box sx={{display: {xs: `block`, sm: `none`}}}><Logo size='s' /></Box>
        </Button>
    </Box>
};

export const useDialog = (
    title: string,
    onAgree: any,
    content?: string,
) => {
    const [open, setOpen] = useState(false);
  
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
  
    return [
        () => setOpen(true),
        <Fragment>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            {content && <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
            </DialogContent>}
            <DialogActions>
                <Button onClick={handleClose}>Disagree</Button>
                <Button onClick={handleClose} autoFocus>
                Agree
                </Button>
            </DialogActions>
            </Dialog>
        </Fragment>,
    ];
}

export const genClipPath = (numbers: number[], solidLine=false, _max=3.8, _min=1) => {
    const ret: [number, number][] = solidLine ? [] : [[100, 100], [0, 100]];
    const step = 100 / (numbers.length - 1);

    numbers.forEach((num, i) => {
        const pct = ((num - _min) / (_max - _min)) * (100);
        ret.push([(step * i), (100 - pct)]);
    });
    if (solidLine) {
        ret.slice().reverse().forEach(([a, b]) => ret.push([a, b - 2]));
    }
    return ret.map(([a, b]) => `${a.toFixed(1)}% ${b.toFixed(1)}%`).join(', ');
};

export const PieChart = ({
    subtitle=undefined,
    sx={} as SxProps,
    allocations=[] as [ string, number, number, number, string ][] | any,
}) => {
    const [ WebsiteFlags ] = useGlobalState(`WebsiteFlags`);
    const total = allocations.reduce((acc, curr) => acc + curr[1], 0);
    console.log(total)
    return <Box component='figure' sx={{
        height:        `min(60svw, 400px)`,
        width:         `min(60svw, 400px)`,
        display:       `flex`,
        flexDirection: `column`,
        alignItems:    `center`,
        position:      `relative`,
        overflow:      'visible',
        p: 4, m: 0, boxSizing: `border-box`,
        gap: 2,
        ...sx,
    }}>
        <Box sx={{
            height:   `100%`,
            width:    `100%`,
            position: `relative`,
            overflow: 'visible',
        }}>
            <_PieChart style={{overflow: 'visible',}} segmentsShift={() => 1} data={
                allocations.map(([title, value, top, left, color]) => ({title: title[WebsiteFlags.local], value: value, color: color})) as any
            } />
            {allocations.map(([title, value, top, left, color], i) => <Typography key={i} sx={{
                    position: `absolute`,
                    top: `${top}%`, left: `${left}%`,
                    borderRadius: `3px`,
                    background: `white`,
                    py: 0.2, px: 0.4,
                    border: `solid 2px ${color}`,
                    whiteSpace: `nowrap`,
                }}>
                    <Span sx={{fontWeight: `bold`, mr: 0.5,}}>{title[WebsiteFlags.local]}:</Span><Span>{((value / total) * 100).toFixed(0)}%</Span>
                </Typography>)}
        </Box>
        {subtitle && <Typography component='figcaption' sx={{fontSize: `0.9rem`, fontWeight: `bold`}}>{subtitle[WebsiteFlags.local]}</Typography>}
    </Box>;
}

export const PieTableChart = ({
    subtitle=undefined,
    sx={} as SxProps,
    allocations=[] as [ number, string, number | null, {en: string, zh: string} ][],
}) => {
    const [ WebsiteFlags ] = useGlobalState(`WebsiteFlags`);
    const total = allocations.reduce((acc, curr) => acc + (curr[1] as any), 0);

    const colorDict = allocations.reduce((acc, [_1, _2, color, languagePair]) => {
        acc[languagePair.en] = color;
        acc[languagePair.zh] = color;
        return acc;
    }, {});

    const getProfit = profit => {
        const [ fg, bg, txt ] =
            profit == null ? ['black', 'white', ''] :
            profit >   0   ? ['white', `#8bc34a`, `+${profit}%`] :
                             ['white', `maroon`, `${profit}%`];

        return <Typography sx={{
            display: `flex`,
            alignItems: `center`,
            textAlign: `center`,
            // background: bg,
            border: `solid 2px ${bg}`,
            borderRadius: `3px`,
            // color: fg,
            px: 1,
            ml: 1,
            py: 0.5,
            fontFamily: PARAGRAPH,
            justifyContent: `flex-end`,
        }}>{txt}</Typography>
    };

    return <Box component='figure' sx={{
        width:         `90%`,
        display:       `grid`,
        gridTemplateColumns: {xs: `1fr`, sm: '.7fr 1.3fr'},
        gridTemplateRows: {xs: `repeat(3, auto)`, sm: 'repeat(2, auto)'},
        alignItems:    `center`,
        position:      `relative`,
        overflow:      'visible',
        justifyContent: `space-evenly`,
        p: 2, m: 0, boxSizing: `border-box`,
        gap: 2,
        ...sx,
    }}>
        <Box sx={{
            height:   `min(50svw, 200px)`,
            width:    `min(60svw, 200px)`,
            position: `relative`,
            overflow: 'visible',
        }}>
            <_PieChart
            style={{overflow: 'visible',}}
            segmentsShift={() => 2}
            data={allocations.map(([equity, color, totalReturn, title]) => ({title: title[WebsiteFlags.local], value: equity, color: color})) as any} />
        </Box>
        <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(4, auto)',
            gridTemplateRows: `repeat(${allocations.length + 1}, auto)`,
            gap: 0.5,
        }}>
            <Box display='contents'>
                <Box  />
                <Typography />
                <Typography />
                <Typography sx={{
                    fontSize: `1rem`,
                    textAlign: `center`,
                    ml: 1,
                    display: `flex`,
                    alignItems: `flex-end`,
                    justifyContent: `center`,
                    whiteSpace: `nowrap`,
                }}>{{en: `Total Gain`, zh: '总收益'}[WebsiteFlags.local]}</Typography>
            </Box>
            {allocations.map(([equity, color, totalReturn, title], i) => <Box key={i} display='contents'>
                <Box sx={{width: `3ch`, height: `3ch`, background: color, m: 0.5}} />
                <Typography sx={{
                    display: `flex`,
                    alignItems: `center`,
                    fontFamily: PARAGRAPH,
                }}>{title[WebsiteFlags.local]}</Typography>
                <Typography sx={{
                    display: `flex`,
                    alignItems: `center`,
                    fontFamily: PARAGRAPH,
                    justifyContent: `flex-end`,
                }}>{`${equity}%`}</Typography>
                {getProfit(totalReturn)}
            </Box>)}
        </Box>
        <Box sx={{
            gridColumn: {sm: `span 2`},
        }}>
            {/* {subtitle && subtitle[WebsiteFlags.local].trim().split(/\. |。/).map((t, i) => <Typography key={i} sx={{fontSize: `1rem`, textAlign: `center`}}>{t}</Typography>)} */}
            {subtitle && <Typography sx={{fontSize: `1rem`, textAlign: `center`}}>{subtitle[WebsiteFlags.local].trim()}</Typography>}
            </Box>
    </Box>;
}

export const Footer = ({sx={} as SxProps, neverOnPrint=false, idx=null, onlyOnPrint=false, confidential=false}) => <Typography variant="subtitle1" component="footer" sx={{
    p: 3,
    textAlign: `center`,
    ...(onlyOnPrint ? {mt: 'auto', p: 0.3, pb: 0.5, display: 'none', '@media print': {display: 'block'}} : {}),
    ...(neverOnPrint ? {'@media print': {display: 'none'}} : {}),
    borderTop: `solid 1px #9e9e9e3d`,
    position: `relative`,
    lineHeight: 1.2,
    fontSize: `0.9rem`,
    ...sx,
}}>
    {confidential && <Span>
        <Translated en='Confidential - Do Not Disseminate' zh='机密 - 请勿传播'/>
    </Span>}
    <Span sx={{display: `block`}}>
        © 2024 Oberon Research & Investment, LLC
    </Span>
    <Span sx={{display: `block`, maxWidth: `100%`, mx: `auto`}}>
        We recommend you obtain financial advice specific to your situation before making any investment decision.
    </Span>
    {idx && <Typography sx={{position: `absolute`, right: 10, top: 10}}>{`Page ${idx}`}</Typography>}
</Typography>;

export const PAGE_HEADER = ({center=false, updated=undefined as number}) => <Box component='header' sx={{
    display: `none`,
    justifyContent: `space-between`,
    alignItems: `center`,
    width: `100%`,
    pb: 1, mt: 1, mb: center ? 'auto' : 1,
    borderBottom: `solid 1px #9e9e9e3d`,
    flexWrap: 'wrap',
    gap: 1,
    '@media print': {
        display: `flex`,
    },
}}>
    <Box sx={{
        width: '100%',
        display: `flex`,
        justifyContent: `space-between`,
    }}>
        {updated && <Typography><FormattedDate pre={{en: 'Updated', zh: '更新'}} timestamp={updated} /></Typography>}
        <Typography><FormattedDate pre={{en: 'Printed', zh: '印刷'}} /></Typography>
        <Typography>{'oberonresearch.com'}</Typography>
    </Box>
    <Logo />
    <ChinawinLogo exclusive/>
</Box>;

export const PAGE_FOOTER = ({center=false}) => <Box component='footer' sx={{
    display: `none`,
    mt: center ? 'auto' : 1,
    '@media print': {
        display: `flex`,
    },
}}>
    <Box sx={{
        width: '100%',
        display: `flex`,
        p: 1,
        justifyContent: `center`,
        borderTop: `solid 1px lightgrey`,
    }}>
        <Typography><Translated en='Confidential - Do Not Disseminate' zh='机密 - 请勿传播'/></Typography>

    </Box>
</Box>;

export const PAGE = ({sx={} as SxProps, center=false, children}) => <Box component='section' sx={{
    width: `100%`,
    height: `100%`,
    display: `flex`,
    flexDirection: `column`,
    alignItems: `center`,
    justifyContent: `center`,
    '@media print': {
        pageBreakAfter: 'always',
        minHeight: `90svh`,
        overflow: 'hidden',
    },
    gap: 1,
    ...sx,
}}>
    <PAGE_HEADER center={center} />
    {children}
    <Box component='footer' sx={{mt: `auto`,}}>
    </Box>
</Box>;

export const Translated = ({en, zh, img=false, sx={} as SxProps}) => {
    const [ WebsiteFlags ] = useGlobalState(`WebsiteFlags`);
    return img ? <Box component='img' src={WebsiteFlags.local == 'en' ? en : zh} sx={sx} />
        : (<Fragment>{WebsiteFlags.local == 'en' ? en : zh}</Fragment>);
};

export const toGridArea = (x: number, y: number, xl=1, yl=1) => `${y} / ${x} / ${y + yl} / ${x + xl}`;
assert_eq(toGridArea(3, 1), `1 / 3 / 2 / 4`);
assert_eq(toGridArea(2, 2, 2, 2), `2 / 2 / 4 / 4`);

export const FENG = `polygon(45%  0,
                             55%  0,
                             55%  15%,
                             100% 15%,
                             100% 28%,
                             55%  28%,
                             55%  40%,
                             90%  40%,
                             90%  52%,
                             55%  52%,
                             55%  65%,
                             100% 65%,
                             100% 78%,
                             55%  78%,
                             55%  100%,
                             45%  100%,
                             45%  78%,
                             0    78%,
                             0    65%,
                             45%  65%,
                             45%  52%,
                             10%  52%,
                             10%  40%,
                             45%  40%,
                             45%  28%,
                             0    28%,
                             0    15%,
                             45%  15%)`;

