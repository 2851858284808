import { Box, SxProps, Typography } from "@mui/material";
import { PieChart, PieTableChart, Translated } from "./Theme";
import SPXFED from './SPX_FED.png';
import { CHI_CRE_EN, CHI_CRE_ZH, CRE_Firesale, DEFICIT_EN, DEFICIT_ZH, DG_EN, DG_ZH, ELECTION, US_BET_EN, US_BET_ZH } from "./images/Images";

const LONG_US_TECH  = { en:'Misc. US Tech', zh:'杂项美国科技' };
const NASDAQ        = { en:'NASDAQ', zh:'NASDAQ（美国科技股）' };
const CASH          = { en:'Cash', zh:'现金' };
const BTC           = { en:'BitCoin', zh:'比特币' };
const NVIDIA        = { en:'Nvidia', zh:'Nvidia' };
const SHORT_US_TECH = { en:'Short US Tech', zh:'做空美国科技股' };
const US_TAI_SEMI   = { en:'US/Taiwan Semiconductors', zh:'美国/台湾半导体' };

const figure_sx: SxProps = {
    display: `flex`, alignItems: `center`, gap: 1, m: 1, justifyContent: `center`,
};

/*
We considered going short NVDA however we decided against it as there is too much soverign interest in keeping a high NVDA price.
We made a small position in a confidential symbol. This symbol is treated as a Trade Secret at this time and will not be disclosed.
We purchased Gold as there is strong demand from central banks and non-soverieigns.
We purchased AAPL as their intelligence features look fantastic and we are excited for their upcoming products.
We are looking at Qualcomm and MSFT.
People always know in advance. The BLS distributed economic data to banks in advance. NVDA dumping occured before the supeona was issued likely because people knew in advance. NVIDIA accounting issues.

North Korea is trying to steal BitCoin from ETF providers

We considered a Dollar Tree investment however it's outside our core competencies. Same with increasing GOLD allocations. We prefer to make small numbers of big moves.

RUMOR: Microsft has struggled to keep bunsiess office users subscribed to AI features.
Intel is doing poorly and getting worse.
NVDA VP of AI moved to AMD.

OpenAI is designing their own AI chip via TSMC according to a Taiwan report
*/

export default [
    {
        deficit: <Box component='figure' sx={figure_sx}>
            <Translated en={DEFICIT_EN} zh={DEFICIT_ZH} img sx={{
                height: `min(50svh, 400px)`, width: `auto`,
            }} />
        </Box>,
        dg: <Box component='figure' sx={figure_sx}>
            <Translated en={DG_EN} zh={DG_ZH} img sx={{
                height: `min(50svh, 220px)`, width: `auto`,
            }} />
        </Box>,
        election: <Box component='figure' sx={figure_sx}>
            <Translated en={US_BET_EN} zh={US_BET_ZH} img sx={{
                height: `min(50svh, 300px)`, width: `auto`,
            }} />
            <Translated en={ELECTION} zh={ELECTION} img sx={{
                height: `min(50svh, 300px)`, width: `auto`,
            }} />
        </Box>,
        cre_firesale: <Box component='figure' sx={figure_sx}>
            <Translated en={CRE_Firesale} zh={CRE_Firesale} img sx={{
                height: `min(50svh, 300px)`, width: `auto`,
            }} />
            <Translated en={CHI_CRE_EN} zh={CHI_CRE_ZH} img sx={{
                height: `min(50svh, 300px)`, width: `auto`,
            }} />
        </Box>,
        image1: <Box component='figure' sx={{mx: 'auto', my: 0, p: 1, boxSizing: `border-box`, display: `flex`, flexDirection: `column`, alignItems: `center`, width: `90%`}}>
        <Box component='img' src={SPXFED} sx={{
            width: '100%', height: 'auto', m: 0, p: 0,
        }} />
        <Typography component='figcaption' sx={{display: `flex`, gap: 1, alignItems: `center`, flexWrap: `wrap`, justifyContent: `center`}}>
            <Typography sx={{flexGrow: 1, width: `100%`, textAlign: `center`}}> <Translated en='Falling Rate Usually Results in Falling S&P 500' zh='利率下降通常会导致标准普尔 500 指数下跌' /></Typography>
            <Box sx={{display: `flex`, gap: 1, alignItems: `center`,}}><Box sx={{width: `1ch`, height: `1ch`, background: `gold`,}}   /> <Translated en='Federal Fund Rate' zh='联邦基金利率' /></Box>
            <Box sx={{display: `flex`, gap: 1, alignItems: `center`,}}><Box sx={{width: `1ch`, height: `1ch`, background: `blue`,}}   /> <Translated en='S&P 500'           zh='标准普尔 500' /></Box>
            <Box sx={{display: `flex`, gap: 1, alignItems: `center`,}}><Box sx={{width: `1ch`, height: `1ch`, background: `purple`,}} /> <Translated en='Falling Rates'     zh='利率下降' /></Box>
        </Typography>
    </Box>,
    allocation: <PieTableChart subtitle={{
            en: 'Average client allocations. Allocations are derivatives of their asset class. Total Return is the average of clients. Does not guarantee total return or future performance.',
            zh: '平均客户分配。分配是其资产类别的衍生品。近期收益会因客户而异。且不保证总收益或未来表现。',
        }} allocations={[
            [ 48.4, 'darkred',      11.9, LONG_US_TECH  ],
            [ 6,    'skyblue',      null, CASH          ],
            [ 11.8, 'darkseagreen', 13.1, BTC           ],
            [ 14.4, 'dimgrey',      0.5,  SHORT_US_TECH ],
            [ 9.2,  'gold',         51.2, NVIDIA        ],
            [ 10.2, 'limegreen',    12.9, US_TAI_SEMI   ],
        ]} sx={{
            mx: `auto`,
            borderTop: `solid 1px grey`, borderBottom: `solid 1px grey`, my: 3,
        }}/>,
        en: `
            <date 'Tue Sep 3, 2024'>

            **Average Client Asset Allocation**
            We maintain a mixed allocation between Long, Short, and Cash positions. Individual clients will have different allocations. Allocations are in most cases a derivative.

            <allocation>

            **Long Positions incl. BitCoin**
            We are planning to reduce exposure in several long positions, including Bitcoin and major U.S. technology companies. We anticipate divesting the majority of our Nvidia holdings due to the cyclical nature of Nvidia.

            **Short Positions**
            As part of ongoing research and development, we are investigating a new class of Short derivative. However, this research remains confidential and is not available to clients at this time.

            **New Clients**
            New clients will temporarily maintain a majority cash position, as we tend to capitalize on volatility for higher returns, and the current environment lacks significant volatility. We anticipate increased volatility over September.

            <page />

            **News Events**

            _Who will win the US election?_
            * Polling: Harris leads Trump by 1.8 percentage points (RealClearPolitics).
            * Nate Silver (538): Trump has a slightly higher chance at Electoral victory.
            * Betting markets: Trump leads slightly.
            * Our view: a Trump victory has a higher chance than a Harris victory.

            <election>

            _Apple versus Nvidia_
            Nvidia derives 50% of its revenue from just five (suspected) customers: Microsoft, Tesla, Amazon, Google, and Facebook. This is concerning because these companies are known to be developing competing chips to Nvidia's, and Apple very publicly refuses to use Nvidia as a supplier and instead uses Google's competitor TPU chip for AI.

            <page />

            **News Events (Continued)**

            _Bottom End US Comsumer_
            Dollar General is the largest retailer targeting the lowest income US consumer. Its stock price has fallen roughly 70% this year which is a shocking reflection of the purchasing power of the most impoverished among US consumers.

            <dg>

            _Commercial Real Estate (CRE)_
            * Situation: Commercial Real Estate in the mid-range sector is performing terribly.
            * US Banks are known to hold large holdings of CRE.
            <cre_firesale>

            <page />

            **Risks**
            Risks on our radar include:

            _USD Depreciation_: The recent 3% decline in the US dollar (DXY Index) is likely driven by expectations of falling US interest rates. While this may cause short-term volatility and lower equity values, it is expected to boost equity values in the medium term. We are monitoring this situation with concern.

            _September Interest Rate Cut_: history demonstrates time and time again that once interest rates start falling, equity prices soon follow. The Federal Reserve is expected to cut rates in September.

            <image1>

            <page />

            **Risks (Continued)**

            _US Sovereign Default_: unprecedented levels of Federal spending is an increasing risk. We see a high chance of a US Sovereign Debt Crisis within the next US Administration. The US deficit as a percent of GDP has never in history been so large, outside of COVID, GFC, and WW2.

            <deficit>

            **Final Word**
            We've seen fantastic recent returns in US tech and Bitcoin, but rising financial risks due to excess leverage are a concern. Nvidia's upcoming earnings will be pivotal, setting the tone for the remainder of the year. Despite these risks, next year is anticipated to deliver very strong returns.
        `,
        zh: `<date '2024年9月3日，星期二'>

            **平均客户资产配置**
            我们维持在多头、空头和现金头寸之间的混合配置。不同客户会有不同的配置。在大多数情况下，配置是衍生品。

            <allocation>

Short
US Tech
            **多头头寸包括比特币**
            我们计划减少多个多头头寸的敞口，包括比特币和主要的美国科技公司。由于 Nvidia 的周期性特征，我们预计将出售大部分的 Nvidia 持股。

            **空头头寸**
            作为持续研究和开发的一部分，我们正在研究一种新型的空头衍生工具。然而，这项研究目前仍是机密，尚未对客户开放。

            **新客户**
            新客户将暂时保持大部分现金头寸，因为我们倾向于利用波动性来获得更高回报，而当前环境缺乏显著的波动性。我们预计9月份的波动性将会增加。

            <page />

            **新闻事件**

            _谁将赢得美国大选？_

            * 民调：哈里斯领先特朗普1.8个百分点（RealClearPolitics）。
            * 内特·西尔弗（538）：特朗普在选举胜利的几率上略高。
            * 博彩市场：特朗普略占优势。
            * 我们的观点：特朗普胜选的可能性高于哈里斯。

            <election>


            _苹果对 Nvidia_
            Nvidia 50%的收入来自五个（据推测）客户：微软、特斯拉、亚马逊、谷歌和Facebook。这令人担忧，因为这些公司正在开发与Nvidia竞争的芯片，而且苹果公开拒绝将Nvidia作为供应商，而是使用谷歌竞争对手TPU芯片进行人工智能。

            <page />

            **新闻事件（续）**

            _美国低端消费者_
            Dollar General是美国针对最低收入消费者的最大零售商。其股价今年已下跌约70%，这令人震惊地反映了美国最贫困消费者的购买力。

            <dg>

            _商业地产（CRE）_

            * 情况：中档商业地产表现非常糟糕。
            * 众所周知，美国银行持有大量商业地产。

            <cre_firesale>

            <page />

            **风险**
            我们关注的风险包括：

            _美元贬值_：最近美元（DXY指数）下跌3%，可能是由于市场预期美国利率下降所致。这可能导致短期波动和股市价值下降，但中期内有望提振股市价值。我们正密切关注这一情况。

            _9月利率下调_：历史一再证明，一旦利率开始下降，股价很快就会跟随。美联储预计将在9月降息。

            <image1>

            <page />

            **风险（续）**

            _美国主权违约_：前所未有的联邦支出水平正在增加风险。我们认为下一届美国政府期间发生主权债务危机的可能性很高。除了COVID、全球金融危机（GFC）和二战，美国赤字占GDP的比例从未如此之高。

            <deficit>

            **总结**
            我们在美国科技股和比特币上看到了惊人的近期回报，但由于过度杠杆而带来的金融风险令人担忧。Nvidia即将公布的收益将是关键，这将决定今年剩余时间的基调。尽管存在这些风险，预计明年将带来非常强劲的回报。
        `,
    },
    {
        cre_firesale: <Box component='figure' sx={{mx: 'auto', my: 0, p: 1, boxSizing: `border-box`, display: `flex`, flexDirection: `column`, gap: 1, alignItems: `center`}}>
            <Box component='img' src={CRE_Firesale} sx={{width: 'min(350px, 80svw)', height: 'auto', m: 0, p: 0,}} />
            <Typography component='figcaption' sx={{display: `flex`, gap: 1, alignItems: `center`, flexWrap: `wrap`, justifyContent: `center`}}>
                <Translated en='One example of a recent mid-range CRE firesale' zh='近期中档商业地产甩卖的一个例子' />
            </Typography>
        </Box>,
        image1: <Box component='figure' sx={{mx: 'auto', my: 0, p: 1, boxSizing: `border-box`, display: `flex`, flexDirection: `column`, alignItems: `center`, width: `90%`}}>
        <Box component='img' src={SPXFED} sx={{
            width: '100%', height: 'auto', m: 0, p: 0,
        }} />
        <Typography component='figcaption' sx={{display: `flex`, gap: 1, alignItems: `center`, flexWrap: `wrap`, justifyContent: `center`}}>
            <Typography sx={{flexGrow: 1, width: `100%`, textAlign: `center`}}> <Translated en='Falling Rate Usually Results in Falling S&P 500' zh='利率下降通常会导致标准普尔 500 指数下跌' /></Typography>
            <Box sx={{display: `flex`, gap: 1, alignItems: `center`,}}><Box sx={{width: `1ch`, height: `1ch`, background: `gold`,}}   /> <Translated en='Federal Fund Rate' zh='联邦基金利率' /></Box>
            <Box sx={{display: `flex`, gap: 1, alignItems: `center`,}}><Box sx={{width: `1ch`, height: `1ch`, background: `blue`,}}   /> <Translated en='S&P 500'           zh='标准普尔 500' /></Box>
            <Box sx={{display: `flex`, gap: 1, alignItems: `center`,}}><Box sx={{width: `1ch`, height: `1ch`, background: `purple`,}} /> <Translated en='Falling Rates'     zh='利率下降' /></Box>
        </Typography>
    </Box>,
    allocation: <PieTableChart subtitle={{en: 'Average client allocations. Allocations are derivatives of their asset class. Recent gain will differ between clients. Does not guarantee total return or future performance.', zh: '平均客户分配。分配是其资产类别的衍生品。近期收益会因客户而异。且不保证总收益或未来表现。'}} allocations={[
            [ 42, 'darkred',      17, LONG_US_TECH  ],
            [ 15, 'skyblue',    null, CASH          ],
            [ 12, 'darkseagreen', 35, BTC           ],
            [ 12, 'dimgrey',      -3, SHORT_US_TECH ],
            [  9, 'gold',         79, NVIDIA        ],
            [  9, 'limegreen',    17, US_TAI_SEMI   ],
        ]} sx={{
            mx: `auto`,
            borderTop: `solid 1px grey`, borderBottom: `solid 1px grey`, my: 3,
        }}/>,
        en: `
            <date 'Tue Aug 27, 2024'>

            **Average Client Asset Allocation**
            We maintain a mixed allocation between Long, Short, and Cash positions. This is a reflection of the upward and downward risks facing equity prices. Individual clients will have different allocations based on their circumstances. Allocations are in most cases a derivative and may not reflect a true exposure of that asset class.

            <allocation>

            <page />

            **Long Positions incl. BitCoin**
            Our current Long Position has significantly outperformed; however, we are concerned about increasing volatility. Our BitCoin holdings have made strong returns, and we will continue to buy as prices fall.

            **Short Positions**
            We recently acquired a minor short position as a hedge against potential volatility arising from federal interest rate increases. While this position is not anticipated to generate significant performance, it was initiated as a precautionary measure for risk management purposes. We may consider closing this position in the near future.

            **Cash**
            The strong performance of our Long Tech and BitCoin positions has reduced our cash as a percent of total assets.

            <page />

            **News Events**

            _Who will win the US election?_
            * Polling: Harris leads Trump by 1.5 percentage points (RealClearPolitics).
            * Unexpected Events: RFK Jr. has endorsed Donald Trump. RFK Jr. is highly popular with dissatisfied Americans.
            * Our view: a Trump victory has a slightly higher chance than a Harris victory.

            _Commercial Real Estate (CRE)_
            * Situation: Commercial Real Estate in the mid-range sector is performing terribly.
            * Banks are known to hold large holdings of CRE.
            * Wells Fargo recently sold their entire CRE unit, and there are many examples of CRE fire sales for far below levels to service debt.
            * Move is reminiscent of 2008 Crisis as Lehman sold their CRE position.

            <cre_firesale>

            <page />

            **Risks**
            Risks on our radar include:

            _USD Depreciation_: The recent 3% decline in the US dollar (DXY Index) is likely driven by expectations of falling US interest rates. While this may cause short-term volatility and lower equity values, it is expected to boost equity values in the medium term. We are monitoring this situation with concern.

            _US Political Instability_: Worsening US political instability typically leads to higher equity values, as governments often respond with increased spending, which in turn boosts asset values in dollar terms.

            _September Interest Rate Cut_: history demonstrates time and time again that once interest rates start falling, equity prices soon follow. The Federal Reserve is expected to cut rates in September.

            <image1>

            <page />

            **Risks (Continued)**

            _Increasing Unemployment_: August is expected to have the highest layoff count for white collar positions of this year. US unemployment is rising to concerning levels.

            _Hidden Leverage_: there are many obligations dependent on high asset prices, and should asset prices decrease, that may cause a compounding sale effect.

            _US Sovereign Default_: unprecedented levels of Federal spending is an increasing risk. We see a high chance of a US Sovereign Debt Crisis within the next US Administration.

            **Final Word**
            We've seen fantastic recent returns in US tech and Bitcoin, but rising financial risks due to excess leverage are a concern. Nvidia's upcoming earnings will be pivotal, setting the tone for the remainder of the year. Despite these risks, next year is anticipated to deliver very strong returns.
        `,
        zh: `
            <date '2024年8月27日，星期二'>

            **客户资产平均配置**
            我们在长仓、短仓和现金头寸之间保持混合配置。这反映了股价面临的上行和下行风险。根据个人情况，不同客户的配置会有所不同。大多数情况下，这些配置是一种衍生物，可能并不反映该资产类别的真实风险敞口。

            <allocation>

            <page />

            **长仓（包括比特币）**
            我们目前的长仓表现显著优于预期；然而，我们对日益增加的波动性感到担忧。我们的比特币持仓取得了强劲的回报，随着价格的下跌，我们将继续买入。

            **短仓**
            我们最近为对冲可能因联邦利率上升而产生的波动性，获得了一小部分短仓头寸。虽然预计该头寸不会产生显著收益，但它作为风险管理的预防措施而建立。我们可能会在不久的将来考虑关闭这一头寸。

            **现金**
            我们的长仓科技和比特币的强劲表现，已减少了现金占总资产的比例。

            <page />

            **新闻事件**

            _谁将赢得美国大选？_
            * 民调：哈里斯领先特朗普1.5个百分点（RealClearPolitics）。
            * 意外事件：小罗伯特·肯尼迪已表态支持唐纳德·特朗普。小肯尼迪在不满的美国人中非常受欢迎。
            * 我们的看法：特朗普胜利的可能性略高于哈里斯胜利。

            _商业房地产 (CRE)_
            * 情况：中端市场的商业房地产表现极差。
            * 众所周知，银行持有大量商业房地产。
            * 富国银行最近出售了他们的整个商业房地产部门，有许多商业房地产大甩卖的例子，价格远低于偿债水平。
            * 这一举动让人想起2008年危机时雷曼兄弟出售其商业房地产头寸。

            <cre_firesale>

            <page />
            **风险**
            我们关注的风险包括：

            _美元贬值_：最近美元指数（DXY）下跌了3%，可能是由于市场预期美国利率将下降。虽然这可能会引起短期波动并降低股价，但预计中期内会推动股价上升。我们正密切关注这一情况。

            _美国政治不稳定_：美国政治不稳定加剧通常会导致股价上涨，因为政府往往会增加支出，从而推高以美元计价的资产价值。

            _九月降息_：历史一次又一次地证明，一旦利率开始下降，股价很快就会跟随。预计美联储将在九月降息。

            <image1>

            _失业率上升_：预计8月的白领职位裁员数量将是今年最高的。美国的失业率正在上升，达到令人担忧的水平。

            _隐性杠杆_：有许多依赖高资产价格的义务，如果资产价格下降，可能会导致复合销售效应。

            _美国主权违约_：前所未有的联邦支出水平构成了越来越大的风险。我们认为，在下届美国政府期间，美国主权债务危机的可能性很高。

            **最后的话**
            我们在美国科技和比特币方面获得了极好的近期回报，但由于过度杠杆化而导致的金融风险正在增加。Nvidia 即将公布的收益将是关键，决定今年余下时间的基调。尽管存在这些风险，预计明年将带来非常强劲的回报。
        `,
    },
    {
        image1: <Box component='figure' sx={{mx: 'auto', my: 0, p: 1, boxSizing: `border-box`, display: `flex`, flexDirection: `column`, alignItems: `center`, width: `90%`}}>
            <Box component='img' src={SPXFED} sx={{
                width: '100%', height: 'auto', m: 0, p: 0,
            }} />
            <Typography component='figcaption' sx={{display: `flex`, gap: 1, alignItems: `center`, flexWrap: `wrap`, justifyContent: `center`}}>
                <Typography sx={{flexGrow: 1, width: `100%`, textAlign: `center`}}> <Translated en='Falling Rate Usually Results in Falling S&P 500' zh='利率下降通常会导致标准普尔 500 指数下跌' /></Typography>
                <Box sx={{display: `flex`, gap: 1, alignItems: `center`,}}><Box sx={{width: `1ch`, height: `1ch`, background: `gold`,}}   /> <Translated en='Federal Fund Rate' zh='联邦基金利率' /></Box>
                <Box sx={{display: `flex`, gap: 1, alignItems: `center`,}}><Box sx={{width: `1ch`, height: `1ch`, background: `blue`,}}   /> <Translated en='S&P 500'           zh='标准普尔 500' /></Box>
                <Box sx={{display: `flex`, gap: 1, alignItems: `center`,}}><Box sx={{width: `1ch`, height: `1ch`, background: `purple`,}} /> <Translated en='Falling Rates'     zh='利率下降' /></Box>
            </Typography>
        </Box>,
        allocation: <PieTableChart subtitle={{en: 'Average client allocations. Please note that all allocations are derivatives of their base class.', zh: '平均客户端分配。请注意，所有分配都是其基类的派生。'}} allocations={[
            [43, 'skyblue',      null, {en:'Long US Tech',              zh:'做多美国科技股',  }],
            [17, 'darkseagreen', null, {en:'Cash',                      zh:'现金',            }],
            [11, 'gold',         null, {en:'BitCoin',                   zh:'比特币',          }],
            [10, 'limegreen',    null, {en:'Nvidia',                    zh:'Nvidia',          }],
            [10, 'dimgrey',      null, {en:'Short US Tech',             zh:'做空美国科技股',  }],
            [9,  'darkred',      null, {en:'US/Taiwan Semiconductors',  zh:'美国/台湾半导体', }],
        ]} sx={{
            mx: `auto`,
            borderTop: `solid 1px grey`, borderBottom: `solid 1px grey`, my: 3,
        }}/>,
        en: `
            <date 'Tue Aug 19, 2024'>

            **Average Client Asset Allocation**
            We maintain a mixed allocation between Long, Short, and Cash positions. This is a reflection of the upward and downward risks facing equity prices. Individual clients will have different allocations based on their circumstances. Allocations are in most cases a derivative and may not reflect a true exposure of that asset class.

            <allocation>

            **Long Positions incl. BitCoin**
            Our current Long Position has significantly outperformed; however, we are concerned about increasing volatility. We have ceased further purchases of Long Positions for the time being. Our BitCoin holdings have made strong returns, and we will continue to buy as prices fall.

            **Short Positions**
            We have pursued a small Short Position since the beginning of the collapse in the US Market. This position has made outsized returns. We have almost finished selling this position in client portfolios and expect to complete this sale this week.

            **Cash**
            The strong performance of our Long Tech and BitCoin positions has reduced our cash as a percent of total assets. However as equity prices are very high and risks are increasing, we feel investment opportunities will present themselves in the coming weeks and months.

            **Questions From Clients**

            _Who will win the US election?_
            * Betting Markets: Harris and Trump are tied at 49% - 49% (PredictIt).
            * Polling: Harris leads Trump by roughly 1 percentage point (RealClearPolitics).
            * Voter Concerns: Harris's price control proposals may turn off voters.
            * Our view: a Trump victory has a slightly higher chance than a Harris victory.

            _Is TikTok going to be banned in the US?_
            * Both US political parties are using TikTok to reach voters.
            * President Joe Biden signed a bill on April 24th that would ban TikTok, if the ByteDance (owner) doesn't sell TikTok within a year.
            * On May 7th, ByteDance sued the government over the potential ban, calling the law unconstitutional.
            * Our view: a sale to a US company is the most likely - possibly a company with a "close" relationship with ByteDance.

            **Risks**
            Risks on our radar include:

            _September Interest Rate Cut_: history demonstrates time and time again that once interest rates start falling, equity prices soon follow. The Federal Reserve is expected to cut rates in September.

            _Currency Volatility & Failed Brokerage Rumors_: USD/JPY currency volatility, the creation of a 'Financial Stress' Working Group between China and the US (reported by Bloomberg), along with rumors of a failed brokerage are concerning.

            <image1>

            _Increasing Unemployment_: August is expected to have the highest layoff count for white collar positions of this year. US unemployment is rising to concerning levels.

            _Hidden Leverage_: there are many obligations dependent on high asset prices, and should asset prices decrease, that may cause a compounding sale effect.

            _US Sovereign Default_: unprecedented levels of Federal spending is an increasing risk. We see a high chance of a US Sovereign Debt Crisis within the next US Administration.

            **Final Word**
            We've seen fantastic recent returns in US tech and Bitcoin, but rising financial risks due to excess leverage are a concern. Nvidia's upcoming earnings will be pivotal, setting the tone for the remainder of the year. Despite these risks, next year is anticipated to deliver very strong returns.
        `,
        zh: `
            <date '2024年8月13日，星期二'>

            **客户平均资产配置**
            我们保持在多头、空头和现金头寸之间的混合配置。这反映了股票价格面临的上下行风险。根据个别客户的具体情况，分配比例会有所不同。在大多数情况下，分配是衍生出来的，可能无法反映该资产类别的真实敞口。

            <allocation>

            **多头头寸（包括比特币）**
            我们当前的多头头寸表现非常优异；然而，我们对日益增加的波动性表示担忧。目前我们已停止进一步购买多头头寸。我们的比特币持仓收益强劲，并将在价格下跌时继续买入。

            **空头头寸**
            自美国市场崩溃初期以来，我们一直持有少量空头头寸。该头寸已产生了超额回报。我们几乎已完成客户投资组合中这一头寸的出售，预计本周完成。

            **现金**
            我们在科技股和比特币头寸的强劲表现，已使现金占总资产的比例减少。然而，由于股价非常高且风险正在增加，我们认为未来几周和几个月内将出现投资机会。

            **客户问题**

            _谁会赢得美国大选？_
            * 投注市场：哈里斯和特朗普以49%-49%持平（PredictIt）。
            * 民调：哈里斯领先特朗普大约1个百分点（RealClearPolitics）。
            * 选民担忧：哈里斯的价格控制提案可能会让选民望而却步。
            * 我们的看法：特朗普胜选的可能性略高于哈里斯。

            _抖音会在美国被禁吗？_
            * 美国两大政党都在使用抖音吸引选民。
            * 乔·拜登总统在4月24日签署了一项法案，如果字节跳动（抖音的母公司）在一年内不出售抖音，该法案将禁止抖音。
            * 5月7日，字节跳动起诉政府，称该禁令违宪。
            * 我们的看法：最有可能的结果是出售给一家美国公司——可能是一家与字节跳动“关系密切”的公司。

            **风险**
            我们关注的风险包括：

            _9月降息_：历史表明，一旦利率开始下降，股价往往随之下跌。预计美联储将在9月降息。

            _货币波动和经纪公司倒闭传闻_：美元/日元的汇率波动、中国和美国之间建立“金融压力”工作组（据彭博社报道），以及经纪公司倒闭的传闻令人担忧。

            <image1>

            _失业率上升_：预计8月份的白领裁员人数将创今年新高。美国失业率正在上升到令人担忧的水平。

            _隐藏的杠杆_：有许多义务依赖于高资产价格，如果资产价格下降，可能会导致连锁抛售效应。

            _美国主权违约_：联邦支出的前所未有的水平是一个日益增加的风险。我们认为在下一届美国政府任期内发生美国主权债务危机的可能性很高。

            **最后的话**
            我们在美国科技股和比特币上的收益非常可观，但由于过度杠杆而引发的金融风险令人担忧。Nvidia即将发布的财报将至关重要，它将为今年剩余时间定下基调。尽管存在这些风险，但预计明年将带来非常强劲的回报。
        `,
    },
    {
        image1: <Box component='figure' sx={{mx: 'auto', my: 0, p: 1, boxSizing: `border-box`, display: `flex`, flexDirection: `column`, alignItems: `center`, width: `90%`}}>
            <Box component='img' src={SPXFED} sx={{
                width: '100%', height: 'auto', m: 0, p: 0,
            }} />
            <Typography component='figcaption' sx={{display: `flex`, gap: 1, alignItems: `center`, flexWrap: `wrap`, justifyContent: `center`}}>
                <Typography sx={{flexGrow: 1, width: `100%`, textAlign: `center`}}> <Translated en='Falling Rate Usually Results in Falling S&P 500' zh='利率下降通常会导致标准普尔 500 指数下跌' /></Typography>
                <Box sx={{display: `flex`, gap: 1, alignItems: `center`,}}><Box sx={{width: `1ch`, height: `1ch`, background: `gold`,}}   /> <Translated en='Federal Fund Rate' zh='联邦基金利率' /></Box>
                <Box sx={{display: `flex`, gap: 1, alignItems: `center`,}}><Box sx={{width: `1ch`, height: `1ch`, background: `blue`,}}   /> <Translated en='S&P 500'           zh='标准普尔 500' /></Box>
                <Box sx={{display: `flex`, gap: 1, alignItems: `center`,}}><Box sx={{width: `1ch`, height: `1ch`, background: `purple`,}} /> <Translated en='Falling Rates'     zh='利率下降' /></Box>
            </Typography>
        </Box>,
        allocation: <PieChart subtitle={{en: 'Average Allocations', zh: '平均分配'}} allocations={[
            [{en: 'BitCoin',  zh: '比特币'}, 17, 55, 80, 'gold' ],
            [{en: 'Cash',     zh: '现金'  }, 45, 85, 65, '#9db290' ],
            [{en: 'Long',     zh: '多头'  }, 96, 45, -10, 'wheat'   ],
            [{en: 'Short',    zh: '卖空'  }, 66, 15, 75, '#9c27b0' ], // title, value, top, left, color
        ]} sx={{mx: `auto`}}/>,
        en: `
            <date 'Tue Aug 13, 2024'>

            **Average Client Asset Allocation**
            We maintain a mixed allocation between Long, Short, and Cash positions. This is a reflection of the upward and downward risks facing equity prices. Individual clients will have different allocations based on their circumstances. Allocations are in most cases a derivative and may not reflect a true exposure of that asset class.

            <allocation>

            **Short Positions**
            We have pursued a small Short Position since the beginning of the collapse in the US Market. This position has made outsized returns. There is no decision yet on timing of sale of this position however Short Positions are intended to be held for short durations as they lose money over the long term, therefore we intend to exit this position in the near future.

            **Long Positions incl. BitCoin**
            We are cautiously and steadily increasing our Long Position. We are aiming to increase at a rate where we don't feel compelled to sell in the event of a significant downturn. During the Pre-Market session on Black Monday we purchased Long Positions at a significant discount. We are cautiously purchasing a small amount of BitCoin.

            **Cash**
            We are majority cash and have been for a while now. Asset prices are very high and risks are increasing. We feel investment opportunities will present themselves in the coming weeks and months.

            **Risks**
            Risks on our radar include:

            _September Interest Rate Cut_; history demonstrates time and time again that once interest rates start falling, equity prices soon follow. The Federal Reserve is expected to cut rates in September.

            <image1>

            _Increasing Unemployment_; August is expected to have the highest layoff count for white collar positions of this year.

            _Hidden Leverage_; there are many obligations dependent on high asset prices, and should asset prices decrease, that may cause a compounding sale effect.

            _Middle East/Asia War_; the risk may be low as Gold and Oil is not moving significantly higher.

            _US Sovereign Default_; unprecedented levels of Federal spending is an increasing risk. We see a high chance of a US Sovereign Debt Crisis within the next US Administration.

            **Final Word**
            There are many unknowns and no clear direction for the market. Until the market moves decisively higher or decisively lower, we are going to stay cautious.
        `,
        zh: `
            <date '2024 年 8 月 13 日星期二'>

            **平均客户资产配置**
            我们维持多头、空头和现金头寸的混合配置。这反映了股票价格面临的上行和下行风险。个人客户将根据其情况进行不同的配置。在大多数情况下，配置是一种衍生品，可能无法反映该资产类别的真实风险。

            <allocation>

            **短期仓位**
            自美国市场崩溃开始以来，我们追求了一个小的空头仓位。这个位置带来了超常的回报。目前还没有决定何时出售这个仓位，但空头仓位通常持有时间短，因为长期来看会亏损，因此我们打算在不久的将来退出这个位置。

            **长期仓位，包括比特币**
            我们正在谨慎而稳步地增加我们的长仓。我们的目标是以一种不会在重大市场下跌时感到迫不得已要卖出的速度增加仓位。在黑色星期一的盘前交易中，我们以显著折扣购买了长期仓位。我们也在谨慎购买少量比特币。

            **现金**
            我们目前主要持有现金，已经持续一段时间了。资产价格很高，风险在增加。我们认为在未来几周和几个月内，投资机会将会出现。

            **风险**
            我们雷达上的风险包括：

            _九月降息_；历史一次次表明，一旦利率开始下降，股票价格很快就会跟随。美联储预计将在九月份降息。

            <image1>

            _失业率上升_；预计八月将有今年白领职位最高的裁员数。

            _隐性杠杆_；有许多高资产价格依赖的义务，一旦资产价格下降，可能会导致复合销售效应。

            _中东/亚洲战争_；由于黄金和石油价格没有显著上涨，风险可能较低。

            _美国主权违约_；联邦支出的前所未有的水平是一个增加的风险。我们认为在下一届美国政府任期内，美国主权债务危机的可能性很高。

            **最后的话**
            市场有很多未知数，没有明确的方向。在市场明显上涨或下跌之前，我们将保持谨慎。
        `,
    },
    {
        allocation: <PieChart subtitle={{en: 'Average Allocations', zh: '平均分配'}} allocations={[
            [{en: 'Cash',  zh: '现金'}, 30, 70, 60, '#9db290' ],
            [{en: 'Long',  zh: '多头'}, 30, 45, 10, 'wheat'   ],
            [{en: 'Short', zh: '卖空'}, 30, 25, 60, '#9c27b0' ], // title, value, top, left, color
        ]} sx={{}}/>,
        en: `
            <date 'Tue Aug 6, 2024'>

            **Average Client Asset Allocation**
            This is an approximate representation of asset allocations among our clients. Individual clients will have different allocations based on their circumstances.

            <allocation>

            **Short Positions**
            We have pursued a small Short Position since the begin of the collapse in the US Market. This position has made outsized returns. There is no decision yet on timing of sale of this position however Short Positions are intended to be held for short durations as they lose money over the long term, therefore we intend to exit this position in the near future.

            **Long Positions inc. BitCoin**
            We are cautiously and steadily increasing our Long Position. We are aiming to increase at a rate where we don't feel compelled to sell in the event of a significant downturn. During the Pre-Market session on Black Monday we purchased Long Positions at a significant discount. We are cautiously purchasing a small amount of BitCoin.

            **Cash**
            We are majority cash and have been for a while now. Asset prices are very high and risks are increasing. We feel investment opportunities will present themselves in the coming weeks and months.

            **Risks**
            Risks on our radar include:

            Hidden Obligations; which is the principle cause of Black Monday's collapse. There are many obligations dependent on high asset prices, and should asset prices decrease, that may cause a compounding sale effect.

            Middle East War; the risk may be low as Gold and Oil is not significantly moving higher.

            US Credit Event; just as we saw in Japan, there may come a time when US consumers or businesses suddenly cannot meet their obligations.

            **Final Word**
            Stock carnage occurs. We like to be purchasing when there's blood on the ground. We did just that this recent Black Monday.
        `
    },
];
