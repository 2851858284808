
export const WINDOW_LEN = 150;
export const GAME_LEN = 250;
export const INITIAL_CASH = 1000;
export const ML_RATE = 10;

type Price = number;
type Qty = number;

export interface Flags_T {
    normalized: boolean;
    restricted: boolean; // 30 day profit rule
    customDate?: number;
}

export const DEFAULT_FLAGS: Flags_T = {
    normalized: false,
    restricted: true,
};

export interface Log_Row_Transaction_T {
    isBuy: boolean;
    symbol: string;
    symbolColor: string;
    day: number;
    cost: number;
    qty: number;
};

export interface Low_Row_Market_Price_T {
    symbol: string;
    price: number;
};

export interface Log_Row_T {
    type: 'transaction' | 'date' | 'market-prices';
    data: Log_Row_Transaction_T | Low_Row_Market_Price_T[] | {value: number};
};

export interface Game_T {
    day: number;
    current_game_symbols: string[];
    mostRecentPurchaseIndexes: Record<string, number>;
    portfolioCash: number;
    portfolioAssets: Record<string, [Price, Qty]>; // Price, Qty
    loans: [number, number, number][]; // start date - amount - repaymentAmount
    gameSet: Record<string, string[]>;
    view_totalMarginLiability: number;
    view_symbolDesc: Record<string, string>;
    view_symbolColor: Record<string, string>;
    view_totalValue: number;
    view_performanceYTDPct: string;
    startDate: number;
    flags: Flags_T,
    isReplay: boolean;
    gameLength: number;
    windowLength: number;
    initialCash: number;
    marginLoanRate: number;
    logs: Log_Row_T[];
    currentDate?: number;
}

export const getDefaultGame = (startDate: number): Game_T => ({
    day: 0,
    current_game_symbols: [],
    mostRecentPurchaseIndexes: {},
    portfolioCash: INITIAL_CASH,
    portfolioAssets: {},
    loans: [],
    gameSet: {},
    view_totalMarginLiability: 0,
    view_symbolDesc: {},
    view_symbolColor: {},
    view_totalValue: INITIAL_CASH,
    view_performanceYTDPct: `0%`,
    startDate: startDate,
    flags: {
        normalized: true,
        restricted: true,
    },
    isReplay: false,
    gameLength: GAME_LEN,
    windowLength: WINDOW_LEN,
    initialCash: INITIAL_CASH,
    marginLoanRate: ML_RATE,
    logs: [],
});

export type SymbolsData = Record<string, {
    desc: string;
    
    marketTicks: number[];
    windows: number[][];
    dateRanges: number[][];
    windowsFrom100: number[][];
    _30dayChange: number[];
    _ytdChange: number[];
}>;

export interface JSON_Import_T {
    data_sets: {
        name: string;
        color?: string;
        data_points: {
            date: number;
            value: number;
        }[];
    }[];
};

export interface LLM_Stat_T {
    date: number;
    _open: number;
    vol: number;
    openPeriodChange: {
        "1": number;
        "30": number;
    };
};

export interface LLM_Data_T {
    stats: Record<string, LLM_Stat_T[]>;
    symbols: Record<string, string>;
};


export interface LLM_Game_T {
    stored: Game_T;
    generated: {
        symbolData: SymbolsData;
    };
};

export interface Website_Flags_T {
    version: number;
    local: 'en' | 'zh';
};
