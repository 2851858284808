import { Box, Button, Typography } from "@mui/material";
import { _Logo, Span, Footer } from "./Theme";
import _Texts from "./Texts";
import { onEmail } from "./Utils";
import Metadata from "./Metadata";

const services = [
    [
        'Investment Management',
        `Assets are stored in USD in the US and are invested in US-listed equities and derivatives.`,
        `We use market simulation for fine-tuning our strategy that uses historical data going back to 1985.`,
        `We accept US Individuals and US Limited Liability Corporations (LLC) as clients.`,
        `While we accept Foreign Individuals on a case-by-case basis.`,
        `Fees: 2% of assets each 3 months.`,
    ],
    [
        'Investment and Economic Research',
    ],
    [
        'US Limited Liability Corporation for Foreign Individuals',
        `Most Foreign and US Individuals can open a US Limited Liability Corporation.`,
    ],
];

const Strategy = () => {
    const ContactUs = () => {
        const size = 8;
        return <Button onClick={onEmail} sx={{
            backgroundColor: `#9c27b0`,
            color: `white`,
            clipPath: `polygon(0 0, 0% 100%, 100% 100%, 100% ${10 + size}%, 50% ${50 + (size / 2)}%, 0 ${10 + size}%, 0 10%, 50% ${50 - (size / 2)}%, 100% 10%, 100% 0%)`,
            display: `flex`,
            fontWeight: `bold`,
            alignItems: `flex-end`,
            height: `80px`,
            px: 1,
            mr: `auto`,
            '&:hover': { backgroundColor: `#9c27b096` },
        }}>
            <Span>Contact Us</Span>
        </Button>
    };

    const _D = ({values}) => <Typography sx={{
        border: `solid 2px #009688`,
        p: 2,
        boxSizing: `border-box`,
        display: `flex`,
        justifyContent: `center`,
        flexDirection: `column`,
        width: `100%`,
    }}>
        <Typography component='h3' sx={{fontWeight: `bold`}}>{values[0]}</Typography>
        {values.slice(1).map((d, i) => <Typography key={i}>{d}</Typography>)}
    </Typography>;

    return <Box sx={{
        minHeight: `100svh`,
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `center`,
        boxSizing: 'border-box',
        p: 3,
        mx: 'auto',
        gap: 2,
        width: 'min(100%, 900px)',
    }}>
        <_Logo />
        <ContactUs />
        {services.map((_s, i) => <_D key={i} values={_s}/>)}
        <Footer />
    </Box>;
}

export default () => <>
    <Metadata title={{en: 'Strategy', zh: '战略'}} />
    <Strategy />
</>;
