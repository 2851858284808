import React, { Component, Fragment, useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {Emulator} from './Emulator';
import { Outlet, RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import Home from './Home';
import Strategy from './Services';
import { createTheme, ThemeProvider } from '@mui/material';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Metadata from './Metadata';
import Simulator from './Simulator';
import DearClients from './DearClients';
import { useGlobalState } from './State';
import { Website_Flags_T } from './Interfaces';
import Proposal from './Proposal';

const firebaseConfig = {
    apiKey: "AIzaSyDtofVxf89YTDr5wGbN2xbulkdagj_OpDE",
    authDomain: "stocker-dfd.firebaseapp.com",
    projectId: "stocker-dfd",
    storageBucket: "stocker-dfd.appspot.com",
    messagingSenderId: "388626294182",
    appId: "1:388626294182:web:123b47c319f22fbf061b67",
    measurementId: "G-5RYXCJKE0N"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const Settings = ({children}) => {
    const [ WebsiteFlags, setWebsiteFlags ] = useGlobalState(`WebsiteFlags`);
    const [ didLoad, setDidLoad ] = useState(false);

    const getFirstSubdir = useCallback(() => {
        const path = window.location.pathname;
        const segments = path.split('/').filter(segment => segment.length > 0); 
        return segments[0] || null;
    }, []); // window.location.pathname is not expected to change within lifecycle of component

    useEffect(() => {
        const userLang = (navigator.language || 'en').startsWith('zh') ? 'zh' : 'en';
        if (didLoad) {
            return;
        }
        const tmpsetWebsiteFlags: Website_Flags_T = JSON.parse(localStorage.getItem(`WebsiteFlags`));
        if (!tmpsetWebsiteFlags) {
            return;
        }
        setWebsiteFlags({...tmpsetWebsiteFlags, local: userLang});
        setDidLoad(true);
    }, []);

    useEffect(() => {
        localStorage.setItem(`WebsiteFlags`, JSON.stringify(WebsiteFlags));
    }, [WebsiteFlags]);

    useEffect(() => {
        if (!didLoad) {
            return;
        }
        const tmpLocal: any = getFirstSubdir();
        if (!tmpLocal || ![`en`,`zh`].includes(tmpLocal) || tmpLocal == WebsiteFlags.local) {
            return;
        }
        console.log(`updating local:`, tmpLocal);
        setWebsiteFlags(f => ({...f, local: tmpLocal}));
    }, [ didLoad ]);

    return <Fragment>{children}</Fragment>;
};

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        console.error('Error caught by boundary:', error, errorInfo);
    }

    render() {
        if ((this.state as any).hasError) {
            localStorage.clear();
            return <div>Something went wrong.</div>;
        }
        return (this.props as any).children;
    }
}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const routerLinks = [
    {
        path: `/`,
        element: <ErrorBoundary>
            <Settings>
                <Metadata />
                <Outlet />
            </Settings>
        </ErrorBoundary>,
        children: [
            { path: '/',             element: <Home />        },
            { path: '/strategy',     element: <Strategy />    },
            { path: '/simulator',    element: <Simulator />   },
            { path: '/proposal',     element: <Proposal />    },
            { path: '/game',         element: <Emulator />    },
            { path: '/dear-clients', element: <DearClients /> },
        ]
    },
];

const theme = (() => {
    const _theme = createTheme({
        typography: {
            fontFamily: [ `TimesNewRoman`, `"Times New Roman"`, `Times`, `Baskerville`, `Georgia`, `serif`, ].join(', '),
            h1: {
                fontFamily: [ `TimesNewRoman`, `"Times New Roman"`, `Times`, `Baskerville`, `Georgia`, `serif`, ].join(', '),
            },
            h2: {
                fontFamily: [ `TimesNewRoman`, `"Times New Roman"`, `Times`, `Baskerville`, `Georgia`, `serif`, ].join(', '),
            },
            body1: {
            }
        },
    });
    ['h1', 'h2', 'h3', 'body1'].map(i => {
        _theme.typography[i] = {
            [_theme.breakpoints.down('sm')]: {
                fontSize: '1.3rem',
                lineHeight: 1.4,
            },
        };    
    });
    return _theme;
})();

const router = createBrowserRouter(routerLinks);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <RouterProvider router={router} />
        </ThemeProvider>
    </React.StrictMode>
);
