import { Box, Button, CircularProgress, SxProps, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Persistence, useGlobalState } from './State';
import { addQty, removeQty, sumOfAssets } from './Utils';
import SymbolRow from './SymbolRow';
import { assert, assert_is_num } from './Theme';
import Header from './Header';
import Chart from './Chart';
import GameOptions from './GameOptions';
import { Game_T, Log_Row_Transaction_T, Low_Row_Market_Price_T, SymbolsData } from "./Interfaces";
import Log, { downloadLog } from './Log';
import { useInterestRate } from './InterestRate';
import { useNewsData } from './NewsData/NewsData';

export const onTransact = (symbol, qty, symbolsData: SymbolsData, game: Game_T) => {
    const isBuy = qty > 0;
    const [ avgPrice, ownedCnt ] = game.portfolioAssets[symbol] || [0, 0];
    assert(ownedCnt >= 0);
    qty = isBuy ? qty : -Math.min(Math.abs(qty), ownedCnt);
    if (!isBuy) assert(qty < 0);
    const price = symbolsData[symbol].marketTicks[game.day];
    const cost = price * -qty;
    const transactionFee = Math.abs(cost) * 0.01;
    assert(transactionFee > 0);
    const newCash = (game.portfolioCash || 0) + cost - transactionFee;

    if ((qty > 0 && newCash < 0) || ownedCnt + qty < 0) {
        return game;
    }

    const ret: Game_T = { ...game };
    ret.portfolioCash = newCash;
    ret.logs.push({
        type: 'transaction',
        data: {
            isBuy: isBuy,
            symbolColor: game.view_symbolColor[symbol],
            day: game.day,
            symbol: symbol,
            cost: Number(Math.abs(cost).toFixed(2)),
            qty: qty,
        } as Log_Row_Transaction_T,
    });

    if (isBuy) {
        const [ newAvgPrice, newQty ] = addQty(ret, symbol, price, qty);
        ret.portfolioAssets[symbol] = [ newAvgPrice, newQty ];
        ret.mostRecentPurchaseIndexes[symbol] = ret.day;
    }
    else {
        const [ avgPrice, ownedQty ] = ret.portfolioAssets[symbol];
        const [ nextAvgPrice, nextOwnedQty ] = removeQty(ret, symbol, qty) || [0, 0];
        assert(nextOwnedQty < ownedQty, `${ownedQty} < ${nextOwnedQty}`);
        assert(nextOwnedQty >= 0, `${nextOwnedQty} >= 0`);
        ret.portfolioAssets[symbol] = [nextAvgPrice, nextOwnedQty];
    }
    ret.view_totalValue = newCash + sumOfAssets(ret, symbolsData);

    return ret;
};

export const onNextDay = (game: Game_T, symbolsData: SymbolsData) => {
    if (game.day == game.gameLength - 1) {
        downloadLog(game);
    }

    if (game.day == game.gameLength) {
        return;
    }
    
    const nextIndex = game.day + 1;
    let totalMarginLiability = 0;
    const loans = game.loans.map(([ date, amt, _ ]) => {
        const compoundFactor = Math.pow(1 + (game.marginLoanRate / 100 / 365), (nextIndex - date));
        const repayment = amt * compoundFactor;
        totalMarginLiability += repayment;
        return [date, amt, repayment] as [number, number, number];
    });

    const nextGame = {
        ...game,
        view_totalMarginLiability: totalMarginLiability,
        loans: loans,
        day: nextIndex
    };

    const currentDate = symbolsData[Object.keys(symbolsData)[0]].dateRanges[nextIndex].slice(-1)[0];
    nextGame.currentDate = currentDate;
    assert(game.currentDate < nextGame.currentDate);
    console.log(`Current date: ${nextGame.currentDate}`);

    let totalValue = nextGame.portfolioCash + sumOfAssets(nextGame, symbolsData);
    totalValue -= totalMarginLiability;
    nextGame.view_totalValue = totalValue;
    const performanceYTDPct = ((totalValue / game.initialCash) - 1) * 100;
    nextGame.view_performanceYTDPct = `${performanceYTDPct > 0 ? '+' : ''}${performanceYTDPct.toFixed(1)}%`;

    nextGame.logs.push({
        type: 'market-prices',
        data: game.current_game_symbols.map(symbol => ({
            symbol: symbol,
            price: symbolsData[symbol].marketTicks[game.day],
        })) as Low_Row_Market_Price_T[],
    });

    return nextGame;
};

const Transact = () => {
    const [game, setGame] = useGlobalState(`game`);
    const [symbolData] = useGlobalState(`symbolData`);
    const [ interestRate ] = useInterestRate();

    const untradable = [
        'XAU',
        'QQQ',
    ];

    const performTransact = (symbol, isBuy) => {
        const price = symbolData[symbol].marketTicks[game.day];
        const qty = Number((1 / (price / 100)).toFixed(1));
        console.log(`qty:`, qty);
        const nextGame = onTransact(symbol, isBuy ? qty : -qty, symbolData, game);
        setGame(nextGame);
    };

    const performNextDay = () => {
        const nextGame = onNextDay(game, symbolData);
        if (nextGame) {
            setGame(nextGame);
        }
    }

    return <>
        <Box sx={{
            display: `flex`,
            gap: 2,
            flexWrap: `wrap`,
        }}>
            <Button disabled={game.day >= game.gameLength} onClick={() => performNextDay()}>Done for Day</Button>
            <Button onClick={() => setGame(undefined)}>New Game</Button>
        </Box>

        {game && <Box sx={{
            display: `flex`, gap: 2, flexWrap: `wrap`,
        }}>
            <Typography>Day: {game.day + 1}</Typography>
            <Typography>Cash: ${game.portfolioCash.toFixed(1)}</Typography>
            <Typography>Assets: ${game.view_totalValue.toFixed(1)} {`(${game.view_performanceYTDPct})`}</Typography>
            {interestRate && <Typography>FED: {interestRate}</Typography>}
        </Box>}

        {game && <Box component='ul' sx={{
            display: `flex`, gap: 2, flexDirection: `row`, flexWrap: `wrap`,
        }}>
            {!symbolData && <CircularProgress />}
            {symbolData && game.current_game_symbols.filter(i => !untradable.includes(i)).sort().map((symbol, i) => <SymbolRow
                game={game}
                symbolData={symbolData}
                symbol={symbol}
                onBuy={() => performTransact(symbol, true)}
                onSell={() => performTransact(symbol, false)}
                key={i} sx={{width: {xs: `100%`, sm: `400px`}}} />)}
            {symbolData && game.current_game_symbols.filter(i => untradable.includes(i)).map((symbol, i) => <SymbolRow
                game={game}
                symbolData={symbolData}
                symbol={symbol}
                onBuy={null}
                onSell={null}
                key={i} sx={{width: {xs: `100%`, sm: `400px`}}} />)}
        </Box>}
    </>;
};

const MarginLoanFacility = () => {
    const [game, setGame] = useGlobalState(`game`);

    const repayment = game && game.loans.length > 0 ? game.loans[game.loans.length - 1][2] : 0;

    const pushLoan = () => {
        setGame(g => ({
            ...g,
            portfolioCash: g.portfolioCash + 100,
            view_totalMarginLiability: g.view_totalMarginLiability + 100,
            loans: [[g.day, 100, 100], ...g.loans]
        }));
    };

    const removeLoan = () => {
        setGame(g => {
            const [date, amount, repayment] = g.loans.pop();
            return {
                ...g,
                portfolioCash: g.portfolioCash - repayment,
                view_totalMarginLiability: g.view_totalMarginLiability - repayment,
                loans: [...g.loans]
            };
        });
    };

    return <Box>
        {game && <>
            {game.view_totalMarginLiability > 0 && <Typography>Margin Debt: ${game.view_totalMarginLiability.toFixed(1)}</Typography>}
            <Button onClick={pushLoan}>Take Out Loan</Button>
            <Button onClick={removeLoan}>{`Repay $${repayment.toFixed(1)}`}</Button>
        </>}
    </Box>;
};

const NewsViewer = () => {
    const [game] = useGlobalState(`game`);
    assert_is_num(game.currentDate);
    const [ newsItems ] = useNewsData(game.currentDate);
    return <>
        {newsItems && newsItems.map((n, i) => <Box key={i}>
            {n}
        </Box>)}
    </>;
}

export const Emulator = () => {
    const [game] = useGlobalState(`game`);
    const [showLog, setShowLog] = useState(false);

    useEffect(() => {
        if (!game) {
            setShowLog(false);
        }
    }, [game]);

    return <>
        <Persistence />
        {game && <Box sx={{
            width: `100%`,
            height: `100%`,
            display: `flex`,
            flexDirection: `column`,
            gap: 2,
            p: 2,
            pb: 10,
            boxSizing: `border-box`,
            overflowY: `auto`,
        }}>
            <Header />
            <Chart />
            <Transact />
            <NewsViewer />
            <MarginLoanFacility />
            <GameOptions />
            <Button onClick={() => setShowLog(i => !i)}>Toggle Log</Button>
            {showLog && <Log game={game} />}
        </Box>}
    </>;
}
