import { Game_T, SymbolsData } from "./Interfaces";
import { assert, assert_eq } from "./Theme";

export const setLocalItem = (key: string, value: any): void => {
    localStorage.setItem(key, JSON.stringify({setAt: Math.floor(Date.now() / 1000), version: 1, value: value}));
}

interface LocalItemT {
    setAt: number;
    version: number,
    value: any;
}

export const DataVisColors = [
    '#41afaa',
    '#af4b91',
    '#00a0e1',
    '#466eb4',
    '#e6a532',
    '#d7642c',
];

export const getLocalItem = (key: string): LocalItemT | null => {
    try {
        const item = localStorage.getItem(key);
        const ret = item ? JSON.parse(item!) : null;
        if (typeof ret === 'object' && ret !== null && ret.version) {
            return ret;
        }
        localStorage.removeItem(key);
        return null;
    } catch (error) {
        return null;
    }
}

export const sumOfAssets = (game: Game_T, symbolData: SymbolsData) => {
    const portfolioAssets = (game.portfolioAssets || {});
    let sumValue = 0;
    Object.entries(portfolioAssets).forEach(([symbol, [avgPrice, ownedQty]]) => {
        const price = symbolData[symbol].marketTicks[game.day];
        sumValue += price * ownedQty;
    });
    return sumValue;
}

export const removeQty = (game: Game_T, symbol: string, qty: number) => {
    let [ avgPrice, ownedQty ] = (game.portfolioAssets[symbol] || [0, 0]);
    if (ownedQty <= 0) {
        return;
    }
    qty = Math.abs(qty);
    ownedQty -= qty;
    assert(ownedQty >= 0, `${ownedQty} >= 0`);
    return ownedQty == 0 ? undefined : [ avgPrice, ownedQty ] as [number, number];
}

export const formatDate = (number) => {
    const str = number.toString();
    assert_eq(str.length, 8);
    const formattedDate = `${str.slice(0, 4)}-${str.slice(4, 6)}-${str.slice(6, 8)}`;
    return formattedDate;
}

function calculateNewAveragePrice(avgPrice1, qty1, avgPrice2, qty2) {
    const totalValue1 = avgPrice1 * qty1;
    const totalValue2 = avgPrice2 * qty2;
    const newQty = qty1 + qty2;
    if (newQty === 0) {
      return 0;
    }
    const newAvgPrice = (totalValue1 + totalValue2) / newQty;
    return newAvgPrice;
}
assert_eq(calculateNewAveragePrice(100, 5.5, 120, 4.5), 109)
assert_eq(calculateNewAveragePrice(87, 5.5, 120, 4.5), 101.85)

export const addQty = (game: Game_T, symbol: string, price: number, qty: number) => {
    let [ avgPrice, ownedQty ] = (game.portfolioAssets[symbol] || [0, 0]);
    avgPrice = calculateNewAveragePrice(avgPrice, ownedQty, price, qty);
    return [ avgPrice, ownedQty + qty ] as [number, number];
}

export const onEmail = () => {
    const points = ['Name', 'Domicile/Location', 'Contact Details', 'Request'];
    const subject = encodeURIComponent("Via Website");
    const body = encodeURIComponent(points.reduce((i, cur) => `${i}${cur}:\n\n`, ''));
    window.location.href = `mailto:info@oberonresearch.com?subject=${subject}&body=${body}`;
};

const tests = () => {
    {
        let tmp = removeQty({portfolioAssets: {SYM: [25, 2]}} as any, "SYM", 0.5);
        assert_eq(tmp, [25, 1.5]);
    }
    {
        let tmp = removeQty({portfolioAssets: {SYM: [30, 1.5]}} as any, "SYM", 1);
        assert_eq(tmp, [30, 0.5]);
    }
    {
        let tmp = removeQty({portfolioAssets: {SYM: [1, 1]}} as any, "SYM", 1);
        assert_eq(tmp, undefined);
    }
    {
        let tmp = addQty({portfolioAssets: {SYM: [1, 1]}} as any, "SYM", 27, 1.2);
        assert_eq(tmp[0].toFixed(2), `15.18`);
        assert_eq(tmp[1], 2.2);
    }
}

tests();
