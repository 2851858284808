import { useGlobalState } from "./State";
import { Helmet } from 'react-helmet';
import { assert_is_object } from "./Theme";

export default ({title=undefined}) => {
    if (title) assert_is_object(title);
    const [ WebsiteFlags ] = useGlobalState(`WebsiteFlags`);
    const _title = {
        en: `Oberon Research` + (title ? ` | ${title.en}` : ''),
        zh: `奥布朗研究` + (title ? ` | ${title.zh}` : ''),
    };
    const _desc = `Oberon Research is an Investment Management and Economic Research firm that employs mathematical and statistical methods in the design and execution of investment programs. 奥布朗研究是一家投资管理和经济研究公司，在投资计划的设计和执行中采用数学和统计方法。`;
    return <Helmet>
        <title>{_title[WebsiteFlags.local]}</title>
        <meta name='title' content={_title[WebsiteFlags.local]} />
        <meta name='description' content={_desc} />
        <meta property='og:title' content={_title[WebsiteFlags.local]} />
        <meta property='og:description' content={_desc} />
        <script type="application/ld+json">
{`{
    "@context": "https://schema.org",
    "@type": "WebSite",
    "name": "Oberon Research",
    "url": "https://www.oberonresearch.com/"
}`}
        </script>
    </Helmet>;
}
