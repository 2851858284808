export default `
120.39,    19851112, 0.9,   3.7,   8.8
121.82,    19851113, 1.2,   4.6,   8.6
121.165,   19851114, -0.5,  3.2,   9.3
121.835,   19851115, 0.6,   3.6,   9.9
122.175,   19851118, 0.3,   2.4,   11.0
121.955,   19851119, -0.2,  1.3,   12.7
122.57,    19851120, 0.5,   0.6,   14.4
122.2,     19851121, -0.3,  0.9,   12.5
123.61,    19851122, 1.2,   1.5,   13.0
123.97,    19851125, 0.3,   1.5,   11.6
123.135,   19851126, -0.7,  1.0,   8.8
123.95,    19851127, 0.7,   1.1,   9.3
125.77,    19851129, 1.5,   2.9,   10.0
126.06,    19851202, 0.2,   2.0,   9.9
125.185,   19851203, -0.7,  1.0,   9.5
125.23,    19851204, 0.0,   1.7,   10.0
128.07,    19851205, 2.3,   3.3,   11.9
128.46,    19851206, 0.3,   2.1,   11.6
126.955,   19851209, -1.2,  0.7,   9.9
127.485,   19851210, 0.4,   1.8,   11.9
127.975,   19851211, 0.4,   2.2,   12.4
129.18,    19851212, 0.9,   0.9,   11.9
129.765,   19851213, 0.5,   1.0,   12.1
132.125,   19851216, 1.8,   4.1,   14.4
132.545,   19851217, 0.3,   4.0,   14.0
131.525,   19851218, -0.8,  2.8,   13.3
131.14,    19851219, -0.3,  1.5,   12.6
131.925,   19851220, 0.6,   1.7,   12.4
132.26,    19851223, 0.3,   0.1,   12.4
130.725,   19851224, -1.2,  -1.4,  9.6
130.03,    19851226, -0.5,  -1.1,  8.0
130.345,   19851227, 0.2,   -0.6,  7.0
131.485,   19851230, 0.9,   -0.3,  8.5
131.945,   19851231, 0.3,   -0.2,  8.3
132.295,   19860102, 0.3,   1.2,   8.3
131.255,   19860103, -0.8,  0.9,   7.6
130.55,    19860106, -0.5,  0.2,   6.5
130.355,   19860107, -0.1,  -0.9,  6.7
132.79,    19860108, 1.9,   0.6,   7.4
131.005,   19860109, -1.3,  -1.0,  5.7
128.8,     19860110, -1.7,  -1.9,  4.6
129.565,   19860113, 0.6,   -0.8,  4.5
129.095,   19860114, -0.4,  -1.0,  2.6
129.18,    19860115, 0.1,   -2.7,  2.5
131.535,   19860116, 1.8,   0.4,   5.1
133.06,    19860117, 1.2,   3.3,   6.3
132.77,    19860120, -0.2,  2.5,   3.7
131.605,   19860121, -0.9,  1.9,   2.4
131.09,    19860122, -0.4,  1.5,   3.3
129.615,   19860123, -1.1,  -1.5,  1.7
129.27,    19860124, -0.3,  -2.8,  1.0
130.205,   19860127, 0.7,   -1.9,  0.8
130.795,   19860128, 0.5,   -0.6,  0.8
131.99,    19860129, 0.9,   0.7,   -0.1
133.06,    19860130, 0.8,   2.7,   0.4
132.19,    19860131, -0.7,  2.3,   0.5
132.925,   19860203, 0.6,   2.1,   1.4
134.24,    19860204, 1.0,   2.6,   1.8
134.6,     19860205, 0.3,   2.0,   1.8
135.715,   19860206, 0.8,   2.0,   3.8
136.47,    19860207, 0.6,   3.2,   5.0
137.4,     19860210, 0.7,   3.4,   5.4
138.125,   19860211, 0.5,   2.9,   5.1
138.26,    19860212, 0.1,   2.7,   4.8
138.665,   19860213, 0.3,   2.2,   4.8
139.165,   19860214, 0.4,   2.0,   6.0
140.16,    19860218, 0.7,   2.0,   7.4
140.735,   19860219, 0.4,   1.9,   8.0
139.64,    19860220, -0.8,  1.0,   5.2
139.945,   19860221, 0.2,   0.9,   6.8
141.165,   19860224, 0.9,   1.4,   9.6
140.2,     19860225, -0.7,  0.0,   8.2
139.4,     19860226, -0.6,  -0.9,  8.0
138.875,   19860227, -0.4,  -0.5,  7.5
140.9,     19860228, 1.5,   0.7,   7.1
140.425,   19860303, -0.3,  -0.5,  5.5
139.74,    19860304, -0.5,  -0.3,  5.2
140.545,   19860305, 0.6,   0.8,   6.8
140.245,   19860306, -0.2,  1.0,   7.0
140.8,     19860307, 0.4,   -0.1,  8.6
140.71,    19860310, -0.1,  0.2,   8.8
142.435,   19860311, 1.2,   1.9,   9.4
145.345,   19860312, 2.0,   3.4,   11.1
146.62,    19860313, 0.9,   4.5,   11.1
147.01,    19860314, 0.3,   4.4,   10.5
147.0,     19860317, -0.0,  4.5,   11.2
146.365,   19860318, -0.4,  2.8,   10.1
147.625,   19860319, 0.9,   1.6,   10.0
147.475,   19860320, -0.1,  0.6,   9.6
147.8,     19860321, 0.2,   0.5,   8.9
148.005,   19860324, 0.1,   0.7,   8.5
147.09,    19860325, -0.6,  0.5,   7.1
147.485,   19860326, 0.3,   -0.1,  6.8
147.25,    19860327, -0.2,  -0.2,  6.5
148.375,   19860331, 0.8,   0.4,   7.0
148.86,    19860401, 0.3,   0.6,   7.0
148.45,    19860402, -0.3,  0.9,   5.9
148.92,    19860403, 0.3,   1.0,   5.8
148.71,    19860404, -0.1,  1.0,   6.5
147.035,   19860407, -1.1,  -0.9,  5.1
146.055,   19860408, -0.7,  -1.9,  3.5
148.93,    19860409, 2.0,   0.3,   6.2
149.055,   19860410, 0.1,   0.1,   6.9
151.765,   19860411, 1.8,   2.1,   9.3
152.665,   19860414, 0.6,   3.8,   8.3
153.925,   19860415, 0.8,   5.4,   9.6
154.92,    19860416, 0.6,   4.0,   10.9
158.895,   19860417, 2.6,   6.6,   13.1
157.375,   19860418, -1.0,  3.7,   12.2
157.66,    19860421, 0.2,   3.3,   12.0
157.35,    19860422, -0.2,  2.2,   11.8
156.65,    19860423, -0.4,  1.1,   10.0
157.46,    19860424, 0.5,   -0.9,  8.3
159.855,   19860425, 1.5,   1.6,   9.0
160.46,    19860428, 0.4,   1.8,   9.1
159.9,     19860429, -0.3,  1.6,   8.8
158.25,    19860430, -1.0,  1.0,   8.1
154.91,    19860501, -2.1,  -1.6,  4.9
154.205,   19860502, -0.5,  -3.5,  4.6
155.01,    19860505, 0.5,   -3.4,  4.9
157.14,    19860506, 1.4,   -1.7,  6.2
157.125,   19860507, -0.0,  -0.7,  6.8
156.255,   19860508, -0.6,  0.9,   5.9
157.16,    19860509, 0.6,   1.9,   6.7
157.915,   19860512, 0.5,   1.9,   6.4
157.75,    19860513, -0.1,  0.4,   6.0
157.05,    19860514, -0.4,  -0.0,  5.8
157.005,   19860515, -0.0,  0.5,   5.4
155.765,   19860516, -0.8,  -0.9,  4.7
154.995,   19860519, -0.5,  -1.8,  5.4
155.31,    19860520, 0.2,   -1.5,  6.3
157.01,    19860521, 1.1,   -0.0,  5.4
157.44,    19860522, 0.3,   0.3,   5.6
159.63,    19860523, 1.4,   2.5,   5.2
160.165,   19860527, 0.3,   3.3,   4.9
161.175,   19860528, 0.6,   3.8,   4.7
161.305,   19860529, 0.1,   2.7,   4.1
161.62,    19860530, 0.2,   2.7,   1.7
163.16,    19860602, 1.0,   2.2,   3.7
162.03,    19860603, -0.7,  1.2,   2.8
162.11,    19860604, 0.0,   0.6,   3.0
161.825,   19860605, -0.2,  0.3,   3.3
162.36,    19860606, 0.3,   0.5,   3.1
161.885,   19860609, -0.3,  -0.8,  1.3
158.605,   19860610, -2.0,  -2.1,  -1.2
158.105,   19860611, -0.3,  -2.5,  -1.1
160.035,   19860612, 1.2,   -1.1,  1.1
160.365,   19860613, 0.2,   -1.2,  3.5
162.355,   19860616, 1.2,   0.3,   5.3
160.8,     19860617, -1.0,  1.4,   3.7
158.575,   19860618, -1.4,  0.3,   0.9
158.48,    19860619, -0.1,  -1.0,  0.9
159.28,    19860620, 0.5,   -0.7,  1.9
159.585,   19860623, 0.2,   -1.7,  1.5
159.555,   19860624, -0.0,  -0.8,  1.0
160.8,     19860625, 0.8,   1.4,   1.9
162.0,     19860626, 0.7,   2.2,   3.2
161.72,    19860627, -0.2,  1.5,   3.0
161.615,   19860630, -0.1,  1.3,   3.8
162.615,   19860701, 0.6,   1.9,   4.9
163.325,   19860702, 0.4,   1.6,   5.2
163.96,    19860703, 0.4,   1.2,   4.4
164.515,   19860707, 0.3,   1.7,   4.5
157.625,   19860708, -4.2,  -2.5,  -1.3
153.26,    19860709, -2.8,  -5.8,  -4.3
155.41,    19860710, 1.4,   -4.8,  -3.6
154.29,    19860711, -0.7,  -5.9,  -4.3
152.855,   19860714, -0.9,  -7.1,  -5.4
149.285,   19860715, -2.3,  -5.3,  -8.5
146.255,   19860716, -2.0,  -4.6,  -9.7
147.395,   19860717, 0.8,   -5.2,  -9.1
148.465,   19860718, 0.7,   -3.8,  -8.3
147.76,    19860721, -0.5,  -3.3,  -9.0
147.075,   19860722, -0.5,  -1.5,  -9.1
147.405,   19860723, 0.2,   0.8,   -7.1
147.175,   19860724, -0.2,  -0.1,  -6.9
147.28,    19860725, 0.1,   -0.8,  -8.0
148.26,    19860728, 0.7,   0.3,   -7.5
145.505,   19860729, -1.9,  -1.1,  -10.4
144.205,   19860730, -0.9,  -2.2,  -10.3
144.585,   19860731, 0.3,   -1.8,  -8.8
144.705,   19860801, 0.1,   -1.7,  -8.7
144.19,    19860804, -0.4,  -2.7,  -9.5
142.525,   19860805, -1.2,  -2.0,  -10.7
141.86,    19860806, -0.5,  -1.6,  -11.1
140.545,   19860807, -0.9,  -2.8,  -12.6
140.695,   19860808, 0.1,   -2.8,  -13.2
142.295,   19860811, 1.1,   -1.3,  -12.0
145.44,    19860812, 2.2,   2.0,   -10.0
147.06,    19860813, 1.1,   3.7,   -9.6
148.835,   19860814, 1.2,   5.9,   -8.9
150.005,   19860815, 0.8,   6.6,   -8.5
149.575,   19860818, -0.3,  5.1,   -9.1
149.07,    19860819, -0.3,  2.5,   -5.4
148.595,   19860820, -0.3,  1.0,   -3.0
149.755,   19860821, 0.8,   0.6,   -3.6
150.01,    19860822, 0.2,   0.0,   -2.8
150.945,   19860825, 0.6,   0.9,   -1.2
149.91,    19860826, -0.7,  0.6,   0.4
151.155,   19860827, 0.8,   1.7,   3.4
152.02,    19860828, 0.6,   1.5,   3.1
152.045,   19860829, 0.0,   1.4,   2.4
151.7,     19860902, -0.2,  0.5,   2.7
149.34,    19860903, -1.6,  -0.4,  1.5
149.82,    19860904, 0.3,   -0.9,  1.6
151.395,   19860905, 1.1,   -0.4,  2.9
149.67,    19860908, -1.1,  -1.6,  1.6
146.55,    19860909, -2.1,  -3.4,  -1.2
145.76,    19860910, -0.5,  -2.4,  0.2
144.74,    19860911, -0.7,  -3.4,  0.4
138.46,    19860912, -4.3,  -8.5,  -4.2
135.035,   19860915, -2.5,  -9.8,  -6.7
134.94,    19860916, -0.1,  -7.9,  -6.4
135.21,    19860917, 0.2,   -7.2,  -5.1
136.39,    19860918, 0.9,   -5.8,  -3.9
138.135,   19860919, 1.3,   -0.2,  -1.7
138.52,    19860922, 0.3,   2.6,   -1.5
140.12,    19860923, 1.2,   3.8,   -1.5
139.565,   19860924, -0.4,  3.2,   -4.0
137.615,   19860925, -1.4,  0.9,   -6.4
137.615,   19860926, 0.0,   -0.4,  -7.5
137.585,   19860929, -0.0,  -0.7,  -8.3
135.535,   19860930, -1.5,  -3.3,  -9.4
137.52,    19861001, 1.5,   -1.5,  -7.7
138.265,   19861002, 0.5,   0.5,   -7.0
138.22,    19861003, -0.0,  0.4,   -7.7
138.655,   19861006, 0.3,   0.8,   -7.6
139.115,   19861007, 0.3,   2.6,   -7.8
138.255,   19861008, -0.6,  0.5,   -7.8
139.245,   19861009, 0.7,   0.7,   -7.9
139.165,   19861010, -0.1,  0.7,   -8.5
139.87,    19861013, 0.5,   0.9,   -8.0
140.675,   19861014, 0.6,   1.1,   -7.3
140.965,   19861015, 0.2,   2.0,   -5.6
142.1,     19861016, 0.8,   2.1,   -5.2
141.885,   19861017, -0.2,  2.0,   -6.3
141.195,   19861020, -0.5,  0.9,   -5.7
140.1,     19861021, -0.8,  -0.4,  -4.4
139.925,   19861022, -0.1,  -0.7,  -4.0
140.3,     19861023, 0.3,   -1.3,  -3.1
142.15,    19861024, 1.3,   0.2,   2.7
141.965,   19861027, -0.1,  0.5,   5.1
141.76,    19861028, -0.1,  1.2,   5.1
142.495,   19861029, 0.5,   1.8,   5.4
143.13,    19861030, 0.4,   2.0,   4.9
144.78,    19861031, 1.2,   1.9,   4.8
144.48,    19861103, -0.2,  1.8,   4.3
144.81,    19861104, 0.2,   2.2,   3.3
145.965,   19861105, 0.8,   2.4,   4.6
146.185,   19861106, 0.2,   2.1,   6.2
145.41,    19861107, -0.5,  0.4,   5.7
144.91,    19861110, -0.3,  0.3,   5.3
144.295,   19861111, -0.4,  -0.4,  6.5
145.35,    19861112, 0.7,   -0.4,  5.7
145.58,    19861113, 0.2,   -0.4,  5.3
143.415,   19861114, -1.5,  -1.4,  3.8
143.505,   19861117, 0.1,   -1.0,  3.5
143.195,   19861118, -0.2,  -0.8,  2.9
140.66,    19861119, -1.8,  -3.2,  1.7
139.545,   19861120, -0.8,  -4.1,  0.2
141.31,    19861121, 1.3,   -1.5,  1.5
142.9,     19861124, 1.1,   -0.4,  2.2
144.495,   19861125, 1.1,   0.9,   2.7
145.77,    19861126, 0.9,   3.6,   3.4
146.87,    19861128, 0.8,   5.2,   3.4
147.94,    19861201, 0.7,   4.7,   4.3
146.855,   19861202, -0.7,  2.8,   4.0
148.865,   19861203, 1.4,   3.0,   6.3
150.085,   19861204, 0.8,   3.0,   7.3
149.98,    19861205, -0.1,  2.1,   6.9
149.045,   19861208, -0.6,  0.7,   4.9
147.845,   19861209, -0.8,  0.7,   4.1
146.865,   19861210, -0.7,  -1.3,  3.6
146.955,   19861211, 0.1,   -2.1,  3.1
146.075,   19861212, -0.6,  -2.6,  2.1
144.98,    19861215, -0.7,  -2.7,  0.1
144.075,   19861216, -0.6,  -2.5,  -0.3
144.49,    19861217, 0.3,   -1.6,  -0.2
143.045,   19861218, -1.0,  -2.7,  -2.0
142.855,   19861219, -0.1,  -2.2,  -2.3
143.77,    19861222, 0.6,   -0.8,  -1.1
144.085,   19861223, 0.2,   0.0,   -0.6
142.805,   19861224, -0.9,  -1.2,  -1.0
142.795,   19861226, -0.0,  -0.2,  -1.8
142.935,   19861229, 0.1,   0.1,   -1.8
141.515,   19861230, -1.0,  -1.6,  -1.3
141.525,   19861231, 0.0,   -1.8,  -1.4
141.405,   19870102, -0.1,  -1.0,  -1.3
142.86,    19870105, 1.0,   0.0,   1.6
147.405,   19870106, 3.2,   3.1,   5.6
149.06,    19870107, 1.1,   5.3,   5.5
152.975,   19870108, 2.6,   8.1,   7.1
155.69,    19870109, 1.8,   10.1,  7.7
156.99,    19870112, 0.8,   9.9,   7.7
159.305,   19870113, 1.5,   8.1,   8.5
159.62,    19870114, 0.2,   7.1,   7.9
162.66,    19870115, 1.9,   6.3,   10.8
164.145,   19870116, 0.9,   5.4,   10.3
162.515,   19870119, -1.0,  3.5,   8.3
166.455,   19870120, 2.4,   4.5,   11.0
166.52,    19870121, 0.0,   4.3,   11.7
164.26,    19870122, -1.4,  1.0,   11.1
167.945,   19870123, 2.2,   2.3,   14.4
166.765,   19870126, -0.7,  2.6,   13.5
164.14,    19870127, -1.6,  -1.4,  12.4
166.655,   19870128, 1.5,   0.1,   15.0
167.625,   19870129, 0.6,   2.0,   16.3
165.71,    19870130, -1.1,  -1.3,  14.7
166.705,   19870202, 0.6,   -0.0,  16.5
170.305,   19870203, 2.2,   3.8,   19.2
171.555,   19870204, 0.7,   2.9,   19.3
173.5,     19870205, 1.1,   3.5,   20.4
174.335,   19870206, 0.5,   5.2,   22.1
173.225,   19870209, -0.6,  3.9,   21.3
173.625,   19870210, 0.2,   1.9,   21.5
172.325,   19870211, -0.7,  0.4,   21.8
175.985,   19870212, 2.1,   1.4,   24.3
176.265,   19870213, 0.2,   1.1,   24.7
179.065,   19870217, 1.6,   3.4,   25.3
183.65,    19870218, 2.6,   5.8,   24.6
181.21,    19870219, -1.3,  5.2,   21.6
179.87,    19870220, -0.7,  2.2,   17.6
178.685,   19870223, -0.7,  1.4,   14.8
177.705,   19870224, -0.5,  -0.8,  13.2
178.94,    19870225, 0.7,   -2.6,  12.3
180.97,    19870226, 1.1,   -0.1,  13.4
182.185,   19870227, 0.7,   1.3,   12.0
182.75,    19870302, 0.3,   2.3,   11.3
181.065,   19870303, -0.9,  1.9,   11.4
180.565,   19870304, -0.3,  0.9,   8.5
183.275,   19870305, 1.5,   1.3,   10.1
184.67,    19870306, 0.8,   1.4,   12.4
184.285,   19870309, -0.2,  0.8,   9.7
182.475,   19870310, -1.0,  0.8,   9.4
184.465,   19870311, 1.1,   2.2,   12.4
185.31,    19870312, 0.5,   1.1,   11.2
184.77,    19870313, -0.3,  0.1,   10.2
183.165,   19870316, -0.9,  -0.6,  10.5
182.73,    19870317, -0.2,  0.1,   9.6
185.9,     19870318, 1.7,   0.8,   9.2
186.045,   19870319, 0.1,   0.4,   8.4
187.96,    19870320, 1.0,   1.7,   8.3
189.825,   19870323, 1.0,   3.6,   8.9
189.4,     19870324, -0.2,  3.7,   9.3
190.18,    19870325, 0.4,   2.3,   9.5
190.195,   19870326, 0.0,   2.2,   10.4
190.705,   19870327, 0.3,   1.5,   8.4
189.05,    19870330, -0.9,  -0.4,  7.3
183.835,   19870331, -2.8,  -2.9,  2.7
186.04,    19870401, 1.2,   -2.2,  1.3
186.075,   19870402, 0.0,   -2.2,  2.7
179.815,   19870403, -3.4,  -5.7,  -0.0
191.36,    19870406, 6.4,   1.2,   7.1
190.915,   19870407, -0.2,  3.9,   7.4
187.435,   19870408, -1.8,  0.7,   4.7
189.28,    19870409, 1.0,   1.7,   4.6
188.73,    19870410, -0.3,  5.0,   3.6
188.285,   19870413, -0.2,  -1.6,  3.0
183.945,   19870414, -2.3,  -3.7,  1.6
179.725,   19870415, -2.3,  -4.1,  -0.5
182.46,    19870416, 1.5,   -3.6,  -0.4
183.745,   19870420, 0.7,   -2.6,  -0.5
182.125,   19870421, -0.9,  -3.3,  -1.2
184.635,   19870422, 1.4,   0.4,   1.2
183.955,   19870423, -0.4,  2.4,   -0.3
183.77,    19870424, -0.1,  0.7,   -0.8
180.375,   19870427, -1.8,  -1.8,  -2.4
179.55,    19870428, -0.5,  -1.4,  -2.0
181.265,   19870429, 1.0,   -1.8,  -0.8
182.22,    19870430, 0.5,   -0.9,  -2.0
184.83,    19870501, 1.4,   0.6,   -0.7
185.83,    19870504, 0.5,   3.0,   -1.1
186.45,    19870505, 0.3,   3.8,   -1.8
189.735,   19870506, 1.8,   4.7,   0.2
189.295,   19870507, -0.2,  3.9,   -0.5
190.015,   19870508, 0.4,   2.8,   -0.1
190.36,    19870511, 0.2,   2.4,   -0.2
189.24,    19870512, -0.6,  1.5,   0.1
188.975,   19870513, -0.1,  -0.4,  2.8
190.295,   19870514, 0.7,   0.5,   2.3
190.74,    19870515, 0.2,   0.4,   2.5
187.91,    19870518, -1.5,  -1.3,  4.5
185.455,   19870519, -1.3,  -2.0,  -3.1
181.465,   19870520, -2.2,  -4.0,  -4.9
181.7,     19870521, 0.1,   -4.5,  -3.1
182.66,    19870522, 0.5,   -4.2,  -3.5
181.64,    19870526, -0.6,  -3.3,  -3.8
185.17,    19870527, 1.9,   -0.2,  -1.7
185.955,   19870528, 0.4,   2.5,   1.1
188.11,    19870529, 1.2,   3.5,   4.7
188.77,    19870601, 0.4,   3.3,   3.5
185.86,    19870602, -1.5,  2.3,   1.2
184.5,     19870603, -0.7,  -0.4,  1.3
185.76,    19870604, 0.7,   -0.1,  0.6
186.255,   19870605, 0.3,   -1.0,  1.3
185.685,   19870608, -0.3,  -1.6,  1.0
187.535,   19870609, 1.0,   0.9,   4.0
189.475,   19870610, 1.0,   2.7,   5.5
190.02,    19870611, 0.3,   2.3,   4.8
189.665,   19870612, -0.2,  1.8,   4.1
190.36,    19870615, 0.4,   2.5,   3.0
189.6,     19870616, -0.4,  1.1,   2.0
191.14,    19870617, 0.8,   0.9,   2.5
191.32,    19870618, 0.1,   0.7,   0.8
192.37,    19870619, 0.5,   1.4,   1.6
192.05,    19870622, -0.2,  0.9,   1.1
192.9,     19870623, 0.4,   1.7,   1.3
191.84,    19870624, -0.5,  0.4,   1.4
191.905,   19870625, 0.0,   0.3,   1.6
191.345,   19870626, -0.3,  -0.5,  0.6
191.06,    19870629, -0.1,  -0.5,  0.2
190.7,     19870630, -0.2,  -1.1,  1.5
189.24,    19870701, -0.8,  -1.4,  2.0
189.535,   19870702, 0.2,   -1.2,  4.4
190.385,   19870706, 0.4,   -0.5,  4.8
189.025,   19870707, -0.7,  -1.1,  3.5
187.755,   19870708, -0.7,  -1.5,  3.4
187.385,   19870709, -0.2,  -1.0,  1.2
187.975,   19870710, 0.3,   -0.8,  1.1
188.735,   19870713, 0.4,   -0.9,  0.3
190.42,    19870714, 0.9,   0.7,   0.9
194.985,   19870715, 2.4,   3.9,   4.9
194.87,    19870716, -0.1,  4.0,   5.6
196.04,    19870717, 0.6,   4.3,   5.5
195.865,   19870720, -0.1,  3.8,   5.2
193.86,    19870721, -1.0,  1.8,   4.4
193.195,   19870722, -0.3,  -0.9,  3.0
193.635,   19870723, 0.2,   -0.6,  2.2
191.505,   19870724, -1.1,  -2.3,  0.8
193.025,   19870727, 0.8,   -1.4,  1.8
193.195,   19870728, 0.1,   -0.3,  1.5
193.755,   19870729, 0.3,   0.3,   2.2
194.725,   19870730, 0.5,   0.6,   1.9
196.565,   19870731, 0.9,   2.6,   2.7
196.35,    19870803, -0.1,  1.7,   2.1
195.24,    19870804, -0.6,  1.1,   1.7
195.88,    19870805, 0.3,   1.1,   1.5
198.245,   19870806, 1.2,   1.8,   3.3
201.865,   19870807, 1.8,   2.7,   5.2
203.73,    19870810, 0.9,   3.8,   6.5
206.03,    19870811, 1.1,   5.5,   7.8
208.39,    19870812, 1.1,   6.4,   9.3
207.06,    19870813, -0.6,  4.4,   9.4
206.47,    19870814, -0.3,  2.3,   8.9
206.465,   19870817, -0.0,  1.3,   8.4
206.83,    19870818, 0.2,   0.4,   9.4
203.9,     19870819, -1.4,  -2.2,  8.6
205.39,    19870820, 0.7,   -0.8,  9.6
208.93,    19870821, 1.7,   1.2,   11.1
210.575,   19870824, 0.8,   2.0,   11.6
209.48,    19870825, -0.5,  1.3,   10.0
210.715,   19870826, 0.6,   3.3,   8.1
211.16,    19870827, 0.2,   2.8,   8.4
210.635,   19870828, -0.2,  0.8,   7.4
208.845,   19870831, -0.8,  -0.8,  6.6
209.575,   19870901, 0.3,   0.0,   8.1
206.89,    19870902, -1.3,  -1.8,  7.1
205.73,    19870903, -0.6,  -2.6,  6.2
205.015,   19870904, -0.3,  -2.7,  7.1
204.06,    19870908, -0.5,  -2.3,  5.7
199.9,     19870909, -2.0,  -4.6,  3.5
201.6,     19870910, 0.9,   -2.6,  4.0
205.165,   19870911, 1.8,   -0.3,  5.4
206.365,   19870914, 0.6,   0.7,   5.0
205.16,    19870915, -0.6,  0.5,   4.5
202.255,   19870916, -1.4,  1.2,   3.6
201.3,     19870917, -0.5,  -0.1,  2.8
202.055,   19870918, 0.4,   -1.5,  1.9
201.675,   19870921, -0.2,  -2.3,  -0.1
197.26,    19870922, -2.2,  -3.9,  -3.2
201.43,    19870923, 2.1,   -0.4,  -2.2
204.075,   19870924, 1.3,   1.4,   -2.1
204.64,    19870925, 0.3,   1.3,   -1.2
205.115,   19870928, 0.2,   1.7,   -0.7
204.625,   19870929, -0.2,  3.7,   -0.9
204.15,    19870930, -0.2,  1.4,   -1.3
205.505,   19871001, 0.7,   0.7,   0.8
209.705,   19871002, 2.0,   2.5,   2.1
212.145,   19871005, 1.2,   3.4,   1.5
213.81,    19871006, 0.8,   4.5,   1.5
208.07,    19871007, -2.7,  1.9,   -0.7
206.89,    19871008, -0.6,  0.7,   -1.8
203.625,   19871009, -1.6,  -2.9,  -3.6
202.465,   19871012, -0.6,  -4.6,  -3.9
199.625,   19871013, -1.4,  -6.6,  -4.4
201.23,    19871014, 0.8,   -3.3,  -4.0
197.495,   19871015, -1.9,  -4.5,  -4.5
193.065,   19871016, -2.2,  -5.2,  -6.2
183.285,   19871019, -5.1,  -9.5,  -10.6
155.65,    19871020, -15.1, -22.0, -23.7
143.96,    19871021, -7.5,  -28.5, -28.0
158.78,    19871022, 10.3,  -19.6, -21.2
149.985,   19871023, -5.5,  -22.3, -26.9
145.42,    19871026, -3.0,  -20.7, -29.5
128.425,   19871027, -11.7, -17.5, -37.4
129.865,   19871028, 1.1,   -9.8,  -35.8
131.205,   19871029, 1.0,   -17.4, -34.8
143.64,    19871030, 9.5,   -4.2,  -28.9
150.07,    19871102, 4.5,   3.2,   -25.6
152.195,   19871103, 1.4,   18.5,  -22.8
147.135,   19871104, -3.3,  13.3,  -27.0
147.945,   19871105, 0.6,   12.8,  -27.5
153.03,    19871106, 3.4,   6.5,   -25.2
150.68,    19871109, -1.5,  0.4,   -26.5
146.485,   19871110, -2.8,  -3.8,  -28.4
143.445,   19871111, -2.1,  -2.5,  -29.7
145.48,    19871112, 1.4,   -1.7,  -29.2
149.845,   19871113, 3.0,   -2.1,  -28.5
147.95,    19871116, -1.3,  -1.8,  -30.3
147.46,    19871117, -0.3,  0.7,   -31.0
143.575,   19871118, -2.6,  0.1,   -31.0
144.835,   19871119, 0.9,   -0.4,  -30.0
141.06,    19871120, -2.6,  -5.9,  -30.7
140.835,   19871123, -0.2,  -4.8,  -30.4
141.84,    19871124, 0.7,   -3.8,  -28.9
144.66,    19871125, 2.0,   0.8,   -28.1
145.0,     19871127, 0.2,   0.1,   -26.6
143.355,   19871130, -1.1,  1.6,   -25.7
137.125,   19871201, -4.3,  -2.6,  -25.2
137.16,    19871202, 0.0,   -3.3,  -11.9
137.955,   19871203, 0.6,   -4.6,  -4.2
132.95,    19871204, -3.6,  -8.3,  -16.3
130.435,   19871207, -1.9,  -9.0,  -13.0
133.145,   19871208, 2.1,   -2.9,  -8.4
136.69,    19871209, 2.7,   -0.3,  6.4
139.635,   19871210, 2.2,   1.2,   7.5
139.015,   19871211, -0.4,  4.6,   6.0
139.82,    19871214, 0.6,   7.2,   -2.7
146.08,    19871215, 4.5,   9.7,   -2.7
146.89,    19871216, 0.6,   7.5,   -3.5
151.06,    19871217, 2.8,   8.2,   2.7
149.91,    19871218, -0.8,  7.8,   1.3
154.745,   19871221, 3.2,   10.7,  1.1
156.02,    19871222, 0.8,   6.8,   3.5
155.555,   19871223, -0.3,  5.9,   6.2
158.06,    19871224, 1.6,   4.6,   10.2
158.185,   19871228, 0.1,   5.5,   8.7
152.78,    19871229, -3.4,  -1.3,  2.0
154.345,   19871230, 1.0,   -1.1,  4.3
157.285,   19871231, 1.9,   1.1,   6.7
156.25,    19880104, -0.7,  -1.1,  8.8
161.81,    19880105, 3.6,   2.3,   11.7
164.28,    19880106, 1.5,   7.5,   16.5
166.175,   19880107, 1.2,   7.7,   18.0
168.7,     19880108, 1.5,   7.3,   18.9
158.195,   19880111, -6.2,  1.2,   9.4
158.875,   19880112, 0.4,   -1.8,  9.6
155.725,   19880113, -2.0,  -5.2,  8.6
155.545,   19880114, -0.1,  -6.4,  13.4
156.035,   19880115, 0.3,   -7.5,  13.8
159.825,   19880118, 2.4,   1.0,   15.9
159.025,   19880119, -0.5,  0.1,   19.6
158.01,    19880120, -0.6,  1.5,   21.1
152.465,   19880121, -3.5,  -2.0,  14.5
152.95,    19880122, 0.3,   -2.0,  11.9
154.355,   19880125, 0.9,   -3.4,  10.5
157.055,   19880126, 1.7,   -1.2,  13.0
155.23,    19880127, -1.2,  -1.8,  11.0
155.425,   19880128, 0.1,   1.9,   6.4
157.655,   19880129, 1.4,   3.1,   7.3
159.13,    19880201, 0.9,   3.1,   5.3
158.615,   19880202, -0.3,  1.0,   5.8
158.975,   19880203, 0.2,   2.4,   2.7
156.58,    19880204, -1.5,  0.7,   0.4
157.38,    19880205, 0.5,   -0.2,  1.2
158.015,   19880208, 0.4,   -0.7,  -0.0
157.875,   19880209, -0.1,  -0.5,  -0.2
158.93,    19880210, 0.7,   -0.0,  4.0
162.24,    19880211, 2.1,   3.6,   5.1
162.955,   19880212, 0.4,   3.5,   3.6
163.935,   19880216, 0.6,   3.7,   4.9
165.54,    19880217, 1.0,   4.9,   2.3
165.805,   19880218, 0.2,   4.3,   0.9
165.75,    19880219, -0.0,  2.2,   -0.3
167.075,   19880222, 0.8,   2.5,   -1.0
170.275,   19880223, 1.9,   3.9,   7.6
171.285,   19880224, 0.6,   3.5,   7.8
172.475,   19880225, 0.7,   4.0,   10.8
170.785,   19880226, -1.0,  3.0,   9.8
170.645,   19880229, -0.1,  2.1,   9.4
172.64,    19880301, 1.2,   1.4,   8.0
172.635,   19880302, -0.0,  0.8,   8.6
175.115,   19880303, 1.4,   1.5,   10.8
176.5,     19880304, 0.8,   3.3,   15.8
177.39,    19880307, 0.5,   4.0,   16.0
178.465,   19880308, 0.6,   3.4,   15.6
179.625,   19880309, 0.6,   4.0,   14.4
181.24,    19880310, 0.9,   3.5,   16.8
178.435,   19880311, -1.5,  1.1,   14.8
177.89,    19880314, -0.3,  0.3,   12.8
179.21,    19880315, 0.7,   0.4,   12.6
178.315,   19880316, -0.5,  -0.7,  12.4
180.42,    19880317, 1.2,   -0.5,  13.5
181.235,   19880318, 0.5,   1.6,   15.7
181.035,   19880321, -0.1,  1.8,   15.0
178.9,     19880322, -1.2,  -0.2,  13.2
180.015,   19880323, 0.6,   1.0,   14.0
179.245,   19880324, -0.4,  -0.7,  12.8
174.765,   19880325, -2.5,  -3.6,  7.7
172.88,    19880328, -1.1,  -4.5,  6.1
172.84,    19880329, -0.0,  -3.4,  5.4
173.665,   19880330, 0.5,   -3.5,  4.9
172.13,    19880331, -0.9,  -4.0,  3.8
173.26,    19880404, 0.7,   -0.9,  4.5
171.405,   19880405, -1.1,  -0.9,  2.6
172.895,   19880406, 0.9,   0.0,   1.5
176.6,     19880407, 2.1,   1.7,   3.1
177.3,     19880408, 0.4,   3.0,   2.8
179.39,    19880411, 1.2,   3.5,   5.0
180.12,    19880412, 0.4,   5.1,   5.6
180.105,   19880413, -0.0,  4.2,   4.3
180.22,    19880414, 0.1,   2.0,   4.4
173.165,   19880415, -3.9,  -2.3,  -1.1
173.17,    19880418, 0.0,   -3.5,  -1.9
173.915,   19880419, 0.4,   -3.4,  -2.0
174.27,    19880420, 0.2,   -3.2,  -2.4
173.23,    19880421, -0.6,  -3.9,  -3.6
171.89,    19880422, -0.8,  -0.7,  -5.2
173.375,   19880425, 0.9,   0.1,   -2.8
174.48,    19880426, 0.6,   0.3,   -1.9
176.74,    19880427, 1.3,   1.4,   -1.4
177.28,    19880428, 0.3,   2.3,   -0.6
176.74,    19880429, -0.3,  2.8,   -2.0
175.935,   19880502, -0.5,  1.5,   -2.9
176.85,    19880503, 0.5,   1.4,   -2.3
179.115,   19880504, 1.3,   1.3,   0.1
178.53,    19880505, -0.3,  0.7,   -0.8
177.7,     19880506, -0.5,  0.5,   -0.9
177.11,    19880509, -0.3,  0.7,   1.3
174.81,    19880510, -1.3,  -1.2,  1.1
174.005,   19880511, -0.5,  -2.9,  0.7
170.82,    19880512, -1.8,  -4.3,  -1.6
171.97,    19880513, 0.7,   -3.2,  -0.1
174.045,   19880516, 1.2,   -1.7,  0.5
175.245,   19880517, 0.7,   0.2,   2.2
174.07,    19880518, -0.7,  0.0,   0.7
170.145,   19880519, -2.3,  -0.4,  -3.7
170.445,   19880520, 0.2,   -0.9,  -3.9
169.74,    19880523, -0.4,  -2.5,  -5.4
167.795,   19880524, -1.1,  -4.3,  -6.8
169.23,    19880525, 0.9,   -2.8,  -6.0
169.065,   19880526, -0.1,  -0.6,  -6.2
169.695,   19880527, 0.4,   -0.4,  -2.0
169.515,   19880531, -0.1,  -0.1,  -2.1
173.485,   19880601, 2.3,   3.4,   -0.2
176.535,   19880602, 1.8,   4.3,   1.3
176.67,    19880603, 0.1,   4.5,   2.0
177.925,   19880606, 0.7,   4.8,   3.5
179.585,   19880607, 0.9,   5.9,   3.6
179.865,   19880608, 0.2,   3.7,   3.1
183.015,   19880609, 1.8,   3.7,   3.6
182.83,    19880610, -0.1,  3.5,   3.1
184.49,    19880613, 0.9,   3.7,   4.4
185.09,    19880614, 0.3,   3.1,   5.2
185.54,    19880615, 0.2,   3.2,   4.9
185.78,    19880616, 0.1,   1.5,   3.7
184.69,    19880617, -0.6,  1.0,   3.5
184.0,     19880620, -0.4,  -0.3,  3.5
183.385,   19880621, -0.3,  -0.9,  3.5
184.93,    19880622, 0.8,   -0.3,  5.8
186.975,   19880623, 1.1,   0.6,   7.5
187.155,   19880624, 0.1,   1.3,   9.6
187.095,   19880627, -0.0,  1.7,   8.8
185.085,   19880628, -1.1,  0.9,   6.3
188.27,    19880629, 1.7,   1.8,   7.4
187.725,   19880630, -0.3,  0.4,   7.8
189.23,    19880701, 0.8,   1.1,   11.2
189.945,   19880705, 0.4,   1.5,   11.4
191.83,    19880706, 1.0,   3.6,   13.0
190.34,    19880707, -0.8,  1.1,   13.4
189.215,   19880708, -0.6,  0.8,   11.8
188.42,    19880711, -0.4,  -0.4,  11.4
188.31,    19880712, -0.1,  -0.9,  11.0
187.235,   19880713, -0.6,  -2.4,  10.5
188.18,    19880714, 0.5,   -1.1,  8.5
188.57,    19880715, 0.2,   -0.3,  6.8
188.585,   19880718, 0.0,   0.1,   6.7
188.525,   19880719, -0.0,  0.1,   6.0
185.845,   19880720, -1.4,  -0.7,  3.5
184.92,    19880721, -0.5,  -1.7,  2.8
182.38,    19880722, -1.4,  -3.3,  -0.3
181.825,   19880725, -0.3,  -3.6,  -0.5
181.3,     19880726, -0.3,  -3.8,  -1.7
179.735,   19880727, -0.9,  -3.3,  -2.9
177.455,   19880728, -1.3,  -4.0,  -4.4
178.51,    19880729, 0.6,   -2.1,  -3.9
181.155,   19880801, 1.5,   -0.4,  -1.9
181.435,   19880802, 0.2,   0.1,   -1.4
180.69,    19880803, -0.4,  0.5,   -1.5
181.145,   19880804, 0.3,   2.1,   -2.0
181.17,    19880805, 0.0,   1.5,   -3.1
180.035,   19880808, -0.6,  -0.6,  -3.8
179.945,   19880809, -0.0,  -0.8,  -3.8
177.075,   19880810, -1.6,  -2.0,  -4.3
172.965,   19880811, -2.3,  -4.5,  -8.1
174.52,    19880812, 0.9,   -3.7,  -7.0
173.63,    19880815, -0.5,  -3.6,  -8.2
170.085,   19880816, -2.0,  -5.5,  -10.5
172.545,   19880817, 1.4,   -2.6,  -10.1
172.885,   19880818, 0.2,   -0.0,  -9.2
173.69,    19880819, 0.5,   -0.5,  -8.2
172.73,    19880822, -0.6,  -0.5,  -8.3
169.915,   19880823, -1.6,  -0.1,  -9.8
169.715,   19880824, -0.1,  -1.6,  -9.4
171.49,    19880825, 1.0,   -0.8,  -8.9
170.285,   19880826, -0.7,  -2.0,  -9.7
171.135,   19880829, 0.5,   -0.9,  -9.3
172.845,   19880830, 1.0,   1.7,   -8.3
172.455,   19880831, -0.2,  1.6,   -7.2
170.935,   19880901, -0.9,  -0.3,  -7.6
168.73,    19880902, -1.3,  -0.9,  -7.5
171.61,    19880906, 1.7,   0.3,   -5.6
172.145,   19880907, 0.3,   -0.4,  -5.0
172.065,   19880908, -0.0,  -0.2,  -4.3
173.075,   19880909, 0.6,   1.3,   -2.5
175.64,    19880912, 1.5,   4.1,   -1.6
175.095,   19880913, -0.3,  2.0,   -3.3
176.545,   19880914, 0.8,   2.6,   -2.7
176.99,    19880915, 0.3,   2.9,   -2.0
175.645,   19880916, -0.8,  1.5,   -3.0
176.625,   19880919, 0.6,   0.6,   -2.5
175.74,    19880920, -0.5,  0.4,   -2.4
176.78,    19880921, 0.6,   0.1,   -1.8
177.885,   19880922, 0.6,   0.5,   0.5
178.225,   19880923, 0.2,   1.5,   3.0
177.55,    19880926, -0.4,  0.5,   1.7
175.895,   19880927, -0.9,  0.1,   1.3
175.845,   19880928, -0.0,  -0.5,  3.4
177.08,    19880929, 0.7,   -0.5,  2.6
178.99,    19880930, 1.1,   0.4,   3.5
179.29,    19881003, 0.2,   1.0,   3.2
176.74,    19881004, -1.4,  0.5,   2.3
176.225,   19881005, -0.3,  0.2,   3.7
175.895,   19881006, -0.2,  -0.7,  3.6
175.88,    19881007, -0.0,  -1.7,  2.6
175.975,   19881010, 0.1,   -1.8,  3.3
175.075,   19881011, -0.5,  -0.9,  2.3
175.275,   19881012, 0.1,   -0.5,  1.4
173.155,   19881013, -1.2,  -1.6,  0.4
175.48,    19881014, 1.3,   -0.2,  2.7
175.49,    19881017, 0.0,   -0.3,  4.0
176.0,     19881018, 0.3,   0.5,   2.6
177.165,   19881019, 0.7,   1.1,   2.9
177.075,   19881020, -0.1,  2.3,   2.9
180.065,   19881021, 1.7,   2.6,   4.0
179.795,   19881024, -0.1,  2.5,   2.4
178.495,   19881025, -0.7,  1.4,   1.9
177.31,    19881026, -0.7,  0.1,   0.4
176.025,   19881027, -0.7,  -0.6,  -0.5
174.28,    19881028, -1.0,  -3.2,  -0.8
174.87,    19881031, 0.3,   -2.7,  -1.0
174.775,   19881101, -0.1,  -2.1,  -0.5
174.6,     19881102, -0.1,  -1.5,  -1.2
173.9,     19881103, -0.4,  -1.2,  -2.2
173.79,    19881104, -0.1,  -0.3,  -2.5
173.455,   19881107, -0.2,  -0.8,  -2.3
171.62,    19881108, -1.1,  -1.8,  -2.4
173.605,   19881109, 1.2,   -0.6,  -1.3
173.51,    19881110, -0.1,  -0.2,  -2.0
173.225,   19881111, -0.2,  -0.3,  -3.2
169.415,   19881114, -2.2,  -2.3,  -5.5
169.005,   19881115, -0.2,  -1.5,  -4.4
168.415,   19881116, -0.3,  -3.0,  -4.4
165.66,    19881117, -1.6,  -4.5,  -5.8
166.15,    19881118, 0.3,   -4.1,  -5.5
165.385,   19881121, -0.5,  -2.4,  -6.0
163.905,   19881122, -0.9,  -3.0,  -6.4
164.47,    19881123, 0.3,   -2.3,  -6.2
167.065,   19881125, 1.6,   0.8,   -3.5
165.64,    19881128, -0.9,  -0.3,  -5.6
166.14,    19881129, 0.3,   0.5,   -5.3
167.205,   19881130, 0.6,   2.0,   -5.0
169.135,   19881201, 1.2,   2.8,   -4.5
171.295,   19881202, 1.3,   2.5,   -3.3
172.855,   19881205, 0.9,   4.4,   -4.0
172.855,   19881206, 0.0,   4.0,   -3.9
174.015,   19881207, 0.7,   4.1,   -2.5
172.84,    19881208, -0.7,  2.2,   -2.5
171.885,   19881209, -0.6,  0.3,   -2.4
171.795,   19881212, -0.1,  -0.6,  -1.4
171.085,   19881213, -0.4,  -1.0,  -2.2
170.255,   19881214, -0.5,  -2.2,  -2.6
171.01,    19881215, 0.4,   -1.1,  -2.1
171.62,    19881216, 0.4,   -0.2,  -1.3
174.015,   19881219, 1.4,   1.3,   0.1
175.39,    19881220, 0.8,   2.5,   1.1
175.075,   19881221, -0.2,  2.8,   2.0
174.66,    19881222, -0.2,  2.1,   0.6
174.76,    19881223, 0.1,   1.8,   0.7
175.61,    19881227, 0.5,   0.9,   1.4
175.22,    19881228, -0.2,  -0.1,  3.4
175.13,    19881229, -0.1,  0.0,   3.6
176.68,    19881230, 0.9,   1.2,   4.9
177.4,     19890103, 0.4,   1.5,   7.1
174.925,   19890104, -1.4,  -0.4,  5.3
177.56,    19890105, 1.5,   1.3,   7.4
177.485,   19890106, -0.0,  1.3,   8.3
177.52,    19890109, 0.0,   0.5,   7.9
177.65,    19890110, 0.1,   0.1,   6.3
177.065,   19890111, -0.3,  1.2,   6.9
177.815,   19890112, 0.4,   0.1,   7.0
179.0,     19890113, 0.7,   0.9,   7.1
179.1,     19890116, 0.1,   0.9,   5.9
179.2,     19890117, 0.1,   0.9,   4.6
177.09,    19890118, -1.2,  0.0,   2.5
178.645,   19890119, 0.9,   0.5,   3.3
180.57,    19890120, 1.1,   0.9,   3.8
180.32,    19890123, -0.1,  0.7,   4.3
178.205,   19890124, -1.2,  -0.6,  3.7
180.175,   19890125, 1.1,   1.7,   4.9
181.56,    19890126, 0.8,   1.6,   6.1
183.995,   19890127, 1.3,   1.9,   8.1
183.545,   19890130, -0.2,  1.8,   7.3
184.785,   19890131, 0.7,   3.7,   7.7
186.47,    19890201, 0.9,   3.5,   7.2
188.37,    19890202, 1.0,   3.8,   7.4
189.865,   19890203, 0.8,   3.2,   8.4
189.975,   19890206, 0.1,   3.5,   8.8
189.465,   19890207, -0.3,  2.5,   8.4
192.055,   19890208, 1.4,   3.0,   9.4
190.05,    19890209, -1.0,  0.9,   8.5
188.69,    19890210, -0.7,  -0.6,  7.7
185.705,   19890213, -1.6,  -2.2,  5.1
185.405,   19890214, -0.2,  -2.1,  4.5
185.63,    19890215, 0.1,   -3.3,  6.1
187.415,   19890216, 1.0,   -1.4,  5.6
187.825,   19890217, 0.2,   -0.5,  5.8
188.51,    19890221, 0.4,   1.5,   6.2
188.17,    19890222, -0.2,  1.5,   5.9
185.76,    19890223, -1.3,  0.1,   4.9
186.47,    19890224, 0.4,   -0.5,  4.9
184.59,    19890227, -1.0,  -1.7,  3.1
183.815,   19890228, -0.4,  -2.5,  2.6
183.79,    19890301, -0.0,  -2.3,  2.6
183.6,     19890302, -0.1,  -1.2,  3.7
185.46,    19890303, 1.0,   -0.5,  3.8
185.815,   19890306, 0.2,   0.7,   2.9
187.505,   19890307, 0.9,   2.0,   4.0
186.7,     19890308, -0.4,  1.6,   4.8
186.255,   19890309, -0.2,  1.4,   3.4
185.35,    19890310, -0.5,  -0.1,  2.1
184.995,   19890313, -0.2,  -0.4,  0.5
185.88,    19890314, 0.5,   -0.9,  1.3
185.355,   19890315, -0.3,  -0.7,  0.3
185.365,   19890316, 0.0,   -0.5,  -0.6
187.135,   19890317, 1.0,   1.0,   -0.7
182.01,    19890320, -2.7,  -1.6,  -4.1
179.875,   19890321, -1.2,  -3.2,  -5.3
182.04,    19890322, 1.2,   -1.8,  -3.9
180.59,    19890323, -0.8,  -2.6,  -6.0
181.04,    19890327, 0.2,   -3.3,  -4.7
181.145,   19890328, 0.1,   -0.5,  -4.0
182.015,   19890329, 0.5,   1.2,   -2.0
183.34,    19890330, 0.7,   0.7,   -1.1
184.365,   19890331, 0.6,   2.1,   -0.7
185.87,    19890403, 0.8,   2.7,   -0.8
185.83,    19890404, -0.0,  2.6,   -1.1
185.535,   19890405, -0.2,  1.9,   -1.6
186.09,    19890406, 0.3,   1.5,   -1.1
186.2,     19890407, 0.1,   1.0,   0.2
188.4,     19890410, 1.2,   1.4,   1.0
189.275,   19890411, 0.5,   1.9,   2.5
190.91,    19890412, 0.9,   2.9,   3.9
192.53,    19890413, 0.8,   3.5,   4.8
191.83,    19890414, -0.4,  3.0,   4.5
194.63,    19890417, 1.5,   3.3,   4.9
194.59,    19890418, -0.0,  2.8,   4.7
196.83,    19890419, 1.2,   3.1,   5.0
198.07,    19890420, 0.6,   2.9,   6.1
197.65,    19890421, -0.2,  3.0,   6.1
198.86,    19890424, 0.6,   2.2,   7.3
198.315,   19890425, -0.3,  1.9,   7.2
197.24,    19890426, -0.5,  0.2,   6.1
197.25,    19890427, 0.0,   -0.4,  6.4
199.57,    19890428, 1.2,   1.0,   7.7
200.47,    19890501, 0.5,   0.8,   7.1
200.75,    19890502, 0.1,   1.2,   10.3
200.745,   19890503, -0.0,  1.8,   11.6
201.585,   19890504, 0.4,   2.2,   10.7
202.095,   19890505, 0.3,   1.3,   11.9
203.44,    19890508, 0.7,   1.5,   12.4
203.04,    19890509, -0.2,  1.1,   12.1
202.685,   19890510, -0.2,  1.0,   11.4
203.015,   19890511, 0.2,   0.7,   10.7
203.515,   19890512, 0.2,   0.7,   10.4
207.155,   19890515, 1.8,   1.8,   11.5
208.895,   19890516, 0.8,   2.9,   12.4
208.255,   19890517, -0.3,  2.7,   12.2
210.73,    19890518, 1.2,   3.8,   13.2
211.06,    19890519, 0.2,   3.7,   13.4
212.61,    19890522, 0.7,   2.6,   12.9
212.37,    19890523, -0.1,  1.7,   12.2
210.625,   19890524, -0.8,  1.1,   10.3
211.985,   19890525, 0.6,   0.6,   10.1
212.84,    19890526, 0.4,   0.8,   11.0
214.435,   19890530, 0.7,   0.9,   10.2
213.59,    19890531, -0.4,  0.6,   9.8
214.545,   19890601, 0.4,   1.9,   9.0
215.59,    19890602, 0.5,   1.7,   8.8
217.79,    19890605, 1.0,   2.3,   10.2
213.4,     19890606, -2.0,  -0.5,  7.3
214.395,   19890607, 0.5,   0.4,   8.1
219.88,    19890608, 2.6,   2.5,   11.5
219.39,    19890609, -0.2,  1.8,   11.2
218.795,   19890612, -0.3,  0.5,   9.6
218.6,     19890613, -0.1,  2.4,   9.0
216.545,   19890614, -0.9,  1.0,   7.9
216.665,   19890615, 0.1,   -1.5,  7.9
213.09,    19890616, -1.7,  -2.9,  5.7
212.815,   19890619, -0.1,  -2.7,  5.3
212.285,   19890620, -0.2,  -2.9,  4.3
211.04,    19890621, -0.6,  -2.5,  3.9
210.91,    19890622, -0.1,  -2.7,  4.1
212.22,    19890623, 0.6,   -0.4,  4.5
214.26,    19890626, 1.0,   0.7,   5.3
213.615,   19890627, -0.3,  0.6,   3.1
213.935,   19890628, 0.1,   1.4,   2.4
211.07,    19890629, -1.3,  0.1,   1.4
206.77,    19890630, -2.0,  -2.6,  -1.9
204.585,   19890703, -1.1,  -4.5,  -3.1
205.465,   19890705, 0.4,   -3.8,  -3.4
206.365,   19890706, 0.4,   -3.5,  -2.8
207.67,    19890707, 0.6,   -1.6,  -1.4
209.58,    19890710, 0.9,   1.4,   -1.1
209.33,    19890711, -0.1,  2.3,   -1.6
209.94,    19890712, 0.3,   2.2,   -2.1
210.87,    19890713, 0.4,   2.2,   -1.3
211.48,    19890714, 0.3,   1.8,   -1.4
212.865,   19890717, 0.7,   1.6,   -1.3
213.735,   19890718, 0.4,   2.1,   -1.9
211.385,   19890719, -1.1,  0.7,   -0.9
213.61,    19890720, 1.1,   1.3,   -0.4
211.095,   19890721, -1.2,  -0.2,  -4.0
210.865,   19890724, -0.1,  -0.9,  -3.9
208.115,   19890725, -1.3,  -2.6,  -4.9
208.015,   19890726, -0.0,  -1.6,  -4.8
209.895,   19890727, 0.9,   -1.7,  -3.1
212.755,   19890728, 1.4,   0.8,   -1.8
213.145,   19890731, 0.2,   1.1,   0.0
214.275,   19890801, 0.5,   3.0,   0.7
212.835,   19890802, -0.7,  2.3,   0.3
212.84,    19890803, 0.0,   1.4,   0.9
214.56,    19890804, 0.8,   0.8,   1.7
215.14,    19890807, 0.3,   0.9,   1.4
218.76,    19890808, 1.7,   2.1,   2.1
219.73,    19890809, 0.4,   3.2,   2.9
217.85,    19890810, -0.9,  2.4,   1.8
217.42,    19890811, -0.2,  1.3,   3.0
216.305,   19890814, -0.5,  0.5,   4.6
214.81,    19890815, -0.7,  -1.8,  5.0
215.87,    19890816, 0.5,   -1.8,  5.1
214.87,    19890817, -0.5,  -1.4,  4.1
216.15,    19890818, 0.6,   -0.6,  4.1
216.72,    19890821, 0.3,   0.2,   3.4
214.185,   19890822, -1.2,  -0.3,  2.3
215.385,   19890823, 0.6,   -0.2,  2.6
217.49,    19890824, 1.0,   1.2,   3.1
220.395,   19890825, 1.3,   2.0,   4.2
220.82,    19890828, 0.2,   1.9,   3.7
221.46,    19890829, 0.3,   3.4,   3.6
220.3,     19890830, -0.5,  2.3,   4.2
221.4,     19890831, 0.5,   1.8,   3.6
222.005,   19890901, 0.3,   0.7,   5.2
223.515,   19890905, 0.7,   1.2,   6.0
223.58,    19890906, 0.0,   1.0,   7.4
221.67,    19890907, -0.9,  0.6,   6.6
221.2,     19890908, -0.2,  -0.1,  5.4
222.655,   19890911, 0.7,   0.3,   4.7
222.96,    19890912, 0.1,   -0.2,  4.6
224.325,   19890913, 0.6,   0.3,   4.7
224.615,   19890914, 0.1,   1.3,   5.5
222.41,    19890915, -1.0,  0.5,   4.5
221.96,    19890918, -0.2,  -0.3,  3.4
221.75,    19890919, -0.1,  -0.5,  3.1
221.935,   19890920, 0.1,   -1.1,  1.5
222.305,   19890921, 0.2,   -1.0,  1.2
222.655,   19890922, 0.2,   0.1,   2.2
223.89,    19890925, 0.6,   0.9,   3.0
223.89,    19890926, 0.0,   1.0,   3.5
223.87,    19890927, -0.0,  0.9,   4.2
223.805,   19890928, -0.0,  0.7,   3.7
226.25,    19890929, 1.1,   1.6,   5.3
226.415,   19891002, 0.1,   1.1,   4.7
228.11,    19891003, 0.7,   1.9,   5.3
228.735,   19891004, 0.3,   2.2,   6.8
230.9,     19891005, 0.9,   3.2,   7.2
231.74,    19891006, 0.4,   2.4,   6.6
234.405,   19891009, 1.1,   3.5,   6.4
236.785,   19891010, 1.0,   3.8,   7.2
236.085,   19891011, -0.3,  3.2,   6.6
235.085,   19891012, -0.4,  1.8,   6.7
234.545,   19891013, -0.2,  1.2,   5.9
224.665,   19891016, -4.2,  -4.2,  1.2
224.945,   19891017, 0.1,   -5.0,  0.6
224.245,   19891018, -0.3,  -5.0,  0.3
226.525,   19891019, 1.0,   -3.6,  2.2
231.53,    19891020, 2.2,   -1.3,  4.7
231.05,    19891023, -0.2,  2.8,   3.8
228.76,    19891024, -1.0,  1.7,   2.6
226.175,   19891025, -1.1,  0.9,   0.8
226.115,   19891026, -0.0,  -0.2,  0.7
222.055,   19891027, -1.8,  -4.1,  -0.2
218.84,    19891030, -1.4,  -5.3,  -1.4
219.075,   19891031, 0.1,   -4.2,  -1.2
222.615,   19891101, 1.6,   -1.6,  0.3
223.31,    19891102, 0.3,   -1.2,  0.5
219.45,    19891103, -1.7,  -1.2,  -1.4
219.055,   19891106, -0.2,  0.1,   -2.2
215.185,   19891107, -1.8,  -1.8,  -3.9
217.65,    19891108, 1.1,   -2.2,  -2.8
220.37,    19891109, 1.2,   -1.3,  -1.5
220.65,    19891110, 0.1,   0.5,   -2.5
222.53,    19891113, 0.9,   1.6,   -1.7
222.755,   19891114, 0.1,   3.5,   -2.3
221.37,    19891115, -0.6,  1.7,   -3.2
222.345,   19891116, 0.4,   0.9,   -3.7
221.7,     19891117, -0.3,  0.5,   -4.3
222.63,    19891120, 0.4,   0.0,   -5.0
223.415,   19891121, 0.4,   0.3,   -5.6
222.76,    19891122, -0.3,  0.6,   -5.6
223.63,    19891124, 0.4,   0.6,   -4.9
224.835,   19891127, 0.5,   1.4,   -4.1
223.645,   19891128, -0.5,  0.5,   -0.5
223.585,   19891129, -0.0,  0.1,   -0.6
223.495,   19891130, -0.0,  0.3,   -0.3
224.445,   19891201, 0.4,   0.4,   -0.9
225.315,   19891204, 0.4,   0.2,   -2.7
226.72,    19891205, 0.6,   1.4,   -1.9
227.305,   19891206, 0.3,   1.7,   -0.6
224.155,   19891207, -1.4,  0.3,   -0.9
224.765,   19891208, 0.3,   0.1,   -0.6
224.025,   19891211, -0.3,  -0.6,  0.9
220.265,   19891212, -1.7,  -2.8,  0.7
217.805,   19891213, -1.1,  -4.2,  -0.6
217.91,    19891214, 0.0,   -2.8,  -2.1
216.355,   19891215, -0.7,  -3.7,  -3.1
214.14,    19891218, -1.0,  -4.4,  -2.4
210.85,    19891219, -1.5,  -4.3,  -3.7
211.185,   19891220, 0.2,   -3.0,  -1.9
213.235,   19891221, 1.0,   -2.1,  -2.0
216.23,    19891222, 1.4,   -0.1,  -1.9
217.625,   19891226, 0.6,   1.6,   -1.4
217.615,   19891227, -0.0,  3.2,   -2.2
221.575,   19891228, 1.8,   4.9,   -0.5
221.58,    19891229, 0.0,   3.9,   0.1
223.835,   19900102, 1.0,   3.5,   0.7
227.725,   19900103, 1.7,   4.6,   2.7
226.1,     19900104, -0.7,  3.9,   1.6
224.715,   19900105, -0.6,  1.4,   0.6
223.14,    19900108, -0.7,  0.7,   0.2
223.54,    19900109, 0.2,   -0.1,  -0.0
221.18,    19900110, -1.1,  -2.9,  -1.6
216.555,   19900111, -2.1,  -4.2,  -3.2
213.505,   19900112, -1.4,  -5.0,  -4.5
208.795,   19900115, -2.2,  -6.4,  -6.6
207.615,   19900116, -0.6,  -7.1,  -7.5
211.915,   19900117, 2.1,   -4.2,  -5.9
210.605,   19900118, -0.6,  -2.7,  -7.1
210.57,    19900119, -0.0,  -1.4,  -7.4
213.91,    19900122, 1.6,   2.4,   -4.6
208.06,    19900123, -2.7,  0.2,   -7.4
207.48,    19900124, -0.3,  -2.1,  -7.4
206.745,   19900125, -0.4,  -1.8,  -6.1
205.775,   19900126, -0.5,  -2.3,  -5.5
203.245,   19900129, -1.2,  -5.0,  -6.7
202.055,   19900130, -0.6,  -2.9,  -6.6
197.655,   19900131, -2.2,  -4.7,  -7.7
201.94,    19900201, 2.2,   -2.3,  -4.2
203.045,   19900202, 0.5,   -1.3,  -3.9
205.615,   19900205, 1.3,   1.2,   -3.6
206.49,    19900206, 0.4,   2.2,   -4.5
205.075,   19900207, -0.7,  3.8,   -5.8
206.805,   19900208, 0.8,   2.4,   -5.0
207.245,   19900209, 0.2,   2.1,   -6.5
208.495,   19900212, 0.6,   1.4,   -5.9
206.88,    19900213, -0.8,  0.2,   -7.6
207.2,     19900214, 0.2,   1.0,   -9.0
208.06,    19900215, 0.4,   0.6,   -8.0
209.93,    19900216, 0.9,   1.3,   -6.6
208.355,   19900220, -0.8,  -0.1,  -6.6
205.065,   19900221, -1.6,  -0.9,  -8.3
204.75,    19900222, -0.2,  -1.2,  -7.4
204.19,    19900223, -0.3,  -1.9,  -5.7
201.795,   19900226, -1.2,  -3.9,  -5.5
204.65,    19900227, 1.4,   -1.8,  -2.0
205.785,   19900228, 0.6,   0.4,   -0.9
207.915,   19900301, 1.0,   1.5,   -1.9
209.105,   19900302, 0.6,   2.4,   -0.7
211.54,    19900305, 1.2,   4.8,   0.5
210.39,    19900306, -0.5,  2.8,   -1.6
210.96,    19900307, 0.3,   2.5,   1.4
210.155,   19900308, -0.4,  1.1,   1.3
213.11,    19900309, 1.4,   1.9,   3.1
213.82,    19900312, 0.3,   1.1,   3.9
213.525,   19900313, -0.1,  1.5,   5.1
211.51,    19900314, -0.9,  0.3,   4.7
212.305,   19900315, 0.4,   1.0,   7.4
214.08,    19900316, 0.8,   0.5,   6.0
217.96,    19900319, 1.8,   1.9,   7.3
219.87,    19900320, 0.9,   3.0,   6.9
216.925,   19900321, -1.3,  2.6,   5.1
215.85,    19900322, -0.5,  1.7,   5.3
212.54,    19900323, -1.5,  -0.7,  2.8
214.49,    19900326, 0.9,   -1.6,  3.5
216.435,   19900327, 0.9,   -1.6,  3.8
217.47,    19900328, 0.5,   0.3,   5.1
214.23,    19900329, -1.5,  -0.8,  3.4
213.11,    19900330, -0.5,  0.3,   2.4
213.145,   19900402, 0.0,   -0.6,  1.5
212.67,    19900403, -0.2,  -1.7,  2.1
216.81,    19900404, 1.9,   -0.3,  5.7
215.48,    19900405, -0.6,  0.6,   5.2
214.06,    19900406, -0.7,  0.4,   4.8
212.475,   19900409, -0.7,  -0.3,  5.3
212.115,   19900410, -0.2,  -0.3,  3.6
213.225,   19900411, 0.5,   -1.7,  3.6
214.525,   19900412, 0.6,   -0.4,  3.2
216.045,   19900416, 0.7,   0.9,   3.3
216.88,    19900417, 0.4,   2.1,   2.5
216.5,     19900418, -0.2,  2.1,   2.9
213.145,   19900419, -1.5,  -0.0,  1.0
210.87,    19900420, -1.1,  -1.7,  0.3
208.58,    19900423, -1.1,  -3.5,  -2.1
205.63,    19900424, -1.4,  -5.2,  -3.8
204.76,    19900425, -0.4,  -5.4,  -4.1
205.58,    19900426, 0.4,   -3.5,  -2.8
205.785,   19900427, 0.1,   -2.4,  -3.1
203.67,    19900430, -1.0,  -2.4,  -4.9
205.815,   19900501, 1.1,   0.1,   -5.6
207.04,    19900502, 0.6,   1.1,   -5.8
208.58,    19900503, 0.7,   1.5,   -3.8
210.09,    19900504, 0.7,   2.1,   -2.7
212.705,   19900507, 1.2,   4.4,   0.1
215.03,    19900508, 1.1,   4.5,   0.3
215.17,    19900509, 0.1,   3.9,   -0.6
215.43,    19900510, 0.1,   3.3,   -0.9
216.81,    19900511, 0.6,   3.2,   1.2
220.92,    19900514, 1.9,   3.9,   3.7
223.86,    19900515, 1.3,   4.1,   5.0
224.49,    19900516, 0.3,   4.3,   5.6
225.13,    19900517, 0.3,   4.5,   3.8
226.64,    19900518, 0.7,   4.5,   5.2
228.51,    19900521, 0.8,   3.4,   6.8
232.215,   19900522, 1.6,   3.7,   9.3
232.845,   19900523, 0.3,   3.7,   9.8
236.06,    19900524, 1.4,   4.9,   10.7
236.99,    19900525, 0.4,   4.6,   10.5
232.2,     19900529, -2.0,  1.6,   7.5
235.84,    19900530, 1.6,   1.6,   8.7
236.355,   19900531, 0.2,   1.5,   9.2
236.15,    19900601, -0.1,  0.0,   10.8
237.885,   19900604, 0.7,   0.4,   12.8
240.11,    19900605, 0.9,   3.4,   15.1
237.845,   19900606, -0.9,  0.9,   15.7
238.055,   19900607, 0.1,   0.7,   16.3
237.385,   19900608, -0.3,  0.5,   15.5
234.73,    19900611, -1.1,  -1.3,  14.1
236.95,    19900612, 0.9,   -1.3,  16.3
240.11,    19900613, 1.3,   1.0,   16.7
242.055,   19900614, 0.8,   1.7,   16.9
240.515,   19900615, -0.6,  1.3,   15.3
240.785,   19900618, 0.1,   2.6,   14.6
236.71,    19900619, -1.7,  -0.1,  11.3
236.775,   19900620, 0.0,   -1.4,  10.1
237.315,   19900621, 0.2,   -2.0,  10.3
238.145,   19900622, 0.3,   -1.0,  10.5
235.74,    19900625, -1.0,  -2.1,  8.7
232.915,   19900626, -1.2,  -1.6,  5.4
232.94,    19900627, 0.0,   -1.6,  4.1
235.26,    19900628, 1.0,   -0.9,  4.8
237.49,    19900629, 0.9,   -0.3,  5.5
238.46,    19900702, 0.4,   1.2,   5.2
238.42,    19900703, -0.0,  2.4,   4.3
238.185,   19900705, -0.1,  2.3,   2.6
237.3,     19900706, -0.4,  0.9,   1.9
238.465,   19900709, 0.5,   0.4,   1.0
239.6,     19900710, 0.5,   0.5,   1.1
238.66,    19900711, -0.4,  0.1,   2.8
240.775,   19900712, 0.9,   1.1,   2.1
243.425,   19900713, 1.1,   2.6,   3.0
244.74,    19900716, 0.5,   2.6,   3.6
246.18,    19900717, 0.6,   2.7,   3.5
241.525,   19900718, -1.9,  1.2,   0.6
238.22,    19900719, -1.4,  -1.1,  0.2
236.4,     19900720, -0.8,  -2.9,  -0.7
232.44,    19900723, -1.7,  -5.0,  -2.1
227.38,    19900724, -2.2,  -7.6,  -3.1
226.485,   19900725, -0.4,  -6.2,  -4.4
228.765,   19900726, 1.0,   -4.0,  -4.7
227.2,     19900727, -0.7,  -3.9,  -6.1
225.635,   19900730, -0.7,  -2.9,  -6.2
223.92,    19900731, -0.8,  -1.5,  -7.0
223.385,   19900801, -0.2,  -1.4,  -5.6
223.095,   19900802, -0.1,  -2.5,  -5.8
218.97,    19900803, -1.8,  -3.6,  -7.7
212.605,   19900806, -2.9,  -5.8,  -10.7
201.455,   19900807, -5.2,  -10.0, -14.5
202.655,   19900808, 0.6,   -9.3,  -13.0
205.58,    19900809, 1.4,   -7.9,  -11.7
209.125,   19900810, 1.7,   -4.5,  -11.1
206.23,    19900813, -1.4,  -3.0,  -13.2
207.42,    19900814, 0.6,   3.0,   -13.0
208.295,   19900815, 0.4,   2.8,   -12.6
208.465,   19900816, 0.1,   1.4,   -12.5
202.19,    19900817, -3.0,  -3.3,  -14.8
196.845,   19900820, -2.6,  -4.6,  -17.5
193.1,     19900821, -1.9,  -6.9,  -19.4
188.255,   19900822, -2.5,  -9.6,  -21.1
185.015,   19900823, -1.7,  -11.2, -23.2
178.295,   19900824, -3.6,  -11.8, -26.8
184.965,   19900827, 3.7,   -6.0,  -24.4
193.975,   19900828, 4.9,   0.5,   -21.2
194.905,   19900829, 0.5,   3.5,   -19.3
192.905,   19900830, -1.0,  4.3,   -19.0
190.725,   19900831, -1.1,  7.0,   -19.3
193.615,   19900904, 1.5,   4.7,   -16.7
194.745,   19900905, 0.6,   0.4,   -14.4
193.815,   19900906, -0.5,  -0.6,  -14.4
190.67,    19900907, -1.6,  -1.2,  -16.7
191.8,     19900910, 0.6,   0.6,   -15.6
192.65,    19900911, 0.4,   -0.5,  -14.6
190.965,   19900912, -0.9,  -1.9,  -14.7
192.355,   19900913, 0.7,   -0.8,  -13.9
189.72,    19900914, -1.4,  -0.5,  -15.0
189.52,    19900917, -0.1,  -1.2,  -13.4
189.605,   19900918, 0.0,   -1.6,  -10.8
189.43,    19900919, -0.1,  -0.8,  -6.0
188.775,   19900920, -0.3,  -1.9,  -6.8
183.98,    19900921, -2.5,  -3.0,  -10.5
183.2,     19900924, -0.4,  -3.3,  -12.4
177.605,   19900925, -3.1,  -6.3,  -13.9
180.71,    19900926, 1.7,   -4.6,  -12.9
179.455,   19900927, -0.7,  -4.9,  -13.8
174.19,    19900928, -2.9,  -5.3,  -16.4
177.055,   19901001, 1.6,   -3.4,  -12.4
184.745,   19901002, 4.3,   4.0,   -6.1
184.92,    19901003, 0.1,   2.3,   -4.2
180.505,   19901004, -2.4,  0.6,   -4.1
179.495,   19901005, -0.6,  3.0,   -3.0
178.365,   19901008, -0.6,  0.7,   0.0
179.565,   19901009, 0.7,   -2.8,  -2.9
173.625,   19901010, -3.3,  -6.1,  -10.5
169.465,   19901011, -2.4,  -6.1,  -13.1
165.195,   19901012, -2.5,  -8.0,  -14.4
166.715,   19901015, 0.9,   -6.5,  -12.6
169.35,    19901016, 1.6,   -5.7,  -12.5
165.49,    19901017, -2.3,  -4.7,  -15.0
167.27,    19901018, 1.1,   -1.3,  -13.7
172.38,    19901019, 3.1,   4.3,   -9.6
174.62,    19901022, 1.3,   4.7,   -9.0
178.565,   19901023, 2.3,   5.4,   -7.3
178.4,     19901024, -0.1,  7.8,   -6.6
178.47,    19901025, 0.0,   6.7,   -7.2
177.34,    19901026, -0.6,  2.9,   -6.5
174.21,    19901029, -1.8,  -0.2,  -8.1
171.755,   19901030, -1.4,  -3.8,  -9.4
172.995,   19901031, 0.7,   -3.0,  -8.7
172.555,   19901101, -0.3,  -3.3,  -8.6
172.885,   19901102, 0.2,   -2.5,  -6.0
176.865,   19901105, 2.3,   1.5,   -3.5
180.46,    19901106, 2.0,   5.1,   1.6
180.26,    19901107, -0.1,  4.2,   -0.2
177.08,    19901108, -1.8,  2.6,   -1.3
177.705,   19901109, 0.4,   2.8,   2.0
182.24,    19901112, 2.6,   3.0,   2.9
188.67,    19901113, 3.5,   4.5,   2.1
189.875,   19901114, 0.6,   5.3,   2.7
192.45,    19901115, 1.4,   8.7,   6.6
189.55,    19901116, -1.5,  6.7,   5.6
184.96,    19901119, -2.4,  1.5,   3.7
187.04,    19901120, 1.1,   -0.9,  4.2
183.315,   19901121, -2.0,  -3.5,  5.6
183.935,   19901123, 0.3,   -4.4,  8.5
184.28,    19901126, 0.2,   -2.8,  11.6
185.12,    19901127, 0.5,   0.1,   11.0
189.175,   19901128, 2.2,   1.1,   11.7
189.695,   19901129, 0.3,   3.5,   14.6
191.11,    19901130, 0.7,   3.9,   14.3
192.655,   19901203, 0.8,   4.5,   11.8
194.525,   19901204, 1.0,   5.1,   11.4
195.605,   19901205, 0.6,   3.4,   9.5
199.025,   19901206, 1.7,   4.9,   11.6
199.485,   19901207, 0.2,   4.4,   11.8
199.345,   19901210, -0.1,  3.5,   12.4
199.44,    19901211, 0.0,   2.5,   14.5
196.555,   19901212, -1.4,  0.5,   14.4
198.025,   19901213, 0.7,   -0.5,  14.5
198.03,    19901214, 0.0,   -0.7,  14.8
196.135,   19901217, -1.0,  -1.6,  13.4
195.055,   19901218, -0.6,  -2.2,  10.3
199.265,   19901219, 2.2,   1.4,   10.4
200.415,   19901220, 0.6,   1.2,   11.2
201.515,   19901221, 0.5,   1.8,   13.8
202.755,   19901224, 0.6,   3.4,   14.1
201.22,    19901226, -0.8,  3.2,   10.4
200.57,    19901227, -0.3,  0.7,   6.3
199.16,    19901228, -0.7,  -0.6,  4.9
199.42,    19901231, 0.1,   -1.0,  3.6
200.53,    19910102, 0.6,   -1.1,  5.8
199.765,   19910103, -0.4,  -0.7,  8.0
196.395,   19910104, -1.7,  -2.1,  5.0
196.825,   19910107, 0.2,   -1.2,  7.4
192.35,    19910108, -2.3,  -3.5,  4.6
192.09,    19910109, -0.1,  -4.2,  4.2
191.685,   19910110, -0.2,  -4.0,  3.5
196.08,    19910111, 2.3,   -0.2,  3.7
196.195,   19910114, 0.1,   -0.3,  3.4
193.77,    19910115, -1.2,  0.7,   1.4
195.335,   19910116, 0.8,   1.7,   1.4
202.955,   19910117, 3.9,   5.9,   4.3
209.26,    19910118, 3.1,   6.7,   7.0
211.495,   19910121, 1.1,   7.8,   6.3
214.365,   19910122, 1.4,   10.6,  7.5
211.975,   19910123, -1.1,  8.5,   6.3
215.83,    19910124, 1.8,   6.3,   8.2
219.785,   19910125, 1.8,   5.0,   11.8
221.015,   19910128, 0.6,   4.5,   11.6
222.24,    19910129, 0.6,   3.7,   12.2
224.47,    19910130, 1.0,   5.9,   14.4
229.275,   19910131, 2.1,   6.2,   17.5
232.425,   19910201, 1.4,   5.8,   16.6
234.45,    19910204, 0.9,   6.1,   17.0
236.87,    19910205, 1.0,   6.6,   17.5
241.565,   19910206, 2.0,   7.6,   19.1
247.055,   19910207, 2.3,   7.8,   22.8
243.425,   19910208, -1.5,  4.7,   21.4
244.475,   19910211, 0.4,   4.3,   22.8
249.305,   19910212, 2.0,   5.2,   25.0
248.2,     19910213, -0.4,  2.7,   23.8
250.335,   19910214, 0.9,   1.3,   25.3
245.59,    19910215, -1.9,  0.9,   25.0
248.335,   19910219, 1.1,   1.6,   26.2
250.045,   19910220, 0.7,   0.3,   30.0
247.76,    19910221, -0.9,  -0.2,  29.0
246.855,   19910222, -0.4,  -1.4,  28.8
248.635,   19910225, 0.7,   1.2,   26.8
250.04,    19910226, 0.6,   0.7,   27.4
247.805,   19910227, -0.9,  -0.9,  27.9
249.715,   19910228, 0.8,   0.8,   27.8
250.115,   19910301, 0.2,   1.3,   23.2
252.39,    19910304, 0.9,   1.5,   20.6
253.38,    19910305, 0.4,   1.3,   19.8
262.185,   19910306, 3.5,   5.8,   22.3
263.15,    19910307, 0.4,   5.4,   24.1
264.565,   19910308, 0.5,   5.8,   22.6
262.86,    19910311, -0.6,  4.1,   19.6
255.18,    19910312, -2.9,  0.7,   15.5
250.67,    19910313, -1.8,  -4.4,  12.8
257.07,    19910314, 2.6,   -2.3,  14.5
255.795,   19910315, -0.5,  -3.3,  11.6
254.63,    19910318, -0.5,  -3.1,  9.6
255.625,   19910319, 0.4,   0.2,   9.0
253.85,    19910320, -0.7,  1.3,   7.2
256.09,    19910321, 0.9,   -0.4,  6.0
252.97,    19910322, -1.2,  -1.1,  2.4
252.27,    19910325, -0.3,  -0.9,  3.6
255.775,   19910326, 1.4,   0.1,   4.6
264.67,    19910327, 3.5,   4.3,   6.2
266.555,   19910328, 0.7,   4.1,   7.4
264.905,   19910401, -0.6,  4.7,   5.8
264.595,   19910402, -0.1,  4.9,   7.7
273.85,    19910403, 3.5,   7.1,   10.3
275.15,    19910404, 0.5,   4.0,   10.0
277.285,   19910405, 0.8,   4.0,   11.9
275.44,    19910408, -0.7,  4.0,   11.6
275.655,   19910409, 0.1,   4.2,   10.9
272.935,   19910410, -1.0,  -0.3,  9.2
271.065,   19910411, -0.7,  -1.5,  9.4
278.45,    19910412, 2.7,   0.4,   11.5
279.205,   19910415, 0.3,   1.4,   11.6
278.29,    19910416, -0.3,  1.0,   10.3
282.03,    19910417, 1.3,   3.3,   11.3
283.385,   19910418, 0.5,   4.5,   8.1
277.78,    19910419, -2.0,  -0.2,  5.6
274.005,   19910422, -1.4,  -1.9,  3.6
271.325,   19910423, -1.0,  -2.5,  3.2
272.145,   19910424, 0.3,   -3.5,  6.6
274.535,   19910425, 0.9,   -3.1,  9.5
271.965,   19910426, -0.9,  -2.1,  5.8
271.3,     19910429, -0.2,  -1.0,  6.1
266.145,   19910430, -1.9,  -1.9,  4.5
263.655,   19910501, -0.9,  -3.1,  3.1
264.22,    19910502, 0.2,   -3.8,  4.1
266.515,   19910503, 0.9,   -2.0,  4.1
267.11,    19910506, 0.2,   -1.5,  5.6
267.33,    19910507, 0.1,   0.4,   6.0
266.245,   19910508, -0.4,  1.0,   4.1
267.165,   19910509, 0.3,   1.1,   0.9
271.24,    19910510, 1.5,   1.8,   1.8
268.71,    19910513, -0.9,  0.6,   1.4
268.99,    19910514, 0.1,   0.6,   1.7
266.09,    19910515, -1.1,  -0.1,  -2.8
258.11,    19910516, -3.0,  -3.4,  -6.2
260.695,   19910517, 1.0,   -3.9,  -6.0
260.465,   19910520, -0.1,  -3.1,  -5.4
258.62,    19910521, -0.7,  -3.9,  -6.2
261.255,   19910522, 1.0,   -1.8,  -4.3
265.69,    19910523, 1.7,   2.9,   -2.0
267.175,   19910524, 0.6,   2.5,   -4.0
269.505,   19910528, 0.9,   3.5,   -3.5
273.375,   19910529, 1.4,   5.7,   -1.8
274.36,    19910530, 0.4,   5.0,   -2.7
277.24,    19910531, 1.0,   4.3,   -2.2
279.0,     19910603, 0.6,   4.4,   0.4
280.935,   19910604, 0.7,   4.2,   2.5
281.13,    19910605, 0.1,   2.8,   3.6
277.365,   19910606, -1.3,  1.1,   1.9
274.21,    19910607, -1.1,  -1.1,  -0.1
270.57,    19910610, -1.3,  -3.0,  -0.5
268.375,   19910611, -0.8,  -4.5,  -1.1
269.255,   19910612, 0.3,   -4.2,  1.2
265.29,    19910613, -1.5,  -4.4,  0.6
264.79,    19910614, -0.2,  -3.4,  0.2
267.8,     19910617, 1.1,   -1.0,  0.5
267.57,    19910618, -0.1,  -0.3,  0.2
265.48,    19910619, -0.8,  -1.4,  -0.7
260.27,    19910620, -2.0,  -1.9,  -2.2
260.56,    19910621, 0.1,   -1.6,  -2.5
260.28,    19910624, -0.1,  -2.8,  -4.0
250.885,   19910625, -3.6,  -6.2,  -6.6
250.995,   19910626, 0.0,   -5.5,  -6.7
252.45,    19910627, 0.6,   -3.0,  -5.1
255.255,   19910628, 1.1,   -2.0,  -1.1
254.195,   19910701, -0.4,  -2.3,  -2.5
259.12,    19910702, 1.9,   3.3,   -0.5
256.09,    19910703, -1.2,  2.0,   -1.0
252.425,   19910705, -1.4,  -0.0,  -3.4
251.975,   19910708, -0.2,  -1.3,  -5.2
258.93,    19910709, 2.8,   1.9,   -3.1
260.68,    19910710, 0.7,   0.6,   -3.3
263.325,   19910711, 1.0,   2.8,   -3.7
263.84,    19910712, 0.2,   4.5,   -3.8
267.44,    19910715, 1.4,   6.1,   -3.5
268.9,     19910716, 0.5,   3.9,   -3.6
264.475,   19910717, -1.6,  1.5,   -5.9
263.38,    19910718, -0.4,  0.0,   -6.3
266.235,   19910719, 1.1,   0.9,   -4.0
266.925,   19910722, 0.3,   -0.2,  -2.7
264.34,    19910723, -1.0,  -1.7,  -2.3
259.32,    19910724, -1.9,  -1.9,  -3.4
257.855,   19910725, -0.6,  -2.1,  -4.2
261.2,     19910726, 1.3,   -1.9,  -1.5
264.22,    19910729, 1.2,   -1.0,  -0.2
264.625,   19910730, 0.2,   0.1,   -1.2
269.315,   19910731, 1.8,   3.9,   0.7
272.155,   19910801, 1.1,   5.5,   2.5
274.24,    19910802, 0.8,   5.0,   5.4
275.47,    19910805, 0.4,   4.3,   5.7
272.16,    19910806, -1.2,  2.8,   4.6
274.74,    19910807, 0.9,   2.0,   9.5
276.54,    19910808, 0.7,   1.6,   10.2
278.76,    19910809, 0.8,   1.6,   10.4
278.155,   19910812, -0.2,  1.0,   9.0
280.25,    19910813, 0.8,   3.0,   10.3
282.47,    19910814, 0.8,   2.8,   9.0
284.725,   19910815, 0.8,   3.0,   11.2
281.81,    19910816, -1.0,  1.1,   11.6
279.51,    19910819, -0.8,  0.5,   10.9
271.96,    19910820, -2.7,  -3.0,  5.0
274.2,     19910821, 0.8,   -2.9,  5.2
286.065,   19910822, 4.3,   0.5,   8.6
284.555,   19910823, -0.5,  1.0,   7.9
286.775,   19910826, 0.8,   2.6,   7.2
286.7,     19910827, -0.0,  5.4,   6.6
286.775,   19910828, 0.0,   4.6,   8.4
289.875,   19910829, 1.1,   1.3,   10.1
288.59,    19910830, -0.4,  1.4,   8.4
287.405,   19910903, -0.4,  0.2,   7.7
284.33,    19910904, -1.1,  -0.8,  7.6
281.815,   19910905, -0.9,  -1.7,  8.7
280.735,   19910906, -0.4,  -3.2,  8.9
279.965,   19910909, -0.3,  -3.0,  7.2
281.34,    19910910, 0.5,   -2.1,  6.5
276.385,   19910911, -1.8,  -2.8,  4.4
279.655,   19910912, 1.2,   -0.8,  3.8
285.55,    19910913, 2.1,   1.7,   4.9
279.495,   19910916, -2.1,  -0.2,  1.9
278.575,   19910917, -0.3,  -1.0,  1.1
278.09,    19910918, -0.2,  0.6,   2.2
281.035,   19910919, 1.1,   0.5,   2.3
283.51,    19910920, 0.9,   -0.7,  2.5
286.635,   19910923, 1.1,   2.6,   2.8
284.865,   19910924, -0.6,  2.3,   2.4
287.345,   19910925, 0.9,   3.3,   2.5
288.99,    19910926, 0.6,   2.8,   2.3
287.915,   19910927, -0.4,  1.6,   1.1
285.94,    19910930, -0.7,  -0.2,  1.5
287.535,   19911001, 0.6,   0.9,   2.9
288.37,    19911002, 0.3,   0.4,   6.0
285.06,    19911003, -1.1,  -1.4,  4.0
280.41,    19911004, -1.6,  -2.6,  -2.0
280.515,   19911007, 0.0,   -1.9,  -1.4
278.895,   19911008, -0.6,  -3.0,  -2.7
280.025,   19911009, 0.4,   -2.9,  -2.3
276.51,    19911010, -1.3,  -3.0,  -3.6
279.115,   19911011, 0.9,   -0.5,  -3.7
281.5,     19911014, 0.9,   0.4,   -2.5
287.165,   19911015, 2.0,   3.0,   -0.1
293.31,    19911016, 2.1,   4.7,   3.2
297.655,   19911017, 1.5,   7.6,   5.6
292.465,   19911018, -1.7,  4.8,   4.2
293.11,    19911021, 0.2,   4.1,   4.7
290.86,    19911022, -0.8,  1.3,   3.4
290.785,   19911023, -0.0,  -0.9,  5.2
289.345,   19911024, -0.5,  -2.8,  3.5
285.65,    19911025, -1.3,  -2.3,  0.0
282.94,    19911028, -0.9,  -3.5,  1.2
286.375,   19911029, 1.2,   -1.5,  2.8
290.085,   19911030, 1.3,   -0.2,  4.3
294.72,    19911031, 1.6,   1.9,   4.9
292.505,   19911101, -0.8,  2.4,   3.2
290.005,   19911104, -0.9,  2.5,   1.2
286.935,   19911105, -1.1,  0.2,   0.7
289.05,    19911106, 0.7,   -0.4,  0.6
289.43,    19911107, 0.1,   -1.8,  0.2
292.875,   19911108, 1.2,   0.1,   1.7
295.55,    19911111, 0.9,   1.9,   3.4
297.845,   19911112, 0.8,   3.8,   3.6
301.965,   19911113, 1.4,   4.5,   4.7
302.83,    19911114, 0.3,   4.6,   6.2
301.045,   19911115, -0.6,  2.8,   7.4
284.52,    19911118, -5.5,  -3.7,  1.4
288.59,    19911119, 1.4,   -3.1,  3.5
284.39,    19911120, -1.5,  -5.8,  1.6
285.205,   19911121, 0.3,   -5.8,  3.1
288.61,    19911122, 1.2,   -4.1,  3.4
286.32,    19911125, -0.8,  0.6,   1.7
283.015,   19911126, -1.2,  -1.9,  -1.4
284.13,    19911127, 0.4,   -0.1,  -3.1
285.225,   19911129, 0.4,   0.0,   -4.2
284.79,    19911202, -0.2,  -1.3,  -2.6
293.93,    19911203, 3.2,   2.7,   0.3
293.945,   19911204, 0.0,   3.9,   1.1
295.11,    19911205, 0.4,   3.9,   1.5
294.335,   19911206, -0.3,  3.2,   1.7
296.14,    19911209, 0.6,   4.0,   3.7
295.77,    19911210, -0.1,  0.6,   4.5
296.325,   19911211, 0.2,   0.8,   3.5
294.84,    19911212, -0.5,  -0.1,  1.6
297.975,   19911213, 1.1,   1.2,   1.1
301.33,    19911216, 1.1,   1.8,   3.0
303.02,    19911217, 0.6,   2.5,   4.5
298.885,   19911218, -1.4,  0.9,   4.2
299.61,    19911219, 0.2,   1.6,   3.7
294.53,    19911220, -1.7,  -1.2,  1.8
295.82,    19911223, 0.4,   -1.8,  1.0
303.295,   19911224, 2.5,   0.1,   2.6
307.085,   19911226, 1.2,   2.7,   3.1
313.68,    19911227, 2.1,   4.7,   3.9
316.775,   19911230, 1.0,   7.6,   4.6
326.735,   19911231, 3.1,   10.5,  8.5
330.855,   19920102, 1.3,   9.1,   16.3
333.365,   19920103, 0.8,   8.6,   15.5
334.825,   19920106, 0.4,   6.7,   17.7
336.895,   19920107, 0.6,   6.4,   18.1
339.645,   19920108, 0.8,   4.0,   17.7
344.29,    19920109, 1.4,   4.1,   20.2
350.32,    19920110, 1.8,   5.1,   23.8
347.11,    19920113, -0.9,  3.7,   22.2
348.265,   19920114, 0.3,   3.4,   22.1
352.59,    19920115, 1.2,   3.8,   23.8
355.595,   19920116, 0.9,   3.3,   21.0
351.915,   19920117, -1.0,  0.5,   19.7
349.73,    19920120, -0.6,  0.8,   18.5
331.06,    19920121, -5.3,  -4.9,  12.5
331.915,   19920122, 0.3,   -5.9,  12.1
346.32,    19920123, 4.3,   -2.6,  17.1
346.075,   19920124, -0.1,  -1.7,  16.8
345.455,   19920127, -0.2,  -1.2,  17.2
340.48,    19920128, -1.4,  2.8,   14.3
340.845,   19920129, 0.1,   2.7,   13.1
337.29,    19920130, -1.0,  -2.6,  11.3
341.195,   19920131, 1.2,   -1.4,  14.2
338.315,   19920203, -0.8,  -2.1,  12.9
341.38,    19920204, 0.9,   0.3,   15.9
346.915,   19920205, 1.6,   1.8,   17.3
350.86,    19920206, 1.1,   4.0,   15.7
349.07,    19920207, -0.5,  2.3,   13.7
346.805,   19920210, -0.6,  2.5,   10.6
345.235,   19920211, -0.5,  1.1,   9.0
344.405,   19920212, -0.2,  -0.7,  5.4
355.975,   19920213, 3.4,   1.5,   7.6
349.81,    19920214, -1.7,  0.2,   4.9
346.695,   19920218, -0.9,  -0.0,  3.5
338.545,   19920219, -2.4,  -1.9,  0.5
338.06,    19920220, -0.1,  -1.8,  -0.5
345.335,   19920221, 2.2,   -3.0,  0.3
342.39,    19920224, -0.9,  -2.1,  -2.3
338.965,   19920225, -1.0,  -2.2,  -2.3
338.33,    19920226, -0.2,  -0.1,  -2.9
347.81,    19920227, 2.8,   2.9,   -1.4
347.39,    19920228, -0.1,  0.6,   -2.3
345.88,    19920302, -0.4,  1.0,   -1.7
346.635,   19920303, 0.2,   2.3,   -0.9
345.705,   19920304, -0.3,  2.2,   4.4
341.61,    19920305, -1.2,  -1.8,  2.9
337.52,    19920306, -1.2,  -2.8,  -2.5
333.0,     19920309, -1.3,  -3.7,  -3.8
333.315,   19920310, 0.1,   -3.8,  -3.5
340.32,    19920311, 2.1,   -1.6,  -0.0
335.045,   19920312, -1.6,  -1.9,  -1.7
334.83,    19920313, -0.1,  -0.8,  -0.7
336.47,    19920316, 0.5,   1.0,   -1.4
338.275,   19920317, 0.5,   1.5,   -0.0
342.92,    19920318, 1.4,   0.8,   0.5
342.625,   19920319, -0.1,  2.3,   -1.2
342.55,    19920320, -0.0,  2.3,   -2.4
340.865,   19920323, -0.5,  1.3,   -2.4
339.035,   19920324, -0.5,  0.2,   -2.2
336.935,   19920325, -0.6,  -1.7,  -2.4
337.655,   19920326, 0.2,   -1.5,  -2.0
333.295,   19920327, -1.3,  -2.7,  -6.4
324.22,    19920330, -2.7,  -4.9,  -7.3
322.35,    19920331, -0.6,  -4.9,  -7.0
323.05,    19920401, 0.2,   -4.1,  -4.6
323.915,   19920402, 0.3,   -4.1,  -4.2
317.665,   19920403, -1.9,  -4.7,  -8.0
316.385,   19920406, -0.4,  -2.4,  -7.6
324.55,    19920407, 2.6,   0.7,   -4.3
312.955,   19920408, -3.6,  -3.1,  -7.5
310.85,    19920409, -0.7,  -4.0,  -10.6
319.535,   19920410, 2.8,   0.6,   -8.0
312.985,   19920413, -2.0,  -1.1,  -9.5
317.15,    19920414, 1.3,   -2.3,  -8.5
320.55,    19920415, 1.1,   2.4,   -7.3
326.07,    19920416, 1.7,   4.9,   -4.5
319.235,   19920420, -2.1,  -0.1,  -5.4
307.875,   19920421, -3.6,  -1.6,  -7.5
305.625,   19920422, -0.7,  -3.6,  -8.3
309.54,    19920423, 1.3,   -3.4,  -9.0
307.52,    19920424, -0.7,  -5.7,  -8.2
303.815,   19920427, -1.2,  -4.8,  -9.3
299.65,    19920428, -1.4,  -2.7,  -10.9
294.625,   19920429, -1.7,  -3.6,  -12.9
302.975,   19920430, 2.8,   -2.1,  -11.6
307.86,    19920501, 1.6,   0.1,   -10.1
307.475,   19920504, -0.1,  1.2,   -10.2
310.715,   19920505, 1.1,   3.7,   -8.8
314.77,    19920506, 1.3,   6.8,   -7.2
315.105,   19920507, 0.1,   4.0,   -6.5
312.725,   19920508, -0.8,  1.6,   -7.4
311.265,   19920511, -0.5,  1.2,   -6.6
311.9,     19920512, 0.2,   0.4,   -3.8
310.04,    19920513, -0.6,  -1.5,  -3.8
308.75,    19920514, -0.4,  -2.0,  -4.4
304.545,   19920515, -1.4,  -2.6,  -6.0
303.015,   19920518, -0.5,  -2.7,  -4.6
304.575,   19920519, 0.5,   -2.3,  -3.7
306.41,    19920520, 0.6,   -1.2,  -5.6
309.55,    19920521, 1.0,   0.3,   -1.1
307.94,    19920522, -0.5,  1.1,   -0.9
308.38,    19920526, 0.1,   1.8,   -3.5
305.895,   19920527, -0.8,  0.4,   -2.3
308.075,   19920528, 0.7,   0.5,   -2.9
311.98,    19920529, 1.3,   0.8,   -2.7
315.295,   19920601, 1.1,   2.4,   -3.3
318.98,    19920602, 1.2,   3.4,   -0.1
317.235,   19920603, -0.5,  3.7,   3.0
317.34,    19920604, 0.0,   3.0,   3.8
315.935,   19920605, -0.4,  1.3,   2.1
313.245,   19920608, -0.9,  -0.7,  1.9
310.23,    19920609, -1.0,  -2.7,  2.1
304.855,   19920610, -1.7,  -3.9,  1.7
302.6,     19920611, -0.7,  -4.6,  2.7
302.575,   19920612, -0.0,  -4.2,  -0.1
304.11,    19920615, 0.5,   -2.9,  -1.2
303.865,   19920616, -0.1,  -2.1,  -1.2
299.205,   19920617, -1.5,  -1.9,  -3.7
293.4,     19920618, -1.9,  -3.0,  -6.8
291.935,   19920619, -0.5,  -3.5,  -7.4
296.865,   19920622, 1.7,   -2.4,  -5.1
295.245,   19920623, -0.5,  -2.8,  -5.1
297.445,   19920624, 0.7,   -0.6,  -4.6
295.29,    19920625, -0.7,  0.6,   -4.8
291.255,   19920626, -1.4,  -0.2,  -5.7
290.03,    19920629, -0.4,  -2.3,  -4.8
300.795,   19920630, 3.7,   1.9,   -0.7
301.23,    19920701, 0.1,   1.3,   -1.1
306.03,    19920702, 1.6,   3.6,   -0.1
300.875,   19920706, -1.7,  3.3,   -2.8
302.4,     19920707, 0.5,   4.3,   -1.8
296.265,   19920708, -2.0,  -1.5,  -3.9
297.56,    19920709, 0.4,   -1.2,  -2.7
302.19,    19920710, 1.6,   -1.3,  -1.9
302.835,   19920713, 0.2,   0.7,   -2.9
304.98,    19920714, 0.7,   0.9,   -3.3
309.245,   19920715, 1.4,   4.4,   -3.1
309.39,    19920716, 0.0,   4.0,   -2.5
311.28,    19920717, 0.6,   3.0,   -1.9
305.54,    19920720, -1.8,  0.9,   -3.3
300.45,    19920721, -1.7,  -1.5,  -4.1
305.1,     19920722, 1.5,   -1.3,  -1.7
301.17,    19920723, -1.3,  -2.7,  -1.2
302.495,   19920724, 0.4,   -2.8,  -0.0
303.425,   19920727, 0.3,   -0.7,  0.3
301.84,    19920728, -0.5,  0.5,   -0.7
306.755,   19920729, 1.6,   0.5,   1.0
310.105,   19920730, 1.1,   3.0,   3.6
310.935,   19920731, 0.3,   2.8,   6.0
310.895,   19920803, -0.0,  2.5,   6.5
312.75,    19920804, 0.6,   3.6,   5.4
311.075,   19920805, -0.5,  1.4,   5.4
306.585,   19920806, -1.4,  -1.1,  3.1
304.56,    19920807, -0.7,  -2.1,  3.1
303.315,   19920810, -0.4,  -2.4,  4.1
304.23,    19920811, 0.3,   -2.7,  4.9
302.51,    19920812, -0.6,  -2.8,  0.6
300.955,   19920813, -0.5,  -1.8,  -0.1
301.275,   19920814, 0.1,   -1.1,  -1.6
302.23,    19920817, 0.3,   -0.4,  0.5
300.385,   19920818, -0.6,  -1.3,  -0.7
300.44,    19920819, 0.0,   -0.7,  1.4
298.315,   19920820, -0.7,  -0.9,  0.3
299.425,   19920821, 0.4,   -0.6,  -0.9
295.69,    19920824, -1.2,  -2.2,  -2.4
290.715,   19920825, -1.7,  -3.2,  -4.7
292.085,   19920826, 0.5,   -2.8,  -5.5
296.205,   19920827, 1.4,   -0.7,  -4.3
299.46,    19920828, 1.1,   0.0,   -3.8
299.135,   19920831, -0.1,  1.2,   -2.1
299.26,    19920901, 0.0,   2.9,   -0.4
301.97,    19920902, 0.9,   3.4,   -1.0
306.565,   19920903, 1.5,   3.5,   1.8
308.17,    19920904, 0.5,   2.9,   1.9
306.395,   19920908, -0.6,  2.4,   1.0
305.77,    19920909, -0.2,  2.2,   1.3
309.99,    19920910, 1.4,   2.7,   1.1
314.815,   19920911, 1.6,   2.7,   1.5
315.19,    19920914, 0.1,   2.3,   1.4
324.63,    19920915, 3.0,   6.0,   4.4
319.885,   19920916, -1.5,  4.6,   2.3
318.445,   19920917, -0.5,  2.7,   2.4
319.235,   19920918, 0.2,   1.4,   4.1
319.91,    19920921, 0.2,   1.5,   5.0
319.255,   19920922, -0.2,  -1.7,  5.3
314.275,   19920923, -1.6,  -1.8,  3.3
315.815,   19920924, 0.5,   -0.8,  4.4
317.535,   19920925, 0.5,   -0.5,  5.5
310.225,   19920928, -2.3,  -3.0,  3.0
307.87,    19920929, -0.8,  -3.6,  1.9
310.65,    19920930, 0.9,   -1.2,  3.4
313.185,   19921001, 0.8,   -0.8,  4.2
309.035,   19921002, -1.3,  -2.7,  3.6
304.39,    19921005, -1.5,  -1.9,  1.7
303.685,   19921006, -0.2,  -1.4,  2.7
307.96,    19921007, 1.4,   -0.9,  5.9
305.38,    19921008, -0.8,  -2.5,  4.6
308.35,    19921009, 1.0,   -0.2,  4.1
305.63,    19921012, -0.9,  0.4,   2.1
308.105,   19921013, 0.8,   1.5,   3.0
310.13,    19921014, 0.7,   0.7,   3.6
309.585,   19921015, -0.2,  1.4,   2.5
312.135,   19921016, 0.8,   1.2,   1.8
316.385,   19921019, 1.4,   3.5,   2.7
322.785,   19921020, 2.0,   4.8,   5.3
323.38,    19921021, 0.2,   4.3,   5.8
326.07,    19921022, 0.8,   5.3,   5.2
326.095,   19921023, 0.0,   4.5,   3.6
325.44,    19921026, -0.2,  2.9,   3.3
327.17,    19921027, 0.5,   1.4,   0.8
323.925,   19921028, -1.0,  0.2,   1.3
326.675,   19921029, 0.8,   0.2,   2.6
331.09,    19921030, 1.4,   1.5,   3.7
329.155,   19921102, -0.6,  1.1,   2.9
330.33,    19921103, 0.4,   1.0,   3.5
325.965,   19921104, -1.3,  0.6,   3.7
327.76,    19921105, 0.6,   0.3,   3.8
336.49,    19921106, 2.7,   1.6,   6.0
338.145,   19921109, 0.5,   2.7,   9.0
340.585,   19921110, 0.7,   3.1,   10.6
344.39,    19921111, 1.1,   5.7,   10.9
347.855,   19921112, 1.0,   6.1,   11.1
346.8,     19921113, -0.3,  3.1,   12.2
348.49,    19921116, 0.5,   3.1,   14.5
344.63,    19921117, -1.1,  1.2,   13.5
338.23,    19921118, -1.9,  -1.8,  9.8
344.31,    19921119, 1.8,   -1.0,  12.7
346.755,   19921120, 0.7,   -0.0,  12.5
348.805,   19921123, 0.6,   0.1,   14.1
343.44,    19921124, -1.5,  -0.3,  11.5
349.975,   19921125, 1.9,   3.5,   12.8
349.66,    19921127, -0.1,  1.6,   12.9
349.315,   19921130, -0.1,  0.7,   11.9
350.96,    19921201, 0.5,   0.6,   10.9
352.67,    19921202, 0.5,   2.7,   9.3
351.57,    19921203, -0.3,  0.5,   8.7
354.79,    19921204, 0.9,   1.5,   8.8
358.4,     19921207, 1.0,   2.6,   9.9
361.835,   19921208, 1.0,   3.1,   11.2
360.575,   19921209, -0.3,  2.2,   10.2
357.815,   19921210, -0.8,  1.8,   10.5
354.555,   19921211, -0.9,  -0.1,  8.5
350.895,   19921214, -1.0,  -2.1,  6.0
349.405,   19921215, -0.4,  -3.4,  6.2
346.945,   19921216, -0.7,  -3.8,  5.0
348.95,    19921217, 0.6,   -2.5,  7.1
356.845,   19921218, 2.3,   0.6,   8.9
357.895,   19921221, 0.3,   2.0,   6.4
359.335,   19921222, 0.4,   2.8,   6.3
355.64,    19921223, -1.0,  2.5,   4.4
356.495,   19921224, 0.2,   2.2,   3.5
357.56,    19921228, 0.3,   0.2,   2.8
359.065,   19921229, 0.4,   0.3,   3.5
359.81,    19921230, 0.2,   0.1,   3.2
359.935,   19921231, 0.0,   1.2,   4.4
360.185,   19930104, 0.1,   1.0,   6.5
356.37,    19930105, -1.1,  -0.3,  3.5
360.235,   19930106, 1.1,   0.3,   3.9
366.97,    19930107, 1.9,   2.0,   5.2
364.015,   19930108, -0.8,  1.1,   6.0
365.54,    19930111, 0.4,   1.5,   4.4
370.805,   19930112, 1.4,   4.1,   6.0
367.905,   19930113, -0.8,  2.1,   5.3
375.85,    19930114, 2.2,   2.4,   7.1
381.34,    19930115, 1.5,   4.8,   8.1
379.235,   19930118, -0.6,  3.7,   7.9
378.755,   19930119, -0.1,  2.1,   6.8
376.02,    19930120, -0.7,  2.2,   4.9
375.73,    19930121, -0.1,  -0.0,  3.8
379.34,    19930122, 1.0,   -0.5,  5.2
377.82,    19930125, -0.4,  -0.4,  5.6
381.055,   19930126, 0.9,   0.6,   7.5
379.375,   19930127, -0.4,  0.9,   8.1
372.61,    19930128, -1.8,  -0.8,  6.6
370.665,   19930129, -0.5,  -2.3,  6.8
370.56,    19930201, -0.0,  -1.9,  6.2
375.475,   19930202, 1.3,   -1.5,  5.2
377.44,    19930203, 0.5,   -0.5,  5.5
376.535,   19930204, -0.2,  1.1,   4.8
374.245,   19930205, -0.6,  1.0,   5.2
369.0,     19930208, -1.4,  -0.4,  3.5
365.725,   19930209, -0.9,  -2.6,  2.3
362.95,    19930210, -0.8,  -3.8,  1.1
367.3,     19930211, 1.2,   -2.5,  2.1
366.325,   19930212, -0.3,  -2.1,  1.8
361.19,    19930216, -1.4,  -2.1,  0.3
346.89,    19930217, -4.0,  -5.2,  -2.7
345.91,    19930218, -0.3,  -4.7,  -4.0
348.28,    19930219, 0.7,   -5.2,  -5.1
347.915,   19930222, -0.1,  -5.0,  -4.4
340.35,    19930223, -2.2,  -5.8,  -6.9
341.24,    19930224, 0.3,   -1.6,  -8.0
350.125,   19930225, 2.6,   1.2,   -4.8
351.665,   19930226, 0.4,   1.0,   -6.4
351.14,    19930301, -0.1,  0.9,   -7.9
349.095,   19930302, -0.6,  2.6,   -7.9
356.21,    19930303, 2.0,   4.4,   -6.0
359.96,    19930304, 1.1,   2.8,   -4.3
355.53,    19930305, -1.2,  1.1,   -5.4
355.565,   19930308, 0.0,   1.3,   -6.3
359.44,    19930309, 1.1,   3.0,   -4.9
359.4,     19930310, -0.0,  0.9,   -5.7
361.26,    19930311, 0.5,   0.4,   -4.8
361.735,   19930312, 0.1,   1.7,   -2.9
362.65,    19930315, 0.3,   2.0,   -2.2
364.315,   19930316, 0.5,   1.4,   -1.7
363.61,    19930317, -0.2,  1.2,   -3.2
355.655,   19930318, -2.2,  -1.6,  -5.8
355.5,     19930319, -0.0,  -1.7,  -5.6
351.55,    19930322, -1.1,  -3.1,  -6.1
349.415,   19930323, -0.6,  -4.1,  -5.3
348.135,   19930324, -0.4,  -4.3,  -4.8
348.505,   19930325, 0.1,   -2.0,  -4.0
354.375,   19930326, 1.7,   -0.3,  -3.5
352.645,   19930329, -0.5,  0.3,   -3.7
351.405,   19930330, -0.4,  0.6,   -2.7
357.72,    19930331, 1.8,   2.8,   3.1
359.42,    19930401, 0.5,   3.1,   3.9
354.335,   19930402, -1.4,  -0.0,  1.7
341.18,    19930405, -3.7,  -3.3,  -1.9
344.705,   19930406, 1.0,   -1.9,  1.3
340.555,   19930407, -1.2,  -4.8,  -0.2
345.185,   19930408, 1.4,   -4.0,  -1.4
341.56,    19930412, -1.1,  -3.6,  -2.9
349.005,   19930413, 2.2,   2.3,   -0.6
345.87,    19930414, -0.9,  0.3,   -0.9
346.49,    19930415, 0.2,   1.7,   -2.7
343.895,   19930416, -0.7,  -0.4,  -4.5
340.65,    19930419, -0.9,  -0.3,  -4.2
337.455,   19930420, -0.9,  -3.3,  -5.1
339.06,    19930421, 0.5,   -2.0,  -5.7
340.195,   19930422, 0.3,   -1.8,  -5.3
338.48,    19930423, -0.5,  -1.6,  -6.3
333.66,    19930426, -1.4,  -2.1,  -7.8
327.28,    19930427, -1.9,  -3.0,  -9.8
334.6,     19930428, 2.2,   -1.3,  -8.2
339.66,    19930429, 1.5,   -0.2,  -6.6
338.98,    19930430, -0.2,  0.1,   -4.7
339.945,   19930503, 0.3,   1.9,   -4.4
344.97,    19930504, 1.5,   5.4,   -1.9
353.28,    19930505, 2.4,   5.6,   1.1
355.345,   19930506, 0.6,   4.6,   2.1
351.715,   19930507, -1.0,  3.8,   0.9
353.23,    19930510, 0.4,   3.9,   -0.3
354.845,   19930511, 0.5,   2.9,   0.6
353.005,   19930512, -0.5,  -0.1,  0.5
350.96,    19930513, -0.6,  -1.2,  -1.9
347.71,    19930514, -0.9,  -1.1,  -3.3
349.8,     19930517, 0.6,   -1.0,  -1.3
352.67,    19930518, 0.8,   -0.6,  3.4
356.0,     19930519, 0.9,   0.8,   3.3
364.675,   19930520, 2.4,   3.9,   7.1
370.455,   19930521, 1.6,   6.5,   7.3
366.67,    19930524, -1.0,  4.8,   7.4
367.09,    19930525, 0.1,   4.1,   5.2
366.22,    19930526, -0.2,  2.9,   5.9
374.345,   19930527, 2.2,   2.7,   8.0
372.73,    19930528, -0.4,  0.6,   8.4
368.11,    19930601, -1.2,  0.4,   8.1
372.53,    19930602, 1.2,   1.5,   10.4
372.685,   19930603, 0.0,   1.8,   9.9
372.06,    19930604, -0.2,  -0.6,  9.4
368.625,   19930607, -0.9,  -1.1,  8.9
364.355,   19930608, -1.2,  -1.0,  9.2
360.74,    19930609, -1.0,  -3.2,  10.2
360.25,    19930610, -0.1,  -3.3,  7.7
359.55,    19930611, -0.2,  -3.4,  5.9
363.075,   19930614, 1.0,   -1.5,  7.1
367.145,   19930615, 1.1,   0.8,   8.0
366.975,   19930616, -0.0,  1.7,   6.4
365.015,   19930617, -0.5,  1.3,   3.3
364.01,    19930618, -0.3,  1.2,   2.4
357.96,    19930621, -1.7,  -1.4,  1.8
357.865,   19930622, -0.0,  -2.5,  1.3
356.2,     19930623, -0.5,  -2.9,  0.4
354.935,   19930624, -0.4,  -2.8,  0.5
357.825,   19930625, 0.8,   -1.7,  2.0
362.225,   19930628, 1.2,   1.2,   4.2
368.45,    19930629, 1.7,   3.0,   5.3
365.96,    19930630, -0.7,  2.7,   3.8
366.125,   19930701, 0.0,   3.2,   2.8
363.73,    19930702, -0.7,  1.7,   -0.3
364.215,   19930706, 0.1,   0.5,   -1.7
360.795,   19930707, -0.9,  -2.1,  -1.6
357.865,   19930708, -0.8,  -2.2,  -2.5
360.7,     19930709, 0.8,   -1.5,  -1.5
362.46,    19930712, 0.5,   -0.3,  -3.2
362.87,    19930713, 0.1,   -0.4,  -2.6
361.98,    19930714, -0.2,  0.3,   -1.7
363.74,    19930715, 0.5,   1.6,   -2.4
360.465,   19930716, -0.9,  -0.1,  -3.3
351.07,    19930719, -2.6,  -3.1,  -5.6
349.06,    19930720, -0.6,  -3.8,  -5.3
355.76,    19930721, 1.9,   -1.7,  -2.4
352.635,   19930722, -0.9,  -3.1,  -2.2
348.35,    19930723, -1.2,  -3.4,  -3.3
353.205,   19930726, 1.4,   0.6,   -1.8
356.405,   19930727, 0.9,   2.1,   -1.8
352.015,   19930728, -1.2,  -1.1,  -4.1
356.81,    19930729, 1.4,   1.2,   -2.8
357.33,    19930730, 0.1,   2.6,   -2.1
352.865,   19930802, -1.2,  -0.1,  -3.1
355.25,    19930803, 0.7,   -0.3,  -0.8
356.23,    19930804, 0.3,   1.2,   -0.5
360.115,   19930805, 1.1,   0.9,   1.1
362.41,    19930806, 0.6,   1.4,   2.1
363.145,   19930809, 0.2,   2.9,   1.5
362.295,   19930810, -0.2,  2.0,   0.0
358.645,   19930811, -1.0,  0.7,   -2.7
359.735,   19930812, 0.3,   -0.1,  -1.7
359.405,   19930813, -0.1,  -0.8,  -1.8
360.455,   19930816, 0.3,   -0.7,  -0.9
370.6,     19930817, 2.8,   2.3,   1.8
374.16,    19930818, 1.0,   4.3,   3.7
375.815,   19930819, 0.4,   4.5,   5.0
370.265,   19930820, -1.5,  3.0,   2.7
370.87,    19930823, 0.2,   2.9,   2.3
370.49,    19930824, -0.1,  -0.0,  2.1
371.0,     19930825, 0.1,   -0.8,  2.5
367.95,    19930826, -0.8,  -2.1,  1.2
364.67,    19930827, -0.9,  -1.5,  1.2
366.5,     19930830, 0.5,   -1.2,  4.4
369.305,   19930831, 0.8,   -0.3,  5.8
372.65,    19930901, 0.9,   0.4,   4.7
374.16,    19930902, 0.4,   1.7,   6.1
374.565,   19930903, 0.1,   2.7,   7.5
373.705,   19930907, -0.2,  2.0,   5.8
366.68,    19930908, -1.9,  -0.7,  2.9
364.845,   19930909, -0.5,  -2.1,  3.6
368.3,     19930910, 0.9,   -1.6,  3.2
370.635,   19930913, 0.6,   -1.0,  3.7
367.59,    19930914, -0.8,  -1.6,  4.2
364.195,   19930915, -0.9,  -0.7,  2.5
367.32,    19930916, 0.9,   0.7,   3.1
365.74,    19930917, -0.4,  -0.7,  1.6
366.185,   19930920, 0.1,   -1.2,  1.0
367.455,   19930921, 0.3,   -0.0,  1.2
365.645,   19930922, -0.5,  0.4,   0.9
374.0,     19930923, 2.3,   1.8,   4.3
377.065,   19930924, 0.8,   3.1,   4.8
378.04,    19930927, 0.3,   3.2,   5.2
382.505,   19930928, 1.2,   4.1,   6.1
386.02,    19930929, 0.9,   5.6,   4.2
386.89,    19930930, 0.2,   3.4,   3.4
382.715,   19931001, -1.1,  1.5,   1.8
382.81,    19931004, 0.0,   1.3,   3.4
384.295,   19931005, 0.4,   0.5,   3.6
381.275,   19931006, -0.8,  -1.2,  2.9
380.42,    19931007, -0.2,  -1.7,  2.5
377.995,   19931008, -0.6,  -1.2,  2.7
381.315,   19931011, 0.9,   -0.4,  4.6
380.655,   19931012, -0.2,  -0.9,  3.9
382.565,   19931013, 0.5,   0.3,   3.6
386.3,     19931014, 1.0,   1.5,   3.7
391.985,   19931015, 1.5,   3.7,   4.8
392.45,    19931018, 0.1,   2.9,   4.8
389.85,    19931019, -0.7,  2.4,   4.3
382.73,    19931020, -1.8,  0.0,   4.4
383.93,    19931021, 0.3,   -0.6,  5.2
387.355,   19931022, 0.9,   -1.2,  5.2
388.19,    19931025, 0.2,   -1.1,  4.7
387.405,   19931026, -0.2,  -0.6,  5.4
384.6,     19931027, -0.7,  0.5,   5.6
390.25,    19931028, 1.5,   1.6,   6.2
389.245,   19931029, -0.3,  0.5,   6.4
390.985,   19931101, 0.4,   0.7,   6.8
395.03,    19931102, 1.0,   2.0,   7.5
395.32,    19931103, 0.1,   2.8,   8.1
387.93,    19931104, -1.9,  -0.6,  3.7
377.465,   19931105, -2.7,  -3.0,  0.1
383.635,   19931108, 1.6,   -1.9,  1.5
384.85,    19931109, 0.3,   -2.6,  0.6
385.915,   19931110, 0.3,   -2.4,  -0.0
392.985,   19931111, 1.8,   1.3,   1.6
394.66,    19931112, 0.4,   4.6,   3.1
395.175,   19931115, 0.1,   3.0,   3.2
391.12,    19931116, -1.0,  1.6,   1.8
393.375,   19931117, 0.6,   1.9,   3.2
385.795,   19931118, -1.9,  -1.8,  1.4
381.74,    19931119, -1.1,  -3.3,  1.0
380.565,   19931122, -0.3,  -3.7,  -0.2
373.37,    19931123, -1.9,  -4.5,  -1.9
381.22,    19931124, 2.1,   -3.1,  -0.4
385.035,   19931126, 1.0,   -0.2,  -0.3
386.315,   19931129, 0.3,   1.2,   -1.4
383.185,   19931130, -0.8,  0.7,   -2.4
386.76,    19931201, 0.9,   3.6,   -0.8
392.945,   19931202, 1.6,   3.1,   2.7
396.165,   19931203, 0.8,   2.9,   3.2
401.775,   19931206, 1.4,   4.0,   3.7
399.005,   19931207, -0.7,  4.1,   2.8
396.985,   19931208, -0.5,  2.6,   2.5
394.81,    19931209, -0.5,  0.5,   2.7
387.25,    19931210, -1.9,  -2.3,  -0.8
386.96,    19931213, -0.1,  -3.7,  -0.6
387.78,    19931214, 0.2,   -2.8,  -0.8
382.83,    19931215, -1.3,  -3.6,  -3.1
384.945,   19931216, 0.6,   -2.5,  -2.6
386.18,    19931217, 0.3,   -0.3,  -0.5
389.035,   19931220, 0.7,   0.5,   3.1
389.72,    19931221, 0.2,   0.5,   1.6
388.51,    19931222, -0.3,  1.5,   1.0
388.405,   19931223, -0.0,  0.9,   0.6
390.32,    19931227, 0.5,   1.1,   -0.7
391.84,    19931228, 0.4,   0.7,   -0.7
394.74,    19931229, 0.7,   1.3,   -0.1
395.38,    19931230, 0.2,   1.8,   1.1
397.725,   19931231, 0.6,   2.4,   1.1
398.26,    19940103, 0.1,   2.0,   3.2
395.53,    19940104, -0.7,  0.9,   3.6
398.19,    19940105, 0.7,   0.9,   4.6
401.07,    19940106, 0.7,   1.4,   7.4
404.21,    19940107, 0.8,   1.6,   6.0
404.78,    19940110, 0.1,   1.6,   5.1
408.91,    19940111, 1.0,   3.4,   5.8
407.85,    19940112, -0.3,  2.4,   6.4
408.47,    19940113, 0.2,   1.8,   5.6
408.0,     19940114, -0.1,  0.9,   3.8
411.75,    19940117, 0.9,   1.7,   3.9
410.79,    19940118, -0.2,  0.5,   2.2
410.95,    19940119, 0.0,   0.8,   3.0
406.38,    19940120, -1.1,  -0.5,  2.4
411.53,    19940121, 1.3,   0.9,   4.2
411.59,    19940124, 0.0,   -0.0,  6.3
409.23,    19940125, -0.6,  -0.4,  5.8
406.48,    19940126, -0.7,  -1.1,  4.8
407.19,    19940127, 0.2,   0.2,   6.4
409.93,    19940128, 0.7,   -0.4,  6.5
412.52,    19940131, 0.6,   0.2,   6.8
413.99,    19940201, 0.4,   1.2,   6.4
410.22,    19940202, -0.9,  0.9,   5.3
411.78,    19940203, 0.4,   1.1,   6.0
410.05,    19940204, -0.4,  0.0,   5.6
397.48,    19940207, -3.1,  -3.6,  1.8
399.62,    19940208, 0.5,   -3.5,  2.0
401.46,    19940209, 0.5,   -2.1,  1.7
403.91,    19940210, 0.6,   -1.9,  2.2
401.31,    19940211, -0.6,  -2.1,  0.9
401.83,    19940214, 0.1,   1.1,   0.9
405.11,    19940215, 0.8,   1.4,   2.4
408.28,    19940216, 0.8,   1.7,   2.5
408.74,    19940217, 0.1,   1.2,   1.9
407.39,    19940218, -0.3,  1.5,   0.8
407.94,    19940222, 0.1,   1.5,   0.8
410.48,    19940223, 0.6,   1.3,   0.4
408.91,    19940224, -0.4,  0.2,   0.3
402.79,    19940225, -1.5,  -1.5,  -1.4
406.45,    19940228, 0.9,   -0.2,  -0.4
412.17,    19940301, 1.4,   1.0,   0.1
409.69,    19940302, -0.6,  -0.2,  -0.3
408.18,    19940303, -0.4,  -0.2,  -0.7
408.17,    19940304, -0.0,  1.3,   0.4
411.99,    19940307, 0.9,   1.4,   0.1
415.3,     19940308, 0.8,   0.8,   0.9
411.97,    19940309, -0.8,  0.6,   0.7
413.08,    19940310, 0.3,   1.2,   1.6
409.77,    19940311, -0.8,  0.4,   0.6
409.43,    19940314, -0.1,  -0.6,  -0.1
412.93,    19940315, 0.9,   -0.6,  0.1
412.83,    19940316, -0.0,  0.2,   -0.3
415.31,    19940317, 0.6,   0.5,   1.2
418.45,    19940318, 0.8,   2.1,   1.6
417.52,    19940321, -0.2,  2.0,   1.8
412.99,    19940322, -1.1,  0.0,   3.9
410.67,    19940323, -0.6,  -0.5,  2.8
410.5,     19940324, -0.0,  -1.2,  2.3
403.92,    19940325, -1.6,  -3.5,  0.0
399.86,    19940328, -1.0,  -4.2,  -0.4
393.92,    19940329, -1.5,  -4.6,  -2.0
383.0,     19940330, -2.8,  -6.7,  -5.5
380.25,    19940331, -0.7,  -7.4,  -6.9
382.96,    19940404, 0.7,   -5.2,  -6.3
375.42,    19940405, -2.0,  -6.1,  -7.8
389.31,    19940406, 3.7,   -1.2,  -4.6
388.76,    19940407, -0.1,  1.5,   -5.3
390.97,    19940408, 0.6,   2.8,   -4.4
384.9,     19940411, -1.6,  0.5,   -4.4
383.85,    19940412, -0.3,  2.2,   -5.6
375.46,    19940413, -2.2,  -3.6,  -8.9
367.25,    19940414, -2.2,  -5.5,  -10.4
365.65,    19940415, -0.4,  -6.5,  -10.4
366.29,    19940418, 0.2,   -4.8,  -10.3
360.46,    19940419, -1.6,  -6.1,  -12.5
357.36,    19940420, -0.9,  -4.8,  -14.0
355.38,    19940421, -0.6,  -3.2,  -13.7
367.02,    19940422, 3.3,   0.4,   -11.2
369.08,    19940425, 0.6,   0.8,   -9.9
374.79,    19940426, 1.5,   4.0,   -8.5
375.46,    19940428, 0.2,   5.1,   -9.1
372.45,    19940429, -0.8,  4.8,   -9.8
373.25,    19940502, 0.2,   1.7,   -10.1
379.82,    19940503, 1.8,   2.9,   -9.2
377.07,    19940504, -0.7,  0.6,   -9.7
377.53,    19940505, 0.1,   0.6,   -8.6
378.23,    19940506, 0.2,   1.6,   -7.9
374.57,    19940509, -1.0,  0.4,   -8.8
368.0,     19940510, -1.8,  -3.1,  -8.9
370.02,    19940511, 0.5,   -1.9,  -7.5
365.18,    19940512, -1.3,  -3.3,  -7.3
366.64,    19940513, 0.4,   -3.1,  -4.3
364.82,    19940516, -0.5,  -2.6,  -4.1
360.49,    19940517, -1.2,  -2.0,  -5.9
360.5,     19940518, 0.0,   -2.6,  -4.0
368.59,    19940519, 2.2,   0.9,   -5.3
371.71,    19940520, 0.8,   1.4,   -4.4
370.37,    19940523, -0.4,  1.5,   -5.3
369.89,    19940524, -0.1,  2.6,   -3.9
377.0,     19940525, 1.9,   4.6,   -1.8
378.24,    19940526, 0.3,   2.6,   0.7
375.8,     19940527, -0.6,  1.1,   2.3
376.81,    19940531, 0.3,   1.7,   3.1
378.85,    19940601, 0.5,   2.4,   3.4
379.23,    19940602, 0.1,   0.6,   5.2
380.66,    19940603, 0.4,   0.6,   6.5
382.78,    19940606, 0.6,   1.9,   7.7
382.8,     19940607, 0.0,   1.6,   4.3
379.99,    19940608, -0.7,  0.3,   3.0
371.0,     19940609, -2.4,  -2.2,  -1.0
370.3,     19940610, -0.2,  -2.7,  -1.4
374.64,    19940613, 1.2,   -2.1,  0.6
371.88,    19940614, -0.7,  -2.9,  -0.4
377.38,    19940615, 1.5,   -0.7,  -0.6
377.78,    19940616, 0.1,   1.8,   0.2
377.8,     19940617, 0.0,   2.0,   0.1
371.94,    19940620, -1.6,  -0.7,  -1.7
367.83,    19940621, -1.1,  -1.1,  -1.8
360.98,    19940622, -1.9,  -4.3,  -1.9
363.23,    19940623, 0.6,   -3.9,  -1.8
353.49,    19940624, -2.7,  -6.4,  -3.2
351.76,    19940627, -0.5,  -5.4,  -4.1
361.16,    19940628, 2.7,   -1.8,  -1.0
362.05,    19940629, 0.2,   0.3,   0.4
362.73,    19940630, 0.2,   -0.1,  0.6
360.3,     19940701, -0.7,  1.9,   -2.2
360.03,    19940705, -0.1,  2.4,   -3.1
356.91,    19940706, -0.9,  -1.2,  -3.6
353.62,    19940707, -0.9,  -2.3,  -4.4
359.23,    19940708, 1.6,   -1.0,  -4.7
359.31,    19940711, 0.0,   -0.3,  -5.0
358.79,    19940712, -0.1,  -0.3,  -4.5
361.31,    19940713, 0.7,   1.2,   -4.1
369.14,    19940714, 2.2,   4.4,   -2.6
367.65,    19940715, -0.4,  2.3,   -3.1
368.35,    19940718, 0.2,   2.5,   -3.2
368.18,    19940719, -0.0,  2.6,   -3.8
364.72,    19940720, -0.9,  0.9,   -4.7
359.53,    19940721, -1.4,  -2.6,  -5.4
362.85,    19940722, 0.9,   -1.3,  -2.2
365.06,    19940725, 0.6,   -0.9,  -1.4
366.11,    19940726, 0.3,   -0.6,  -2.3
364.01,    19940727, -0.6,  -0.2,  -2.1
361.81,    19940728, -0.6,  0.6,   -4.1
362.14,    19940729, 0.1,   -0.2,  -4.1
370.16,    19940801, 2.2,   1.4,   -2.0
373.57,    19940802, 0.9,   2.0,   0.4
372.78,    19940803, -0.2,  2.4,   1.3
370.48,    19940804, -0.6,  2.4,   2.6
367.55,    19940805, -0.8,  1.5,   1.2
367.14,    19940808, -0.1,  -0.8,  3.9
368.87,    19940809, 0.5,   -1.3,  4.9
370.66,    19940810, 0.5,   -0.6,  2.6
373.93,    19940811, 0.9,   0.9,   3.3
374.49,    19940812, 0.1,   1.9,   3.2
376.96,    19940815, 0.7,   2.7,   4.6
377.08,    19940816, 0.0,   2.2,   4.7
380.53,    19940817, 0.9,   2.7,   6.6
386.9,     19940818, 1.7,   3.5,   9.4
385.63,    19940819, -0.3,  3.0,   7.3
384.12,    19940822, -0.4,  1.9,   6.9
382.81,    19940823, -0.3,  1.5,   6.7
386.74,    19940824, 1.0,   1.6,   7.0
388.44,    19940825, 0.4,   0.4,   5.2
391.49,    19940826, 0.8,   1.5,   6.5
399.26,    19940829, 2.0,   3.9,   8.4
398.44,    19940830, -0.2,  4.1,   8.2
401.74,    19940831, 0.8,   3.9,   10.2
397.9,     19940901, -1.0,  2.4,   10.7
391.61,    19940902, -1.6,  0.0,   7.9
391.41,    19940906, -0.1,  -2.0,  7.2
393.69,    19940907, 0.6,   -1.2,  7.5
397.05,    19940908, 0.9,   -1.2,  9.1
400.75,    19940909, 0.9,   0.7,   10.8
394.84,    19940912, -1.5,  0.8,   9.0
391.67,    19940913, -0.8,  0.1,   5.8
396.69,    19940914, 1.3,   0.8,   6.2
397.79,    19940915, 0.3,   0.2,   6.7
407.1,     19940916, 2.3,   1.6,   9.9
404.47,    19940919, -0.6,  2.4,   10.0
403.96,    19940920, -0.1,  3.1,   10.0
396.85,    19940921, -1.8,  0.0,   7.6
393.71,    19940922, -0.8,  -1.0,  6.2
393.55,    19940923, -0.0,  -3.3,  5.2
390.18,    19940926, -0.9,  -3.5,  4.2
388.9,     19940927, -0.3,  -3.7,  3.2
389.33,    19940928, 0.1,   -1.9,  3.2
391.97,    19940929, 0.7,   -0.4,  3.0
391.22,    19940930, -0.2,  -0.6,  1.1
393.85,    19941003, 0.7,   0.9,   2.1
392.72,    19941004, -0.3,  1.0,   2.2
382.71,    19941005, -2.5,  -1.7,  -0.0
386.99,    19941006, 1.1,   -1.3,  0.1
382.85,    19941007, -1.1,  -2.1,  -1.4
387.36,    19941010, 1.2,   -1.6,  -1.1
392.85,    19941011, 1.4,   0.0,   -1.6
400.29,    19941012, 1.9,   4.6,   0.5
399.85,    19941013, -0.1,  3.3,   -0.5
399.86,    19941014, 0.0,   4.4,   0.5
398.8,     19941017, -0.3,  3.0,   1.8
396.85,    19941018, -0.5,  1.0,   1.4
398.0,     19941019, 0.3,   -0.6,  1.1
403.73,    19941020, 1.4,   1.0,   1.7
402.99,    19941021, -0.2,  0.8,   0.6
400.52,    19941024, -0.6,  0.4,   1.4
398.79,    19941025, -0.4,  0.5,   1.8
398.48,    19941026, -0.1,  0.1,   0.5
403.83,    19941027, 1.3,   0.0,   1.5
405.62,    19941028, 0.4,   0.7,   -0.4
411.84,    19941031, 1.5,   2.8,   1.8
413.05,    19941101, 0.3,   3.6,   2.3
410.06,    19941102, -0.7,  2.9,   3.3
408.91,    19941103, -0.3,  1.3,   3.9
408.17,    19941104, -0.2,  0.6,   3.7
401.46,    19941107, -1.6,  -2.5,  2.9
400.19,    19941108, -0.3,  -3.1,  2.9
407.44,    19941109, 1.8,   -0.6,  4.7
408.32,    19941110, 0.2,   -0.1,  4.2
406.12,    19941111, -0.5,  -0.5,  3.8
404.78,    19941114, -0.3,  0.8,   2.8
412.17,    19941115, 1.8,   3.0,   5.0
411.83,    19941116, -0.1,  1.1,   7.6
412.72,    19941117, 0.2,   1.1,   6.6
410.98,    19941118, -0.4,  1.2,   7.3
412.43,    19941121, 0.4,   1.9,   6.5
409.28,    19941122, -0.8,  -0.7,  4.2
397.93,    19941123, -2.8,  -3.4,  -0.6
397.17,    19941125, -0.2,  -3.8,  -0.7
401.17,    19941128, 1.0,   -2.4,  0.3
403.82,    19941129, 0.7,   -2.1,  1.3
409.1,     19941130, 1.3,   -0.0,  3.1
404.82,    19941201, -1.0,  1.7,   1.7
399.06,    19941202, -1.4,  0.5,   -1.2
402.04,    19941205, 0.7,   0.2,   -0.2
402.71,    19941206, 0.2,   -0.3,  0.5
399.98,    19941207, -0.7,  -2.2,  0.3
395.75,    19941208, -1.1,  -2.2,  -0.7
386.3,     19941209, -2.4,  -3.2,  -4.3
388.63,    19941212, 0.6,   -3.3,  -4.2
388.88,    19941213, 0.1,   -3.4,  -5.6
387.53,    19941214, -0.3,  -3.1,  -6.2
391.65,    19941215, 1.1,   -1.0,  -4.5
393.7,     19941216, 0.5,   1.9,   -3.7
392.01,    19941219, -0.4,  0.9,   -4.0
390.42,    19941220, -0.4,  0.4,   -2.7
390.61,    19941221, 0.0,   0.8,   -2.4
397.8,     19941222, 1.8,   1.6,   -2.4
399.8,     19941223, 0.5,   1.5,   -2.1
401.15,    19941227, 0.3,   2.3,   -1.2
404.03,    19941228, 0.7,   3.5,   -0.2
400.26,    19941229, -0.9,  2.5,   -2.9
406.31,    19941230, 1.5,   2.1,   -1.3
404.27,    19950103, -0.5,  1.1,   -2.0
398.0,     19950104, -1.6,  -0.8,  -3.2
399.65,    19950105, 0.4,   -1.1,  -3.1
398.02,    19950106, -0.4,  -0.6,  -2.8
401.59,    19950109, 0.9,   -1.2,  0.9
403.53,    19950110, 0.5,   -0.2,  1.6
407.57,    19950111, 1.0,   2.4,   1.6
407.1,     19950112, -0.1,  1.9,   0.8
407.32,    19950113, 0.1,   2.3,   -0.4
410.48,    19950116, 0.8,   2.2,   1.4
413.65,    19950117, 0.8,   2.5,   3.7
416.89,    19950118, 0.8,   2.3,   3.7
419.26,    19950119, 0.6,   3.0,   4.1
417.83,    19950120, -0.3,  2.6,   4.5
410.13,    19950123, -1.8,  -0.1,  3.6
411.89,    19950124, 0.4,   -0.4,  6.6
413.6,     19950125, 0.4,   -0.8,  6.4
410.87,    19950126, -0.7,  -2.0,  5.7
406.5,     19950127, -1.1,  -2.7,  4.9
407.22,    19950130, 0.2,   -0.7,  4.0
401.71,    19950131, -1.4,  -2.5,  2.0
405.33,    19950201, 0.9,   -2.0,  3.4
407.06,    19950202, 0.4,   -0.9,  4.3
411.15,    19950203, 1.0,   1.1,   5.3
416.14,    19950206, 1.2,   2.2,   4.6
420.93,    19950207, 1.2,   4.8,   5.3
420.79,    19950208, -0.0,  3.8,   4.9
425.66,    19950209, 1.2,   4.6,   5.4
426.43,    19950210, 0.2,   3.7,   6.5
430.77,    19950213, 1.0,   3.5,   6.0
428.77,    19950214, -0.5,  1.9,   6.1
429.86,    19950215, 0.3,   2.2,   8.0
432.49,    19950216, 0.6,   1.6,   8.2
430.86,    19950217, -0.4,  1.0,   8.3
425.66,    19950221, -1.2,  -1.2,  6.0
424.82,    19950222, -0.2,  -0.9,  5.3
429.2,     19950223, 1.0,   -0.2,  5.3
431.03,    19950224, 0.4,   -0.3,  5.9
428.42,    19950227, -0.6,  -0.6,  5.2
424.75,    19950228, -0.9,  -0.2,  3.5
432.5,     19950301, 1.8,   1.8,   4.6
429.8,     19950302, -0.6,  0.1,   3.1
431.68,    19950303, 0.4,   0.2,   3.0
436.68,    19950306, 1.2,   1.9,   4.5
437.24,    19950307, 0.1,   2.9,   6.6
432.12,    19950308, -1.2,  -0.1,  4.9
437.01,    19950309, 1.1,   1.7,   5.7
437.02,    19950310, 0.0,   1.2,   6.4
441.75,    19950313, 1.1,   1.2,   8.7
440.81,    19950314, -0.2,  0.8,   8.2
446.27,    19950315, 1.2,   3.3,   11.1
443.57,    19950316, -0.6,  1.5,   9.4
444.68,    19950317, 0.3,   1.8,   9.2
443.94,    19950320, -0.2,  0.5,   8.0
446.61,    19950321, 0.6,   1.3,   7.3
447.13,    19950322, 0.1,   0.2,   6.2
447.13,    19950323, 0.0,   0.8,   6.3
448.82,    19950324, 0.4,   0.9,   5.4
455.43,    19950327, 1.5,   2.6,   6.8
457.12,    19950328, 0.4,   2.4,   6.1
460.54,    19950329, 0.7,   3.0,   7.4
451.87,    19950330, -1.9,  1.1,   5.1
448.36,    19950331, -0.8,  -0.1,  3.7
447.15,    19950403, -0.3,  -1.8,  3.8
448.49,    19950404, 0.3,   -1.9,  5.4
441.86,    19950405, -1.5,  -4.1,  4.0
444.66,    19950406, 0.6,   -1.6,  3.6
440.33,    19950407, -1.0,  -1.8,  2.2
444.01,    19950410, 0.8,   -0.7,  3.6
449.36,    19950411, 1.2,   0.2,   5.8
453.33,    19950412, 0.9,   2.6,   4.8
456.98,    19950413, 0.8,   2.8,   6.3
458.93,    19950417, 0.4,   4.2,   6.3
457.06,    19950418, -0.4,  2.9,   4.7
453.48,    19950419, -0.8,  0.9,   3.7
447.17,    19950420, -1.4,  -1.4,  3.5
449.01,    19950421, 0.4,   -1.7,  2.7
451.34,    19950424, 0.5,   -1.7,  3.3
456.43,    19950425, 1.1,   -0.1,  3.3
459.1,     19950426, 0.6,   1.2,   4.1
463.03,    19950427, 0.9,   3.5,   3.8
465.34,    19950428, 0.5,   3.6,   4.9
469.56,    19950501, 0.9,   4.0,   5.6
466.05,    19950502, -0.7,  2.1,   5.0
465.38,    19950503, -0.1,  1.4,   4.2
474.93,    19950504, 2.1,   2.6,   6.2
474.1,     19950505, -0.2,  1.9,   6.0
470.3,     19950508, -0.8,  0.2,   4.8
476.78,    19950509, 1.4,   2.3,   4.7
476.0,     19950510, -0.2,  2.3,   4.1
472.87,    19950511, -0.7,  -0.4,  2.7
479.57,    19950512, 1.4,   1.2,   6.1
483.19,    19950515, 0.8,   2.7,   7.8
486.34,    19950516, 0.7,   2.0,   8.8
491.04,    19950517, 1.0,   3.2,   9.5
494.69,    19950518, 0.7,   4.6,   12.0
488.02,    19950519, -1.3,  1.8,   9.8
489.77,    19950522, 0.4,   1.4,   11.2
495.09,    19950523, 1.1,   1.8,   11.5
503.12,    19950524, 1.6,   2.5,   12.0
500.5,     19950525, -0.5,  1.2,   10.4
501.66,    19950526, 0.2,   2.8,   9.8
496.06,    19950530, -1.1,  1.3,   8.1
482.08,    19950531, -2.8,  -2.6,  5.5
488.1,     19950601, 1.2,   -3.0,  7.6
490.96,    19950602, 0.6,   -1.9,  9.8
492.65,    19950605, 0.3,   -1.8,  9.7
500.81,    19950606, 1.7,   1.0,   11.0
495.12,    19950607, -1.1,  2.7,   8.5
497.15,    19950608, 0.4,   1.9,   8.3
500.86,    19950609, 0.7,   2.0,   8.2
500.41,    19950612, -0.1,  1.6,   7.5
501.69,    19950613, 0.3,   0.2,   6.8
505.32,    19950614, 0.7,   2.1,   8.4
506.09,    19950615, 0.2,   1.8,   8.7
511.99,    19950616, 1.2,   2.2,   7.8
517.55,    19950619, 1.1,   3.4,   9.2
530.53,    19950620, 2.5,   5.7,   12.8
539.55,    19950621, 1.7,   6.8,   13.2
535.52,    19950622, -0.7,  5.8,   12.5
546.06,    19950623, 2.0,   6.7,   15.5
544.66,    19950626, -0.3,  5.2,   13.6
536.49,    19950627, -1.5,  1.1,   11.0
527.84,    19950628, -1.6,  -2.2,  8.5
530.65,    19950629, 0.5,   -0.9,  8.1
536.21,    19950630, 1.0,   -1.8,  8.4
538.03,    19950703, 0.3,   -1.2,  10.2
538.97,    19950705, 0.2,   0.5,   10.0
543.39,    19950706, 0.8,   2.9,   9.8
553.86,    19950707, 1.9,   4.4,   10.1
567.56,    19950710, 2.5,   5.8,   13.4
573.56,    19950711, 1.1,   6.6,   14.3
563.98,    19950712, -1.7,  4.6,   13.7
579.71,    19950713, 2.8,   6.7,   20.3
583.27,    19950714, 0.6,   5.3,   19.5
589.85,    19950717, 1.1,   3.9,   20.1
595.19,    19950718, 0.9,   3.8,   20.8
578.46,    19950719, -2.8,  2.6,   15.5
549.85,    19950720, -4.9,  -5.2,  11.1
550.33,    19950721, 0.1,   -5.6,  10.7
547.78,    19950724, -0.5,  -7.1,  9.4
562.64,    19950725, 2.7,   -5.5,  12.4
572.71,    19950726, 1.8,   -1.0,  14.2
576.84,    19950727, 0.7,   4.9,   14.2
584.25,    19950728, 1.3,   6.2,   15.4
574.18,    19950731, -1.7,  4.8,   12.1
568.88,    19950801, -0.9,  1.1,   9.9
559.36,    19950802, -1.7,  -2.3,  5.4
551.56,    19950803, -1.4,  -4.4,  2.2
553.06,    19950804, 0.3,   -5.3,  3.3
560.58,    19950807, 1.4,   -2.4,  2.7
563.65,    19950808, 0.5,   -0.9,  3.5
565.71,    19950809, 0.4,   1.1,   5.4
575.49,    19950810, 1.7,   4.3,   9.0
569.75,    19950811, -1.0,  3.0,   7.4
575.67,    19950814, 1.0,   2.7,   7.4
584.73,    19950815, 1.6,   3.7,   8.7
584.04,    19950816, -0.1,  3.2,   8.4
596.43,    19950817, 2.1,   3.6,   9.8
596.28,    19950818, -0.0,  4.7,   7.7
595.52,    19950821, -0.1,  3.4,   4.9
578.89,    19950822, -2.8,  -1.0,  0.9
589.25,    19950823, 1.8,   0.9,   4.5
591.32,    19950824, 0.4,   -0.9,  2.0
583.16,    19950825, -1.4,  -2.2,  -0.0
580.1,     19950828, -0.5,  -2.6,  -1.7
566.05,    19950829, -2.4,  -2.2,  -4.9
566.82,    19950830, 0.1,   -3.8,  -2.0
572.85,    19950831, 1.1,   -3.1,  4.2
576.77,    19950901, 0.7,   -1.1,  4.8
573.21,    19950905, -0.6,  -1.2,  4.6
595.36,    19950906, 3.9,   5.2,   5.8
597.16,    19950907, 0.3,   5.4,   4.3
599.14,    19950908, 0.3,   4.6,   3.9
604.27,    19950911, 0.9,   4.8,   3.4
610.08,    19950912, 1.0,   6.4,   6.3
605.99,    19950913, -0.7,  1.8,   6.5
607.7,     19950914, 0.3,   1.8,   8.6
604.12,    19950915, -0.6,  0.8,   9.5
589.01,    19950918, -2.5,  -2.5,  6.5
591.13,    19950919, 0.4,   -3.1,  5.4
601.02,    19950920, 1.7,   -0.8,  6.6
603.19,    19950921, 0.4,   -0.7,  6.6
594.85,    19950922, -1.4,  -1.5,  3.4
592.64,    19950925, -0.4,  0.6,   4.0
587.93,    19950926, -0.8,  -0.5,  2.1
580.7,     19950927, -1.2,  -3.4,  -0.7
576.22,    19950928, -0.8,  -4.5,  -1.3
595.44,    19950929, 3.3,   0.1,   -0.2
585.08,    19951002, -1.7,  -1.3,  -1.9
573.87,    19951003, -1.9,  -2.4,  -3.6
573.16,    19951004, -0.1,  -1.3,  -1.0
558.99,    19951005, -2.5,  -3.0,  -5.1
572.75,    19951006, 2.5,   -3.8,  -3.1
567.41,    19951009, -0.9,  -3.0,  -2.7
546.93,    19951010, -3.6,  -4.7,  -5.7
550.76,    19951011, 0.7,   -3.9,  -2.7
564.33,    19951012, 2.5,   1.0,   -0.4
573.1,     19951013, 1.6,   0.1,   0.0
570.01,    19951016, -0.5,  0.5,   -1.2
568.46,    19951017, -0.3,  3.9,   -0.8
589.43,    19951018, 3.7,   7.0,   -1.0
597.97,    19951019, 1.4,   6.0,   0.1
601.26,    19951020, 0.6,   4.9,   0.4
587.84,    19951023, -2.2,  3.1,   -2.7
593.2,     19951024, 0.9,   4.4,   -2.8
594.23,    19951025, 0.2,   0.8,   -1.9
584.33,    19951026, -1.7,  -2.3,  -3.8
582.47,    19951027, -0.3,  -3.1,  -3.6
593.53,    19951030, 1.9,   1.0,   0.8
607.1,     19951031, 2.3,   2.3,   2.7
598.78,    19951101, -1.4,  0.8,   -0.4
600.91,    19951102, 0.4,   2.8,   -0.4
614.69,    19951103, 2.3,   5.5,   3.3
621.71,    19951106, 1.1,   4.7,   4.9
615.72,    19951107, -1.0,  1.4,   4.7
596.2,     19951108, -3.2,  -0.4,  2.7
598.72,    19951109, 0.4,   -0.4,  3.9
616.95,    19951110, 3.0,   0.4,   3.6
613.61,    19951113, -0.5,  -1.3,  4.9
608.32,    19951114, -0.9,  -1.2,  6.0
591.68,    19951115, -2.7,  -0.8,  3.2
593.48,    19951116, 0.3,   -0.9,  6.2
591.7,     19951117, -0.3,  -4.1,  3.3
588.81,    19951120, -0.5,  -4.0,  3.8
571.67,    19951121, -2.9,  -6.0,  4.5
574.22,    19951122, 0.4,   -3.0,  4.3
569.04,    19951124, -0.9,  -4.1,  0.8
578.32,    19951127, 1.6,   -2.3,  0.9
573.91,    19951128, -0.8,  -2.5,  0.7
598.0,     19951129, 4.2,   4.6,   5.2
599.58,    19951130, 0.3,   4.4,   1.7
593.72,    19951201, -1.0,  4.3,   -0.7
586.93,    19951204, -1.1,  1.5,   -2.4
598.0,     19951205, 1.9,   4.2,   1.7
591.23,    19951206, -1.1,  -1.1,  -0.3
589.98,    19951207, -0.2,  -1.6,  -0.7
587.13,    19951208, -0.5,  -1.1,  0.5
599.38,    19951211, 2.1,   2.1,   2.9
596.11,    19951212, -0.5,  -0.3,  0.4
585.04,    19951213, -1.9,  -1.0,  -3.6
587.54,    19951214, 0.4,   -0.4,  -1.9
566.29,    19951215, -3.6,  -3.5,  -5.8
563.28,    19951218, -0.5,  -6.0,  -8.4
548.04,    19951219, -2.7,  -8.1,  -11.8
575.01,    19951220, 4.9,   -1.7,  -6.6
565.15,    19951221, -1.7,  -3.8,  -5.2
580.76,    19951222, 2.8,   2.6,   -3.0
585.18,    19951226, 0.8,   3.9,   -5.1
585.09,    19951227, -0.0,  6.8,   -4.6
580.37,    19951228, -0.8,  0.9,   -4.6
571.96,    19951229, -1.4,  1.2,   -3.3
576.23,    19960102, 0.7,   -0.8,  -2.9
585.94,    19960103, 1.7,   0.1,   -1.0
572.29,    19960104, -2.3,  -2.2,  -2.8
563.48,    19960105, -1.5,  -2.9,  -1.4
565.14,    19960108, 0.3,   -1.2,  -1.6
562.42,    19960109, -0.5,  -2.4,  -1.2
534.42,    19960110, -5.0,  -8.8,  -7.6
535.29,    19960111, 0.2,   -6.5,  -6.7
555.2,     19960112, 3.7,   -1.5,  -7.2
552.75,    19960115, -0.4,  -2.2,  -7.8
534.44,    19960116, -3.3,  -5.0,  -10.0
546.05,    19960117, 2.2,   2.2,   -7.0
545.51,    19960118, -0.1,  1.9,   -8.8
554.32,    19960119, 1.6,   -0.2,  -6.2
564.64,    19960122, 1.9,   2.2,   -4.3
571.25,    19960123, 1.2,   6.9,   -2.7
567.35,    19960124, -0.7,  3.9,   -5.3
582.14,    19960125, 2.6,   6.7,   -2.3
572.26,    19960126, -1.7,  3.2,   -2.2
577.05,    19960129, 0.8,   2.2,   -1.8
576.23,    19960130, -0.1,  0.9,   1.8
583.99,    19960131, 1.3,   2.9,   3.7
591.82,    19960201, 1.3,   1.7,   8.0
601.88,    19960202, 1.7,   5.2,   4.7
601.5,     19960205, -0.1,  4.2,   6.4
615.47,    19960206, 2.3,   6.8,   6.0
619.17,    19960207, 0.6,   6.0,   5.8
615.07,    19960208, -0.7,  3.9,   5.1
623.8,     19960209, 1.4,   3.6,   7.5
623.01,    19960212, -0.1,  3.6,   8.9
625.3,     19960213, 0.4,   1.6,   8.5
618.54,    19960214, -1.1,  -0.1,  5.6
617.59,    19960215, -0.2,  0.4,   7.9
620.23,    19960216, 0.4,   -0.6,  10.1
619.04,    19960220, -0.2,  -0.6,  9.5
616.83,    19960221, -0.4,  -1.4,  9.7
627.28,    19960222, 1.7,   1.4,   17.4
643.41,    19960223, 2.6,   4.2,   20.2
642.59,    19960226, -0.1,  3.6,   15.7
637.92,    19960227, -0.7,  3.0,   15.4
631.1,     19960228, -1.1,  2.3,   18.1
631.44,    19960229, 0.1,   0.7,   15.6
622.83,    19960301, -1.4,  -3.2,  14.2
604.76,    19960304, -2.9,  -5.9,  9.1
601.5,     19960305, -0.5,  -5.7,  6.5
615.88,    19960306, 2.4,   -2.4,  7.8
607.84,    19960307, -1.3,  -3.7,  7.1
609.55,    19960308, 0.3,   -2.1,  4.7
591.71,    19960311, -2.9,  -2.2,  3.4
605.48,    19960312, 2.3,   0.7,   4.9
599.02,    19960313, -1.1,  -2.7,  4.0
614.75,    19960314, 2.6,   1.1,   5.3
610.56,    19960315, -0.7,  0.2,   3.2
621.71,    19960318, 1.8,   5.1,   3.3
633.26,    19960319, 1.9,   4.6,   5.3
629.39,    19960320, -0.6,  5.1,   2.3
615.26,    19960321, -2.2,  0.1,   -0.6
611.51,    19960322, -0.6,  0.2,   -0.6
612.88,    19960325, 0.2,   -1.4,  -1.8
597.82,    19960326, -2.5,  -5.6,  -4.0
602.08,    19960327, 0.7,   -4.3,  -3.7
608.06,    19960328, 1.0,   -1.2,  -1.7
608.08,    19960329, 0.0,   -0.6,  -1.5
609.69,    19960401, 0.3,   -0.5,  -1.7
612.99,    19960402, 0.5,   2.5,   -1.0
615.81,    19960403, 0.5,   2.3,   -0.2
618.57,    19960404, 0.4,   1.7,   -1.4
618.0,     19960408, -0.1,  1.6,   -3.9
610.61,    19960409, -1.2,  0.2,   -5.0
611.06,    19960410, 0.1,   -0.3,  -4.2
609.11,    19960411, -0.3,  -1.1,  -3.5
604.07,    19960412, -0.8,  -2.3,  -4.3
605.05,    19960415, 0.2,   -2.1,  -2.9
611.08,    19960416, 1.0,   0.1,   1.0
625.93,    19960417, 2.4,   2.4,   4.1
620.71,    19960418, -0.8,  1.9,   0.8
633.44,    19960419, 2.1,   4.9,   4.2
633.01,    19960422, -0.1,  4.6,   3.8
644.29,    19960423, 1.8,   5.4,   8.9
654.49,    19960424, 1.6,   4.6,   8.1
664.01,    19960425, 1.5,   7.0,   10.8
666.68,    19960426, 0.4,   5.2,   8.4
666.65,    19960429, -0.0,  5.3,   9.2
667.0,     19960430, 0.1,   3.5,   7.3
666.73,    19960501, -0.0,  1.9,   5.3
673.36,    19960502, 1.0,   1.4,   7.0
656.73,    19960503, -2.5,  -1.5,  6.7
661.07,    19960506, 0.7,   -0.8,  8.1
662.93,    19960507, 0.3,   -0.6,  8.2
660.82,    19960508, -0.3,  -0.9,  10.5
663.43,    19960509, 0.4,   -1.5,  10.2
662.54,    19960510, -0.1,  0.9,   9.0
672.54,    19960513, 1.5,   1.7,   10.6
688.52,    19960514, 2.4,   3.9,   12.9
695.43,    19960515, 1.0,   5.2,   13.4
690.15,    19960516, -0.8,  4.0,   12.1
695.25,    19960517, 0.7,   4.9,   12.4
690.81,    19960520, -0.6,  2.7,   11.8
693.27,    19960521, 0.4,   0.7,   13.5
687.86,    19960522, -0.8,  -1.1,  12.6
688.43,    19960523, 0.1,   -0.2,  13.0
692.09,    19960524, 0.5,   -0.5,  14.6
688.91,    19960528, -0.5,  -0.3,  13.9
685.07,    19960529, -0.6,  -1.2,  12.1
677.09,    19960530, -1.2,  -1.6,  8.2
684.04,    19960531, 1.0,   -0.6,  10.2
692.39,    19960603, 1.2,   0.0,   9.3
689.25,    19960604, -0.5,  0.0,   8.9
692.59,    19960605, 0.5,   1.1,   7.5
699.35,    19960606, 1.0,   3.3,   6.9
687.01,    19960607, -1.8,  0.4,   3.5
691.46,    19960610, 0.6,   -0.1,  3.7
688.84,    19960611, -0.4,  -0.1,  3.3
690.94,    19960612, 0.3,   -0.2,  3.6
697.16,    19960613, 0.9,   -0.3,  4.6
691.84,    19960614, -0.8,  0.7,   2.7
681.14,    19960617, -1.5,  -1.5,  3.7
678.44,    19960618, -0.4,  -1.5,  2.6
666.07,    19960619, -1.8,  -3.6,  0.5
667.71,    19960620, 0.2,   -4.2,  1.0
664.5,     19960621, -0.5,  -4.0,  0.2
672.53,    19960624, 1.2,   -1.3,  1.5
677.17,    19960625, 0.7,   -0.2,  0.7
668.04,    19960626, -1.3,  0.3,   -3.0
659.69,    19960627, -1.2,  -1.2,  -5.1
671.15,    19960628, 1.7,   1.0,   -2.8
677.4,     19960701, 0.9,   0.7,   -2.6
687.07,    19960702, 1.4,   1.5,   -0.5
680.17,    19960703, -1.0,  1.8,   -1.9
673.84,    19960705, -0.9,  2.1,   -2.0
657.68,    19960708, -2.4,  -2.0,  -4.5
656.0,     19960709, -0.3,  -3.2,  -5.2
660.76,    19960710, 0.7,   -3.8,  -4.1
657.45,    19960711, -0.5,  -3.3,  -4.0
635.78,    19960712, -3.3,  -5.6,  -6.1
635.06,    19960715, -0.1,  -3.4,  -7.2
606.89,    19960716, -4.4,  -7.5,  -12.3
614.25,    19960717, 1.2,   -7.0,  -10.9
631.3,     19960718, 2.8,   -4.0,  -8.8
643.75,    19960719, 2.0,   1.3,   -8.0
633.05,    19960722, -1.7,  -0.3,  -7.9
624.03,    19960723, -1.4,  2.8,   -9.8
598.33,    19960724, -4.1,  -2.6,  -13.1
605.05,    19960725, 1.1,   -4.2,  -12.4
620.39,    19960726, 2.5,   -3.6,  -11.0
633.39,    19960729, 2.1,   0.1,   -8.4
620.51,    19960730, -2.0,  -0.6,  -8.9
630.32,    19960731, 1.6,   5.3,   -7.1
636.01,    19960801, 0.9,   5.1,   -4.5
651.07,    19960802, 2.4,   4.9,   -2.5
667.84,    19960805, 2.6,   5.4,   0.5
661.02,    19960806, -1.0,  6.5,   -1.7
671.69,    19960807, 1.6,   6.6,   -0.8
682.33,    19960808, 1.6,   7.3,   2.1
677.52,    19960809, -0.7,  4.1,   2.7
678.31,    19960812, 0.1,   1.6,   1.1
679.39,    19960813, 0.2,   2.8,   0.3
669.1,     19960814, -1.5,  -0.4,  -2.6
677.17,    19960815, 1.2,   -0.8,  -0.4
677.09,    19960816, -0.0,  -0.1,  0.5
672.28,    19960819, -0.7,  -0.9,  2.2
670.25,    19960820, -0.3,  -1.3,  2.2
662.87,    19960821, -1.1,  -0.9,  0.3
667.42,    19960822, 0.7,   -1.4,  1.5
681.63,    19960823, 2.1,   0.7,   7.2
677.57,    19960826, -0.6,  0.8,   6.7
672.86,    19960827, -0.7,  0.4,   10.9
679.05,    19960828, 0.9,   2.4,   10.5
679.67,    19960829, 0.1,   1.8,   7.7
671.1,     19960830, -1.3,  -1.5,  4.2
663.57,    19960903, -1.1,  -2.1,  4.8
669.29,    19960904, 0.9,   -0.5,  7.3
669.2,     19960905, -0.0,  -1.5,  11.8
652.32,    19960906, -2.5,  -4.0,  7.8
664.22,    19960909, 1.8,   -1.0,  7.1
671.98,    19960910, 1.2,   1.3,   6.1
671.65,    19960911, -0.0,  0.4,   8.2
675.12,    19960912, 0.5,   0.9,   7.1
685.65,    19960913, 1.6,   5.1,   7.8
707.86,    19960916, 3.2,   6.6,   8.7
709.22,    19960917, 0.2,   5.5,   6.2
722.5,     19960918, 1.9,   7.6,   9.3
727.63,    19960919, 0.7,   7.8,   8.3
736.76,    19960920, 1.3,   7.5,   8.0
743.42,    19960923, 0.9,   5.0,   9.7
733.41,    19960924, -1.3,  3.4,   8.1
736.59,    19960925, 0.4,   2.0,   8.4
745.73,    19960926, 1.2,   2.5,   11.5
744.61,    19960927, -0.2,  1.1,   10.0
744.73,    19960930, 0.0,   0.2,   10.0
737.58,    19961001, -1.0,  0.6,   9.7
734.5,     19961002, -0.4,  -0.3,  9.6
747.55,    19961003, 1.8,   0.2,   12.8
743.28,    19961004, -0.6,  -0.2,  11.4
759.12,    19961007, 2.1,   1.9,   11.4
764.96,    19961008, 0.8,   3.7,   12.9
753.24,    19961009, -1.5,  2.6,   11.9
753.97,    19961010, 0.1,   0.9,   11.0
751.44,    19961011, -0.3,  1.1,   10.6
765.64,    19961014, 1.9,   0.9,   14.1
771.99,    19961015, 0.8,   0.9,   16.3
775.08,    19961016, 0.4,   2.9,   15.8
767.64,    19961017, -1.0,  1.8,   14.7
755.77,    19961018, -1.5,  0.6,   15.9
756.83,    19961021, 0.1,   -1.2,  13.9
746.28,    19961022, -1.4,  -3.3,  11.1
736.64,    19961023, -1.3,  -5.0,  9.7
749.7,     19961024, 1.8,   -2.3,  11.0
748.41,    19961025, -0.2,  -1.0,  9.2
743.55,    19961028, -0.6,  -1.8,  5.0
741.64,    19961029, -0.3,  -0.6,  4.6
731.21,    19961030, -1.4,  -0.7,  1.2
736.36,    19961031, 0.7,   -1.8,  1.2
751.99,    19961101, 2.1,   0.5,   2.1
755.51,    19961104, 0.5,   1.6,   1.6
756.23,    19961105, 0.1,   2.0,   3.1
769.9,     19961106, 1.8,   5.3,   4.5
786.9,     19961107, 2.2,   6.9,   5.5
795.42,    19961108, 1.1,   5.8,   6.8
798.66,    19961111, 0.4,   5.7,   7.2
804.72,    19961112, 0.8,   6.4,   9.1
794.09,    19961113, -1.3,  3.1,   8.1
798.7,     19961114, 0.6,   1.5,   6.8
809.75,    19961115, 1.4,   1.8,   8.9
799.43,    19961118, -1.3,  0.1,   5.3
793.29,    19961119, -0.8,  -1.4,  3.7
805.78,    19961120, 1.6,   1.5,   7.0
808.39,    19961121, 0.3,   1.2,   7.2
800.47,    19961122, -1.0,  -1.1,  6.5
818.51,    19961125, 2.3,   2.4,   6.9
821.24,    19961126, 0.3,   3.5,   6.4
825.51,    19961127, 0.5,   2.4,   6.5
832.37,    19961129, 0.8,   3.0,   8.4
834.01,    19961202, 0.2,   4.2,   10.4
844.63,    19961203, 1.3,   3.2,   11.6
837.91,    19961204, -0.8,  2.0,   12.3
834.65,    19961205, -0.4,  1.1,   13.3
835.8,     19961206, 0.1,   0.4,   11.5
827.21,    19961209, -1.0,  -0.8,  10.5
856.64,    19961210, 3.6,   1.4,   15.2
848.96,    19961211, -0.9,  1.3,   14.5
856.38,    19961212, 0.9,   2.6,   17.1
843.37,    19961213, -1.5,  0.9,   14.5
828.45,    19961216, -1.8,  0.1,   10.2
804.62,    19961217, -2.9,  -6.1,  6.5
815.92,    19961218, 1.4,   -3.9,  7.9
836.54,    19961219, 2.5,   -2.3,  8.7
843.72,    19961220, 0.9,   0.0,   7.2
834.05,    19961223, -1.1,  0.7,   4.9
824.72,    19961224, -1.1,  2.5,   3.3
835.4,     19961226, 1.3,   2.4,   3.8
842.98,    19961227, 0.9,   0.8,   6.2
835.19,    19961230, -0.9,  -1.0,  4.6
826.49,    19961231, -1.0,  -0.9,  2.1
821.36,    19970102, -0.6,  -0.4,  2.7
815.6,     19970103, -0.7,  -2.4,  2.8
848.52,    19970106, 4.0,   0.7,   5.3
853.24,    19970107, 0.6,   2.2,   5.5
864.55,    19970108, 1.3,   4.6,   8.0
853.09,    19970109, -1.3,  3.9,   4.2
856.95,    19970110, 0.5,   5.1,   4.3
865.59,    19970113, 1.0,   2.0,   4.9
864.37,    19970114, -0.1,  1.3,   3.8
881.38,    19970115, 2.0,   1.9,   5.7
864.37,    19970116, -1.9,  1.3,   2.3
873.63,    19970117, 1.1,   1.9,   4.3
883.44,    19970120, 1.1,   2.1,   5.8
899.75,    19970121, 1.8,   4.1,   7.7
913.73,    19970122, 1.6,   3.7,   10.5
925.52,    19970123, 1.3,   7.1,   8.0
912.74,    19970124, -1.4,  4.5,   7.5
898.37,    19970127, -1.6,  1.7,   4.9
889.82,    19970128, -1.0,  -1.1,  5.5
890.79,    19970129, 0.1,   -2.5,  7.5
892.46,    19970130, 0.2,   -3.6,  10.9
911.71,    19970131, 2.2,   -0.1,  11.7
921.55,    19970203, 1.1,   2.6,   10.2
918.01,    19970204, -0.4,  3.2,   8.8
916.3,     19970205, -0.2,  2.9,   9.9
887.38,    19970206, -3.2,  -0.6,  7.6
885.52,    19970207, -0.2,  -2.9,  6.0
899.84,    19970210, 1.6,   -2.4,  6.7
872.06,    19970211, -3.1,  -5.0,  4.4
871.71,    19970212, -0.0,  -4.9,  5.5
900.5,     19970213, 3.3,   1.5,   9.6
907.52,    19970214, 0.8,   2.5,   11.3
899.91,    19970218, -0.8,  0.0,   6.1
897.53,    19970219, -0.3,  2.9,   5.2
897.72,    19970220, 0.0,   3.0,   3.8
880.25,    19970221, -1.9,  -2.2,  3.2
864.3,     19970224, -1.8,  -4.8,  0.9
880.26,    19970225, 1.8,   -2.2,  1.7
882.04,    19970226, 0.2,   -1.7,  2.0
880.74,    19970227, -0.1,  -1.9,  -0.1
848.49,    19970228, -3.7,  -3.6,  -1.8
850.46,    19970303, 0.2,   -1.6,  -2.7
856.08,    19970304, 0.7,   -2.7,  -3.1
862.04,    19970305, 0.7,   -2.3,  -4.2
872.07,    19970306, 1.2,   -1.0,  -4.6
851.51,    19970307, -2.4,  0.4,   -8.0
841.03,    19970310, -1.2,  -1.1,  -7.9
854.84,    19970311, 1.6,   -0.1,  -4.8
848.5,     19970312, -0.7,  -1.6,  -4.6
839.32,    19970313, -1.1,  -3.8,  -5.8
840.5,     19970314, 0.1,   -1.3,  -5.8
838.97,    19970317, -0.2,  -0.2,  -8.0
832.24,    19970318, -0.8,  -2.6,  -9.7
827.57,    19970319, -0.6,  -2.5,  -9.9
811.8,     19970320, -1.9,  -3.3,  -11.4
821.73,    19970321, 1.2,   -2.2,  -7.4
812.73,    19970324, -1.1,  -3.1,  -8.2
802.37,    19970325, -1.3,  -3.6,  -10.8
807.19,    19970326, 0.6,   -2.5,  -7.4
834.38,    19970327, 3.4,   2.8,   -4.3
818.74,    19970331, -1.9,  -0.4,  -9.1
797.06,    19970401, -2.6,  -1.9,  -12.2
796.79,    19970402, -0.0,  -0.7,  -11.5
783.92,    19970403, -1.6,  -2.9,  -12.7
804.67,    19970404, 2.6,   -3.6,  -10.4
822.4,     19970407, 2.2,   0.4,   -6.6
832.31,    19970408, 1.2,   4.4,   -3.7
840.36,    19970409, 1.0,   5.5,   -4.5
828.73,    19970410, -1.4,  5.7,   -6.0
813.45,    19970411, -1.8,  1.1,   -7.6
789.97,    19970414, -2.9,  -3.9,  -6.9
806.79,    19970415, 2.1,   -3.1,  -5.1
797.99,    19970416, -1.1,  -5.0,  -6.8
798.65,    19970417, 0.1,   -3.6,  -7.4
808.22,    19970418, 1.2,   -0.6,  -7.3
816.35,    19970421, 1.0,   3.3,   -4.1
802.5,     19970422, -1.7,  -0.5,  -4.6
818.08,    19970423, 1.9,   2.5,   -4.3
837.97,    19970424, 2.4,   4.9,   -1.2
837.75,    19970425, -0.0,  3.7,   -0.2
818.57,    19970428, -2.3,  0.3,   -2.6
829.39,    19970429, 1.3,   3.4,   -1.1
856.26,    19970430, 3.2,   4.7,   2.9
874.74,    19970501, 2.2,   4.4,   5.7
882.12,    19970502, 0.8,   5.3,   8.7
909.49,    19970505, 3.1,   11.1,  10.7
932.77,    19970506, 2.6,   12.5,  14.8
914.9,     19970507, -1.9,  6.8,   14.0
909.29,    19970508, -0.6,  3.9,   12.6
919.15,    19970509, 1.1,   4.2,   10.2
919.14,    19970512, -0.0,  1.1,   12.3
924.64,    19970513, 0.6,   -0.9,  16.0
910.57,    19970514, -1.5,  -0.5,  14.3
909.92,    19970515, -0.1,  0.1,   16.1
931.79,    19970516, 2.4,   1.4,   15.8
915.29,    19970519, -1.8,  -0.4,  11.3
913.57,    19970520, -0.2,  -1.2,  9.8
942.11,    19970521, 3.1,   3.5,   12.1
950.17,    19970522, 0.9,   4.4,   14.7
946.11,    19970523, -0.4,  1.5,   16.3
959.08,    19970527, 1.4,   4.8,   21.4
984.61,    19970528, 2.7,   7.8,   22.0
982.6,     19970529, -0.2,  4.3,   23.1
970.54,    19970530, -1.2,  2.1,   21.5
958.85,    19970602, -1.2,  1.3,   18.6
958.69,    19970603, -0.0,  -0.0,  17.4
929.81,    19970604, -3.0,  -5.6,  15.9
921.96,    19970605, -0.8,  -6.2,  12.7
930.61,    19970606, 0.9,   -4.1,  11.1
945.01,    19970609, 1.5,   -1.4,  12.8
954.56,    19970610, 1.0,   -0.4,  16.6
941.13,    19970611, -1.4,  1.2,   13.5
949.0,     19970612, 0.8,   2.9,   10.8
949.97,    19970613, 0.1,   2.1,   8.6
964.4,     19970616, 1.5,   2.1,   9.3
975.68,    19970617, 1.2,   2.2,   7.3
989.37,    19970618, 1.4,   5.1,   6.1
971.55,    19970619, -1.8,  2.4,   6.2
981.95,    19970620, 1.1,   3.4,   8.0
981.41,    19970623, -0.1,  1.8,   6.8
969.05,    19970624, -1.3,  -0.7,  5.4
986.42,    19970625, 1.8,   -0.3,  6.7
979.22,    19970626, -0.7,  0.8,   7.5
967.34,    19970627, -1.2,  -1.5,  6.3
963.07,    19970630, -0.4,  -1.9,  3.4
957.3,     19970701, -0.6,  -1.2,  4.6
953.44,    19970702, -0.4,  -3.3,  4.4
975.93,    19970703, 2.4,   -0.3,  3.6
986.52,    19970707, 1.1,   2.0,   3.8
991.04,    19970708, 0.5,   2.9,   4.7
1005.81,   19970709, 1.5,   5.1,   4.9
1010.14,   19970710, 0.4,   5.9,   2.6
1010.07,   19970711, -0.0,  3.5,   2.8
1015.62,   19970714, 0.5,   2.9,   4.6
1041.88,   19970715, 2.6,   5.1,   8.7
1059.0,    19970716, 1.6,   5.3,   10.5
1103.43,   19970717, 4.2,   9.2,   18.7
1094.95,   19970718, -0.8,  8.4,   18.8
1070.99,   19970721, -2.2,  5.5,   15.1
1058.45,   19970722, -1.2,  1.6,   12.0
1090.94,   19970723, 3.1,   3.0,   14.3
1092.06,   19970724, 0.1,   -1.0,  16.0
1087.91,   19970725, -0.4,  -0.6,  14.6
1085.39,   19970728, -0.2,  1.3,   14.3
1075.18,   19970729, -0.9,  1.6,   11.5
1087.95,   19970730, 1.2,   -0.3,  11.5
1100.99,   19970731, 1.2,   0.8,   11.3
1107.03,   19970801, 0.5,   1.8,   13.9
1108.95,   19970804, 0.2,   2.2,   12.9
1130.86,   19970805, 2.0,   5.2,   15.2
1136.41,   19970806, 0.5,   4.5,   17.3
1141.82,   19970807, 0.5,   3.7,   15.8
1135.61,   19970808, -0.5,  2.6,   16.0
1115.02,   19970811, -1.8,  0.5,   15.3
1101.18,   19970812, -1.2,  -2.6,  14.3
1090.78,   19970813, -0.9,  -4.0,  13.9
1099.1,    19970814, 0.8,   -3.7,  15.3
1103.54,   19970815, 0.4,   -2.8,  13.1
1076.65,   19970818, -2.4,  -3.4,  9.1
1089.55,   19970819, 1.2,   -1.1,  9.9
1121.18,   19970820, 2.9,   2.8,   11.5
1145.07,   19970821, 2.1,   4.2,   13.4
1118.72,   19970822, -2.3,  1.4,   10.8
1110.91,   19970825, -0.7,  3.2,   9.4
1107.8,    19970826, -0.3,  1.7,   6.3
1093.21,   19970827, -1.3,  -2.5,  3.2
1091.89,   19970828, -0.1,  -4.6,  -1.0
1070.62,   19970829, -1.9,  -4.3,  -2.2
1074.17,   19970902, 0.3,   -3.3,  0.3
1102.74,   19970903, 2.7,   -0.5,  4.2
1100.44,   19970904, -0.2,  0.7,   0.9
1105.82,   19970905, 0.5,   1.3,   1.3
1111.15,   19970908, 0.5,   3.8,   2.1
1117.82,   19970909, 0.6,   4.1,   3.0
1126.73,   19970910, 0.8,   2.2,   4.8
1102.95,   19970911, -2.1,  0.2,   1.4
1105.06,   19970912, 0.2,   -0.1,  0.4
1106.07,   19970915, 0.1,   -0.5,  -0.1
1080.29,   19970916, -2.3,  -3.4,  -2.6
1116.52,   19970917, 3.4,   -0.9,  -1.3
1108.63,   19970918, -0.7,  0.5,   -2.4
1109.29,   19970919, 0.1,   0.4,   -2.8
1118.68,   19970922, 0.8,   1.1,   -1.5
1123.65,   19970923, 0.4,   4.0,   0.8
1135.04,   19970924, 1.0,   1.7,   3.1
1115.88,   19970925, -1.7,  0.7,   2.3
1106.48,   19970926, -0.8,  -0.3,  0.7
1106.82,   19970929, 0.0,   -1.1,  0.3
1118.77,   19970930, 1.1,   -0.4,  3.9
1097.17,   19971001, -1.9,  -3.3,  0.7
1102.05,   19971002, 0.4,   -1.2,  -1.7
1112.89,   19971003, 1.0,   0.6,   -2.8
1124.44,   19971006, 1.0,   1.6,   0.5
1125.93,   19971007, 0.1,   0.6,   1.4
1140.75,   19971008, 1.3,   4.0,   3.0
1145.37,   19971009, 0.4,   3.9,   4.8
1148.21,   19971010, 0.2,   3.2,   5.2
1137.77,   19971013, -0.9,  1.2,   6.3
1140.48,   19971014, 0.2,   1.3,   6.2
1131.43,   19971015, -0.8,  -0.8,  2.6
1119.07,   19971016, -1.1,  -2.3,  1.7
1099.38,   19971017, -1.8,  -4.3,  -0.6
1075.12,   19971020, -2.2,  -5.5,  -3.2
1088.25,   19971021, 1.2,   -4.6,  -2.6
1110.55,   19971022, 2.0,   -1.8,  -1.4
1098.71,   19971023, -1.1,  -1.8,  -0.4
1074.65,   19971024, -2.2,  -2.2,  -2.8
1057.34,   19971027, -1.6,  -1.7,  -4.4
978.84,    19971028, -7.4,  -10.1, -9.4
1046.87,   19971029, 7.0,   -5.7,  -6.2
1029.76,   19971030, -1.6,  -6.3,  -7.1
1000.7,    19971031, -2.8,  -6.9,  -9.8
1019.62,   19971103, 1.9,   -3.6,  -8.9
1050.72,   19971104, 3.1,   7.3,   -6.5
1047.62,   19971105, -0.3,  0.1,   -7.7
1046.92,   19971106, -0.1,  1.7,   -6.2
1034.17,   19971107, -1.2,  3.3,   -6.5
1028.32,   19971110, -0.6,  0.9,   -7.1
1011.52,   19971111, -1.6,  -3.7,  -9.6
1010.96,   19971112, -0.1,  -3.5,  -7.9
980.14,    19971113, -3.0,  -6.4,  -11.1
1002.92,   19971114, 2.3,   -3.0,  -9.9
1027.85,   19971117, 2.5,   -0.0,  -8.6
1049.7,    19971118, 2.1,   3.8,   -6.8
1036.31,   19971119, -1.3,  2.5,   -9.2
1039.12,   19971120, 0.3,   6.0,   -9.3
1062.49,   19971121, 2.2,   5.9,   -7.5
1056.59,   19971124, -0.6,  2.8,   -7.1
1030.22,   19971125, -2.5,  -1.9,  -9.7
1038.06,   19971126, 0.8,   0.2,   -8.3
1044.24,   19971128, 0.6,   0.5,   -6.7
1050.51,   19971201, 0.6,   -1.1,  -4.4
1079.23,   19971202, 2.7,   2.1,   0.4
1052.07,   19971203, -2.5,  2.1,   -3.3
1061.62,   19971204, 0.9,   2.3,   -4.4
1052.76,   19971205, -0.8,  0.8,   -4.2
1070.27,   19971208, 1.7,   1.9,   -0.4
1082.92,   19971209, 1.2,   0.3,   2.4
1050.67,   19971210, -3.0,  -0.1,  7.3
1033.22,   19971211, -1.7,  -2.7,  -1.3
1002.18,   19971212, -3.0,  -4.8,  -2.7
978.49,    19971215, -2.4,  -8.6,  -2.2
984.38,    19971216, 0.6,   -9.1,  -3.5
997.3,     19971217, 1.3,   -5.1,  -5.1
983.63,    19971218, -1.4,  -4.8,  -6.1
966.77,    19971219, -1.7,  -3.5,  -7.7
969.89,    19971222, 0.3,   -0.9,  -6.2
975.59,    19971223, 0.6,   -0.9,  -5.1
949.57,    19971224, -2.7,  -4.8,  -6.1
938.99,    19971226, -1.1,  -4.5,  -7.1
954.07,    19971229, 1.6,   -1.3,  -2.7
978.53,    19971230, 2.6,   0.9,   -2.4
998.46,    19971231, 2.0,   2.3,   -2.9
990.8,     19980102, -0.8,  4.3,   -5.6
1008.23,   19980105, 1.8,   7.4,   -2.7
1017.42,   19980106, 0.9,   6.6,   -2.1
1006.29,   19980107, -1.1,  2.8,   -5.3
990.97,    19980108, -1.5,  -0.8,  -6.2
994.54,    19980109, 0.4,   0.4,   -3.5
956.19,    19980112, -3.9,  -5.2,  -7.9
971.17,    19980113, 1.6,   -4.5,  -7.0
995.05,    19980114, 2.5,   -1.1,  -5.3
996.37,    19980115, 0.1,   0.5,   -7.7
995.58,    19980116, -0.1,  0.1,   -5.4
1005.87,   19980120, 1.0,   5.2,   -5.3
1026.88,   19980121, 2.1,   5.7,   -2.5
1026.74,   19980122, -0.0,  3.2,   -4.1
1020.63,   19980123, -0.6,  2.4,   -5.8
1024.47,   19980126, 0.4,   2.9,   -2.5
1016.4,    19980127, -0.8,  1.0,   -1.6
1035.76,   19980128, 1.9,   0.9,   3.4
1063.22,   19980129, 2.7,   3.6,   8.7
1069.0,    19980130, 0.5,   4.7,   8.6
1071.13,   19980202, 0.2,   4.6,   7.4
1100.99,   19980203, 2.8,   8.3,   11.9
1111.25,   19980204, 0.9,   7.3,   14.9
1124.44,   19980205, 1.2,   5.8,   15.9
1114.86,   19980206, -0.9,  4.3,   14.3
1134.32,   19980209, 1.7,   5.9,   19.5
1122.68,   19980210, -1.0,  2.0,   19.6
1139.79,   19980211, 1.5,   2.6,   19.5
1138.81,   19980212, -0.1,  1.3,   16.4
1144.8,    19980213, 0.5,   2.7,   14.7
1136.64,   19980217, -0.7,  0.2,   14.7
1128.62,   19980218, -0.7,  0.5,   11.9
1140.76,   19980219, 1.1,   0.1,   12.1
1156.76,   19980220, 1.4,   1.6,   15.0
1159.73,   19980223, 0.3,   1.3,   17.0
1185.53,   19980224, 2.2,   4.3,   19.2
1172.4,    19980225, -1.1,  3.9,   22.6
1200.39,   19980226, 2.4,   5.2,   23.6
1206.92,   19980227, 0.5,   4.3,   21.3
1194.13,   19980302, -1.1,  3.0,   19.8
1177.59,   19980303, -1.4,  -0.7,  18.3
1175.72,   19980304, -0.2,  0.3,   16.9
1179.87,   19980305, 0.4,   -1.7,  14.9
1126.75,   19980306, -4.5,  -6.6,  9.7
1163.11,   19980309, 3.2,   -2.6,  14.0
1128.37,   19980310, -3.0,  -4.2,  10.1
1148.61,   19980311, 1.8,   -2.3,  13.0
1153.87,   19980312, 0.5,   -2.2,  11.4
1163.27,   19980313, 0.8,   3.2,   9.4
1169.37,   19980316, 0.5,   0.5,   9.4
1180.17,   19980317, 0.9,   4.6,   10.2
1167.89,   19980318, -1.0,  1.7,   6.1
1177.01,   19980319, 0.8,   2.0,   5.9
1186.16,   19980320, 0.8,   2.0,   5.5
1169.43,   19980323, -1.4,  0.0,   4.9
1179.18,   19980324, 0.8,   -0.1,  4.0
1197.81,   19980325, 1.6,   2.6,   6.7
1214.83,   19980326, 1.4,   3.2,   6.6
1217.94,   19980327, 0.3,   2.7,   6.9
1211.64,   19980330, -0.5,  3.6,   5.8
1206.28,   19980331, -0.4,  2.3,   6.1
1220.66,   19980401, 1.2,   1.9,   8.2
1228.58,   19980402, 0.6,   1.1,   7.7
1230.18,   19980403, 0.1,   1.0,   6.3
1233.66,   19980406, 0.3,   1.8,   6.4
1205.37,   19980407, -2.3,  -0.1,  1.7
1182.13,   19980408, -1.9,  -3.2,  0.8
1191.06,   19980409, 0.8,   -3.1,  -0.8
1196.99,   19980413, 0.5,   -2.7,  -0.8
1202.97,   19980414, 0.5,   -2.5,  0.7
1213.12,   19980415, 0.8,   0.6,   3.0
1231.36,   19980416, 1.5,   4.2,   4.7
1227.53,   19980417, -0.3,  3.1,   4.0
1231.25,   19980420, 0.3,   2.9,   9.3
1253.11,   19980421, 1.8,   4.2,   7.7
1269.7,    19980422, 1.3,   4.7,   12.5
1290.9,    19980423, 1.7,   4.8,   12.4
1258.58,   19980424, -2.5,  2.5,   9.1
1250.14,   19980427, -0.7,  1.5,   7.5
1220.05,   19980428, -2.4,  -2.6,  4.3
1222.5,    19980429, 0.2,   -3.7,  3.6
1239.56,   19980430, 1.4,   -4.0,  6.1
1248.12,   19980501, 0.7,   -0.8,  6.0
1251.53,   19980504, 0.3,   0.1,   5.5
1255.67,   19980505, 0.3,   2.9,   7.4
1247.27,   19980506, -0.7,  2.0,   5.8
1242.26,   19980507, -0.4,  0.2,   3.7
1222.68,   19980508, -1.6,  -2.0,  0.6
1254.03,   19980511, 2.6,   0.2,   3.0
1237.67,   19980512, -1.3,  -1.4,  2.1
1256.41,   19980513, 1.5,   0.7,   4.2
1263.08,   19980514, 0.5,   1.7,   3.5
1266.1,    19980515, 0.2,   3.6,   3.1
1249.49,   19980518, -1.3,  -0.4,  1.6
1242.5,    19980519, -0.6,  0.4,   0.7
1255.59,   19980520, 1.1,   -0.1,  4.2
1237.75,   19980521, -1.4,  -2.0,  4.7
1223.7,    19980522, -1.1,  -3.3,  2.7
1214.13,   19980526, -0.8,  -2.8,  1.4
1200.12,   19980527, -1.2,  -3.4,  -0.2
1209.46,   19980528, 0.8,   -3.7,  -0.3
1214.83,   19980529, 0.4,   -1.9,  -1.3
1192.07,   19980601, -1.9,  -2.6,  -2.9
1165.55,   19980602, -2.2,  -4.0,  -5.3
1187.15,   19980603, 1.9,   -1.1,  -5.3
1163.98,   19980604, -2.0,  -3.8,  -8.3
1195.19,   19980605, 2.7,   -1.6,  -7.4
1206.7,    19980608, 1.0,   1.2,   -4.1
1207.01,   19980609, 0.0,   3.6,   -3.5
1223.48,   19980610, 1.4,   3.1,   0.3
1199.3,    19980611, -2.0,  3.0,   -1.9
1185.11,   19980612, -1.2,  -0.8,  -4.4
1186.99,   19980615, 0.2,   -1.6,  -4.9
1173.57,   19980616, -1.1,  -2.8,  -6.2
1210.9,    19980617, 3.2,   -1.0,  -3.6
1224.09,   19980618, 1.1,   2.1,   -1.9
1227.33,   19980619, 0.3,   3.6,   -1.2
1239.71,   19980622, 1.0,   4.4,   1.4
1264.64,   19980623, 2.0,   7.8,   0.8
1302.39,   19980624, 3.0,   7.6,   5.2
1331.16,   19980625, 2.2,   8.7,   5.9
1311.4,    19980626, -1.5,  6.8,   3.8
1320.94,   19980629, 0.7,   6.6,   4.3
1339.72,   19980630, 1.4,   5.9,   7.2
1337.34,   19980701, -0.2,  2.7,   7.6
1356.14,   19980702, 1.4,   1.9,   8.0
1332.53,   19980706, -1.7,  1.6,   7.7
1342.58,   19980707, 0.8,   1.6,   9.7
1346.32,   19980708, 0.3,   0.5,   10.9
1377.84,   19980709, 2.3,   3.0,   14.8
1383.22,   19980710, 0.4,   2.0,   14.4
1391.11,   19980713, 0.6,   4.4,   14.5
1420.59,   19980714, 2.1,   5.8,   19.2
1418.13,   19980715, -0.2,  5.3,   21.7
1449.58,   19980716, 2.2,   5.2,   22.1
1452.62,   19980717, 0.2,   5.0,   24.8
1463.97,   19980720, 0.8,   5.2,   22.5
1465.89,   19980721, 0.1,   3.2,   21.5
1431.89,   19980722, -2.3,  1.0,   18.6
1430.58,   19980723, -0.1,  -1.3,  16.9
1405.8,    19980724, -1.7,  -3.2,  17.2
1408.6,    19980727, 0.2,   -3.8,  18.9
1426.37,   19980728, 1.3,   -2.7,  20.2
1393.28,   19980729, -2.3,  -2.7,  18.7
1380.08,   19980730, -0.9,  -3.5,  14.0
1422.06,   19980731, 3.0,   1.2,   16.2
1377.26,   19980803, -3.2,  -2.2,  12.2
1368.44,   19980804, -0.6,  -4.1,  10.4
1317.24,   19980805, -3.7,  -5.5,  4.2
1329.9,    19980806, 1.0,   -3.6,  2.1
1365.87,   19980807, 2.7,   -4.0,  2.6
1364.08,   19980810, -0.1,  -1.0,  4.0
1362.88,   19980811, -0.1,  -0.4,  3.2
1330.13,   19980812, -2.4,  1.0,   -0.7
1354.66,   19980813, 1.8,   1.9,   1.3
1338.39,   19980814, -1.2,  -2.0,  -1.3
1330.04,   19980817, -0.6,  -2.5,  -0.2
1367.79,   19980818, 2.8,   0.4,   1.9
1401.24,   19980819, 2.4,   5.3,   4.1
1398.26,   19980820, -0.2,  3.2,   1.5
1398.2,    19980821, -0.0,  4.5,   1.1
1374.77,   19980824, -1.7,  3.4,   -1.2
1370.92,   19980825, -0.3,  0.2,   -3.5
1388.35,   19980826, 1.3,   -0.9,  -2.1
1374.05,   19980827, -1.0,  -1.7,  -5.2
1312.28,   19980828, -4.5,  -6.1,  -9.7
1265.04,   19980831, -3.6,  -8.0,  -13.6
1140.34,   19980901, -9.9,  -16.8, -22.2
1215.06,   19980902, 6.6,   -12.5, -15.1
1221.44,   19980903, 0.5,   -11.1, -14.6
1208.96,   19980904, -1.0,  -7.9,  -14.0
1205.41,   19980908, -0.3,  -4.7,  -14.4
1286.47,   19980909, 6.7,   12.8,  -9.8
1261.77,   19980910, -1.9,  3.8,   -9.4
1235.37,   19980911, -2.1,  1.1,   -10.5
1290.2,    19980914, 4.4,   6.7,   -9.3
1308.23,   19980915, 1.4,   8.5,   -5.0
1324.17,   19980916, 1.2,   2.9,   -3.2
1331.45,   19980917, 0.5,   5.5,   1.1
1291.79,   19980918, -3.0,  4.6,   -2.9
1300.7,    19980921, 0.7,   0.8,   -4.8
1327.7,    19980922, 2.1,   1.5,   -2.7
1336.19,   19980923, 0.6,   0.9,   -2.0
1394.65,   19980924, 4.4,   4.7,   4.9
1356.46,   19980925, -2.7,  5.0,   0.1
1390.09,   19980928, 2.5,   6.9,   3.9
1383.3,    19980929, -0.5,  4.2,   4.0
1384.44,   19980930, 0.1,   3.6,   1.2
1345.48,   19981001, -2.8,  -3.5,  -4.0
1273.74,   19981002, -5.3,  -6.1,  -8.9
1267.49,   19981005, -0.5,  -8.8,  -9.3
1207.68,   19981006, -4.7,  -12.7, -12.2
1184.1,    19981007, -2.0,  -14.5, -13.6
1144.69,   19981008, -3.3,  -14.9, -17.6
1128.88,   19981009, -1.4,  -11.4, -17.8
1198.42,   19981012, 6.2,   -5.4,  -8.7
1243.4,    19981013, 3.8,   3.0,   -1.7
1206.5,    19981014, -3.0,  1.9,   5.8
1235.67,   19981015, 2.4,   7.9,   1.7
1299.66,   19981016, 5.2,   15.1,  6.4
1293.91,   19981019, -0.4,  8.0,   7.0
1310.17,   19981020, 1.3,   5.4,   8.7
1283.23,   19981021, -2.1,  6.4,   -0.3
1332.05,   19981022, 3.8,   7.8,   5.6
1355.19,   19981023, 1.7,   4.3,   9.7
1340.6,    19981026, -1.1,  3.6,   3.9
1368.93,   19981027, 2.1,   4.5,   4.6
1353.34,   19981028, -1.1,  5.5,   2.2
1379.96,   19981029, 2.0,   3.6,   3.6
1396.3,    19981030, 1.2,   3.0,   8.1
1400.52,   19981102, 0.3,   4.5,   7.7
1418.79,   19981103, 1.3,   3.6,   6.9
1401.73,   19981104, -1.2,  3.6,   4.9
1434.0,    19981105, 2.3,   3.9,   2.8
1442.03,   19981106, 0.6,   3.3,   6.3
1458.34,   19981109, 1.1,   4.1,   4.9
1467.63,   19981110, 0.6,   3.4,   6.1
1477.48,   19981111, 0.7,   5.4,   6.7
1478.22,   19981112, 0.1,   3.1,   9.9
1466.54,   19981113, -0.8,  1.7,   15.1
1465.22,   19981116, -0.1,  0.5,   15.6
1475.6,    19981117, 0.7,   0.5,   22.2
1498.55,   19981118, 1.6,   1.4,   26.6
1514.89,   19981119, 1.1,   2.5,   32.3
1537.45,   19981120, 1.5,   4.8,   36.2
1544.22,   19981123, 0.4,   5.4,   28.9
1589.06,   19981124, 2.9,   7.7,   27.8
1580.83,   19981125, -0.5,  5.5,   31.0
1598.48,   19981127, 1.1,   5.5,   29.4
1628.38,   19981130, 1.9,   5.9,   25.3
1557.96,   19981201, -4.3,  0.9,   20.4
1632.53,   19981202, 4.8,   2.7,   24.6
1619.14,   19981203, -0.8,  2.4,   26.2
1574.83,   19981204, -2.7,  -1.5,  18.2
1630.01,   19981207, 3.5,   0.1,   20.3
1676.89,   19981208, 2.9,   7.6,   25.1
1668.16,   19981209, -0.5,  2.2,   21.9
1685.38,   19981210, 1.0,   4.1,   24.5
1652.78,   19981211, -1.9,  4.9,   19.8
1674.68,   19981214, 1.3,   2.7,   19.9
1614.08,   19981215, -3.6,  -3.7,  15.2
1663.78,   19981216, 3.1,   -0.3,  17.3
1656.28,   19981217, -0.5,  -1.7,  18.2
1689.63,   19981218, 2.0,   2.2,   17.8
1735.6,    19981221, 2.7,   3.6,   20.4
1787.3,    19981222, 3.0,   10.7,  22.6
1767.82,   19981223, -1.1,  6.3,   20.5
1818.96,   19981224, 2.9,   9.8,   23.1
1806.04,   19981228, -0.7,  6.9,   22.2
1823.99,   19981229, 1.0,   5.1,   24.4
1823.31,   19981230, -0.0,  2.0,   24.4
1812.07,   19981231, -0.6,  2.5,   22.8
1836.01,   19990104, 1.3,   0.9,   22.5
1854.39,   19990105, 1.0,   2.7,   22.4
1903.0,    19990106, 2.6,   4.3,   23.8
1963.95,   19990107, 3.2,   7.7,   27.2
1966.35,   19990108, 0.1,   8.5,   23.7
1973.66,   19990111, 0.4,   7.5,   24.8
2000.18,   19990112, 1.3,   7.9,   25.1
1939.08,   19990113, -3.1,  1.9,   19.1
1965.1,    19990114, 1.3,   0.1,   26.1
1906.12,   19990115, -3.0,  -3.1,  16.8
1981.62,   19990119, 4.0,   0.4,   22.4
2033.71,   19990120, 2.6,   1.7,   29.1
2033.71,   19990121, 0.0,   4.9,   24.8
1961.76,   19990122, -3.5,  -0.2,  17.0
1964.21,   19990125, 0.1,   3.0,   17.7
2004.14,   19990126, 2.0,   1.1,   18.9
2062.21,   19990127, 2.9,   1.4,   24.8
2030.36,   19990128, -1.5,  -0.2,  21.2
2104.59,   19990129, 3.7,   7.3,   30.4
2127.19,   19990201, 1.1,   8.3,   27.9
2130.93,   19990202, 0.2,   6.3,   28.7
2078.77,   19990203, -2.4,  0.8,   23.0
2119.95,   19990204, 2.0,   4.4,   22.1
2027.66,   19990205, -4.4,  -3.7,  13.4
1988.55,   19990208, -1.9,  -6.5,  12.5
2034.21,   19990209, 2.3,   -4.5,  11.8
1935.33,   19990210, -4.9,  -6.9,  7.2
1946.41,   19990211, 0.6,   -8.2,  6.7
2044.68,   19990212, 5.0,   0.8,   12.1
1958.6,    19990216, -4.2,  -1.5,  8.1
1954.48,   19990217, -0.2,  -3.9,  6.5
1891.37,   19990218, -3.2,  -2.3,  2.0
1906.09,   19990219, 0.8,   -2.1,  0.2
1931.65,   19990222, 1.3,   -5.5,  -1.6
1996.02,   19990223, 3.3,   1.9,   1.5
2028.23,   19990224, 1.6,   3.8,   2.8
1989.14,   19990225, -1.9,  5.2,   -0.6
1974.39,   19990226, -0.7,  3.6,   1.8
1925.97,   19990301, -2.5,  -0.3,  -2.0
1937.7,    19990302, 0.6,   -2.9,  1.7
1891.7,    19990303, -2.4,  -6.7,  -4.5
1899.81,   19990304, 0.4,   -4.5,  -6.6
1933.06,   19990305, 1.8,   -2.1,  -4.9
1969.87,   19990308, 1.9,   2.3,   0.4
2034.05,   19990309, 3.3,   5.0,   3.6
2028.57,   19990310, -0.3,  7.2,   1.2
2038.51,   19990311, 0.5,   7.3,   -1.1
2043.76,   19990312, 0.3,   5.7,   0.7
2009.36,   19990315, -1.7,  2.0,   -4.5
2060.34,   19990316, 2.5,   1.3,   -3.1
2073.14,   19990317, 0.6,   2.2,   -2.7
2064.5,    19990318, -0.4,  1.3,   -0.7
2102.91,   19990319, 1.9,   2.9,   -0.8
2053.69,   19990322, -2.3,  2.2,   1.3
2026.84,   19990323, -1.3,  -1.6,  1.9
1961.31,   19990324, -3.2,  -5.4,  -3.6
2013.55,   19990325, 2.7,   -2.5,  4.0
2083.35,   19990326, 3.5,   -0.9,  7.0
2067.3,    19990329, -0.8,  0.7,   1.1
2144.66,   19990330, 3.7,   5.8,   9.5
2125.69,   19990331, -0.9,  8.4,   8.8
2106.39,   19990401, -0.9,  4.6,   11.4
2146.13,   19990405, 1.9,   3.0,   12.6
2219.64,   19990406, 3.4,   7.4,   14.9
2218.83,   19990407, -0.0,  3.5,   11.2
2192.29,   19990408, -1.2,  3.1,   8.1
2224.75,   19990409, 1.5,   5.6,   11.8
2232.47,   19990412, 0.3,   4.0,   13.1
2219.21,   19990413, -0.6,  -0.0,  15.2
2175.18,   19990414, -2.0,  -2.0,  12.3
2103.63,   19990415, -3.3,  -4.0,  11.2
2135.81,   19990416, 1.5,   -4.0,  12.4
2086.46,   19990419, -2.3,  -6.5,  7.9
1967.57,   19990420, -5.7,  -11.3, -0.1
2031.6,    19990421, 3.3,   -6.6,  -0.1
2105.82,   19990422, 3.7,   0.1,   3.8
2183.49,   19990423, 3.7,   2.2,   7.1
2210.45,   19990426, 1.2,   5.9,   8.2
2263.01,   19990427, 2.4,   15.0,  12.6
2207.03,   19990428, -2.5,  8.6,   7.1
2150.44,   19990429, -2.6,  2.1,   3.7
2119.47,   19990430, -1.4,  -2.9,  2.7
2136.39,   19990503, 0.8,   -3.4,  1.6
2137.95,   19990504, 0.1,   -5.5,  4.1
2097.55,   19990505, -1.9,  -5.0,  3.5
2155.77,   19990506, 2.8,   0.2,   9.9
2079.42,   19990507, -3.5,  -1.9,  3.3
2117.73,   19990510, 1.8,   -0.9,  1.7
2134.75,   19990511, 0.8,   -0.1,  3.3
2175.85,   19990512, 1.9,   3.7,   1.5
2217.68,   19990513, 1.9,   2.9,   4.3
2179.08,   19990514, -1.7,  4.8,   3.5
2125.15,   19990517, -2.5,  0.4,   -1.0
2175.74,   19990518, 2.4,   1.9,   -2.0
2159.36,   19990519, -0.8,  -0.8,  -2.7
2186.31,   19990520, 1.2,   -1.4,  -0.3
2139.95,   19990521, -2.1,  -1.8,  -3.8
2114.4,    19990524, -1.2,  -0.5,  -5.3
2060.59,   19990525, -2.5,  -5.3,  -7.1
1999.04,   19990526, -3.0,  -7.4,  -8.1
2053.04,   19990527, 2.7,   -6.1,  -2.4
2050.09,   19990528, -0.1,  -4.2,  -4.0
2089.7,    19990601, 1.9,   -1.2,  0.2
2025.79,   19990602, -3.1,  -1.7,  3.0
2074.54,   19990603, 2.4,   3.8,   2.1
2031.97,   19990604, -2.1,  -1.0,  -3.5
2109.41,   19990607, 3.8,   2.9,   -3.4
2152.69,   19990608, 2.1,   3.0,   -2.6
2089.14,   19990609, -3.0,  3.1,   -7.7
2135.48,   19990610, 2.2,   2.9,   -3.2
2097.79,   19990611, -1.8,  3.2,   -2.4
2062.29,   19990614, -1.7,  -2.2,  -2.7
2028.56,   19990615, -1.6,  -5.8,  -5.0
2058.17,   19990616, 1.5,   -1.5,  -3.7
2159.1,    19990617, 4.9,   1.1,   2.9
2190.83,   19990618, 1.5,   4.4,   1.6
2205.46,   19990621, 0.7,   6.9,   6.1
2268.67,   19990622, 2.9,   11.8,  7.1
2209.89,   19990623, -2.6,  7.4,   3.5
2239.33,   19990624, 1.3,   3.7,   2.9
2184.87,   19990625, -2.4,  -0.3,  -1.5
2185.76,   19990628, 0.0,   -0.9,  0.3
2243.73,   19990629, 2.7,   -1.1,  5.6
2280.07,   19990630, 1.6,   3.2,   4.8
2296.77,   19990701, 0.7,   2.6,   6.4
2322.32,   19990702, 1.1,   6.3,   6.2
2347.88,   19990706, 1.1,   7.4,   9.7
2331.12,   19990707, -0.7,  3.9,   10.2
2340.67,   19990708, 0.4,   2.7,   13.6
2374.58,   19990709, 1.4,   3.4,   18.8
2393.02,   19990712, 0.8,   3.0,   16.6
2384.21,   19990713, -0.4,  1.5,   16.3
2373.61,   19990714, -0.4,  1.8,   13.6
2417.05,   19990715, 1.8,   3.3,   19.3
2429.27,   19990716, 0.5,   2.3,   17.1
2459.5,    19990719, 1.2,   2.8,   21.0
2424.62,   19990720, -1.4,  1.7,   14.9
2329.32,   19990721, -3.9,  -1.9,  8.2
2363.63,   19990722, 1.5,   -2.2,  13.1
2281.92,   19990723, -3.5,  -6.1,  6.9
2302.02,   19990726, 0.9,   -6.4,  9.7
2233.51,   19990727, -3.0,  -7.9,  8.3
2308.39,   19990728, 3.4,   -0.9,  13.8
2333.78,   19990729, 1.1,   -1.3,  13.4
2263.06,   19990730, -3.0,  -0.8,  4.8
2270.93,   19990802, 0.3,   -1.4,  3.7
2264.81,   19990803, -0.3,  1.4,   2.7
2239.18,   19990804, -1.1,  -3.0,  -1.3
2204.33,   19990805, -1.6,  -5.5,  -0.3
2232.23,   19990806, 1.3,   -1.4,  -0.3
2212.15,   19990809, -0.9,  -2.6,  1.2
2187.61,   19990810, -1.1,  -3.4,  0.1
2164.55,   19990811, -1.1,  -3.3,  -3.5
2241.8,    19990812, 3.6,   1.7,   -1.7
2216.15,   19990813, -1.1,  -0.7,  -3.5
2307.06,   19990816, 4.1,   4.3,   -0.7
2313.08,   19990817, 0.3,   5.7,   -1.5
2337.37,   19990818, 1.1,   8.0,   0.3
2317.39,   19990819, -0.9,  3.4,   -1.0
2278.79,   19990820, -1.7,  2.8,   -4.0
2315.07,   19990823, 1.6,   0.3,   -3.3
2385.84,   19990824, 3.1,   3.1,   0.1
2404.82,   19990825, 0.8,   2.9,   1.3
2460.92,   19990826, 2.3,   6.2,   1.8
2417.3,    19990827, -1.8,  6.1,   -0.5
2402.57,   19990830, -0.6,  3.8,   -2.3
2366.88,   19990831, -1.5,  -0.8,  -2.4
2396.87,   19990901, 1.3,   -0.3,  2.9
2404.94,   19990902, 0.3,   -2.3,  1.7
2393.75,   19990903, -0.5,  -1.0,  4.9
2506.69,   19990907, 4.7,   4.3,   8.9
2491.82,   19990908, -0.6,  5.3,   11.6
2454.67,   19990909, -1.5,  2.4,   6.3
2505.5,    19990910, 2.1,   4.2,   7.4
2533.36,   19990913, 1.1,   5.8,   11.9
2483.3,    19990914, -2.0,  -0.9,  9.4
2518.84,   19990915, 1.4,   1.1,   11.2
2459.79,   19990916, -2.3,  0.2,   9.9
2468.88,   19990917, 0.4,   -1.5,  12.0
2541.16,   19990920, 2.9,   0.3,   13.8
2536.66,   19990921, -0.2,  2.1,   14.7
2474.57,   19990922, -2.4,  -1.8,  13.1
2524.71,   19990923, 2.0,   2.6,   16.6
2398.92,   19990924, -5.0,  -2.8,  7.0
2401.27,   19990927, 0.1,   -5.5,  8.4
2425.7,    19990928, 1.0,   -4.4,  5.1
2428.32,   19990929, 0.1,   -1.9,  5.0
2401.49,   19990930, -1.1,  -4.9,  2.7
2407.7,    19991001, 0.3,   0.4,   3.9
2406.45,   19991004, -0.1,  0.2,   5.6
2468.44,   19991005, 2.6,   1.8,   6.6
2479.06,   19991006, 0.4,   2.1,   3.9
2535.69,   19991007, 2.3,   5.6,   5.4
2534.1,    19991008, -0.1,  5.2,   3.0
2556.86,   19991011, 0.9,   6.3,   5.8
2585.53,   19991012, 1.1,   4.7,   7.6
2515.8,    19991013, -2.7,  1.5,   6.3
2463.26,   19991014, -2.1,  -2.9,  2.8
2467.2,    19991015, 0.2,   -2.6,  2.6
2401.2,    19991018, -2.7,  -6.1,  0.3
2369.55,   19991019, -1.3,  -8.4,  -5.5
2384.52,   19991020, 0.6,   -5.2,  -4.3
2450.44,   19991021, 2.8,   -0.5,  -0.2
2479.74,   19991022, 1.2,   0.5,   -1.0
2488.16,   19991025, 0.3,   3.6,   -1.8
2484.85,   19991026, -0.1,  4.9,   0.1
2466.45,   19991027, -0.7,  3.4,   -2.1
2471.91,   19991028, 0.2,   0.9,   0.5
2539.93,   19991029, 2.8,   2.4,   2.9
2637.04,   19991101, 3.8,   6.0,   3.8
2617.07,   19991102, -0.8,  5.3,   3.2
2630.73,   19991103, 0.5,   6.7,   6.3
2682.55,   19991104, 2.0,   8.5,   6.3
2704.88,   19991105, 0.8,   6.5,   12.8
2732.53,   19991108, 1.0,   3.6,   13.8
2791.56,   19991109, 2.2,   6.7,   15.1
2770.76,   19991110, -0.7,  5.3,   14.1
2798.07,   19991111, 1.0,   4.3,   16.5
2851.36,   19991112, 1.9,   5.4,   18.4
2891.58,   19991115, 1.4,   5.8,   20.2
2875.87,   19991116, -0.5,  3.0,   16.5
2952.91,   19991117, 2.7,   6.6,   19.1
2914.25,   19991118, -1.3,  4.2,   14.9
3001.28,   19991119, 3.0,   5.3,   18.4
3028.51,   19991122, 0.9,   4.7,   18.4
3049.4,    19991123, 0.7,   6.0,   17.9
3003.02,   19991124, -1.5,  1.7,   19.4
3109.35,   19991126, 3.5,   6.7,   26.2
3114.88,   19991129, 0.2,   3.8,   26.3
3061.68,   19991130, -1.7,  1.1,   27.5
2970.04,   19991201, -3.0,  -2.6,  25.3
3002.9,    19991202, 1.1,   -0.0,  25.9
3120.12,   19991203, 3.9,   0.3,   27.3
3172.31,   19991206, 1.7,   1.8,   27.9
3190.57,   19991207, 0.6,   4.2,   28.2
3197.51,   19991208, 0.2,   7.7,   28.7
3184.5,    19991209, -0.4,  6.0,   29.1
3172.38,   19991210, -0.4,  1.7,   28.3
3198.35,   19991213, 0.8,   0.8,   25.9
3243.6,    19991214, 1.4,   1.7,   23.0
3165.68,   19991215, -2.4,  -1.0,  21.0
3253.98,   19991216, 2.8,   2.2,   23.7
3362.69,   19991217, 3.3,   6.0,   25.4
3361.97,   19991220, -0.0,  5.1,   24.3
3397.26,   19991221, 1.0,   4.7,   24.3
3536.92,   19991222, 4.1,   11.7,  26.7
3566.4,    19991223, 0.8,   9.6,   28.7
3588.43,   19991227, 0.6,   6.7,   28.2
3601.14,   19991228, 0.4,   7.1,   26.3
3582.93,   19991229, -0.5,  5.5,   23.9
3692.57,   19991230, 3.1,   4.4,   28.4
3700.0,    19991231, 0.2,   3.7,   25.3
3755.74,   20000103, 1.5,   4.7,   28.9
3766.57,   20000104, 0.3,   4.6,   25.5
3543.13,   20000105, -5.9,  -1.1,  17.0
3488.31,   20000106, -1.5,  -5.5,  14.4
3337.26,   20000107, -4.3,  -9.8,  11.1
3558.21,   20000110, 6.6,   -5.3,  14.4
3715.22,   20000111, 4.4,   -1.4,  19.3
3548.77,   20000112, -4.5,  0.2,   15.9
3495.83,   20000113, -1.5,  0.2,   17.7
3653.3,    20000114, 4.5,   9.5,   21.7
3704.74,   20000118, 1.4,   4.1,   18.7
3741.45,   20000119, 1.0,   0.7,   17.9
3798.84,   20000120, 1.5,   7.0,   19.1
3841.74,   20000121, 1.1,   9.9,   20.1
3888.33,   20000124, 1.2,   6.4,   22.1
3667.02,   20000125, -5.7,  -1.0,  15.6
3762.69,   20000126, 2.6,   0.6,   17.6
3628.88,   20000127, -3.6,  -4.5,  11.9
3595.89,   20000128, -0.9,  -6.4,  13.6
3438.09,   20000131, -4.4,  -11.6, 5.7
3580.29,   20000201, 4.1,   -2.4,  6.5
3700.2,    20000202, 3.3,   -1.7,  10.1
3726.13,   20000203, 0.7,   2.7,   9.7
3858.6,    20000204, 3.6,   7.3,   9.1
3873.43,   20000207, 0.4,   12.7,  8.6
3932.24,   20000208, 1.5,   9.8,   9.6
4061.74,   20000209, 3.3,   9.8,   12.8
3974.83,   20000210, -2.1,  6.7,   10.9
4090.26,   20000211, 2.9,   6.0,   10.8
3969.95,   20000214, -2.9,  2.5,   7.3
3983.85,   20000215, 0.4,   1.3,   6.1
3997.03,   20000216, 0.3,   -1.6,  6.1
4012.29,   20000217, 0.4,   0.9,   13.2
4130.55,   20000218, 2.9,   1.0,   18.4
3970.53,   20000222, -3.9,  0.0,   19.0
3966.0,    20000223, -0.1,  -0.4,  11.5
4169.66,   20000224, 5.1,   4.3,   12.2
4254.56,   20000225, 2.0,   6.0,   19.9
4178.58,   20000228, -1.8,  1.2,   19.5
4174.75,   20000229, -0.1,  5.1,   14.3
4275.72,   20000301, 2.4,   7.8,   15.4
4311.97,   20000302, 0.8,   3.4,   15.2
4257.53,   20000303, -1.3,  0.1,   12.1
4448.48,   20000306, 4.5,   6.5,   15.8
4489.49,   20000307, 0.9,   7.5,   15.5
4409.98,   20000308, -1.8,  3.1,   20.3
4446.48,   20000309, 0.8,   3.1,   18.2
4586.26,   20000310, 3.1,   7.7,   26.4
4585.16,   20000313, -0.0,  3.1,   27.5
4448.63,   20000314, -3.0,  -0.9,  29.4
4250.74,   20000315, -4.4,  -3.6,  18.7
4141.23,   20000316, -2.6,  -6.9,  11.9
4349.26,   20000317, 5.0,   -5.2,  16.7
4443.35,   20000320, 2.2,   -3.1,  15.2
4259.58,   20000321, -4.1,  -4.2,  10.0
4449.22,   20000322, 4.5,   4.7,   13.1
4594.37,   20000323, 3.3,   10.9,  13.1
4659.76,   20000324, 1.4,   7.1,   17.2
4687.6,    20000327, 0.6,   5.5,   14.6
4705.75,   20000328, 0.4,   10.5,  18.5
4583.81,   20000329, -2.6,  3.0,   15.1
4376.41,   20000330, -4.5,  -4.7,  9.5
4268.64,   20000331, -2.5,  -8.4,  6.4
4355.7,    20000403, 2.0,   -7.1,  5.5
4089.78,   20000404, -6.1,  -13.1, 3.0
4028.4,    20000405, -1.5,  -12.1, 1.6
4036.39,   20000406, 0.2,   -7.8,  -3.2
4111.18,   20000407, 1.9,   -3.7,  -3.4
4293.63,   20000410, 4.4,   -1.4,  2.8
3987.24,   20000411, -7.1,  -2.5,  -4.5
3909.0,    20000412, -2.0,  -3.0,  -8.6
3645.79,   20000413, -6.7,  -9.7,  -15.4
3536.41,   20000414, -3.0,  -14.0, -16.9
3194.5,    20000417, -9.7,  -25.6, -28.2
3531.07,   20000418, 10.5,  -11.4, -21.3
3720.24,   20000419, 5.4,   -4.8,  -15.6
3587.27,   20000420, -3.6,  -1.6,  -19.3
3448.23,   20000424, -3.9,  -2.5,  -24.8
3371.21,   20000425, -2.2,  5.5,   -26.5
3622.8,    20000426, 7.5,   2.6,   -18.6
3504.2,    20000427, -3.3,  -5.8,  -17.6
3696.42,   20000428, 5.5,   3.0,   -10.7
3789.2,    20000501, 2.5,   9.9,   -12.9
3768.04,   20000502, -0.6,  11.8,  -15.2
3627.31,   20000503, -3.7,  0.1,   -14.8
3561.72,   20000504, -1.8,  1.6,   -19.9
3571.27,   20000505, 0.3,   -3.4,  -22.3
3688.36,   20000508, 3.3,   -2.7,  -20.8
3525.23,   20000509, -4.4,  -6.4,  -24.8
3436.68,   20000510, -2.5,  -5.3,  -27.0
3311.51,   20000511, -3.6,  -7.0,  -27.8
3381.57,   20000512, 2.1,   -5.3,  -22.7
3404.56,   20000515, 0.7,   -7.7,  -20.2
3520.98,   20000516, 3.4,   -0.1,  -19.2
3635.03,   20000517, 3.2,   5.8,   -11.1
3547.52,   20000518, -2.4,  7.1,   -11.9
3425.38,   20000519, -3.4,  1.3,   -15.1
3261.47,   20000522, -4.8,  -4.2,  -20.7
3262.77,   20000523, 0.0,   -7.3,  -24.0
3023.49,   20000524, -7.3,  -16.8, -24.2
3181.5,    20000525, 5.2,   -10.3, -18.6
3100.97,   20000526, -2.5,  -9.5,  -14.9
3101.43,   20000530, 0.0,   -4.9,  -12.3
3414.03,   20000531, 10.1,  4.6,   6.9
3331.8,    20000601, -2.4,  10.2,  -5.6
3518.98,   20000602, 5.6,   10.6,  -5.4
3742.28,   20000605, 6.3,   20.7,  4.3
3730.31,   20000606, -0.3,  20.3,  8.2
3644.46,   20000607, -2.3,  6.7,   8.1
3737.93,   20000608, 2.6,   12.2,  3.2
3707.31,   20000609, -0.8,  5.4,   5.8
3761.15,   20000612, 1.5,   0.5,   1.8
3638.54,   20000613, -3.3,  -2.5,  -4.0
3767.54,   20000614, 3.5,   3.4,   -0.0
3680.67,   20000615, -2.3,  -1.5,  1.5
3758.36,   20000616, 2.1,   1.4,   5.5
3787.36,   20000619, 0.8,   0.7,   6.1
3933.7,    20000620, 3.9,   8.1,   6.7
3928.98,   20000621, -0.1,  4.3,   11.5
3968.85,   20000622, 1.0,   7.8,   15.5
3809.4,    20000623, -4.0,  1.4,   15.0
3685.3,    20000626, -3.3,  -2.7,  9.0
3771.45,   20000627, 2.3,   -4.1,  10.8
3701.11,   20000628, -1.9,  -5.8,  5.1
3764.83,   20000629, 1.7,   -5.1,  3.6
3666.18,   20000630, -2.6,  -3.8,  3.3
3763.79,   20000703, 2.7,   2.1,   9.9
3804.96,   20000705, 1.1,   0.9,   16.7
3648.13,   20000706, -4.1,  -1.4,  11.8
3793.01,   20000707, 4.0,   0.7,   25.5
3841.51,   20000710, 1.3,   4.8,   20.7
3772.61,   20000711, -1.8,  0.2,   21.7
3810.36,   20000712, 1.0,   0.1,   22.9
3901.12,   20000713, 2.4,   6.9,   14.3
3962.82,   20000714, 1.6,   4.5,   18.9
4041.15,   20000717, 2.0,   5.2,   14.8
4060.31,   20000718, 0.5,   7.6,   8.5
3960.96,   20000719, -2.4,  4.0,   6.2
3843.98,   20000720, -3.0,  -1.5,  5.5
3992.83,   20000721, 3.9,   0.8,   6.8
3908.75,   20000724, -2.1,  -3.3,  5.4
3790.62,   20000725, -3.0,  -6.6,  0.8
3865.33,   20000726, 2.0,   -2.4,  6.2
3816.49,   20000727, -1.3,  -0.7,  1.3
3689.69,   20000728, -3.3,  -7.6,  0.2
3477.53,   20000731, -5.8,  -11.0, -7.5
3609.35,   20000801, 3.8,   -4.8,  -4.7
3521.15,   20000802, -2.4,  -8.9,  -10.5
3490.34,   20000803, -0.9,  -8.5,  -11.2
3624.17,   20000804, 3.8,   -1.8,  -8.7
3618.63,   20000807, -0.2,  4.1,   -5.0
3708.95,   20000808, 2.5,   2.8,   0.6
3708.63,   20000809, -0.0,  5.3,   -1.7
3687.69,   20000810, -0.6,  5.7,   -0.4
3595.15,   20000811, -2.5,  -0.8,  -4.5
3644.63,   20000814, 1.4,   0.7,   -0.6
3718.7,    20000815, 2.0,   0.3,   -1.2
3722.62,   20000816, 0.1,   0.4,   -2.2
3721.25,   20000817, -0.0,  0.9,   2.0
3830.59,   20000818, 2.9,   6.5,   1.0
3809.86,   20000821, -0.5,  4.5,   -0.8
3832.1,    20000822, 0.6,   3.0,   1.6
3818.06,   20000823, -0.4,  2.6,   0.2
3897.05,   20000824, 2.1,   4.7,   -0.1
3945.5,    20000825, 1.2,   3.0,   -0.4
3933.33,   20000828, -0.3,  3.2,   -2.7
3955.54,   20000829, 0.6,   3.2,   -2.6
3951.67,   20000830, -0.1,  3.5,   -0.2
3971.32,   20000831, 0.5,   1.9,   3.3
4084.11,   20000901, 2.8,   3.5,   2.3
4085.89,   20000905, 0.0,   3.9,   4.5
3978.23,   20000906, -2.6,  0.6,   4.9
3847.79,   20000907, -3.3,  -2.6,  -0.5
3949.15,   20000908, 2.6,   -0.6,  3.5
3813.44,   20000911, -3.4,  -6.6,  3.4
3706.74,   20000912, -2.8,  -9.3,  6.6
3666.87,   20000913, -1.1,  -7.8,  1.6
3741.75,   20000914, 2.0,   -2.8,  6.3
3731.73,   20000915, -0.3,  -5.5,  6.9
3676.33,   20000918, -1.5,  -3.6,  1.4
3588.37,   20000919, -2.4,  -3.2,  -0.8
3756.4,    20000920, 4.7,   2.4,   1.3
3790.45,   20000921, 0.9,   1.3,   2.2
3718.15,   20000922, -1.9,  -0.4,  0.8
3753.31,   20000925, 0.9,   2.1,   4.4
3643.65,   20000926, -2.9,  1.5,   -0.0
3631.08,   20000927, -0.3,  -3.3,  -2.4
3556.99,   20000928, -2.0,  -6.2,  -4.4
3684.87,   20000929, 3.6,   -0.9,  -1.0
3613.74,   20001002, -1.9,  -3.7,  -5.7
3528.0,    20001003, -2.4,  -3.2,  -7.4
3337.91,   20001004, -5.4,  -8.1,  -12.9
3421.46,   20001005, 2.5,   -3.8,  -10.4
3432.82,   20001006, 0.3,   -6.8,  -11.9
3306.75,   20001009, -3.7,  -8.5,  -16.2
3271.69,   20001010, -1.1,  -7.3,  -16.8
3077.51,   20001011, -5.9,  -7.8,  -22.2
3175.75,   20001012, 3.2,   -7.2,  -19.6
2990.99,   20001013, -5.8,  -12.9, -24.7
3265.96,   20001016, 9.2,   -1.2,  -20.0
3313.81,   20001017, 1.5,   1.3,   -18.9
3045.7,    20001018, -8.1,  -1.0,  -23.4
3316.63,   20001019, 8.9,   4.4,   -13.8
3379.73,   20001020, 1.9,   13.0,  -14.4
3451.4,    20001023, 2.1,   5.7,   -9.5
3455.37,   20001024, 0.1,   4.3,   -6.8
3245.69,   20001025, -6.1,  6.6,   -11.5
3127.44,   20001026, -3.6,  -5.7,  -16.4
3232.81,   20001027, 3.4,   -4.3,  -13.4
3128.94,   20001030, -3.2,  -9.3,  -14.9
3120.21,   20001031, -0.3,  -9.7,  -13.0
3214.4,    20001101, 3.0,   -1.0,  -14.4
3292.13,   20001102, 2.4,   5.3,   -13.1
3329.26,   20001103, 1.1,   3.0,   -10.5
3346.15,   20001106, 0.5,   6.9,   -10.8
3262.58,   20001107, -2.5,  4.6,   -10.5
3295.77,   20001108, 1.0,   2.5,   -9.2
2993.02,   20001109, -9.2,  -9.1,  -15.9
2974.43,   20001110, -0.6,  -10.7, -19.3
2798.95,   20001113, -5.9,  -16.4, -22.5
2952.62,   20001114, 5.5,   -9.5,  -16.3
3027.61,   20001115, 2.5,   -8.1,  -9.3
3016.8,    20001116, -0.4,  0.8,   -11.8
2934.51,   20001117, -2.7,  -1.3,  -14.5
2838.29,   20001120, -3.3,  1.4,   -14.2
2807.42,   20001121, -1.1,  -4.9,  -14.2
2738.29,   20001122, -2.5,  -9.6,  -11.0
2745.39,   20001124, 0.3,   -9.0,  -13.6
2910.57,   20001127, 6.0,   -0.8,  -2.7
2763.86,   20001128, -5.0,  -2.6,  -15.4
2635.05,   20001129, -4.7,  -6.1,  -20.5
2470.66,   20001130, -6.2,  -9.8,  -18.9
2566.68,   20001201, 3.9,   -6.5,  -22.6
2572.88,   20001204, 0.2,   -11.6, -23.9
2648.54,   20001205, 2.9,   -4.2,  -23.3
2833.52,   20001206, 7.0,   7.5,   -18.0
2688.61,   20001207, -5.1,  8.8,   -17.2
2840.2,    20001208, 5.6,   10.7,  -9.2
2900.28,   20001211, 2.1,   12.7,  -10.3
2942.2,    20001212, 1.4,   11.1,  -6.0
2940.18,   20001213, -0.1,  3.8,   -5.8
2744.26,   20001214, -6.7,  2.1,   -14.6
2590.84,   20001215, -5.6,  -8.8,  -21.3
2604.08,   20001218, 0.5,   -10.2, -21.8
2538.86,   20001219, -2.5,  -13.7, -24.1
2288.28,   20001220, -9.9,  -22.2, -29.9
2191.82,   20001221, -4.2,  -20.1, -33.5
2303.21,   20001222, 5.1,   -11.1, -23.0
2435.51,   20001226, 5.7,   -6.5,  -18.1
2383.42,   20001227, -2.1,  -6.1,  -14.8
2435.84,   20001228, 2.2,   6.4,   -17.5
2465.37,   20001229, 1.2,   12.5,  -18.6
2341.28,   20010102, -5.0,  1.7,   -22.4
2087.32,   20010103, -10.8, -14.3, -28.9
2485.52,   20010104, 19.1,  4.3,   -12.4
2460.81,   20010105, -1.0,  1.0,   -12.3
2245.44,   20010108, -8.8,  -8.9,  -18.0
2312.53,   20010109, 3.0,   -1.2,  -15.8
2253.85,   20010110, -2.5,  8.0,   -22.6
2376.86,   20010111, 5.5,   -4.4,  -14.0
2522.45,   20010112, 6.1,   2.5,   -4.3
2502.66,   20010116, -0.8,  11.5,  1.3
2586.8,    20010117, 3.4,   11.9,  0.8
2574.64,   20010118, -0.5,  14.2,  0.1
2740.11,   20010119, 6.4,   15.3,  3.5
2639.61,   20010122, -3.7,  4.6,   -6.8
2642.33,   20010123, 0.1,   5.6,   -1.7
2732.92,   20010124, 3.4,   5.6,   -3.8
2687.05,   20010125, -1.7,  4.4,   -7.4
2538.28,   20010126, -5.5,  -7.4,  -13.7
2601.67,   20010129, 2.5,   -1.4,  -11.5
2701.34,   20010130, 3.8,   2.2,   -1.6
2695.03,   20010131, -0.2,  -1.4,  4.0
2586.24,   20010201, -4.0,  -3.8,  -0.7
2605.64,   20010202, 0.8,   2.7,   2.6
2447.72,   20010205, -6.1,  -5.9,  7.0
2463.5,    20010206, 0.6,   -8.8,  12.4
2421.64,   20010207, -1.7,  -10.1, 5.1
2436.48,   20010208, 0.6,   -5.8,  0.0
2333.01,   20010209, -4.2,  -10.5, -2.1
2247.2,    20010212, -3.7,  -8.2,  -7.7
2315.46,   20010213, 3.0,   -6.0,  -6.1
2224.35,   20010214, -3.9,  -8.1,  -5.0
2360.56,   20010215, 6.1,   -3.1,  13.1
2228.77,   20010216, -5.6,  -4.5,  -10.3
2229.91,   20010220, 0.1,   -0.8,  -9.4
2056.15,   20010221, -7.8,  -11.2, -8.4
2058.56,   20010222, 0.1,   -7.5,  -11.0
1997.55,   20010223, -3.0,  -15.4, -11.4
2088.03,   20010226, 4.5,   -6.3,  -12.2
2067.15,   20010227, -1.0,  -7.3,  -18.0
1983.35,   20010228, -4.1,  -3.5,  -20.8
1879.84,   20010301, -5.2,  -8.7,  -27.3
1886.04,   20010302, 0.3,   -5.6,  -26.7
1908.62,   20010305, 1.2,   -8.6,  -30.3
1983.28,   20010306, 3.9,   -4.1,  -24.9
2018.94,   20010307, 1.8,   1.8,   -23.6
1982.44,   20010308, -1.8,  5.5,   -27.5
1892.79,   20010309, -4.5,  0.4,   -29.6
1758.06,   20010312, -7.1,  -7.9,  -30.7
1711.99,   20010313, -2.6,  -13.7, -34.2
1711.06,   20010314, -0.1,  -15.2, -36.7
1809.5,    20010315, 5.8,   -8.7,  -32.9
1656.78,   20010316, -8.4,  -12.5, -35.9
1659.7,    20010319, 0.2,   -5.6,  -36.3
1742.99,   20010320, 5.0,   1.8,   -28.8
1624.31,   20010321, -6.8,  -5.1,  -34.1
1624.81,   20010322, 0.0,   -10.2, -32.9
1743.96,   20010323, 7.3,   5.3,   -28.4
1735.53,   20010326, -0.5,  4.6,   -25.6
1680.73,   20010327, -3.2,  -3.6,  -25.2
1677.63,   20010328, -0.2,  3.3,   -27.5
1578.99,   20010329, -5.9,  -2.8,  -29.0
1573.82,   20010330, -0.3,  -9.8,  -33.3
1569.66,   20010402, -0.3,  -9.6,  -29.6
1487.87,   20010403, -5.2,  -11.5, -33.3
1392.03,   20010404, -6.4,  -17.0, -32.3
1452.04,   20010405, 4.3,   -8.0,  -29.5
1483.96,   20010406, 2.2,   -5.7,  -25.7
1472.81,   20010409, -0.8,  -6.2,  -29.5
1515.58,   20010410, 2.9,   1.9,   -26.7
1694.6,    20010411, 11.8,  21.7,  -14.6
1617.95,   20010412, -4.5,  11.4,  -13.9
1682.17,   20010416, 4.0,   13.4,  -10.8
1605.54,   20010417, -4.6,  9.0,   -15.9
1760.05,   20010418, 9.6,   16.1,  -11.3
1854.2,    20010419, 5.3,   9.4,   -8.2
1933.61,   20010420, 4.3,   19.5,  -2.5
1881.52,   20010423, -2.7,  11.9,  -0.6
1809.47,   20010424, -3.8,  12.7,  2.9
1758.07,   20010425, -2.8,  -0.1,  2.7
1839.4,    20010426, 4.6,   -0.8,  7.5
1808.92,   20010427, -1.7,  -6.4,  -0.0
1855.9,    20010430, 2.6,   -1.4,  12.0
1854.67,   20010501, -0.1,  2.5,   11.7
1958.17,   20010502, 5.6,   11.4,  12.3
1913.93,   20010503, -2.3,  4.1,   17.8
1820.57,   20010504, -4.9,  0.6,   12.0
1924.68,   20010507, 5.7,   3.7,   10.4
1939.02,   20010508, 0.7,   4.5,   11.7
1885.0,    20010509, -2.8,  -3.7,  12.2
1926.47,   20010510, 2.2,   0.7,   14.8
1842.65,   20010511, -4.4,  1.2,   16.7
1819.39,   20010514, -1.3,  -5.5,  15.6
1801.46,   20010515, -1.0,  -7.1,  14.8
1778.71,   20010516, -1.3,  -5.6,  19.5
1900.84,   20010517, 6.9,   -1.3,  36.6
1910.71,   20010518, 0.5,   3.7,   31.6
1931.29,   20010521, 1.1,   6.2,   30.1
2062.9,    20010522, 6.8,   14.5,  40.1
2022.16,   20010523, -2.0,  13.7,  33.4
1965.44,   20010524, -2.8,  3.4,   16.0
2000.12,   20010525, 1.8,   4.7,   23.6
1946.57,   20010529, -2.7,  0.8,   15.7
1823.84,   20010530, -6.3,  -11.6, 13.6
1785.25,   20010531, -2.1,  -11.7, 1.4
1826.02,   20010601, 2.3,   -7.1,  -1.5
1861.12,   20010604, 1.9,   -6.9,  -3.7
1853.65,   20010605, -0.4,  -4.8,  -1.5
1931.45,   20010606, 4.2,   5.9,   6.7
1892.98,   20010607, -2.0,  6.0,   7.7
1959.71,   20010608, 3.5,   7.3,   6.5
1885.17,   20010611, -3.8,  1.3,   4.2
1799.58,   20010612, -4.5,  -2.9,  -3.0
1860.05,   20010613, 3.4,   -3.7,  0.3
1766.41,   20010614, -5.0,  -6.7,  -9.8
1679.33,   20010615, -4.9,  -14.3, -12.3
1712.04,   20010618, 1.9,   -9.2,  -6.0
1736.29,   20010619, 1.4,   -3.5,  -9.8
1655.26,   20010620, -4.7,  -11.0, -14.6
1714.0,    20010621, 3.5,   -3.0,  -9.1
1755.57,   20010622, 2.4,   4.5,   -8.9
1752.71,   20010625, -0.2,  2.4,   -4.9
1708.89,   20010626, -2.5,  -1.6,  -6.1
1755.25,   20010627, 2.7,   6.0,   -2.6
1782.32,   20010628, 1.5,   4.0,   0.2
1812.17,   20010629, 1.7,   3.2,   -4.7
1831.42,   20010702, 1.1,   4.5,   -4.1
1817.71,   20010703, -0.7,  6.4,   -5.9
1800.63,   20010705, -0.9,  2.6,   -12.7
1728.57,   20010706, -4.0,  -3.0,  -14.5
1680.88,   20010709, -2.8,  -7.2,  -14.5
1718.81,   20010710, 2.3,   -6.1,  -14.1
1617.51,   20010711, -5.9,  -11.0, -16.9
1708.32,   20010712, 5.6,   -5.1,  -6.3
1741.36,   20010713, 1.9,   0.7,   -2.5
1743.09,   20010716, 0.1,   3.7,   -4.5
1675.06,   20010717, -3.9,  -2.5,  -10.0
1692.16,   20010718, 1.0,   4.6,   -8.7
1700.56,   20010719, 0.5,   -0.5,  -12.0
1662.38,   20010720, -2.2,  -4.5,  -12.2
1695.2,    20010723, 2.0,   -2.7,  -13.5
1618.74,   20010724, -4.5,  -3.4,  -14.1
1609.37,   20010725, -0.6,  -4.9,  -10.6
1624.22,   20010726, 0.9,   -4.5,  -12.7
1673.43,   20010727, 3.0,   0.7,   -5.3
1689.21,   20010730, 0.9,   -0.4,  0.6
1678.71,   20010731, -0.6,  3.7,   -1.9
1713.67,   20010801, 2.1,   6.5,   -1.3
1758.93,   20010802, 2.6,   8.3,   6.3
1744.02,   20010803, -0.8,  4.2,   1.8
1705.88,   20010806, -2.2,  1.0,   -2.8
1695.27,   20010807, -0.6,  1.0,   -3.3
1681.85,   20010808, -0.8,  -1.9,  -1.6
1622.45,   20010809, -3.5,  -7.8,  -7.6
1621.5,    20010810, -0.1,  -7.0,  -9.0
1627.34,   20010813, 0.4,   -4.6,  -10.2
1665.16,   20010814, 2.3,   -1.8,  -9.1
1629.53,   20010815, -2.1,  -3.1,  -10.4
1548.3,    20010816, -5.0,  -4.6,  -14.0
1541.61,   20010817, -0.4,  -4.9,  -10.8
1518.19,   20010820, -1.5,  -6.7,  -9.7
1538.8,    20010821, 1.4,   -7.6,  -10.5
1504.65,   20010822, -2.2,  -7.7,  -7.0
1513.37,   20010823, 0.6,   -2.3,  -11.4
1521.48,   20010824, 0.5,   -1.3,  -12.6
1576.16,   20010827, 3.6,   3.8,   -9.6
1579.55,   20010828, 0.2,   2.6,   -5.7
1538.16,   20010829, -2.6,  2.2,   -9.1
1472.65,   20010830, -4.3,  -2.7,  -13.4
1444.73,   20010831, -1.9,  -5.0,  -13.1
1466.32,   20010904, 1.5,   -7.0,  -13.5
1425.35,   20010905, -2.8,  -9.8,  -11.9
1392.0,    20010906, -2.3,  -9.5,  -13.5
1347.62,   20010907, -3.2,  -8.5,  -17.0
1342.08,   20010910, -0.4,  -7.1,  -19.8
1277.12,   20010917, -4.8,  -12.9, -24.4
1268.28,   20010918, -0.7,  -11.0, -24.4
1230.14,   20010919, -3.0,  -11.6, -28.2
1175.1,    20010920, -4.5,  -12.8, -33.2
1088.96,   20010921, -7.3,  -18.9, -37.6
1166.72,   20010924, 7.1,   -8.6,  -31.6
1194.09,   20010925, 2.3,   -5.8,  -29.6
1203.44,   20010926, 0.8,   -2.2,  -28.4
1137.49,   20010927, -5.5,  -3.2,  -29.9
1158.07,   20010928, 1.8,   6.3,   -28.6
1161.74,   20011001, 0.3,   -0.4,  -28.6
1150.79,   20011002, -0.9,  -3.6,  -30.9
1144.76,   20011003, -0.5,  -4.9,  -29.7
1271.74,   20011004, 11.1,  11.8,  -17.9
1248.18,   20011005, -1.9,  7.8,   -19.0
1249.32,   20011008, 0.1,   7.5,   -17.7
1278.99,   20011009, 2.4,   11.1,  -16.9
1239.77,   20011010, -3.1,  8.3,   -17.6
1333.87,   20011011, 7.6,   4.9,   -11.9
1376.27,   20011012, 3.2,   10.3,  -9.5
1370.2,    20011015, -0.4,  9.7,   -13.1
1390.37,   20011016, 1.5,   8.7,   -12.0
1440.63,   20011017, 3.6,   16.2,  -6.3
1315.34,   20011018, -8.7,  -1.4,  -10.7
1317.23,   20011019, 0.1,   -4.3,  -8.8
1340.96,   20011022, 1.8,   -2.1,  -8.5
1397.27,   20011023, 4.2,   0.5,   -2.0
1388.51,   20011024, -0.6,  -3.6,  -0.3
1396.35,   20011025, 0.6,   6.2,   3.6
1461.88,   20011026, 4.7,   11.0,  8.9
1445.64,   20011029, -1.1,  7.8,   13.2
1353.96,   20011030, -6.3,  -3.1,  6.8
1368.54,   20011031, 1.1,   -1.4,  11.3
1381.7,    20011101, 1.0,   -1.0,  17.6
1417.4,    20011102, 2.6,   -3.0,  30.2
1453.94,   20011105, 2.6,   0.6,   24.6
1471.11,   20011106, 1.2,   8.7,   23.2
1506.11,   20011107, 2.4,   10.1,  25.2
1548.0,    20011108, 2.8,   12.0,  36.1
1508.09,   20011109, -2.6,  6.4,   30.2
1511.77,   20011112, 0.2,   4.0,   30.1
1566.48,   20011113, 3.6,   6.5,   36.1
1601.52,   20011114, 2.2,   6.3,   39.9
1572.82,   20011115, -1.8,  1.6,   23.7
1586.8,    20011116, 0.9,   5.2,   27.1
1597.8,    20011119, 0.7,   5.7,   27.9
1607.61,   20011120, 0.6,   2.6,   25.7
1543.71,   20011121, -4.0,  -3.6,  24.5
1557.09,   20011123, 0.9,   -1.0,  16.7
1590.05,   20011126, 2.1,   0.2,   15.5
1607.61,   20011127, 1.1,   0.6,   17.3
1594.88,   20011128, -0.8,  -0.8,  14.7
1569.42,   20011129, -1.6,  1.7,   8.9
1594.83,   20011130, 1.6,   2.4,   21.2
1576.26,   20011203, -1.2,  -0.9,  19.7
1582.07,   20011204, 0.4,   -1.6,  18.0
1653.63,   20011205, 4.5,   3.7,   18.3
1715.01,   20011206, 3.7,   9.3,   23.5
1706.27,   20011207, -0.5,  7.0,   22.2
1658.47,   20011210, -2.8,  5.2,   13.4
1666.26,   20011211, 0.5,   5.3,   15.3
1666.5,    20011212, 0.0,   0.8,   23.1
1634.62,   20011213, -1.9,  -4.7,  19.4
1601.22,   20011214, -2.0,  -6.2,  15.9
1607.08,   20011217, 0.4,   -3.1,  13.4
1653.31,   20011218, 2.9,   -0.8,  13.7
1629.47,   20011219, -1.4,  -2.2,  10.8
1613.06,   20011220, -1.0,  -1.3,  7.1
1579.89,   20011221, -2.1,  -1.3,  2.1
1581.16,   20011224, 0.1,   -1.6,  4.8
1583.69,   20011226, 0.2,   -4.2,  4.8
1599.25,   20011227, 1.0,   -1.9,  2.1
1617.85,   20011228, 1.2,   0.3,   1.0
1617.35,   20011231, -0.0,  2.4,   2.8
1590.71,   20020102, -1.6,  0.6,   0.2
1618.3,    20020103, 1.7,   2.2,   1.3
1685.54,   20020104, 4.2,   5.4,   4.8
1691.36,   20020107, 0.3,   4.5,   9.6
1651.42,   20020108, -2.4,  2.1,   6.1
1688.26,   20020109, 2.2,   6.1,   6.2
1653.97,   20020110, -2.0,  2.2,   2.9
1659.47,   20020111, 0.3,   -1.5,  4.0
1624.91,   20020114, -2.1,  -3.9,  3.5
1607.01,   20020115, -1.1,  -2.7,  0.8
1588.77,   20020116, -1.1,  -5.9,  0.8
1587.01,   20020117, -0.1,  -4.0,  0.3
1558.41,   20020118, -1.8,  -6.1,  -5.8
1567.59,   20020122, 0.6,   -3.5,  -8.6
1511.95,   20020123, -3.5,  -5.9,  -11.4
1565.34,   20020124, 3.5,   -1.5,  -5.6
1546.1,    20020125, -1.2,  -2.6,  -7.2
1573.39,   20020128, 1.8,   1.0,   -5.6
1570.7,    20020129, -0.2,  0.2,   -3.9
1525.09,   20020130, -2.9,  0.9,   -4.8
1549.47,   20020131, 1.6,   -1.0,  -3.6
1544.42,   20020201, -0.3,  -0.1,  -6.6
1526.32,   20020204, -1.2,  -3.0,  -6.3
1468.61,   20020205, -3.8,  -6.5,  -9.0
1480.45,   20020206, 0.8,   -2.9,  -6.3
1445.11,   20020207, -2.4,  -6.7,  -8.6
1426.26,   20020208, -1.3,  -7.7,  -9.9
1451.13,   20020211, 1.7,   -4.9,  -9.3
1458.28,   20020212, 0.5,   -0.7,  -9.9
1476.25,   20020213, 1.2,   -0.3,  -8.7
1491.19,   20020214, 1.0,   3.2,   -6.3
1477.46,   20020215, -0.9,  3.6,   -8.7
1421.07,   20020219, -3.8,  -2.1,  -15.7
1400.83,   20020220, -1.4,  -3.9,  -17.2
1397.17,   20020221, -0.3,  -5.4,  -15.4
1351.24,   20020222, -3.3,  -9.4,  -20.0
1366.44,   20020225, 1.1,   -7.5,  -17.4
1416.1,    20020226, 3.6,   -0.3,  -14.7
1420.3,    20020227, 0.3,   1.4,   -12.6
1386.15,   20020228, -2.4,  -0.8,  -13.7
1373.15,   20020301, -0.9,  1.6,   -13.6
1434.69,   20020304, 4.5,   5.0,   -9.6
1484.83,   20020305, 3.5,   4.9,   -4.7
1488.8,    20020306, 0.3,   4.8,   -5.0
1531.43,   20020307, 2.9,   10.5,  1.3
1537.03,   20020308, 0.4,   11.9,  -1.8
1542.09,   20020311, 0.3,   7.5,   -0.3
1510.06,   20020312, -2.1,  1.7,   -4.0
1502.4,    20020313, -0.5,  0.9,   -4.3
1488.36,   20020314, -0.9,  -2.8,  -2.4
1484.46,   20020315, -0.3,  -3.4,  -4.2
1511.83,   20020318, 1.8,   -2.0,  -2.1
1510.46,   20020319, -0.1,  0.0,   -1.0
1482.1,    20020320, -1.9,  -1.4,  0.9
1452.18,   20020321, -2.0,  -2.4,  -1.9
1483.04,   20020322, 2.1,   -0.1,  2.6
1473.62,   20020325, -0.6,  -2.5,  3.3
1423.57,   20020326, -3.4,  -5.8,  -1.9
1431.35,   20020327, 0.5,   -3.4,  -1.8
1447.48,   20020328, 1.1,   -0.3,  -1.9
1440.38,   20020401, -0.5,  -2.9,  -3.4
1444.95,   20020402, 0.3,   -1.9,  -2.2
1417.78,   20020403, -1.9,  -0.4,  -0.2
1385.53,   20020404, -2.3,  -3.2,  -1.1
1405.92,   20020405, 1.5,   -2.9,  0.6
1346.77,   20020408, -4.2,  -6.5,  -0.3
1397.59,   20020409, 3.8,   -3.3,  2.3
1356.47,   20020410, -2.9,  -4.3,  -4.2
1352.76,   20020411, -0.3,  -2.4,  -4.8
1340.66,   20020412, -0.9,  -4.6,  -3.3
1358.76,   20020415, 1.4,   0.9,   -1.0
1382.86,   20020416, 1.8,   -1.1,  -3.6
1422.42,   20020417, 2.9,   4.9,   -4.2
1397.75,   20020418, -1.7,  3.3,   -6.1
1408.31,   20020419, 0.8,   5.0,   -8.0
1365.34,   20020422, -3.1,  0.5,   -11.2
1348.93,   20020423, -1.2,  -2.5,  -12.5
1334.87,   20020424, -1.0,  -6.2,  -11.6
1295.59,   20020425, -2.9,  -7.3,  -13.8
1310.8,    20020426, 1.2,   -6.9,  -11.9
1256.58,   20020429, -4.1,  -8.0,  -15.4
1245.65,   20020430, -0.9,  -7.7,  -17.6
1273.63,   20020501, 2.2,   -4.6,  -15.7
1262.66,   20020502, -0.9,  -2.5,  -14.8
1223.89,   20020503, -3.1,  -6.6,  -15.7
1191.53,   20020506, -2.6,  -5.2,  -19.7
1173.76,   20020507, -1.5,  -5.8,  -20.3
1212.99,   20020508, 3.3,   -4.8,  -14.8
1268.91,   20020509, 4.6,   0.5,   -11.3
1247.09,   20020510, -1.7,  1.9,   -13.8
1200.69,   20020513, -3.7,  0.8,   -16.6
1287.73,   20020514, 7.2,   9.7,   -10.9
1290.4,    20020515, 0.2,   6.4,   -9.0
1309.25,   20020516, 1.5,   3.2,   -5.5
1333.23,   20020517, 1.8,   6.9,   -5.2
1309.83,   20020520, -1.8,  9.1,   -2.7
1300.82,   20020521, -0.7,  1.0,   -6.9
1246.08,   20020522, -4.2,  -3.4,  -8.1
1270.28,   20020523, 1.9,   -3.0,  -6.1
1269.91,   20020524, -0.0,  -4.7,  -5.3
1264.13,   20020528, -0.5,  -3.5,  -7.0
1232.16,   20020529, -2.5,  -5.3,  -10.9
1207.44,   20020530, -2.0,  -3.1,  -15.1
1237.56,   20020531, 2.5,   -2.6,  -11.5
1205.2,    20020603, -2.6,  -5.1,  -14.4
1155.81,   20020604, -4.1,  -8.6,  -15.3
1182.74,   20020605, 2.3,   -4.0,  -12.3
1183.91,   20020606, 0.1,   -1.9,  -11.3
1107.94,   20020607, -6.4,  -10.5, -14.5
1142.51,   20020610, 3.1,   -5.2,  -12.8
1148.72,   20020611, 0.5,   -0.6,  -8.6
1092.45,   20020612, -4.9,  -7.6,  -12.3
1117.22,   20020613, 2.3,   -5.6,  -12.3
1078.69,   20020614, -3.4,  -2.6,  -14.6
1126.54,   20020617, 4.4,   -1.4,  -8.0
1139.52,   20020618, 1.2,   -0.8,  -4.4
1129.32,   20020619, -0.9,  3.4,   -3.8
1094.53,   20020620, -3.1,  -2.0,  -9.8
1051.8,    20020621, -3.9,  -2.5,  -17.1
1023.06,   20020624, -2.7,  -9.2,  -18.0
1070.62,   20020625, 4.6,   -6.0,  -10.8
979.87,    20020626, -8.5,  -13.2, -23.9
1048.54,   20020627, 7.0,   -4.2,  -18.7
1047.56,   20020628, -0.1,  -0.4,  -20.0
1044.48,   20020701, -0.3,  2.1,   -21.7
989.25,    20020702, -5.3,  -7.6,  -24.5
957.26,    20020703, -3.2,  -2.3,  -26.4
1018.63,   20020705, 6.4,   -2.9,  -18.3
1051.27,   20020708, 3.2,   0.4,   -17.2
1015.27,   20020709, -3.4,  -2.8,  -20.1
1005.99,   20020710, -0.9,  1.7,   -20.4
954.01,    20020711, -5.2,  -0.3,  -22.6
1016.0,    20020712, 6.5,   -0.3,  -15.9
990.98,    20020715, -2.5,  -5.7,  -19.9
1012.51,   20020716, 2.2,   -0.3,  -16.0
1047.79,   20020717, 3.5,   4.2,   -9.3
1020.82,   20020718, -2.6,  7.0,   -13.7
975.06,    20020719, -4.5,  -4.0,  -17.6
958.34,    20020722, -1.7,  -3.3,  -13.5
943.29,    20020723, -1.6,  -6.8,  -17.4
875.48,    20020724, -7.2,  -16.4, -23.8
934.93,    20020725, 6.8,   -8.4,  -14.4
905.88,    20020726, -3.1,  -7.1,  -18.9
935.05,    20020729, 3.2,   -2.4,  -13.3
957.08,    20020730, 2.4,   1.5,   -15.0
966.05,    20020731, 0.9,   10.3,  -15.2
954.56,    20020801, -1.2,  2.1,   -15.5
913.6,     20020802, -4.3,  0.9,   -16.5
889.49,    20020805, -2.6,  -4.9,  -15.4
875.53,    20020806, -1.6,  -8.5,  -14.4
932.53,    20020807, 6.5,   -3.5,  -12.9
915.89,    20020808, -1.8,  -4.1,  -6.5
932.33,    20020809, 1.8,   2.1,   -11.1
925.68,    20020812, -0.7,  4.1,   -11.6
933.74,    20020813, 0.9,   6.6,   -10.6
912.35,    20020814, -2.3,  -2.2,  -7.8
975.09,    20020815, 6.9,   6.5,   1.9
968.05,    20020816, -0.7,  3.8,   -5.0
997.39,    20020819, 3.0,   7.7,   -5.1
1014.51,   20020820, 1.7,   8.7,   -0.1
1020.95,   20020821, 0.6,   11.9,  1.5
1036.16,   20020822, 1.5,   6.3,   8.6
1036.13,   20020823, -0.0,  7.0,   2.0
1017.62,   20020826, -1.8,  2.0,   2.7
1021.5,    20020827, 0.4,   0.7,   0.9
965.57,    20020828, -5.5,  -5.4,  -7.8
933.46,    20020829, -3.3,  -9.9,  -8.6
951.56,    20020830, 1.9,   -8.2,  -2.4
928.5,     20020903, -2.4,  -8.8,  -3.1
904.82,    20020904, -2.6,  -11.4, -4.1
900.9,     20020905, -0.4,  -6.7,  2.9
911.6,     20020906, 1.2,   -2.3,  -2.5
913.68,    20020909, 0.2,   -4.0,  0.9
934.43,    20020910, 2.3,   0.6,   -0.1
958.29,    20020911, 2.6,   5.9,   0.1
935.53,    20020912, -2.4,  3.8,   -3.2
909.63,    20020913, -2.8,  -0.2,  -4.7
919.1,     20020916, 1.0,   0.6,   0.6
927.29,    20020917, 0.9,   -0.8,  4.2
884.03,    20020918, -4.7,  -7.7,  1.0
875.77,    20020919, -0.9,  -6.4,  -6.1
876.75,    20020920, 0.1,   -3.6,  -4.3
860.99,    20020923, -1.8,  -6.3,  -7.7
830.21,    20020924, -3.6,  -10.5, -10.3
858.46,    20020925, 3.4,   -2.9,  -8.1
891.02,    20020926, 3.8,   1.7,   -2.3
866.09,    20020927, -2.8,  -1.2,  -11.2
848.72,    20020930, -2.0,  -1.4,  -12.3
841.29,    20021001, -0.9,  1.3,   -15.7
865.13,    20021002, 2.8,   0.8,   -14.7
846.74,    20021003, -2.1,  -5.0,  -17.1
842.95,    20021004, -0.4,  -2.7,  -18.6
813.06,    20021007, -3.5,  -4.2,  -21.5
815.22,    20021008, 0.3,   -3.1,  -19.9
800.92,    20021009, -1.8,  -7.4,  -21.6
809.46,    20021010, 1.1,   -4.4,  -16.2
867.64,    20021011, 7.2,   2.9,   -7.1
878.75,    20021014, 1.3,   8.1,   -7.7
941.46,    20021015, 7.1,   15.5,  1.4
911.33,    20021016, -3.2,  13.8,  0.7
951.14,    20021017, 4.4,   17.5,  5.6
936.43,    20021018, -1.5,  7.9,   2.7
945.86,    20021021, 1.0,   7.6,   3.5
954.28,    20021022, 0.9,   1.4,   2.1
960.18,    20021023, 0.6,   5.4,   0.2
992.5,     20021024, 3.4,   4.3,   6.1
964.01,    20021025, -2.9,  2.9,   6.0
1007.72,   20021028, 4.5,   6.5,   9.6
975.66,    20021029, -3.2,  2.2,   5.2
968.48,    20021030, -0.7,  0.9,   9.6
990.39,    20021031, 2.3,   -0.2,  13.1
981.24,    20021101, -0.9,  1.8,   11.9
1049.49,   20021104, 7.0,   4.1,   21.9
1033.79,   20021105, -1.5,  6.0,   24.5
1057.59,   20021106, 2.3,   9.2,   23.2
1043.73,   20021107, -1.3,  5.4,   17.1
1026.04,   20021108, -1.7,  4.6,   18.5
1004.41,   20021111, -2.1,  -4.3,  18.3
982.45,    20021112, -2.2,  -5.0,  16.8
994.4,     20021113, 1.2,   -6.0,  14.9
1029.36,   20021114, 3.5,   -1.4,  21.6
1044.3,    20021115, 1.5,   1.8,   23.9
1073.89,   20021118, 2.8,   6.9,   32.1
1037.26,   20021119, -3.4,  5.6,   27.2
1028.57,   20021120, -0.8,  3.4,   28.4
1082.92,   20021121, 5.3,   5.2,   33.8
1102.64,   20021122, 1.8,   5.6,   27.1
1118.01,   20021125, 1.4,   4.1,   27.2
1116.29,   20021126, -0.2,  7.6,   18.6
1107.97,   20021127, -0.7,  7.7,   21.6
1133.13,   20021129, 2.3,   4.6,   19.1
1144.21,   20021202, 1.0,   3.8,   22.2
1109.46,   20021203, -3.0,  -0.8,  17.3
1068.94,   20021204, -3.7,  -4.2,  12.0
1085.02,   20021205, 1.5,   -2.1,  13.0
1038.72,   20021206, -4.3,  -8.3,  4.7
1054.87,   20021209, 1.6,   -7.8,  9.4
1022.39,   20021210, -3.1,  -7.8,  1.5
1023.91,   20021211, 0.1,   -4.2,  4.9
1047.47,   20021212, 2.3,   -3.5,  8.2
1027.66,   20021213, -1.9,  -1.1,  3.8
1011.89,   20021216, -1.5,  -4.1,  3.1
1038.96,   20021217, 2.7,   1.6,   -1.0
1029.82,   20021218, -0.9,  0.6,   -0.4
1012.51,   20021219, -1.7,  -3.3,  -4.3
1009.67,   20021220, -0.3,  -1.8,  -3.3
1012.46,   20021223, 0.3,   0.1,   -1.3
1026.57,   20021224, 1.4,   -1.2,  2.2
1026.44,   20021226, -0.0,  -0.3,  4.5
1012.34,   20021227, -1.4,  -0.0,  1.8
999.39,    20021230, -1.3,  -1.0,  -2.9
987.81,    20021231, -1.2,  -2.4,  -5.4
995.64,    20030102, 0.8,   -3.0,  -7.3
1024.47,   20030103, 2.9,   -0.2,  -1.2
1034.02,   20030106, 0.9,   2.1,   0.5
1063.18,   20030107, 2.8,   6.4,   -1.8
1063.48,   20030108, 0.0,   7.7,   -3.6
1056.61,   20030109, -0.6,  6.1,   -5.5
1059.86,   20030110, 0.3,   3.5,   -5.1
1101.24,   20030113, 3.9,   6.5,   -0.6
1082.37,   20030114, -1.7,  1.8,   -4.5
1096.96,   20030115, 1.3,   3.1,   -4.1
1076.18,   20030116, -1.9,  1.9,   -3.0
1041.43,   20030117, -3.2,  -1.7,  -2.6
1022.94,   20030121, -1.8,  -7.1,  -5.7
1005.57,   20030122, -1.7,  -7.1,  -3.2
1024.47,   20030123, 1.9,   -6.6,  -2.9
1027.7,    20030124, 0.3,   -4.5,  0.5
985.08,    20030127, -4.1,  -5.4,  -3.8
996.88,    20030128, 1.2,   -2.5,  -4.8
994.6,     20030129, -0.2,  -1.1,  -3.2
1018.48,   20030130, 2.4,   -0.6,  0.7
971.6,     20030131, -4.6,  -5.5,  -6.5
987.37,    20030203, 1.6,   0.2,   -4.1
972.93,    20030204, -1.5,  -2.4,  -3.9
980.75,    20030205, 0.8,   -1.4,  -2.9
966.05,    20030206, -1.5,  -5.1,  -4.6
980.5,     20030207, 1.5,   0.9,   -4.5
961.69,    20030210, -1.9,  -2.6,  -6.3
975.52,    20030211, 1.4,   0.3,   -3.6
967.89,    20030212, -0.8,  -1.3,  -3.2
959.56,    20030213, -0.9,  -0.7,  -2.9
958.2,     20030214, -0.1,  -2.3,  -3.8
990.21,    20030218, 3.3,   3.0,   -3.3
1011.69,   20030219, 2.2,   3.7,   -2.2
1010.5,    20030220, -0.1,  4.4,   -5.0
1002.14,   20030221, -0.8,  4.4,   -5.8
1009.14,   20030224, 0.7,   5.3,   -4.5
981.52,    20030225, -2.7,  -0.9,  -7.4
992.91,    20030226, 1.2,   -1.9,  -9.8
982.51,    20030227, -1.0,  -2.8,  -9.2
998.26,    20030228, 1.6,   -0.4,  -9.0
1015.87,   20030303, 1.8,   0.7,   -5.6
991.6,     20030304, -2.4,  1.0,   -4.8
981.67,    20030305, -1.0,  -1.1,  -4.0
982.04,    20030306, 0.0,   -0.0,  -2.3
967.93,    20030307, -1.4,  -3.0,  -5.5
977.95,    20030310, 1.0,   -3.7,  -4.8
966.74,    20030311, -1.1,  -2.5,  -1.9
954.66,    20030312, -1.2,  -2.8,  -4.2
990.36,    20030313, 3.7,   0.8,   -0.4
1034.43,   20030314, 4.4,   6.9,   1.6
1021.97,   20030317, -1.2,  4.5,   5.2
1075.3,    20030318, 5.2,   11.2,  8.9
1078.19,   20030319, 0.3,   12.9,  10.8
1064.32,   20030320, -1.3,  7.5,   8.5
1096.3,    20030321, 3.0,   6.0,   13.5
1064.65,   20030324, -2.9,  4.2,   8.6
1051.71,   20030325, -1.2,  -2.2,  9.4
1065.78,   20030326, 1.3,   -1.2,  9.3
1055.7,    20030327, -0.9,  -0.8,  9.1
1052.25,   20030328, -0.3,  -4.0,  9.7
1028.43,   20030331, -2.3,  -3.4,  7.3
1025.02,   20030401, -0.3,  -2.5,  3.5
1047.39,   20030402, 2.2,   -1.7,  3.5
1070.51,   20030403, 2.2,   1.4,   5.9
1067.51,   20030404, -0.3,  1.5,   6.5
1089.93,   20030407, 2.1,   6.0,   8.0
1051.75,   20030408, -3.5,  2.6,   7.2
1048.92,   20030409, -0.3,  0.1,   5.6
1024.74,   20030410, -2.3,  -4.3,  4.3
1046.45,   20030411, 2.1,   -2.0,  4.8
1028.0,    20030414, -1.8,  -5.7,  1.2
1044.33,   20030415, 1.6,   -0.7,  5.3
1070.64,   20030416, 2.5,   2.1,   9.1
1051.36,   20030417, -1.8,  2.6,   7.1
1083.75,   20030421, 3.1,   3.6,   12.0
1073.88,   20030422, -0.9,  4.5,   9.8
1105.45,   20030423, 2.9,   5.9,   14.3
1100.84,   20030424, -0.4,  2.8,   15.3
1100.65,   20030425, -0.0,  4.7,   11.1
1086.58,   20030428, -1.3,  0.3,   5.0
1113.05,   20030429, 2.4,   3.6,   8.9
1111.54,   20030430, -0.1,  0.6,   3.4
1104.38,   20030501, -0.6,  0.3,   2.4
1109.92,   20030502, 0.5,   0.8,   4.3
1139.6,    20030505, 2.7,   4.9,   3.9
1134.56,   20030506, -0.4,  1.9,   6.6
1142.88,   20030507, 0.7,   2.8,   8.7
1120.53,   20030508, -2.0,  1.5,   5.1
1129.29,   20030509, 0.8,   1.7,   7.0
1141.08,   20030512, 1.0,   0.1,   8.4
1152.75,   20030513, 1.0,   1.6,   12.1
1161.47,   20030514, 0.8,   1.6,   13.3
1155.11,   20030515, -0.5,  3.1,   10.3
1158.51,   20030516, 0.3,   2.6,   8.2
1146.74,   20030519, -1.0,  0.5,   7.4
1118.3,    20030520, -2.5,  -3.0,  2.6
1110.78,   20030521, -0.7,  -4.4,  5.6
1116.21,   20030522, 0.5,   -3.4,  6.4
1128.41,   20030523, 1.1,   -2.6,  10.1
1123.66,   20030527, -0.4,  -2.0,  7.4
1172.99,   20030528, 4.4,   4.9,   14.1
1175.4,    20030529, 0.2,   5.8,   12.6
1188.63,   20030530, 1.1,   6.5,   11.0
1210.77,   20030602, 1.9,   7.3,   15.2
1183.6,    20030603, -2.2,  5.3,   9.2
1199.74,   20030604, 1.4,   2.3,   11.7
1210.64,   20030605, 0.9,   3.0,   9.5
1254.64,   20030606, 3.6,   5.6,   14.0
1206.95,   20030609, -3.8,  -0.3,  9.7
1201.26,   20030610, -0.5,  1.5,   10.6
1210.61,   20030611, 0.8,   0.9,   8.8
1233.48,   20030612, 1.9,   1.9,   11.0
1231.75,   20030613, -0.1,  -1.8,  11.5
1211.58,   20030616, -1.6,  0.4,   9.2
1247.57,   20030617, 3.0,   3.9,   9.5
1233.83,   20030618, -1.1,  1.9,   8.7
1247.5,    20030619, 1.1,   1.1,   9.2
1235.2,    20030620, -1.0,  0.3,   10.2
1220.19,   20030623, -1.2,  0.7,   8.0
1196.62,   20030624, -1.9,  -4.1,  4.9
1193.16,   20030625, -0.3,  -3.3,  3.5
1194.87,   20030626, 0.1,   -4.2,  2.9
1216.86,   20030627, 1.8,   -1.5,  5.3
1212.51,   20030630, -0.4,  -0.6,  4.7
1195.3,    20030701, -1.4,  -0.1,  4.2
1223.19,   20030702, 2.3,   2.5,   9.4
1233.4,    20030703, 0.8,   3.2,   11.0
1250.03,   20030707, 1.3,   2.7,   12.0
1275.79,   20030708, 2.1,   5.2,   13.1
1294.58,   20030709, 1.5,   8.3,   15.2
1280.44,   20030710, -1.1,  4.7,   9.2
1273.94,   20030711, -0.5,  3.3,   8.4
1301.19,   20030714, 2.1,   4.1,   9.5
1309.69,   20030715, 0.7,   2.7,   8.2
1305.76,   20030716, -0.3,  0.9,   10.3
1274.73,   20030717, -2.4,  -0.4,  6.3
1265.0,    20030718, -0.8,  -0.7,  4.5
1256.12,   20030721, -0.7,  -3.5,  0.1
1252.99,   20030722, -0.2,  -4.3,  3.8
1262.59,   20030723, 0.8,   -3.3,  5.1
1282.13,   20030724, 1.5,   0.6,   5.9
1252.62,   20030725, -2.3,  -1.0,  1.6
1281.5,    20030728, 2.3,   2.0,   4.0
1284.24,   20030729, 0.2,   2.5,   6.0
1276.58,   20030730, -0.6,  1.1,   2.3
1278.29,   20030731, 0.1,   -0.3,  3.6
1274.61,   20030801, -0.3,  1.8,   2.2
1263.62,   20030804, -0.9,  -1.4,  2.3
1263.79,   20030805, 0.0,   -1.6,  3.6
1221.99,   20030806, -3.3,  -4.3,  2.1
1214.89,   20030807, -0.6,  -5.0,  1.8
1223.66,   20030808, 0.7,   -4.0,  2.4
1209.35,   20030811, -1.2,  -4.3,  -0.6
1227.5,    20030812, 1.5,   -2.9,  1.2
1247.56,   20030813, 1.6,   2.1,   4.4
1241.17,   20030814, -0.5,  2.2,   1.5
1250.45,   20030815, 0.7,   2.2,   1.4
1258.18,   20030818, 0.6,   4.0,   0.7
1291.37,   20030819, 2.6,   5.2,   1.2
1289.43,   20030820, -0.2,  3.4,   -0.4
1309.57,   20030821, 1.6,   5.5,   2.3
1338.19,   20030822, 2.2,   7.0,   5.0
1302.5,    20030825, -2.7,  3.5,   0.1
1298.23,   20030826, -0.3,  0.5,   -0.9
1305.98,   20030827, 0.6,   1.3,   0.0
1323.21,   20030828, 1.3,   1.0,   3.8
1328.49,   20030829, 0.4,   -0.7,  5.0
1347.64,   20030902, 1.4,   3.5,   7.3
1370.35,   20030903, 1.7,   5.6,   9.4
1360.22,   20030904, -0.7,  4.2,   7.7
1366.29,   20030905, 0.4,   3.3,   6.6
1366.55,   20030908, 0.0,   2.9,   9.1
1380.13,   20030909, 1.0,   2.4,   7.7
1357.8,    20030910, -1.6,  -0.9,  5.7
1339.88,   20030911, -1.3,  -1.5,  5.0
1340.78,   20030912, 0.1,   -1.9,  4.9
1359.97,   20030915, 1.4,   -0.5,  6.7
1348.75,   20030916, -0.8,  -2.3,  6.7
1379.37,   20030917, 2.3,   1.6,   9.1
1374.56,   20030918, -0.3,  2.6,   12.5
1406.61,   20030919, 2.3,   4.9,   15.8
1371.18,   20030922, -2.5,  0.8,   12.1
1367.91,   20030923, -0.2,  1.4,   13.1
1388.54,   20030924, 1.5,   0.7,   13.1
1344.63,   20030925, -3.2,  -2.2,  7.8
1323.87,   20030926, -1.5,  -5.9,  6.7
1318.33,   20030929, -0.4,  -3.9,  5.4
1323.95,   20030930, 0.4,   -3.2,  5.2
1312.43,   20031001, -0.9,  -5.5,  1.6
1332.16,   20031002, 1.5,   -0.9,  3.3
1363.1,    20031003, 2.3,   3.0,   4.1
1378.2,    20031006, 1.1,   4.5,   3.0
1372.09,   20031007, -0.4,  3.6,   5.3
1398.46,   20031008, 1.9,   6.6,   7.7
1404.88,   20031009, 0.5,   5.5,   7.6
1399.18,   20031010, -0.4,  2.6,   5.7
1412.37,   20031013, 0.9,   2.5,   6.3
1411.53,   20031014, -0.1,  2.9,   4.7
1439.97,   20031015, 2.0,   3.0,   5.1
1411.23,   20031016, -2.0,  0.5,   3.8
1423.99,   20031017, 0.9,   1.8,   4.2
1395.41,   20031020, -2.0,  -1.2,  2.1
1413.01,   20031021, 1.3,   0.1,   2.4
1403.76,   20031022, -0.7,  -2.5,  3.4
1371.9,    20031023, -2.3,  -2.8,  2.4
1360.55,   20031024, -0.8,  -4.5,  1.5
1378.95,   20031027, 1.4,   -1.2,  1.4
1385.57,   20031028, 0.5,   -1.9,  2.7
1414.18,   20031029, 2.1,   0.7,   2.5
1437.65,   20031030, 1.7,   4.8,   4.6
1423.39,   20031031, -1.0,  4.6,   1.2
1423.13,   20031103, -0.0,  3.2,   3.8
1434.35,   20031104, 0.8,   3.5,   4.9
1428.04,   20031105, -0.4,  1.0,   2.8
1439.22,   20031106, 0.8,   0.1,   7.0
1450.47,   20031107, 0.8,   1.9,   9.6
1435.87,   20031110, -1.0,  0.9,   8.9
1412.5,    20031111, -1.6,  -1.5,  6.7
1414.21,   20031112, 0.1,   -1.0,  7.8
1434.75,   20031113, 1.5,   -0.3,  7.7
1438.99,   20031114, 0.3,   -0.8,  5.6
1399.38,   20031117, -2.8,  -2.5,  1.5
1401.46,   20031118, 0.1,   -0.8,  2.1
1368.47,   20031119, -2.4,  -3.2,  -2.1
1365.73,   20031120, -0.2,  -4.8,  -2.8
1370.63,   20031121, 0.4,   -4.8,  -2.0
1385.2,    20031124, 1.1,   -1.0,  -1.9
1418.26,   20031125, 2.4,   1.2,   0.5
1422.01,   20031126, 0.3,   3.9,   -1.2
1417.41,   20031128, -0.3,  3.8,   0.4
1434.1,    20031201, 1.2,   4.6,   0.7
1441.71,   20031202, 0.5,   4.1,   3.3
1440.37,   20031203, -0.1,  1.6,   1.9
1424.65,   20031204, -1.1,  0.2,   1.5
1415.78,   20031205, -0.6,  -0.1,  3.2
1406.02,   20031208, -0.7,  -2.0,  3.3
1423.75,   20031209, 1.3,   -1.2,  3.2
1387.25,   20031210, -2.6,  -3.7,  0.1
1388.88,   20031211, 0.1,   -2.5,  -1.8
1419.7,    20031212, 2.2,   0.3,   -1.2
1442.05,   20031215, 1.6,   2.6,   1.3
1397.41,   20031216, -3.1,  -1.9,  -1.8
1400.78,   20031217, 0.2,   1.0,   -2.3
1404.08,   20031218, 0.2,   1.1,   -1.7
1434.93,   20031219, 2.2,   1.1,   -0.3
1422.79,   20031222, -0.8,  -1.3,  -1.9
1431.64,   20031223, 0.6,   2.4,   -0.3
1442.58,   20031224, 0.8,   3.0,   2.1
1444.41,   20031226, 0.1,   2.9,   2.1
1447.62,   20031229, 0.2,   0.9,   0.9
1467.74,   20031230, 1.4,   3.2,   2.0
1471.56,   20031231, 0.3,   2.8,   5.2
1474.16,   20040102, 0.2,   2.2,   5.2
1474.55,   20040105, 0.0,   2.1,   7.8
1492.41,   20040106, 1.2,   3.1,   9.3
1498.38,   20040107, 0.4,   2.1,   9.3
1524.06,   20040108, 1.7,   3.6,   10.0
1516.59,   20040109, -0.5,  2.9,   6.9
1524.54,   20040112, 0.5,   3.4,   7.2
1540.35,   20040113, 1.0,   3.2,   8.7
1531.93,   20040114, -0.5,  2.2,   6.8
1524.73,   20040115, -0.5,  0.0,   5.8
1545.32,   20040116, 1.4,   1.9,   7.3
1558.89,   20040120, 0.9,   2.3,   9.4
1545.53,   20040121, -0.9,  0.3,   9.2
1550.98,   20040122, 0.4,   1.2,   10.3
1535.41,   20040123, -1.0,  0.7,   7.8
1526.57,   20040126, -0.6,  -1.2,  10.0
1548.64,   20040127, 1.4,   -0.7,  11.5
1527.35,   20040128, -1.4,  -1.2,  7.6
1499.84,   20040129, -1.8,  -3.3,  4.0
1494.88,   20040130, -0.3,  -2.6,  7.0
1497.78,   20040202, 0.2,   -1.9,  6.9
1485.93,   20040203, -0.8,  -4.0,  5.8
1473.53,   20040204, -0.8,  -3.5,  2.7
1468.96,   20040205, -0.3,  -2.1,  3.2
1472.02,   20040206, 0.2,   -1.5,  2.8
1502.32,   20040209, 2.1,   0.3,   4.1
1490.69,   20040210, -0.8,  0.3,   3.2
1498.08,   20040211, 0.5,   1.7,   3.5
1508.19,   20040212, 0.7,   2.7,   2.8
1504.7,    20040213, -0.2,  2.2,   2.3
1498.7,    20040217, -0.4,  -0.2,  1.7
1509.55,   20040218, 0.7,   1.3,   2.4
1522.39,   20040219, 0.9,   1.6,   2.0
1490.81,   20040220, -2.1,  -1.2,  -0.5
1488.28,   20040223, -0.2,  -1.1,  -2.3
1458.38,   20040224, -2.0,  -2.7,  -3.8
1465.83,   20040225, 0.5,   -2.9,  -3.9
1465.4,    20040226, -0.0,  -3.7,  -4.9
1479.56,   20040227, 1.0,   -0.8,  -3.4
1476.04,   20040301, -0.2,  -0.8,  -3.2
1487.7,    20040302, 0.8,   2.0,   -3.7
1469.77,   20040303, -1.2,  0.3,   -5.7
1466.7,    20040304, -0.2,  0.1,   -5.1
1464.89,   20040305, -0.1,  -1.0,  -5.6
1476.85,   20040308, 0.8,   0.1,   -3.8
1441.28,   20040309, -2.4,  -3.1,  -5.6
1438.53,   20040310, -0.2,  -2.1,  -7.1
1409.14,   20040311, -2.0,  -3.9,  -7.7
1415.9,    20040312, 0.5,   -3.3,  -5.6
1423.15,   20040315, 0.5,   -3.6,  -4.8
1412.1,    20040316, -0.8,  -2.0,  -5.7
1418.57,   20040317, 0.5,   -1.4,  -4.5
1423.86,   20040318, 0.4,   1.0,   -3.4
1416.23,   20040319, -0.5,  0.0,   -3.6
1386.76,   20040322, -2.1,  -2.6,  -5.8
1391.64,   20040323, 0.4,   -1.4,  -7.4
1374.74,   20040324, -1.2,  -3.1,  -7.8
1394.2,    20040325, 1.4,   -2.1,  -6.9
1420.41,   20040326, 1.9,   0.3,   -5.8
1429.54,   20040329, 0.6,   3.1,   -5.0
1435.59,   20040330, 0.4,   3.2,   -4.2
1445.17,   20040331, 0.7,   5.1,   -4.3
1438.91,   20040401, -0.4,  3.2,   -5.5
1482.33,   20040402, 3.0,   4.4,   -0.6
1488.45,   20040405, 0.4,   4.1,   0.0
1492.93,   20040406, 0.3,   4.0,   2.4
1489.1,    20040407, -0.3,  3.0,   1.6
1502.77,   20040408, 0.9,   4.4,   2.6
1489.59,   20040412, -0.9,  0.5,   0.7
1501.98,   20040413, 0.8,   0.9,   1.8
1464.32,   20040414, -2.5,  -1.9,  -1.6
1479.56,   20040415, 1.0,   -0.6,  0.7
1458.51,   20040416, -1.4,  -2.9,  -0.6
1448.89,   20040419, -0.7,  -2.7,  -1.1
1473.98,   20040420, 1.7,   -1.9,  -0.2
1446.02,   20040421, -1.9,  -1.2,  0.3
1450.38,   20040422, 0.3,   -2.0,  0.8
1492.24,   20040423, 2.9,   2.3,   5.9
1496.67,   20040426, 0.3,   3.3,   5.7
1485.67,   20040427, -0.7,  0.8,   4.4
1475.88,   20040428, -0.7,  2.1,   4.5
1451.4,    20040429, -1.7,  0.1,   2.3
1435.16,   20040430, -1.1,  -3.8,  0.8
1409.06,   20040503, -1.8,  -5.9,  -0.5
1417.12,   20040504, 0.6,   -4.6,  2.2
1424.71,   20040505, 0.5,   -3.5,  2.4
1416.59,   20040506, -0.6,  -2.4,  3.0
1411.05,   20040507, -0.4,  -1.7,  1.2
1395.21,   20040510, -1.1,  -1.0,  -1.8
1406.36,   20040511, 0.8,   -0.8,  -1.6
1412.5,    20040512, 0.4,   -0.9,  -1.6
1408.58,   20040513, -0.3,  -0.6,  -2.5
1415.44,   20040514, 0.5,   0.3,   -1.6
1379.99,   20040517, -2.5,  -1.1,  -6.9
1395.23,   20040518, 1.1,   -0.8,  -6.3
1414.38,   20040519, 1.4,   0.1,   -5.3
1400.37,   20040520, -1.0,  -0.6,  -6.0
1409.61,   20040521, 0.7,   -0.4,  -6.2
1418.77,   20040524, 0.6,   2.8,   -4.8
1412.59,   20040525, -0.4,  1.2,   -6.0
1443.13,   20040526, 2.2,   2.0,   -1.4
1460.06,   20040527, 1.2,   4.3,   -1.3
1464.83,   20040528, 0.3,   3.9,   0.4
1457.71,   20040601, -0.5,  2.7,   0.6
1472.54,   20040602, 1.0,   4.2,   -0.1
1459.19,   20040603, -0.9,  1.1,   0.9
1463.46,   20040604, 0.3,   0.2,   0.9
1465.56,   20040607, 0.1,   0.0,   -1.8
1483.59,   20040608, 1.2,   1.8,   -0.9
1487.37,   20040609, 0.3,   1.0,   0.1
1475.71,   20040610, -0.8,  1.1,   -0.0
1470.62,   20040614, -0.3,  0.5,   1.3
1470.37,   20040615, -0.0,  0.3,   2.5
1478.72,   20040616, 0.6,   -0.3,  4.9
1473.94,   20040617, -0.3,  -0.9,  4.0
1457.88,   20040618, -1.1,  -1.2,  2.3
1467.98,   20040621, 0.7,   -0.2,  3.6
1455.92,   20040622, -0.8,  -1.0,  3.2
1471.54,   20040623, 1.1,   -0.5,  5.5
1490.58,   20040624, 1.3,   1.1,   6.0
1488.82,   20040625, -0.1,  2.1,   5.4
1507.32,   20040628, 1.2,   2.7,   7.0
1490.65,   20040629, -1.1,  2.4,   5.3
1507.6,    20040630, 1.1,   2.5,   9.2
1514.82,   20040701, 0.5,   1.6,   8.6
1488.34,   20040702, -1.7,  -0.0,  5.2
1470.47,   20040706, -1.2,  -2.4,  5.0
1445.1,    20040707, -1.7,  -3.1,  2.5
1439.57,   20040708, -0.4,  -4.5,  1.5
1442.43,   20040709, 0.2,   -4.8,  2.1
1429.91,   20040712, -0.9,  -3.9,  -0.9
1436.08,   20040713, 0.4,   -2.3,  -1.6
1414.42,   20040714, -1.5,  -2.1,  -3.4
1422.26,   20040715, 0.6,   -1.2,  -2.4
1426.49,   20040716, 0.3,   -1.1,  -3.1
1398.92,   20040719, -1.9,  -2.2,  -4.1
1398.64,   20040720, -0.0,  -2.6,  -4.4
1433.27,   20040721, 2.5,   1.3,   -2.2
1385.89,   20040722, -3.3,  -2.6,  -6.6
1394.56,   20040723, 0.6,   -2.2,  -6.2
1376.8,    20040726, -1.3,  -1.6,  -6.7
1374.69,   20040727, -0.2,  -1.7,  -6.5
1384.51,   20040728, 0.7,   -3.4,  -5.8
1395.34,   20040729, 0.8,   0.7,   -5.6
1397.05,   20040730, 0.1,   0.2,   -5.2
1390.21,   20040802, -0.5,  1.0,   -4.6
1402.34,   20040803, 0.9,   2.0,   -4.5
1372.46,   20040804, -2.1,  -0.9,  -5.7
1380.93,   20040805, 0.6,   -1.0,  -6.2
1341.08,   20040806, -2.9,  -4.0,  -10.0
1320.83,   20040809, -1.5,  -5.0,  -11.3
1325.96,   20040810, 0.4,   -5.4,  -12.0
1319.7,    20040811, -0.5,  -3.8,  -11.5
1313.81,   20040812, -0.4,  -4.9,  -12.9
1311.84,   20040813, -0.1,  -2.2,  -13.4
1309.5,    20040816, -0.2,  -0.9,  -12.0
1332.94,   20040817, 1.8,   0.5,   -9.4
1328.99,   20040818, -0.3,  0.7,   -8.0
1356.89,   20040819, 2.1,   3.3,   -5.7
1352.28,   20040820, -0.3,  3.1,   -6.2
1370.51,   20040823, 1.3,   4.7,   -4.2
1376.99,   20040824, 0.5,   3.3,   -4.1
1369.2,    20040825, -0.6,  3.0,   -3.2
1383.8,    20040826, 1.1,   2.0,   -2.7
1384.61,   20040827, 0.1,   2.4,   -2.9
1383.12,   20040830, -0.1,  0.9,   -1.1
1368.91,   20040831, -1.0,  -0.6,  -2.1
1363.27,   20040901, -0.4,  -0.4,  -4.9
1374.69,   20040902, 0.8,   -0.7,  -0.8
1384.41,   20040903, 0.7,   -0.0,  -0.7
1382.48,   20040907, -0.1,  -0.0,  0.4
1378.4,    20040908, -0.3,  0.7,   0.3
1385.22,   20040909, 0.5,   1.6,   0.1
1391.2,    20040910, 0.4,   1.2,   -0.3
1417.84,   20040913, 1.9,   2.4,   1.5
1425.29,   20040914, 0.5,   3.1,   2.5
1425.51,   20040915, 0.0,   3.4,   1.7
1417.37,   20040916, -0.6,  2.3,   3.3
1417.73,   20040917, 0.0,   1.9,   2.7
1417.39,   20040920, -0.0,  -0.0,  5.7
1429.85,   20040921, 0.9,   0.3,   8.3
1424.72,   20040922, -0.4,  -0.1,  7.4
1405.76,   20040923, -1.3,  -0.8,  6.5
1407.71,   20040924, 0.1,   -0.7,  7.1
1389.94,   20040927, -1.3,  -1.9,  6.0
1390.11,   20040928, 0.0,   -2.8,  6.2
1389.69,   20040929, -0.0,  -2.5,  4.3
1409.23,   20040930, 1.4,   0.2,   6.0
1426.38,   20041001, 1.2,   1.3,   5.1
1463.22,   20041004, 2.6,   5.3,   8.2
1456.19,   20041005, -0.5,  4.8,   6.3
1460.19,   20041006, 0.3,   5.1,   6.0
1469.7,    20041007, 0.7,   4.3,   7.3
1449.49,   20041008, -1.4,  1.6,   4.7
1435.54,   20041011, -1.0,  -1.9,  3.7
1424.22,   20041012, -0.8,  -2.2,  3.0
1451.73,   20041013, 1.9,   -0.6,  6.1
1434.92,   20041014, -1.2,  -2.4,  5.3
1430.31,   20041015, -0.3,  -1.3,  4.0
1428.08,   20041018, -0.2,  -0.5,  3.2
1463.01,   20041019, 2.4,   2.7,   5.8
1441.21,   20041020, -1.5,  -0.7,  4.6
1459.68,   20041021, 1.3,   1.7,   5.4
1472.92,   20041022, 0.9,   3.0,   5.9
1435.68,   20041025, -2.5,  0.5,   1.3
1432.25,   20041026, -0.2,  -2.1,  0.5
1441.79,   20041027, 0.7,   0.0,   1.1
1473.41,   20041028, 2.2,   0.9,   4.0
1485.68,   20041029, 0.8,   0.9,   4.8
1486.25,   20041101, 0.0,   3.5,   4.9
1490.32,   20041102, 0.3,   4.1,   4.2
1519.97,   20041103, 2.0,   5.4,   6.7
1498.06,   20041104, -1.4,  1.7,   6.6
1526.71,   20041105, 1.9,   2.8,   8.5
1525.13,   20041108, -0.1,  2.6,   9.7
1527.25,   20041109, 0.1,   2.5,   9.9
1524.1,    20041110, -0.2,  0.3,   9.7
1524.62,   20041111, 0.0,   1.8,   8.2
1543.31,   20041112, 1.2,   1.1,   8.2
1554.31,   20041115, 0.7,   1.9,   6.2
1554.79,   20041116, 0.0,   1.8,   6.8
1561.8,    20041117, 0.5,   2.5,   7.0
1568.02,   20041118, 0.4,   2.8,   6.7
1578.86,   20041119, 0.7,   2.3,   8.9
1551.16,   20041122, -1.8,  -0.2,  8.1
1566.71,   20041123, 1.0,   0.8,   10.0
1572.67,   20041124, 0.4,   0.7,   8.3
1581.65,   20041126, 0.6,   0.9,   10.2
1586.46,   20041129, 0.3,   0.5,   10.9
1579.51,   20041130, -0.4,  1.8,   10.6
1578.25,   20041201, -0.1,  0.7,   7.9
1602.11,   20041202, 1.5,   1.9,   11.2
1621.1,    20041203, 1.2,   2.5,   11.1
1612.33,   20041206, -0.5,  1.6,   9.5
1622.84,   20041207, 0.7,   2.7,   13.0
1593.87,   20041208, -1.8,  1.0,   11.3
1585.99,   20041209, -0.5,  -1.0,  10.0
1601.46,   20041210, 1.0,   -1.2,  8.7
1617.53,   20041213, 1.0,   0.3,   8.9
1618.2,    20041214, 0.0,   -0.3,  8.9
1626.99,   20041215, 0.5,   2.1,   9.2
1621.43,   20041216, -0.3,  2.2,   6.7
1608.64,   20041217, -0.8,  0.4,   7.4
1603.84,   20041220, -0.3,  -0.8,  5.1
1598.6,    20041221, -0.3,  -1.2,  4.8
1605.72,   20041222, 0.4,   -1.3,  5.1
1608.87,   20041223, 0.2,   -0.8,  5.6
1621.33,   20041227, 0.8,   0.8,   6.3
1610.9,    20041228, -0.6,  0.4,   4.4
1619.36,   20041229, 0.5,   1.3,   4.2
1626.21,   20041230, 0.4,   1.3,   4.6
1624.37,   20041231, -0.1,  1.0,   4.0
1628.75,   20050103, 0.3,   0.5,   3.9
1611.22,   20050104, -1.1,  0.0,   2.0
1568.68,   20050105, -2.6,  -3.1,  1.1
1567.66,   20050106, -0.1,  -3.6,  0.1
1565.63,   20050107, -0.1,  -3.6,  -0.4
1561.23,   20050110, -0.3,  -4.1,  -1.3
1556.66,   20050111, -0.3,  -3.4,  -1.9
1561.84,   20050112, 0.3,   -0.4,  -1.1
1567.55,   20050113, 0.4,   -0.0,  -0.7
1552.11,   20050114, -1.0,  -0.9,  -3.1
1554.19,   20050118, 0.1,   -0.5,  -4.1
1572.81,   20050119, 1.2,   1.0,   -2.5
1526.8,    20050120, -2.9,  -2.2,  -5.9
1519.3,    20050121, -0.5,  -3.1,  -4.7
1507.79,   20050124, -0.8,  -2.9,  -4.9
1492.65,   20050125, -1.0,  -4.0,  -6.8
1502.05,   20050126, 0.6,   -4.5,  -7.1
1505.25,   20050127, 0.2,   -1.4,  -7.0
1510.88,   20050128, 0.4,   -0.6,  -7.1
1515.16,   20050131, 0.3,   0.5,   -6.6
1521.0,    20050201, 0.4,   1.9,   -5.4
1527.29,   20050202, 0.4,   1.7,   -4.8
1516.22,   20050203, -0.7,  0.7,   -5.2
1507.74,   20050204, -0.6,  -0.2,  -6.1
1534.48,   20050207, 1.8,   1.3,   -4.6
1529.55,   20050208, -0.3,  0.6,   -5.7
1534.41,   20050209, 0.3,   0.5,   -4.7
1512.71,   20050210, -1.4,  -0.2,  -6.6
1505.09,   20050211, -0.5,  -0.2,  -7.4
1531.19,   20050214, 1.7,   -0.2,  -5.7
1538.38,   20050215, 0.5,   0.6,   -5.5
1541.26,   20050216, 0.2,   0.4,   -4.3
1543.03,   20050217, 0.1,   2.0,   -1.6
1519.21,   20050218, -1.5,  0.9,   -3.1
1502.94,   20050222, -1.1,  -1.8,  -4.0
1502.83,   20050223, -0.0,  -2.3,  -3.7
1493.59,   20050224, -0.6,  -3.1,  -4.1
1515.75,   20050225, 1.5,   -1.8,  -3.0
1517.06,   20050228, 0.1,   -0.1,  -3.2
1516.63,   20050301, -0.0,  0.9,   -2.3
1517.68,   20050302, 0.1,   1.0,   -2.3
1530.13,   20050303, 0.8,   2.4,   -2.7
1524.21,   20050304, -0.4,  0.6,   -0.2
1524.04,   20050307, -0.0,  0.5,   0.3
1540.8,    20050308, 1.1,   1.6,   2.2
1526.9,    20050309, -0.9,  0.6,   2.3
1525.64,   20050310, -0.1,  -0.3,  1.6
1528.65,   20050311, 0.2,   0.3,   1.6
1509.19,   20050314, -1.3,  -1.0,  -0.1
1520.26,   20050315, 0.7,   -1.3,  0.3
1497.4,    20050316, -1.5,  -1.9,  -1.6
1487.72,   20050317, -0.6,  -2.5,  -2.6
1488.55,   20050318, 0.1,   -2.6,  -1.8
1483.16,   20050321, -0.4,  -1.7,  -1.6
1484.13,   20050322, 0.1,   -2.4,  -3.3
1463.43,   20050323, -1.4,  -2.3,  -4.3
1477.46,   20050324, 1.0,   -0.7,  -3.7
1476.4,    20050328, -0.1,  -0.8,  -2.4
1471.25,   20050329, -0.3,  -0.8,  -2.2
1470.13,   20050330, -0.1,  -0.9,  -4.0
1489.44,   20050331, 1.3,   1.8,   -3.2
1491.83,   20050401, 0.2,   1.0,   -3.2
1469.42,   20050404, -1.5,  -0.5,  -4.8
1480.99,   20050405, 0.8,   0.7,   -2.5
1487.15,   20050406, 0.4,   1.2,   -1.1
1481.13,   20050407, -0.4,  -0.6,  -1.4
1499.93,   20050408, 1.3,   0.5,   0.4
1490.43,   20050411, -0.6,  1.4,   -1.7
1477.01,   20050412, -0.9,  -0.3,  -2.6
1484.39,   20050413, 0.5,   -0.2,  -2.1
1461.83,   20050414, -1.5,  -1.3,  -3.7
1425.73,   20050415, -2.5,  -4.9,  -6.8
1406.3,    20050418, -1.4,  -5.6,  -7.7
1419.53,   20050419, 0.9,   -3.9,  -6.9
1430.25,   20050420, 0.8,   -3.6,  -7.2
1423.62,   20050421, -0.5,  -2.6,  -6.8
1437.97,   20050422, 1.0,   0.9,   -5.7
1430.99,   20050425, -0.5,  1.8,   -6.4
1431.75,   20050426, 0.1,   0.9,   -5.1
1413.22,   20050427, -1.3,  -1.2,  -7.0
1418.12,   20050428, 0.3,   -0.4,  -5.3
1420.64,   20050429, 0.2,   -1.2,  -4.5
1421.9,    20050502, 0.1,   -0.6,  -4.5
1421.28,   20050503, -0.0,  -0.7,  -4.2
1431.06,   20050504, 0.7,   1.3,   -3.6
1450.73,   20050505, 1.4,   2.3,   -0.9
1460.26,   20050506, 0.7,   2.8,   -1.2
1455.68,   20050509, -0.3,  2.4,   -1.4
1453.25,   20050510, -0.2,  2.2,   -1.2
1452.31,   20050511, -0.1,  1.5,   -1.2
1459.07,   20050512, 0.5,   0.6,   -2.0
1459.23,   20050513, 0.0,   -0.1,  -2.2
1470.31,   20050516, 0.8,   1.0,   0.1
1474.05,   20050517, 0.3,   1.4,   -0.5
1493.03,   20050518, 1.3,   2.8,   0.4
1510.62,   20050519, 1.2,   3.5,   2.0
1519.26,   20050520, 0.6,   4.1,   1.3
1526.97,   20050523, 0.5,   3.9,   2.5
1531.25,   20050524, 0.3,   3.9,   3.7
1535.9,    20050525, 0.3,   2.9,   3.5
1540.05,   20050526, 0.3,   1.9,   5.4
1546.74,   20050527, 0.4,   1.8,   8.5
1549.48,   20050531, 0.2,   1.5,   10.2
1541.73,   20050601, -0.5,  0.7,   8.6
1553.43,   20050602, 0.8,   1.1,   8.6
1565.48,   20050603, 0.8,   1.7,   10.0
1544.96,   20050606, -1.3,  -0.1,  7.4
1546.71,   20050607, 0.1,   -0.2,  8.1
1536.92,   20050608, -0.6,  -0.3,  7.3
1526.15,   20050609, -0.7,  -1.8,  8.0
1537.57,   20050610, 0.7,   -1.8,  8.4
1519.61,   20050613, -1.2,  -1.6,  7.0
1529.02,   20050614, 0.6,   -1.1,  7.5
1533.9,    20050615, 0.3,   -0.2,  7.9
1531.02,   20050616, -0.2,  0.3,   7.0
1550.63,   20050617, 1.3,   0.8,   6.9
1529.65,   20050620, -1.4,  0.7,   4.8
1535.48,   20050621, 0.4,   0.4,   5.5
1542.13,   20050622, 0.4,   0.5,   6.1
1537.64,   20050623, -0.3,  0.4,   5.9
1516.84,   20050624, -1.4,  -2.2,  4.0
1497.29,   20050627, -1.3,  -2.1,  2.6
1500.65,   20050628, 0.2,   -2.3,  2.1
1511.95,   20050629, 0.8,   -2.0,  2.6
1507.02,   20050630, -0.3,  -2.0,  0.9
1497.02,   20050701, -0.7,  -1.3,  -0.9
1485.96,   20050705, -0.7,  -0.8,  -2.2
1503.48,   20050706, 1.2,   0.2,   -1.5
1484.92,   20050707, -1.2,  -1.8,  -3.0
1505.53,   20050708, 1.4,   -0.1,  -2.0
1536.45,   20050711, 2.1,   2.6,   -0.2
1546.52,   20050712, 0.7,   4.1,   -0.0
1555.24,   20050713, 0.6,   3.4,   0.4
1570.31,   20050714, 1.0,   5.8,   1.9
1577.27,   20050715, 0.4,   4.8,   1.5
1573.62,   20050718, -0.2,  2.4,   0.5
1580.25,   20050719, 0.4,   2.2,   2.3
1580.06,   20050720, -0.0,  1.6,   2.2
1606.84,   20050721, 1.7,   2.3,   4.5
1601.68,   20050722, -0.3,  1.5,   4.9
1600.62,   20050725, -0.1,  1.7,   4.1
1596.81,   20050726, -0.2,  1.0,   5.1
1601.39,   20050727, 0.3,   1.3,   4.7
1613.29,   20050728, 0.7,   0.4,   5.2
1616.42,   20050729, 0.2,   0.9,   5.6
1609.74,   20050801, -0.4,  0.6,   3.8
1611.86,   20050802, 0.1,   0.9,   5.4
1619.98,   20050803, 0.5,   1.2,   5.5
1619.18,   20050804, -0.0,  0.4,   5.0
1606.63,   20050805, -0.8,  -0.6,  4.5
1604.23,   20050808, -0.1,  -0.3,  5.8
1596.15,   20050809, -0.5,  -1.0,  6.6
1604.8,    20050810, 0.5,   -0.9,  6.9
1587.77,   20050811, -1.1,  -1.9,  5.0
1587.69,   20050812, -0.0,  -1.2,  5.4
1587.59,   20050815, -0.0,  -1.0,  6.1
1595.86,   20050816, 0.5,   -0.0,  7.4
1577.57,   20050817, -1.1,  -1.7,  4.9
1578.2,    20050818, 0.0,   -0.6,  6.3
1576.57,   20050819, -0.1,  -0.7,  4.7
1577.15,   20050822, 0.0,   -0.7,  2.6
1575.47,   20050823, -0.1,  -1.3,  1.9
1568.02,   20050824, -0.5,  -0.6,  0.8
1564.42,   20050825, -0.2,  -0.9,  -0.4
1563.85,   20050826, -0.0,  -0.8,  -0.9
1551.36,   20050829, -0.8,  -1.6,  -1.4
1565.17,   20050830, 0.9,   -0.7,  -1.0
1566.86,   20050831, 0.1,   -0.1,  -0.8
1581.23,   20050901, 0.9,   1.1,   -1.6
1580.71,   20050902, -0.0,  1.1,   -1.3
1580.21,   20050906, -0.0,  1.9,   -1.3
1594.02,   20050907, 0.9,   1.8,   -0.2
1594.82,   20050908, 0.1,   1.8,   -0.4
1599.98,   20050909, 0.3,   1.2,   -0.8
1607.6,    20050912, 0.5,   1.7,   -0.5
1609.58,   20050913, 0.1,   1.9,   -0.0
1609.22,   20050914, -0.0,  1.0,   -0.2
1594.51,   20050915, -0.9,  -0.0,  -1.6
1592.1,    20050916, -0.2,  -0.5,  -1.7
1595.08,   20050919, 0.2,   -0.8,  -0.7
1589.33,   20050920, -0.4,  -1.3,  -0.9
1577.1,    20050921, -0.8,  -2.0,  -1.2
1559.94,   20050922, -1.1,  -2.2,  -2.8
1563.97,   20050923, 0.3,   -1.8,  -1.5
1581.16,   20050926, 1.1,   -0.9,  -0.4
1575.84,   20050927, -0.3,  -0.8,  -0.7
1575.16,   20050928, -0.0,  -0.1,  -1.3
1567.44,   20050929, -0.5,  0.5,   -0.6
1589.24,   20050930, 1.4,   1.6,   0.7
1603.47,   20051003, 0.9,   1.4,   1.7
1606.15,   20051004, 0.2,   1.9,   1.8
1595.39,   20051005, -0.7,  1.3,   1.3
1573.15,   20051006, -1.4,  0.4,   0.3
1558.93,   20051007, -0.9,  -1.9,  -0.4
1554.74,   20051010, -0.3,  -3.0,  -0.6
1551.01,   20051011, -0.2,  -3.4,  -0.0
1532.23,   20051012, -1.2,  -4.0,  -2.1
1519.36,   20051013, -0.8,  -3.4,  -3.0
1537.43,   20051014, 1.2,   -1.4,  -2.8
1544.58,   20051017, 0.5,   -0.7,  -2.3
1548.29,   20051018, 0.2,   -0.2,  -2.0
1532.47,   20051019, -1.0,  0.0,   -3.9
1568.53,   20051020, 2.4,   3.2,   -1.6
1571.17,   20051021, 0.2,   2.2,   -1.8
1570.33,   20051024, -0.1,  1.7,   -2.3
1584.95,   20051025, 0.9,   2.4,   -1.5
1580.0,    20051026, -0.3,  3.1,   -1.8
1571.64,   20051027, -0.5,  0.2,   -1.4
1548.83,   20051028, -1.5,  -1.4,  -2.7
1559.84,   20051031, 0.7,   -0.7,  -2.2
1572.97,   20051101, 0.8,   -0.8,  -1.0
1570.83,   20051102, -0.1,  -0.6,  -0.4
1611.52,   20051103, 2.6,   2.5,   3.3
1622.56,   20051104, 0.7,   4.8,   3.7
1630.76,   20051107, 0.5,   4.5,   3.1
1626.24,   20051108, -0.3,  3.4,   3.2
1627.62,   20051109, 0.1,   3.6,   3.3
1629.56,   20051110, 0.1,   1.1,   4.0
1653.41,   20051111, 1.5,   1.9,   4.0
1653.65,   20051114, 0.0,   1.4,   3.1
1651.97,   20051115, -0.1,  1.6,   2.9
1648.92,   20051116, -0.2,  1.3,   3.4
1659.16,   20051117, 0.6,   1.8,   5.5
1685.05,   20051118, 1.6,   1.9,   8.1
1678.7,    20051121, -0.4,  1.5,   8.0
1681.37,   20051122, 0.2,   1.8,   8.4
1691.96,   20051123, 0.6,   2.6,   10.4
1699.13,   20051125, 0.4,   2.4,   11.8
1702.4,    20051128, 0.2,   1.0,   10.7
1693.11,   20051129, -0.5,  0.9,   9.6
1675.92,   20051130, -1.0,  -0.3,  8.2
1683.39,   20051201, 0.4,   -0.5,  9.8
1702.36,   20051202, 1.1,   0.2,   8.5
1704.12,   20051205, 0.1,   0.1,   8.5
1706.62,   20051206, 0.1,   0.8,   8.7
1703.31,   20051207, -0.2,  1.6,   7.5
1701.01,   20051208, -0.1,  1.0,   7.7
1687.44,   20051209, -0.8,  -0.9,  7.4
1698.15,   20051212, 0.6,   -0.4,  9.6
1696.28,   20051213, -0.1,  -0.6,  8.7
1701.49,   20051214, 0.3,   -0.1,  8.2
1703.39,   20051215, 0.1,   0.1,   8.4
1700.92,   20051216, -0.1,  0.8,   5.5
1692.21,   20051219, -0.5,  -0.3,  4.3
1665.6,    20051220, -1.6,  -1.8,  2.1
1669.34,   20051221, 0.2,   -1.9,  2.7
1672.59,   20051222, 0.2,   -1.8,  2.8
1687.58,   20051223, 0.9,   -0.8,  3.6
1686.92,   20051227, -0.0,  -0.3,  2.0
1671.27,   20051228, -0.9,  0.3,   1.1
1668.78,   20051229, -0.1,  -0.0,  1.0
1648.1,    20051230, -1.2,  -1.5,  -0.0
1654.39,   20060103, 0.4,   -2.0,  -0.3
1684.05,   20060104, 1.8,   -0.2,  -0.1
1697.8,    20060105, 0.8,   1.6,   1.1
1718.17,   20060106, 1.2,   3.0,   2.2
1734.5,    20060109, 1.0,   5.2,   2.5
1732.25,   20060110, -0.1,  4.7,   1.9
1748.88,   20060111, 1.0,   3.8,   2.7
1754.91,   20060112, 0.3,   3.4,   3.7
1748.37,   20060113, -0.4,  1.8,   4.3
1733.91,   20060117, -0.8,  -0.0,  3.0
1708.13,   20060118, -1.5,  -1.4,  0.3
1725.34,   20060119, 1.0,   -1.3,  1.2
1724.12,   20060120, -0.1,  -1.8,  1.0
1681.46,   20060123, -2.5,  -3.8,  -1.3
1682.8,    20060124, 0.1,   -2.9,  -1.1
1694.54,   20060125, 0.7,   -0.8,  0.4
1687.5,    20060126, -0.4,  -2.2,  -0.6
1699.16,   20060127, 0.7,   -1.4,  0.2
1712.84,   20060130, 0.8,   1.9,   0.7
1713.98,   20060131, 0.1,   1.9,   0.6
1697.12,   20060201, -1.0,  0.2,   -0.2
1709.31,   20060202, 0.7,   1.3,   1.0
1673.04,   20060203, -2.1,  -1.5,  0.4
1663.71,   20060206, -0.6,  -2.9,  -0.3
1656.05,   20060207, -0.5,  -3.4,  -1.0
1665.07,   20060208, 0.5,   -1.9,  -1.3
1675.94,   20060209, 0.7,   -2.0,  -0.7
1655.05,   20060210, -1.2,  -1.1,  -1.0
1653.76,   20060213, -0.1,  -0.6,  -0.9
1648.64,   20060214, -0.3,  -0.4,  0.0
1658.17,   20060215, 0.6,   -0.4,  0.2
1677.78,   20060216, 1.2,   0.1,   -0.4
1683.19,   20060217, 0.3,   1.7,   -0.9
1675.88,   20060221, -0.4,  1.3,   -2.5
1657.46,   20060222, -1.1,  0.5,   -4.4
1675.02,   20060223, 1.1,   1.0,   -3.3
1672.21,   20060224, -0.2,  -0.3,  -4.4
1681.45,   20060227, 0.6,   -0.1,  -4.2
1690.44,   20060228, 0.5,   0.9,   -3.3
1675.92,   20060301, -0.9,  1.1,   -3.3
1686.95,   20060302, 0.7,   0.7,   -1.2
1685.13,   20060303, -0.1,  0.8,   -2.3
1688.87,   20060306, 0.2,   0.4,   -2.0
1666.73,   20060307, -1.3,  -1.4,  -0.9
1656.29,   20060308, -0.6,  -1.2,  -1.6
1666.3,    20060309, 0.6,   -1.2,  -1.7
1645.27,   20060310, -1.3,  -2.4,  -2.5
1655.35,   20060313, 0.6,   -2.0,  -2.6
1650.93,   20060314, -0.3,  -0.9,  -3.6
1687.82,   20060315, 2.2,   1.9,   -1.5
1701.0,    20060316, 0.8,   2.1,   0.2
1682.68,   20060317, -1.1,  2.3,   -1.6
1690.9,    20060320, 0.5,   2.1,   1.1
1688.59,   20060321, -0.1,  2.3,   1.5
1663.61,   20060322, -1.5,  -1.4,  0.5
1672.12,   20060323, 0.5,   -1.7,  0.4
1677.09,   20060324, 0.3,   -0.3,  0.1
1678.93,   20060327, 0.1,   -0.7,  1.4
1681.1,    20060328, 0.1,   -0.4,  1.7
1677.11,   20060329, -0.2,  0.8,   1.7
1706.61,   20060330, 1.8,   2.1,   2.9
1712.93,   20060331, 0.4,   2.1,   2.1
1715.12,   20060403, 0.1,   2.2,   1.9
1708.92,   20060404, -0.4,  1.7,   2.0
1722.22,   20060405, 0.8,   2.7,   3.9
1734.21,   20060406, 0.7,   1.6,   3.5
1743.74,   20060407, 0.5,   1.8,   4.3
1724.65,   20060410, -1.1,  0.6,   2.6
1723.96,   20060411, -0.0,  0.9,   2.0
1705.17,   20060412, -1.1,  -1.0,  1.7
1704.47,   20060413, -0.0,  -1.7,  1.0
1710.91,   20060417, 0.4,   -1.9,  1.5
1699.0,    20060418, -0.7,  -1.5,  0.6
1729.71,   20060419, 1.8,   0.3,   3.8
1734.5,    20060420, 0.3,   1.7,   4.7
1736.73,   20060421, 0.1,   1.9,   4.2
1706.51,   20060424, -1.7,  -0.3,  3.7
1711.35,   20060425, 0.3,   0.7,   3.4
1704.43,   20060426, -0.4,  -1.5,  3.2
1695.03,   20060427, -0.6,  -2.3,  0.4
1705.52,   20060428, 0.6,   -1.8,  0.3
1706.8,    20060501, 0.1,   0.0,   1.4
1692.95,   20060502, -0.8,  -1.1,  0.1
1692.62,   20060503, -0.0,  -0.7,  0.2
1688.41,   20060504, -0.2,  -0.4,  1.5
1711.64,   20060505, 1.4,   0.4,   2.4
1713.74,   20060508, 0.1,   0.4,   2.2
1711.93,   20060509, -0.1,  1.1,   2.0
1706.19,   20060510, -0.3,  0.8,   1.5
1693.71,   20060511, -0.7,  0.3,   1.0
1649.83,   20060512, -2.6,  -3.6,  -3.3
1629.25,   20060515, -1.2,  -4.9,  -4.9
1635.62,   20060516, 0.4,   -4.5,  -4.6
1611.21,   20060517, -1.5,  -5.6,  -5.7
1607.98,   20060518, -0.2,  -5.1,  -6.6
1589.82,   20060519, -1.1,  -3.6,  -8.3
1587.98,   20060522, -0.1,  -2.5,  -8.9
1597.5,    20060523, 0.6,   -2.3,  -7.4
1567.77,   20060524, -1.9,  -2.7,  -9.1
1589.99,   20060525, 1.4,   -1.1,  -6.8
1605.19,   20060526, 1.0,   1.0,   -5.8
1599.11,   20060530, -0.4,  0.7,   -6.5
1575.77,   20060531, -1.5,  -1.4,  -7.3
1581.08,   20060601, 0.3,   0.8,   -8.6
1626.19,   20060602, 2.9,   2.3,   -6.2
1606.11,   20060605, -1.2,  0.1,   -7.5
1581.94,   20060606, -1.5,  -1.1,  -7.3
1578.77,   20060607, -0.2,  0.2,   -7.7
1555.85,   20060608, -1.5,  -1.6,  -8.7
1567.92,   20060609, 0.8,   -3.6,  -7.5
1553.65,   20060612, -0.9,  -3.3,  -8.9
1523.64,   20060613, -1.9,  -3.7,  -10.7
1519.29,   20060614, -0.3,  -3.8,  -10.3
1538.14,   20060615, 1.2,   -1.1,  -9.1
1568.53,   20060616, 2.0,   0.0,   -7.1
1568.38,   20060619, -0.0,  0.9,   -8.4
1549.95,   20060620, -1.2,  1.7,   -9.6
1550.66,   20060621, 0.0,   2.1,   -9.4
1571.11,   20060622, 1.3,   2.1,   -7.9
1551.47,   20060623, -1.3,  -1.1,  -8.4
1553.48,   20060626, 0.1,   -1.0,  -5.8
1556.14,   20060627, 0.2,   0.4,   -4.5
1531.3,    20060628, -1.6,  -1.2,  -6.4
1546.01,   20060629, 1.0,   -1.6,  -4.0
1590.27,   20060630, 2.9,   2.5,   -1.1
1580.63,   20060703, -0.6,  1.7,   -0.6
1573.35,   20060705, -0.5,  1.1,   -0.9
1556.24,   20060706, -1.1,  1.6,   -2.6
1543.46,   20060707, -0.8,  -0.2,  -1.6
1538.78,   20060710, -0.3,  -3.2,  -3.2
1516.19,   20060711, -1.5,  -4.1,  -5.5
1527.44,   20060712, 0.7,   -2.9,  -4.5
1490.54,   20060713, -2.4,  -4.2,  -5.4
1478.6,    20060714, -0.8,  -4.2,  -6.5
1462.39,   20060717, -1.1,  -5.0,  -10.1
1472.06,   20060718, 0.7,   -2.9,  -8.3
1467.58,   20060719, -0.3,  -3.9,  -7.2
1497.14,   20060720, 2.0,   0.4,   -5.2
1457.52,   20060721, -2.6,  -1.4,  -6.3
1459.39,   20060724, 0.1,   -0.2,  -6.9
1483.01,   20060725, 1.6,   0.7,   -4.5
1483.74,   20060726, 0.0,   1.1,   -2.6
1497.0,    20060727, 0.9,   -0.0,  -1.5
1488.06,   20060728, -0.6,  2.1,   -3.3
1506.81,   20060731, 1.3,   3.2,   -3.9
1498.17,   20060801, -0.6,  1.0,   -4.5
1490.64,   20060802, -0.5,  0.5,   -3.8
1487.02,   20060803, -0.2,  -0.7,  -4.1
1522.65,   20060804, 2.4,   2.3,   -3.1
1501.43,   20060807, -1.4,  -0.4,  -3.2
1498.95,   20060808, -0.2,  0.1,   -3.5
1503.86,   20060809, 0.3,   0.9,   -3.4
1482.11,   20060810, -1.4,  -0.3,  -3.2
1491.1,    20060811, 0.6,   -2.1,  -3.6
1500.65,   20060814, 0.6,   -0.1,  -5.6
1514.84,   20060815, 0.9,   1.1,   -4.2
1546.48,   20060816, 2.1,   2.8,   -1.7
1564.97,   20060817, 1.2,   5.6,   0.6
1571.7,    20060818, 0.4,   5.4,   1.8
1566.16,   20060821, -0.4,  4.4,   1.8
1560.21,   20060822, -0.4,  3.0,   2.9
1565.99,   20060823, 0.4,   1.3,   2.5
1557.64,   20060824, -0.5,  -0.5,  4.5
1550.84,   20060825, -0.4,  -1.3,  4.9
1557.21,   20060828, 0.4,   -0.6,  6.5
1569.95,   20060829, 0.8,   0.6,   6.6
1577.43,   20060830, 0.5,   0.7,   7.5
1583.98,   20060831, 0.4,   1.7,   5.8
1589.21,   20060901, 0.3,   2.5,   9.0
1589.92,   20060905, 0.0,   2.1,   8.9
1590.92,   20060906, 0.1,   1.3,   7.3
1567.73,   20060907, -1.5,  -0.6,  5.7
1568.81,   20060908, 0.1,   -1.0,  4.8
1563.08,   20060911, -0.4,  -1.6,  5.0
1585.77,   20060912, 1.5,   -0.3,  5.2
1615.32,   20060913, 1.9,   1.5,   7.8
1620.37,   20060914, 0.3,   3.4,   8.7
1637.27,   20060915, 1.0,   4.4,   10.1
1631.48,   20060918, -0.4,  4.4,   7.1
1638.08,   20060919, 0.4,   3.3,   9.1
1636.02,   20060920, -0.1,  1.3,   9.1
1648.84,   20060921, 0.8,   1.8,   9.6
1633.15,   20060922, -1.0,  -0.3,  10.2
1631.34,   20060925, -0.1,  -0.0,  9.4
1649.82,   20060926, 1.1,   0.7,   9.9
1655.52,   20060927, 0.3,   1.2,   9.3
1658.43,   20060928, 0.2,   0.6,   7.2
1666.03,   20060929, 0.5,   2.0,   6.5
1650.32,   20061002, -0.9,  1.2,   5.0
1629.26,   20061003, -1.3,  -1.2,  4.0
1637.36,   20061004, 0.5,   -1.1,  4.9
1680.06,   20061005, 2.6,   1.3,   7.3
1682.42,   20061006, 0.1,   1.0,   8.0
1683.2,    20061009, 0.0,   2.0,   8.5
1693.22,   20061010, 0.6,   3.9,   8.7
1686.41,   20061011, -0.4,  3.0,   7.4
1699.05,   20061012, 0.7,   1.1,   7.7
1717.39,   20061013, 1.1,   2.1,   8.4
1728.23,   20061016, 0.6,   2.7,   8.7
1718.1,    20061017, -0.6,  1.5,   8.1
1718.01,   20061018, -0.0,  1.9,   8.0
1699.06,   20061019, -1.1,  0.0,   8.4
1706.37,   20061020, 0.4,   -0.6,  8.8
1706.18,   20061023, -0.0,  -1.3,  9.2
1723.79,   20061024, 1.0,   0.3,   8.7
1717.27,   20061025, -0.4,  -0.0,  6.3
1730.73,   20061026, 0.8,   1.9,   6.8
1740.0,    20061027, 0.5,   2.0,   6.3
1715.79,   20061030, -1.4,  0.6,   5.2
1733.82,   20061031, 1.1,   0.6,   5.8
1738.55,   20061101, 0.3,   1.2,   6.3
1702.26,   20061102, -2.1,  -1.6,  3.2
1714.27,   20061103, 0.7,   -1.5,  5.0
1712.03,   20061106, -0.1,  -0.2,  4.9
1731.81,   20061107, 1.2,   -0.1,  5.0
1732.21,   20061108, 0.0,   -0.4,  4.6
1760.05,   20061109, 1.6,   3.4,   6.1
1743.1,    20061110, -1.0,  1.7,   4.6
1751.84,   20061113, 0.5,   2.3,   6.2
1769.37,   20061114, 1.0,   2.2,   8.6
1787.33,   20061115, 1.0,   3.2,   9.2
1798.85,   20061116, 0.6,   2.2,   7.1
1791.67,   20061117, -0.4,  2.8,   6.5
1795.68,   20061120, 0.2,   2.5,   6.7
1807.34,   20061121, 0.6,   2.1,   6.7
1815.62,   20061122, 0.5,   1.6,   7.7
1805.39,   20061124, -0.6,  0.4,   6.3
1810.91,   20061127, 0.3,   1.1,   5.4
1769.42,   20061128, -2.3,  -1.5,  2.4
1787.65,   20061129, 1.0,   -1.1,  4.0
1791.34,   20061130, 0.2,   -1.3,  4.3
1790.63,   20061201, -0.0,  -0.8,  5.4
1782.6,    20061204, -0.4,  -1.6,  4.5
1806.5,    20061205, 1.3,   2.1,   5.9
1801.4,    20061206, -0.3,  0.8,   4.5
1802.56,   20061207, 0.1,   0.6,   5.0
1773.8,    20061208, -1.6,  -0.9,  2.5
1786.21,   20061211, 0.7,   0.2,   2.7
1792.18,   20061212, 0.3,   -0.8,  4.5
1793.86,   20061213, 0.1,   -0.4,  3.5
1786.52,   20061214, -0.4,  -0.9,  2.8
1818.21,   20061215, 1.8,   2.5,   6.8
1812.93,   20061218, -0.3,  1.5,   5.8
1776.61,   20061219, -2.0,  -0.9,  3.8
1787.66,   20061220, 0.6,   -0.3,  3.2
1780.53,   20061221, -0.4,  -0.3,  2.8
1765.29,   20061222, -0.9,  -2.9,  0.3
1746.86,   20061226, -1.0,  -3.6,  0.2
1757.09,   20061227, 0.6,   -1.1,  0.3
1757.59,   20061228, 0.0,   -1.7,  -0.7
1759.15,   20061229, 0.1,   -1.2,  -1.6
1769.22,   20070103, 0.6,   0.2,   -1.6
1763.15,   20070104, -0.3,  0.9,   -1.6
1787.79,   20070105, 1.4,   1.7,   -0.4
1786.39,   20070108, -0.1,  1.6,   -1.2
1791.75,   20070109, 0.3,   1.9,   -1.3
1790.51,   20070110, -0.1,  1.2,   -0.8
1820.75,   20070111, 1.7,   3.3,   0.5
1832.15,   20070112, 0.6,   2.5,   3.5
1844.73,   20070116, 0.7,   3.3,   3.2
1835.05,   20070117, -0.5,  2.4,   2.4
1821.99,   20070118, -0.7,  1.8,   1.8
1787.82,   20070119, -1.9,  -1.8,  0.3
1800.33,   20070122, 0.7,   -1.7,  -0.3
1776.83,   20070123, -1.3,  -3.7,  -1.4
1785.42,   20070124, 0.5,   -2.7,  -1.0
1806.75,   20070125, 1.2,   -0.8,  1.9
1783.38,   20070126, -1.3,  -0.2,  -0.2
1771.05,   20070129, -0.7,  -1.6,  -1.2
1780.26,   20070130, 0.5,   0.2,   -0.8
1774.44,   20070131, -0.3,  -0.6,  -0.7
1801.6,    20070201, 1.5,   -0.3,  -0.9
1795.68,   20070202, -0.3,  0.7,   -1.0
1796.48,   20070205, 0.0,   1.4,   1.1
1799.33,   20070206, 0.2,   1.1,   0.7
1803.23,   20070207, 0.2,   1.6,   1.3
1807.41,   20070208, 0.2,   0.3,   2.4
1815.22,   20070209, 0.4,   1.1,   3.9
1787.11,   20070212, -1.5,  -0.5,  1.7
1782.16,   20070213, -0.3,  -1.0,  1.4
1792.74,   20070214, 0.6,   -0.6,  1.9
1815.49,   20070215, 1.3,   0.4,   2.6
1818.56,   20070216, 0.2,   0.2,   3.1
1817.84,   20070220, -0.0,  1.7,   1.7
1827.03,   20070221, 0.5,   2.5,   2.3
1845.47,   20070222, 1.0,   2.9,   3.0
1845.41,   20070223, -0.0,  1.6,   3.1
1848.48,   20070226, 0.2,   1.6,   1.5
1801.87,   20070227, -2.5,  -0.9,  -1.7
1754.88,   20070228, -2.6,  -3.9,  -4.9
1729.03,   20070301, -1.5,  -6.3,  -5.8
1740.87,   20070302, 0.7,   -5.7,  -4.5
1726.03,   20070305, -0.9,  -6.6,  -3.5
1730.39,   20070306, 0.3,   -4.0,  -3.9
1740.79,   20070307, 0.6,   -0.8,  -2.0
1753.6,    20070308, 0.7,   1.4,   -1.8
1759.54,   20070309, 0.3,   1.1,   -2.6
1742.98,   20070312, -0.9,  1.0,   -2.3
1747.32,   20070313, 0.2,   1.0,   -1.3
1724.65,   20070314, -1.3,  -0.9,  -3.1
1743.48,   20070315, 1.1,   -0.6,  -1.7
1743.58,   20070316, 0.0,   -0.9,  -3.2
1752.19,   20070319, 0.5,   0.5,   -2.4
1759.49,   20070320, 0.4,   0.7,   -2.1
1774.51,   20070321, 0.9,   2.9,   -1.4
1806.76,   20070322, 1.8,   3.6,   0.2
1800.18,   20070323, -0.4,  3.2,   -0.4
1796.01,   20070326, -0.2,  2.5,   -1.1
1798.14,   20070327, 0.1,   2.2,   0.6
1781.66,   20070328, -0.9,  0.4,   -0.0
1783.44,   20070329, 0.1,   -1.3,  -0.5
1772.86,   20070330, -0.6,  -1.5,  -2.3
1775.92,   20070402, 0.2,   -1.1,  -2.3
1782.5,    20070403, 0.4,   -0.9,  -1.9
1797.28,   20070404, 0.8,   0.9,   -1.6
1801.1,    20070405, 0.2,   1.0,   -2.4
1818.75,   20070409, 1.0,   2.6,   -1.4
1807.06,   20070410, -0.6,  1.8,   -2.2
1815.38,   20070411, 0.5,   1.8,   0.7
1795.15,   20070412, -1.1,  -0.1,  2.3
1811.66,   20070413, 0.9,   0.6,   4.8
1823.69,   20070416, 0.7,   0.3,   4.8
1835.67,   20070417, 0.7,   1.6,   6.4
1828.76,   20070418, -0.4,  0.7,   5.7
1823.2,    20070419, -0.3,  1.6,   4.7
1848.69,   20070420, 1.4,   2.0,   5.4
1848.49,   20070423, -0.0,  1.4,   5.1
1856.32,   20070424, 0.4,   1.1,   6.5
1865.77,   20070425, 0.5,   2.0,   6.8
1889.58,   20070426, 1.3,   3.6,   9.6
1886.32,   20070427, -0.2,  2.0,   8.2
1891.81,   20070430, 0.3,   2.3,   8.5
1872.34,   20070501, -1.0,  0.9,   6.9
1874.56,   20070502, 0.1,   0.5,   6.5
1892.6,    20070503, 1.0,   0.2,   6.7
1902.4,    20070504, 0.5,   0.9,   5.3
1896.1,    20070507, -0.3,  0.2,   5.3
1890.08,   20070508, -0.3,  0.9,   5.2
1891.2,    20070509, 0.1,   0.9,   5.2
1896.42,   20070510, 0.3,   0.2,   6.4
1881.54,   20070511, -0.8,  -1.1,  5.5
1902.1,    20070514, 1.1,   0.3,   7.3
1887.59,   20070515, -0.8,  -0.1,  6.3
1878.52,   20070516, -0.5,  -0.7,  5.4
1889.17,   20070517, 0.6,   -0.4,  5.1
1890.88,   20070518, 0.1,   0.5,   5.0
1899.6,    20070521, 0.5,   -0.1,  4.4
1913.51,   20070522, 0.7,   1.4,   5.9
1919.45,   20070523, 0.3,   2.2,   5.7
1904.19,   20070524, -0.8,  0.8,   6.1
1883.41,   20070525, -1.1,  -0.4,  4.0
1891.07,   20070529, 0.4,   -0.4,  3.7
1889.28,   20070530, -0.1,  -1.3,  2.9
1923.56,   20070531, 1.8,   0.2,   5.2
1934.74,   20070601, 0.6,   1.6,   6.1
1921.95,   20070604, -0.7,  2.0,   4.0
1928.65,   20070605, 0.3,   2.0,   4.3
1924.43,   20070606, -0.2,  1.9,   3.7
1908.66,   20070607, -0.8,  -0.8,  2.3
1884.66,   20070608, -1.3,  -2.6,  -0.3
1904.91,   20070611, 1.1,   -0.9,  1.0
1892.13,   20070612, -0.7,  -1.9,  0.0
1897.79,   20070613, 0.3,   -1.4,  1.4
1914.28,   20070614, 0.9,   0.3,   2.1
1943.91,   20070615, 1.5,   3.1,   2.7
1947.79,   20070618, 0.2,   2.3,   2.4
1939.07,   20070619, -0.4,  2.5,   2.3
1945.06,   20070620, 0.3,   2.5,   2.9
1922.32,   20070621, -1.2,  0.4,   1.6
1936.78,   20070622, 0.8,   -0.4,  2.1
1924.18,   20070625, -0.7,  -1.2,  2.3
1923.7,    20070626, -0.0,  -0.8,  1.1
1901.6,    20070627, -1.1,  -2.2,  0.7
1931.77,   20070628, 1.6,   0.5,   2.8
1944.5,    20070629, 0.7,   0.4,   2.9
1943.28,   20070702, -0.1,  1.0,   2.8
1957.48,   20070703, 0.7,   1.8,   3.0
1967.74,   20070705, 0.5,   3.5,   2.8
1980.78,   20070706, 0.7,   2.5,   3.2
1989.76,   20070709, 0.5,   2.3,   4.5
1979.13,   20070710, -0.5,  1.8,   5.1
1968.98,   20070711, -0.5,  0.6,   4.1
1992.57,   20070712, 1.2,   1.3,   5.5
2018.84,   20070713, 1.3,   1.9,   5.0
2030.34,   20070716, 0.6,   2.0,   4.9
2031.33,   20070717, 0.0,   2.6,   5.7
2030.54,   20070718, -0.0,  3.1,   5.3
2052.31,   20070719, 1.1,   3.0,   6.6
2047.14,   20070720, -0.3,  1.4,   7.3
2043.82,   20070723, -0.2,  0.7,   8.4
2021.52,   20070724, -1.1,  -0.5,  6.1
2017.56,   20070725, -0.2,  -0.6,  6.6
1996.93,   20070726, -1.0,  -2.7,  5.2
1988.85,   20070727, -0.4,  -2.8,  3.9
1961.78,   20070730, -1.4,  -4.0,  0.9
1992.53,   20070731, 1.6,   -1.4,  2.3
1929.09,   20070801, -3.2,  -4.4,  -0.5
1950.95,   20070802, 1.1,   -2.3,  0.3
1963.49,   20070803, 0.6,   -1.3,  2.1
1931.38,   20070806, -1.6,  -1.5,  -0.3
1943.39,   20070807, 0.6,   -2.5,  1.0
1974.33,   20070808, 1.6,   2.3,   2.6
1959.97,   20070809, -0.7,  0.5,   3.1
1918.99,   20070810, -2.1,  -2.3,  -0.7
1941.41,   20070813, 1.2,   0.5,   -0.2
1938.79,   20070814, -0.1,  -0.2,  -0.2
1897.05,   20070815, -2.2,  -3.9,  -3.1
1850.17,   20070816, -2.5,  -5.6,  -6.0
1883.15,   20070817, 1.8,   -1.9,  -4.9
1891.13,   20070820, 0.4,   -2.6,  -5.0
1887.83,   20070821, -0.2,  -2.6,  -4.6
1927.31,   20070822, 2.1,   1.6,   -2.1
1942.12,   20070823, 0.8,   5.0,   -2.5
1928.8,    20070824, -0.7,  2.4,   -4.5
1954.88,   20070827, 1.4,   3.4,   -3.7
1934.93,   20070828, -1.0,  2.5,   -4.7
1914.25,   20070829, -1.1,  -0.7,  -5.7
1942.79,   20070830, 1.5,   0.0,   -5.3
1987.94,   20070831, 2.3,   3.1,   -2.9
1989.28,   20070904, 0.1,   1.8,   -2.7
2013.66,   20070905, 1.2,   4.1,   -0.4
2001.76,   20070906, -0.6,  4.6,   -0.8
1972.12,   20070907, -1.5,  1.5,   -1.2
1975.67,   20070910, 0.2,   -0.6,  -0.7
1973.16,   20070911, -0.1,  -0.8,  0.6
1986.21,   20070912, 0.7,   -1.4,  -0.3
2004.73,   20070913, 0.9,   0.1,   3.9
1984.24,   20070914, -1.0,  0.6,   1.7
1993.5,    20070917, 0.5,   0.9,   1.5
1993.85,   20070918, 0.0,   1.0,   3.2
2047.46,   20070919, 2.7,   3.1,   5.4
2035.87,   20070920, -0.6,  1.6,   3.1
2042.64,   20070921, 0.3,   2.9,   4.2
2054.77,   20070924, 0.6,   3.1,   7.1
2049.78,   20070925, -0.2,  2.8,   5.6
2088.12,   20070926, 1.9,   2.0,   7.7
2098.74,   20070927, 0.5,   3.1,   10.6
2096.37,   20070928, -0.1,  2.6,   13.3
2092.6,    20071001, -0.2,  1.8,   11.1
2116.15,   20071002, 1.1,   3.2,   11.9
2108.04,   20071003, -0.4,  1.0,   11.7
2107.11,   20071004, -0.0,  0.4,   9.3
2123.49,   20071005, 0.8,   1.3,   9.3
2151.11,   20071008, 1.3,   2.8,   11.5
2168.53,   20071009, 0.8,   2.5,   10.9
2172.67,   20071010, 0.2,   3.1,   12.3
2188.72,   20071011, 0.7,   3.9,   14.3
2151.94,   20071012, -1.7,  1.3,   10.8
2182.06,   20071015, 1.4,   1.4,   9.8
2147.88,   20071016, -1.6,  -1.0,  8.0
2182.7,    20071017, 1.6,   0.5,   8.4
2168.24,   20071018, -0.7,  -0.9,  8.3
2188.75,   20071019, 0.9,   1.7,   11.0
2121.01,   20071022, -3.1,  -2.8,  7.4
2181.79,   20071023, 2.9,   1.6,   10.6
2187.03,   20071024, 0.2,   0.2,   10.1
2190.51,   20071025, 0.2,   1.0,   9.3
2200.69,   20071026, 0.5,   0.5,   10.9
2202.96,   20071029, 0.1,   3.9,   10.5
2197.14,   20071030, -0.3,  0.7,   10.2
2214.14,   20071031, 0.8,   1.2,   8.1
2223.52,   20071101, 0.4,   1.5,   9.2
2211.39,   20071102, -0.5,  0.5,   8.3
2193.06,   20071105, -0.8,  -0.4,  6.7
2212.2,    20071106, 0.9,   0.7,   7.9
2205.29,   20071107, -0.3,  -0.4,  5.6
2165.57,   20071108, -1.8,  -2.6,  3.2
2061.52,   20071109, -4.8,  -6.8,  -1.7
2028.91,   20071112, -1.6,  -7.5,  -3.0
2009.74,   20071113, -0.9,  -9.2,  -5.0
2090.67,   20071114, 4.0,   -5.2,  -0.8
2035.46,   20071115, -2.6,  -6.0,  -3.4
2038.27,   20071116, 0.1,   -1.1,  -4.0
2040.17,   20071119, 0.1,   0.6,   -5.2
2030.94,   20071120, -0.5,  1.1,   -6.3
2008.88,   20071121, -1.1,  -3.9,  -7.5
2019.4,    20071123, 0.5,   -0.8,  -7.7
2033.24,   20071126, 0.7,   -0.2,  -5.5
2007.35,   20071127, -1.3,  -1.6,  -8.0
2055.77,   20071128, 2.4,   1.2,   -4.3
2084.57,   20071129, 1.4,   3.8,   -4.5
2122.64,   20071130, 1.8,   5.1,   -2.1
2080.1,    20071203, -2.0,  2.3,   -5.0
2053.17,   20071204, -1.3,  2.3,   -3.2
2083.19,   20071205, 1.5,   1.3,   -4.5
2099.7,    20071206, 0.8,   0.7,   -4.0
2127.54,   20071207, 1.3,   0.2,   -2.9
2131.67,   20071210, 0.2,   2.5,   -3.1
2137.82,   20071211, 0.3,   4.1,   -3.0
2125.14,   20071212, -0.6,  2.0,   -3.3
2083.08,   20071213, -2.0,  -0.8,  -5.9
2078.4,    20071214, -0.2,  -2.3,  -6.5
2064.45,   20071217, -0.7,  -3.2,  -6.6
2041.66,   20071218, -1.1,  -4.5,  -6.9
2027.9,    20071219, -0.7,  -4.6,  -8.3
2056.12,   20071220, 1.4,   -1.3,  -6.8
2100.71,   20071221, 2.2,   1.1,   -3.0
2113.12,   20071224, 0.6,   2.4,   2.5
2121.41,   20071226, 0.4,   3.9,   4.6
2129.01,   20071227, 0.4,   5.0,   5.9
2118.63,   20071228, -0.5,  3.0,   1.3
2097.91,   20071231, -1.0,  -0.1,  3.1
2085.53,   20080102, -0.6,  -1.3,  2.3
2050.64,   20080103, -1.7,  -3.3,  0.5
2025.36,   20080104, -1.2,  -4.9,  -0.3
1971.28,   20080107, -2.7,  -7.0,  -1.9
1965.42,   20080108, -0.3,  -6.3,  -2.7
1915.23,   20080109, -2.6,  -8.2,  -5.8
1930.91,   20080110, 0.8,   -5.8,  -3.8
1940.98,   20080111, 0.5,   -4.2,  -5.6
1940.33,   20080114, -0.0,  -1.6,  -6.9
1925.95,   20080115, -0.7,  -2.0,  -9.3
1876.45,   20080116, -2.6,  -2.0,  -9.8
1882.36,   20080117, 0.3,   -2.5,  -8.3
1862.22,   20080118, -1.1,  -4.1,  -10.6
1740.53,   20080122, -6.5,  -10.3, -17.1
1725.46,   20080123, -0.9,  -10.4, -18.9
1800.84,   20080124, 4.4,   -4.0,  -15.5
1860.92,   20080125, 3.3,   -1.1,  -13.0
1788.14,   20080128, -3.9,  -4.0,  -15.9
1812.43,   20080129, 1.4,   4.1,   -13.0
1800.36,   20080130, -0.7,  4.3,   -13.4
1782.33,   20080131, -1.0,  -1.0,  -13.7
1846.07,   20080201, 3.6,   -0.8,  -9.6
1854.62,   20080204, 0.5,   3.7,   -8.5
1798.65,   20080205, -3.0,  -0.8,  -12.5
1786.96,   20080206, -0.6,  -0.7,  -14.9
1723.21,   20080207, -3.6,  -3.3,  -18.5
1754.37,   20080208, 1.8,   -5.0,  -17.3
1780.36,   20080211, 1.5,   -4.0,  -16.4
1802.65,   20080212, 1.3,   0.2,   -14.9
1803.29,   20080213, 0.0,   0.9,   -14.0
1823.1,    20080214, 1.1,   5.8,   -12.6
1776.1,    20080215, -2.6,  1.2,   -13.4
1802.17,   20080219, 1.5,   1.2,   -11.0
1756.64,   20080220, -2.5,  -2.6,  -10.9
1802.02,   20080221, 2.6,   -0.1,  -8.3
1772.88,   20080222, -1.6,  -2.8,  -7.4
1773.05,   20080225, 0.0,   -0.2,  -8.2
1773.5,    20080226, 0.0,   -1.6,  -8.6
1778.62,   20080227, 0.3,   1.3,   -8.3
1794.11,   20080228, 0.9,   -0.4,  -6.8
1776.01,   20080229, -1.0,  0.2,   -5.4
1743.92,   20080303, -1.8,  -1.6,  -7.4
1724.33,   20080304, -1.1,  -2.8,  -7.4
1746.4,    20080305, 1.3,   -1.8,  0.3
1749.42,   20080306, 0.2,   -2.5,  1.4
1701.34,   20080307, -2.7,  -4.2,  -5.5
1705.55,   20080310, 0.2,   -2.2,  -8.3
1704.05,   20080311, -0.1,  -1.2,  -4.7
1746.19,   20080312, 2.5,   -0.0,  -3.7
1715.84,   20080313, -1.7,  -1.9,  -4.7
1757.47,   20080314, 2.4,   3.3,   -1.4
1680.54,   20080317, -4.4,  -1.5,  -9.0
1717.04,   20080318, 2.2,   0.8,   -7.4
1765.1,    20080319, 2.8,   1.1,   -1.9
1719.7,    20080320, -2.6,  0.2,   -3.8
1760.14,   20080324, 2.4,   0.2,   2.1
1816.33,   20080325, 3.2,   8.1,   3.5
1815.83,   20080326, -0.0,  5.8,   2.0
1807.14,   20080327, -0.5,  2.4,   0.2
1788.56,   20080328, -1.0,  4.0,   -0.8
1772.96,   20080331, -0.9,  0.7,   -2.8
1806.15,   20080401, 1.9,   -0.6,  1.7
1855.91,   20080402, 2.8,   2.2,   3.0
1842.69,   20080403, -0.7,  2.0,   4.9
1859.93,   20080404, 0.9,   4.0,   3.2
1878.3,    20080407, 1.0,   5.9,   5.9
1853.37,   20080408, -1.3,  2.6,   4.5
1848.36,   20080409, -0.3,  -0.4,  4.2
1827.0,    20080410, -1.2,  -0.9,  2.7
1832.05,   20080411, 0.3,   -1.5,  2.1
1796.77,   20080414, -1.9,  -4.3,  1.2
1799.48,   20080415, 0.2,   -2.9,  3.2
1817.59,   20080416, 1.0,   -1.7,  5.4
1846.8,    20080417, 1.6,   1.1,   5.7
1889.2,    20080418, 2.3,   3.1,   8.0
1892.97,   20080421, 0.2,   5.4,   11.3
1901.27,   20080422, 0.4,   5.7,   11.5
1897.63,   20080423, -0.2,  4.4,   11.4
1908.13,   20080424, 0.6,   3.3,   9.3
1922.88,   20080425, 0.8,   1.8,   12.1
1920.53,   20080428, -0.1,  1.5,   9.3
1917.83,   20080429, -0.1,  0.9,   14.1
1939.12,   20080430, 1.1,   2.2,   12.9
1921.87,   20080501, -0.9,  0.7,   8.9
1996.31,   20080502, 3.9,   3.8,   16.1
1980.84,   20080505, -0.8,  3.1,   12.5
1968.61,   20080506, -0.6,  2.6,   8.4
1987.44,   20080507, 1.0,   2.5,   9.5
1960.71,   20080508, -1.3,  2.0,   8.5
1950.63,   20080509, -0.5,  -2.3,  9.1
1969.5,    20080512, 1.0,   -0.6,  11.1
1997.62,   20080513, 1.4,   1.5,   10.6
2008.54,   20080514, 0.5,   1.1,   8.2
1995.41,   20080515, -0.7,  1.8,   8.3
2033.95,   20080516, 1.9,   4.3,   9.4
2031.82,   20080519, -0.1,  3.2,   8.2
2008.5,    20080520, -1.1,  0.5,   8.4
2005.29,   20080521, -0.2,  -0.2,  8.5
1962.56,   20080522, -2.1,  -1.6,  7.4
1959.67,   20080523, -0.1,  -3.7,  7.0
1965.0,    20080527, 0.3,   -3.3,  9.4
2002.77,   20080528, 1.9,   -0.3,  11.3
2001.8,    20080529, -0.0,  -0.2,  10.1
2028.25,   20080530, 1.3,   3.3,   9.8
2026.58,   20080602, -0.1,  3.4,   7.3
2015.03,   20080603, -0.6,  2.5,   6.4
1991.89,   20080604, -1.1,  -0.5,  4.8
2027.17,   20080605, 1.8,   1.3,   6.8
2036.15,   20080606, 0.4,   0.4,   6.7
1995.37,   20080609, -2.0,  -1.5,  3.8
1960.41,   20080610, -1.8,  -2.7,  2.1
1968.87,   20080611, 0.4,   -1.2,  2.7
1941.68,   20080612, -1.4,  -4.2,  0.1
1938.34,   20080613, -0.2,  -4.8,  0.9
1954.04,   20080616, 0.8,   -2.1,  -2.1
1990.02,   20080617, 1.8,   1.5,   0.5
1962.78,   20080618, -1.4,  -0.3,  -0.3
1947.64,   20080619, -0.8,  0.3,   -2.0
1962.33,   20080620, 0.8,   1.2,   0.1
1936.71,   20080623, -1.3,  -0.9,  -0.7
1906.84,   20080624, -1.5,  -4.2,  -3.2
1911.07,   20080625, 0.2,   -2.6,  -4.3
1898.78,   20080626, -0.6,  -2.5,  -5.5
1854.39,   20080627, -2.3,  -5.5,  -7.1
1852.83,   20080630, -0.1,  -4.3,  -8.9
1821.93,   20080701, -1.7,  -4.5,  -10.3
1868.8,    20080702, 2.6,   -2.2,  -7.0
1828.75,   20080703, -2.1,  -3.7,  -8.8
1834.28,   20080707, 0.3,   -1.1,  -6.5
1829.55,   20080708, -0.3,  -1.3,  -6.6
1866.81,   20080709, 2.0,   2.5,   -5.0
1826.63,   20080710, -2.2,  -2.3,  -8.8
1820.23,   20080711, -0.4,  -0.5,  -9.1
1834.86,   20080714, 0.8,   0.0,   -9.5
1786.9,    20080715, -2.6,  -2.3,  -11.8
1799.6,    20080716, 0.7,   -3.6,  -10.7
1851.0,    20080717, 2.9,   1.3,   -7.1
1827.34,   20080718, -1.3,  0.4,   -9.9
1831.86,   20080721, 0.2,   -0.2,  -10.0
1787.5,    20080722, -2.4,  0.0,   -10.4
1823.37,   20080723, 2.0,   1.3,   -7.0
1854.97,   20080724, 1.7,   0.2,   -5.8
1829.93,   20080725, -1.3,  0.1,   -5.8
1841.32,   20080728, 0.6,   0.5,   -5.0
1813.3,    20080729, -1.5,  1.4,   -7.2
1850.29,   20080730, 2.0,   1.5,   -7.0
1835.35,   20080731, -0.8,  -1.1,  -6.5
1847.47,   20080801, 0.7,   1.0,   -5.1
1825.68,   20080804, -1.2,  -0.8,  -7.0
1825.87,   20080805, 0.0,   0.7,   -5.7
1866.86,   20080806, 2.2,   0.9,   -2.1
1882.97,   20080807, 0.9,   2.6,   -1.5
1880.41,   20080808, -0.1,  1.8,   -1.0
1921.76,   20080811, 2.2,   5.3,   3.6
1938.99,   20080812, 0.9,   6.2,   4.7
1938.06,   20080813, -0.0,  3.8,   6.4
1930.81,   20080814, -0.4,  2.5,   3.3
1967.49,   20080815, 1.9,   4.6,   7.6
1962.17,   20080818, -0.3,  2.1,   7.0
1924.61,   20080819, -1.9,  -0.7,  5.2
1921.75,   20080820, -0.1,  -0.8,  2.9
1900.11,   20080821, -1.1,  -1.6,  4.0
1915.77,   20080822, 0.8,   -2.6,  5.2
1918.45,   20080825, 0.1,   -2.2,  4.6
1889.87,   20080826, -1.5,  -1.8,  5.8
1887.08,   20080827, -0.1,  -1.8,  4.9
1906.09,   20080828, 1.0,   0.3,   3.0
1895.78,   20080829, -0.5,  -1.0,  3.7
1904.21,   20080902, 0.4,   -0.7,  3.9
1847.54,   20080903, -3.0,  -2.2,  3.4
1819.51,   20080904, -1.5,  -3.6,  -0.2
1758.52,   20080905, -3.4,  -7.7,  -5.2
1794.08,   20080908, 2.0,   -5.4,  -2.0
1765.03,   20080909, -1.6,  -7.3,  -4.1
1739.7,    20080910, -1.4,  -5.8,  -4.1
1714.76,   20080911, -1.4,  -5.8,  -7.3
1756.14,   20080912, 2.4,   -0.1,  -4.3
1721.78,   20080915, -2.0,  -4.0,  -6.8
1686.73,   20080916, -2.0,  -4.4,  -7.6
1701.8,    20080917, 0.9,   -2.2,  -6.8
1668.33,   20080918, -2.0,  -2.7,  -10.6
1760.3,    20080919, 5.5,   0.2,   -6.5
1742.52,   20080922, -1.0,  1.2,   -7.3
1678.94,   20080923, -3.6,  -0.5,  -12.6
1657.6,    20080924, -1.3,  -2.6,  -14.5
1675.45,   20080925, 1.1,   0.4,   -13.6
1642.65,   20080926, -2.0,  -6.7,  -14.9
1638.38,   20080929, -0.3,  -6.0,  -16.7
1540.51,   20080930, -6.0,  -8.2,  -21.5
1581.06,   20081001, 2.6,   -4.6,  -17.9
1547.13,   20081002, -2.1,  -7.7,  -19.5
1516.05,   20081003, -2.0,  -7.7,  -20.2
1431.01,   20081006, -5.6,  -12.7, -25.3
1416.28,   20081007, -1.0,  -8.1,  -26.2
1298.63,   20081008, -8.3,  -17.9, -31.3
1349.55,   20081009, 3.9,   -12.8, -28.5
1234.9,    20081010, -8.5,  -18.5, -35.2
1338.6,    20081013, 8.4,   -6.5,  -29.4
1470.85,   20081014, 9.9,   3.9,   -22.8
1351.38,   20081015, -8.1,  4.1,   -26.9
1256.21,   20081016, -7.0,  -6.9,  -31.0
1291.58,   20081017, 2.8,   4.6,   -26.6
1329.68,   20081020, 2.9,   -0.7,  -25.9
1329.68,   20081021, 0.0,   -9.6,  -24.7
1272.87,   20081022, -4.3,  -5.8,  -26.8
1240.18,   20081023, -2.6,  -1.3,  -27.7
1149.12,   20081024, -7.3,  -11.0, -34.6
1182.62,   20081027, 2.9,   -11.1, -31.3
1210.03,   20081028, 2.3,   -9.0,  -28.3
1292.29,   20081029, 6.8,   1.5,   -24.1
1333.27,   20081030, 3.2,   7.5,   -20.1
1318.39,   20081031, -1.1,  14.7,  -25.1
1333.19,   20081103, 1.1,   12.7,  -23.5
1363.3,    20081104, 2.3,   12.7,  -18.8
1359.82,   20081105, -0.3,  5.2,   -18.0
1278.05,   20081106, -6.0,  -4.1,  -23.7
1258.43,   20081107, -1.5,  -4.5,  -23.4
1297.97,   20081110, 3.1,   -2.6,  -20.8
1236.45,   20081111, -4.7,  -9.3,  -19.7
1204.16,   20081112, -2.6,  -11.4, -23.8
1167.83,   20081113, -3.0,  -8.6,  -24.5
1209.6,    20081114, 3.6,   -3.9,  -20.2
1157.54,   20081117, -4.3,  -10.8, -19.1
1158.08,   20081118, 0.0,   -6.3,  -18.2
1154.16,   20081119, -0.3,  -4.2,  -11.1
1076.5,    20081120, -6.7,  -7.8,  -20.2
1059.69,   20081121, -1.6,  -12.4, -14.2
1106.02,   20081124, 4.4,   -4.5,  -17.4
1164.36,   20081125, 5.3,   0.5,   -20.8
1126.95,   20081126, -3.2,  -2.4,  -16.6
1181.43,   20081128, 4.8,   9.7,   -6.0
1185.75,   20081201, 0.4,   11.9,  -8.2
1109.99,   20081202, -6.4,  0.4,   -16.5
1103.94,   20081203, -0.5,  -5.2,  -17.0
1144.3,    20081204, 3.7,   1.5,   -10.1
1113.45,   20081205, -2.7,  -5.8,  -10.2
1202.78,   20081208, 8.0,   1.4,   4.7
1204.72,   20081209, 0.2,   8.5,   1.9
1223.48,   20081210, 1.6,   10.8,  1.1
1210.84,   20081211, -1.0,  5.8,   -6.3
1161.59,   20081212, -4.1,  4.3,   -12.9
1206.88,   20081215, 3.9,   0.3,   -8.5
1194.82,   20081216, -1.0,  -0.8,  -10.4
1225.68,   20081217, 2.6,   0.2,   -10.1
1227.31,   20081218, 0.1,   1.4,   -9.7
1219.65,   20081219, -0.6,  5.0,   -4.6
1212.31,   20081222, -0.6,  0.4,   -3.7
1194.64,   20081223, -1.5,  -0.0,  -8.0
1186.62,   20081224, -0.7,  -3.2,  -4.0
1189.57,   20081226, 0.2,   -3.1,  -1.2
1185.96,   20081229, -0.3,  -2.8,  1.6
1182.15,   20081230, -0.3,  -2.5,  -2.3
1202.26,   20081231, 1.7,   0.6,   3.9
1212.74,   20090102, 0.9,   2.2,   4.7
1254.7,    20090105, 3.5,   5.5,   8.7
1274.36,   20090106, 1.6,   7.5,   18.4
1249.98,   20090107, -1.9,  5.7,   18.0
1231.75,   20090108, -1.5,  2.5,   11.4
1252.6,    20090109, 1.7,   3.3,   7.6
1225.12,   20090112, -2.2,  -2.4,  8.7
1200.92,   20090113, -2.0,  -5.8,  1.6
1183.79,   20090114, -1.4,  -5.3,  -0.2
1159.94,   20090115, -2.0,  -5.8,  4.5
1199.2,    20090116, 3.4,   -4.3,  8.6
1193.98,   20090120, -0.4,  -2.5,  4.3
1156.65,   20090121, -3.1,  -3.7,  3.9
1161.95,   20090122, 0.5,   -1.8,  -3.4
1148.61,   20090123, -1.1,  -1.0,  -4.7
1177.44,   20090126, 2.5,   -1.8,  -3.8
1185.81,   20090127, 0.7,   -0.7,  -2.1
1213.84,   20090128, 2.4,   4.9,   4.5
1217.75,   20090129, 0.3,   4.8,   0.9
1212.31,   20090130, -0.4,  5.5,   1.5
1168.13,   20090202, -3.6,  -0.8,  -4.7
1198.79,   20090203, 2.6,   1.1,   -2.3
1215.64,   20090204, 1.4,   0.1,   -0.3
1200.26,   20090205, -1.3,  -1.4,  -1.0
1244.43,   20090206, 3.7,   2.6,   4.2
1276.72,   20090209, 2.6,   9.3,   7.6
1269.48,   20090210, -0.6,  5.9,   6.7
1231.62,   20090211, -3.0,  1.3,   3.9
1212.02,   20090212, -1.6,  1.0,   2.5
1240.81,   20090213, 2.4,   -0.3,  3.2
1197.59,   20090217, -3.5,  -6.2,  -1.2
1193.89,   20090218, -0.3,  -6.0,  -4.8
1194.31,   20090219, 0.0,   -3.0,  -6.3
1157.14,   20090220, -3.1,  -4.5,  -7.4
1180.1,    20090223, 2.0,   -4.9,  -4.2
1138.71,   20090224, -3.5,  -4.9,  -9.1
1158.91,   20090225, 1.8,   -2.9,  -5.4
1168.12,   20090226, 0.8,   -2.2,  -2.7
1116.78,   20090227, -4.4,  -3.5,  -5.7
1098.99,   20090302, -1.6,  -6.9,  -5.3
1092.42,   20090303, -0.6,  -4.1,  -8.9
1096.2,    20090304, 0.3,   -5.4,  -8.2
1094.08,   20090305, -0.2,  -6.3,  -5.4
1082.53,   20090306, -1.1,  -3.1,  -6.8
1058.85,   20090309, -2.2,  -3.7,  -7.8
1060.42,   20090310, 0.1,   -2.9,  -9.9
1118.35,   20090311, 5.5,   2.0,   -5.7
1121.91,   20090312, 0.3,   2.5,   -7.6
1162.88,   20090313, 3.7,   7.4,   -4.5
1177.33,   20090316, 1.2,   11.2,  -2.9
1151.83,   20090317, -2.2,  8.6,   -1.4
1185.18,   20090318, 2.9,   6.0,   -1.1
1220.48,   20090319, 3.0,   8.8,   0.4
1207.48,   20090320, -1.1,  3.8,   0.6
1213.12,   20090323, 0.5,   3.0,   -2.5
1245.65,   20090324, 2.7,   8.1,   -2.4
1243.44,   20090325, -0.2,  4.9,   -2.1
1254.09,   20090326, 0.9,   2.8,   1.8
1263.62,   20090327, 0.8,   4.6,   4.3
1230.89,   20090330, -2.6,  1.5,   -0.8
1232.59,   20090331, 0.1,   -1.0,  2.9
1215.9,    20090401, -1.4,  -2.2,  1.8
1274.41,   20090402, 4.8,   1.6,   6.7
1306.5,    20090403, 2.5,   3.4,   12.9
1301.43,   20090406, -0.4,  5.7,   10.3
1296.2,    20090407, -0.4,  5.2,   13.8
1289.7,    20090408, -0.5,  6.1,   11.3
1322.23,   20090409, 2.5,   3.8,   13.2
1333.72,   20090413, 0.9,   2.1,   19.4
1325.73,   20090414, -0.6,  1.9,   20.6
1311.15,   20090415, -1.1,  1.2,   20.0
1334.02,   20090416, 1.7,   3.4,   21.7
1347.37,   20090417, 1.0,   1.9,   23.2
1330.18,   20090420, -1.3,  -0.3,  22.9
1303.87,   20090421, -2.0,  -1.6,  23.1
1322.1,    20090422, 1.4,   0.8,   24.7
1340.77,   20090423, 1.4,   0.5,   19.9
1352.55,   20090424, 0.9,   0.4,   20.6
1359.88,   20090427, 0.5,   2.2,   16.9
1362.94,   20090428, 0.2,   4.5,   15.8
1373.3,    20090429, 0.8,   3.9,   19.2
1398.44,   20090430, 1.8,   4.3,   18.0
1396.22,   20090501, -0.2,  3.2,   14.4
1407.65,   20090504, 0.8,   3.5,   16.6
1423.88,   20090505, 1.2,   4.5,   17.4
1433.58,   20090506, 0.7,   4.4,   15.1
1429.97,   20090507, -0.3,  2.3,   15.0
1399.21,   20090508, -2.2,  0.2,   11.6
1375.05,   20090511, -1.7,  -2.3,  8.8
1402.14,   20090512, 2.0,   -1.5,  13.9
1364.43,   20090513, -2.7,  -4.8,  10.7
1348.33,   20090514, -1.2,  -5.7,  10.9
1355.55,   20090515, 0.5,   -3.1,  6.4
1367.05,   20090518, 0.8,   -0.6,  4.6
1387.2,    20090519, 1.5,   -1.1,  6.6
1401.37,   20090520, 1.0,   2.7,   8.1
1378.83,   20090521, -1.6,  2.3,   6.9
1371.76,   20090522, -0.5,  1.2,   3.7
1351.13,   20090526, -1.5,  -1.2,  1.3
1408.59,   20090527, 4.3,   1.5,   6.3
1411.42,   20090528, 0.2,   0.7,   7.6
1421.11,   20090529, 0.7,   3.1,   6.5
1451.37,   20090601, 2.1,   5.8,   7.7
1470.3,    20090602, 1.3,   8.8,   10.5
1472.25,   20090603, 0.1,   4.5,   12.9
1476.28,   20090604, 0.3,   4.6,   11.7
1503.78,   20090605, 1.9,   5.8,   12.2
1482.21,   20090608, -1.4,  2.1,   9.6
1495.04,   20090609, 0.9,   1.7,   9.9
1509.43,   20090610, 1.0,   2.5,   10.7
1494.07,   20090611, -1.0,  1.2,   8.8
1488.73,   20090612, -0.4,  -1.0,  6.5
1472.77,   20090615, -1.1,  -0.6,  5.5
1463.46,   20090616, -0.6,  -2.1,  4.0
1449.0,    20090617, -1.0,  -4.0,  1.8
1456.14,   20090618, 0.5,   -2.5,  1.6
1465.88,   20090619, 0.7,   -1.5,  2.5
1455.78,   20090622, -0.7,  -1.2,  4.0
1427.68,   20090623, -1.9,  -2.4,  3.8
1436.59,   20090624, 0.6,   -0.9,  2.5
1438.75,   20090625, 0.2,   -1.2,  5.4
1467.38,   20090626, 2.0,   0.1,   8.8
1483.66,   20090629, 1.1,   1.9,   9.5
1484.82,   20090630, 0.1,   4.0,   8.6
1486.5,    20090701, 0.1,   3.5,   7.2
1464.66,   20090702, -1.5,  1.8,   4.5
1435.4,    20090706, -2.0,  -2.2,  4.1
1440.83,   20090707, 0.4,   -2.9,  5.0
1412.54,   20090708, -2.0,  -4.9,  4.5
1417.0,    20090709, 0.3,   -4.7,  0.6
1409.55,   20090710, -0.5,  -3.8,  -0.1
1423.2,    20090713, 1.0,   -0.8,  0.1
1444.59,   20090714, 1.5,   0.3,   -0.5
1473.59,   20090715, 2.0,   4.3,   0.2
1495.4,    20090716, 1.5,   5.5,   1.6
1516.77,   20090717, 1.4,   7.6,   2.7
1534.39,   20090720, 1.2,   7.8,   2.0
1548.9,    20090721, 0.9,   7.2,   4.5
1552.63,   20090722, 0.2,   5.4,   3.9
1561.6,    20090723, 0.6,   4.4,   3.5
1579.25,   20090724, 1.1,   4.1,   5.7
1597.02,   20090727, 1.1,   4.1,   7.3
1590.77,   20090728, -0.4,  2.7,   8.0
1596.81,   20090729, 0.4,   2.8,   9.1
1614.11,   20090730, 1.1,   3.4,   11.4
1608.26,   20090731, -0.4,  1.8,   10.4
1619.24,   20090803, 0.7,   1.4,   10.5
1619.09,   20090804, -0.0,  1.8,   11.2
1631.75,   20090805, 0.8,   2.2,   14.3
1618.01,   20090806, -0.8,  0.2,   12.6
1620.74,   20090807, 0.2,   0.8,   12.6
1612.73,   20090810, -0.5,  -0.4,  9.9
1603.68,   20090811, -0.6,  -1.0,  8.1
1595.26,   20090812, -0.5,  -2.2,  7.4
1628.37,   20090813, 2.1,   0.6,   9.5
1623.49,   20090814, -0.3,  0.2,   10.8
1583.29,   20090817, -2.5,  -1.8,  10.3
1573.32,   20090818, -0.6,  -1.9,  9.2
1568.41,   20090819, -0.3,  -1.7,  11.0
1594.58,   20090820, 1.7,   -2.1,  12.5
1620.47,   20090821, 1.6,   -0.2,  15.0
1641.45,   20090824, 1.3,   3.7,   15.3
1640.42,   20090825, -0.1,  4.3,   13.6
1636.82,   20090826, -0.2,  4.4,   11.1
1636.13,   20090827, -0.0,  2.6,   9.4
1661.49,   20090828, 1.5,   2.5,   9.5
1628.21,   20090831, -2.0,  -0.8,  6.1
1620.09,   20090901, -0.5,  -1.2,  4.6
1588.09,   20090902, -2.0,  -3.0,  2.3
1601.16,   20090903, 0.8,   -2.1,  2.5
1609.63,   20090904, 0.5,   -3.1,  1.9
1651.64,   20090908, 2.6,   1.4,   3.4
1655.92,   20090909, 0.3,   2.2,   4.1
1668.64,   20090910, 0.8,   5.1,   4.5
1684.84,   20090911, 1.0,   5.2,   4.4
1673.55,   20090914, -0.7,  4.0,   4.1
1691.28,   20090915, 1.1,   2.4,   4.4
1705.97,   20090916, 0.9,   3.0,   5.4
1721.58,   20090917, 0.9,   3.2,   5.5
1728.91,   20090918, 0.4,   2.6,   6.9
1717.37,   20090921, -0.7,  2.6,   6.0
1740.13,   20090922, 1.3,   2.9,   7.9
1739.93,   20090923, -0.0,  2.0,   8.5
1732.95,   20090924, -0.4,  0.7,   8.6
1696.99,   20090925, -2.1,  -1.8,  4.2
1702.95,   20090928, 0.4,   -0.8,  4.9
1724.2,    20090929, 1.2,   -0.9,  8.9
1723.91,   20090930, -0.0,  -0.9,  9.6
1710.5,    20091001, -0.8,  -1.3,  9.1
1656.77,   20091002, -3.1,  -2.4,  3.9
1668.7,    20091005, 0.7,   -2.0,  3.0
1684.75,   20091006, 1.0,   -2.3,  2.6
1701.4,    20091007, 1.0,   -1.3,  3.7
1720.91,   20091008, 1.1,   0.6,   5.1
1713.47,   20091009, -0.4,  3.4,   4.7
1732.75,   20091012, 1.1,   3.8,   4.3
1729.16,   20091013, -0.2,  2.6,   6.2
1750.26,   20091014, 1.2,   2.9,   8.0
1747.81,   20091015, -0.1,  1.6,   10.1
1747.47,   20091016, -0.0,  2.0,   9.1
1741.43,   20091019, -0.3,  0.5,   8.2
1766.05,   20091020, 1.4,   2.1,   6.9
1754.71,   20091021, -0.6,  0.3,   6.0
1749.3,    20091022, -0.3,  0.1,   4.8
1774.05,   20091023, 1.4,   1.5,   5.3
1756.5,    20091026, -1.0,  0.9,   5.0
1746.99,   20091027, -0.5,  -1.1,  3.3
1712.8,    20091028, -2.0,  -2.4,  0.4
1694.27,   20091029, -1.1,  -3.1,  -1.6
1707.92,   20091030, 0.8,   -3.7,  -1.2
1667.06,   20091102, -2.4,  -5.1,  -2.9
1661.85,   20091103, -0.3,  -4.9,  -4.5
1687.12,   20091104, 1.5,   -1.5,  -3.0
1700.07,   20091105, 0.8,   0.3,   -1.9
1709.82,   20091106, 0.6,   0.1,   0.8
1743.55,   20091109, 2.0,   4.6,   2.4
1765.52,   20091110, 1.3,   6.2,   2.4
1784.9,    20091111, 1.1,   5.8,   3.5
1784.69,   20091112, -0.0,  5.0,   4.3
1780.04,   20091113, -0.3,  4.1,   7.4
1794.88,   20091116, 0.8,   2.9,   7.6
1802.04,   20091117, 0.4,   2.1,   7.0
1807.11,   20091118, 0.3,   1.2,   6.2
1788.75,   20091119, -1.0,  0.2,   3.9
1760.54,   20091120, -1.6,  -1.1,  2.7
1782.92,   20091123, 1.3,   -0.7,  2.9
1791.54,   20091124, 0.5,   -0.6,  3.6
1790.69,   20091125, -0.0,  -0.9,  2.3
1743.39,   20091127, -2.6,  -2.5,  -0.3
1762.99,   20091130, 1.1,   0.1,   0.9
1782.85,   20091201, 1.1,   -0.0,  2.4
1791.61,   20091202, 0.5,   0.0,   1.4
1795.25,   20091203, 0.2,   0.3,   2.3
1808.6,    20091204, 0.7,   3.7,   3.4
1791.35,   20091207, -1.0,  1.6,   1.0
1772.39,   20091208, -1.1,  -0.6,  0.9
1770.05,   20091209, -0.1,  -1.2,  1.3
1799.85,   20091210, 1.7,   0.3,   5.1
1808.84,   20091211, 0.5,   0.0,   6.8
1802.28,   20091214, -0.4,  0.6,   5.5
1801.58,   20091215, -0.0,  1.6,   8.1
1805.53,   20091216, 0.2,   2.0,   8.6
1791.46,   20091217, -0.8,  -0.5,  6.2
1794.12,   20091218, 0.1,   -0.8,  5.5
1818.39,   20091221, 1.4,   0.9,   6.3
1833.72,   20091222, 0.8,   1.8,   5.2
1842.65,   20091223, 0.5,   2.1,   4.4
1856.06,   20091224, 0.7,   3.6,   4.0
1874.71,   20091228, 1.0,   4.5,   5.0
1879.72,   20091229, 0.3,   3.4,   5.6
1872.63,   20091230, -0.4,  2.1,   4.3
1881.71,   20091231, 0.5,   2.1,   4.4
1882.69,   20100104, 0.1,   1.4,   4.2
1887.28,   20100105, 0.2,   0.7,   5.5
1887.91,   20100106, 0.0,   0.4,   7.2
1878.16,   20100107, -0.5,  0.3,   5.3
1870.9,    20100108, -0.4,  -0.6,  4.4
1896.05,   20100111, 1.3,   0.7,   5.9
1874.75,   20100112, -1.1,  -0.7,  7.5
1868.2,    20100113, -0.3,  -1.0,  6.0
1880.46,   20100114, 0.7,   0.1,   5.5
1887.31,   20100115, 0.4,   0.9,   5.3
1869.24,   20100119, -1.0,  -1.4,  4.1
1882.06,   20100120, 0.7,   0.4,   4.1
1874.54,   20100121, -0.4,  0.3,   4.6
1842.11,   20100122, -1.7,  -2.0,  3.9
1806.6,    20100125, -1.9,  -4.3,  2.1
1798.36,   20100126, -0.5,  -3.8,  -0.1
1801.67,   20100127, 0.2,   -4.3,  -0.4
1805.64,   20100128, 0.2,   -3.7,  0.2
1787.14,   20100129, -1.0,  -3.0,  -0.8
1745.91,   20100201, -2.3,  -3.4,  -3.3
1760.65,   20100202, 0.8,   -2.1,  -1.7
1767.14,   20100203, 0.4,   -1.9,  -1.5
1771.78,   20100204, 0.3,   -1.9,  -2.6
1739.19,   20100205, -1.8,  -2.7,  -5.2
1744.02,   20100208, 0.3,   -0.1,  -5.4
1756.28,   20100209, 0.7,   -0.2,  -5.4
1752.46,   20100210, -0.2,  -0.8,  -6.5
1747.55,   20100211, -0.3,  -1.4,  -7.0
1761.29,   20100212, 0.8,   1.3,   -5.9
1792.9,    20100216, 1.8,   2.8,   -4.7
1808.55,   20100217, 0.9,   3.0,   -3.9
1809.01,   20100218, 0.0,   3.2,   -4.1
1816.94,   20100219, 0.4,   4.0,   -3.8
1828.69,   20100222, 0.6,   3.8,   -2.6
1815.43,   20100223, -0.7,  1.3,   -3.0
1803.29,   20100224, -0.7,  -0.3,  -4.9
1791.15,   20100225, -0.7,  -1.0,  -4.5
1812.24,   20100226, 1.2,   -0.3,  -3.0
1826.62,   20100301, 0.8,   -0.1,  -2.9
1851.79,   20100302, 1.4,   2.0,   -1.9
1854.71,   20100303, 0.2,   2.9,   -0.8
1852.7,    20100304, -0.1,  3.4,   -1.6
1871.62,   20100305, 1.0,   3.3,   -0.2
1888.04,   20100308, 0.9,   3.4,   2.5
1885.99,   20100309, -0.1,  1.8,   4.4
1901.24,   20100310, 0.8,   2.5,   5.7
1911.07,   20100311, 0.5,   3.2,   6.1
1930.0,    20100312, 1.0,   3.1,   6.9
1918.14,   20100315, -0.6,  1.6,   7.3
1924.27,   20100316, 0.3,   2.0,   10.2
1934.65,   20100317, 0.5,   1.8,   9.9
1937.63,   20100318, 0.2,   1.4,   9.6
1943.96,   20100319, 0.3,   0.7,   9.7
1922.73,   20100322, -1.1,  0.2,   10.6
1952.54,   20100323, 1.6,   1.5,   12.0
1956.63,   20100324, 0.2,   1.1,   11.4
1966.56,   20100325, 0.5,   1.5,   12.2
1958.27,   20100326, -0.4,  0.7,   12.1
1960.63,   20100329, 0.1,   2.0,   11.3
1966.51,   20100330, 0.3,   0.7,   9.7
1962.17,   20100331, -0.2,  0.3,   8.5
1968.44,   20100401, 0.3,   0.1,   8.8
1964.03,   20100405, -0.2,  0.3,   8.1
1971.09,   20100406, 0.4,   0.5,   7.8
1980.2,    20100407, 0.5,   0.7,   9.1
1972.82,   20100408, -0.4,  0.5,   9.4
1984.01,   20100409, 0.6,   0.8,   10.8
1995.35,   20100412, 0.6,   1.6,   10.1
1994.34,   20100413, -0.1,  1.2,   9.2
2015.61,   20100414, 1.1,   1.8,   8.8
2027.92,   20100415, 0.6,   2.8,   9.3
2030.34,   20100416, 0.1,   2.3,   9.6
2011.4,    20100419, -0.9,  0.8,   7.5
2025.04,   20100420, 0.7,   1.5,   7.3
2037.15,   20100421, 0.6,   1.1,   8.0
2016.49,   20100422, -1.0,  -0.6,  6.1
2042.8,    20100423, 1.3,   0.6,   6.9
2054.84,   20100426, 0.6,   2.2,   6.5
2040.43,   20100427, -0.7,  0.8,   6.4
2016.52,   20100428, -1.2,  -1.0,  4.8
2020.51,   20100429, 0.2,   0.2,   4.4
2040.58,   20100430, 1.0,   -0.1,  5.3
2010.94,   20100503, -1.5,  -2.1,  3.4
2003.59,   20100504, -0.4,  -1.8,  4.2
1947.35,   20100505, -2.8,  -3.4,  -0.3
1948.25,   20100506, 0.0,   -3.6,  -0.4
1879.76,   20100507, -3.5,  -7.9,  -4.4
1931.52,   20100510, 2.8,   -3.9,  -1.4
1920.24,   20100511, -0.6,  -4.2,  -2.1
1951.99,   20100512, 1.7,   0.2,   -0.7
1969.44,   20100513, 0.9,   1.1,   0.4
1928.58,   20100514, -2.1,  2.6,   -2.0
1911.38,   20100517, -0.9,  -1.0,  -2.7
1929.12,   20100518, 0.9,   0.5,   -2.1
1879.32,   20100519, -2.6,  -3.7,  -5.1
1830.17,   20100520, -2.6,  -7.1,  -7.2
1772.34,   20100521, -3.2,  -8.1,  -10.7
1818.46,   20100524, 2.6,   -4.9,  -8.9
1769.32,   20100525, -2.7,  -8.3,  -11.3
1827.58,   20100526, 3.3,   -2.8,  -9.3
1837.65,   20100527, 0.6,   0.4,   -9.4
1865.89,   20100528, 1.5,   5.3,   -8.1
1846.6,    20100601, -1.0,  1.5,   -8.2
1844.69,   20100602, -0.1,  4.3,   -8.9
1883.25,   20100603, 2.1,   3.0,   -7.6
1857.83,   20100604, -1.3,  1.1,   -7.9
1839.77,   20100607, -1.0,  -1.4,  -9.9
1799.26,   20100608, -2.2,  -2.6,  -12.4
1805.87,   20100609, 0.4,   -2.1,  -11.5
1800.57,   20100610, -0.3,  -4.4,  -10.7
1814.25,   20100611, 0.8,   -2.3,  -10.2
1862.7,    20100614, 2.7,   1.2,   -8.7
1855.69,   20100615, -0.4,  3.1,   -7.7
1891.26,   20100616, 1.9,   4.7,   -5.6
1914.75,   20100617, 1.2,   6.3,   -1.7
1911.06,   20100618, -0.2,  5.3,   -1.9
1939.77,   20100621, 1.5,   4.1,   3.2
1903.31,   20100622, -1.9,  2.6,   -1.5
1883.97,   20100623, -1.0,  -0.4,  -1.9
1868.47,   20100624, -0.8,  -2.4,  -4.3
1848.82,   20100625, -1.1,  -3.3,  -6.1
1843.27,   20100628, -0.3,  -5.0,  -4.4
1805.55,   20100629, -2.0,  -5.1,  -5.5
1761.98,   20100630, -2.4,  -6.5,  -8.7
1741.93,   20100701, -1.1,  -6.8,  -7.3
1736.25,   20100702, -0.3,  -6.1,  -5.1
1752.98,   20100706, 1.0,   -4.9,  -1.1
1739.01,   20100707, -0.8,  -3.7,  -4.4
1801.55,   20100708, 3.6,   2.2,   1.8
1797.04,   20100709, -0.3,  3.2,   -1.7
1814.49,   20100712, 1.0,   4.5,   -1.3
1835.02,   20100713, 1.1,   4.7,   -1.7
1847.92,   20100714, 0.7,   6.3,   0.1
1849.24,   20100715, 0.1,   2.6,   0.2
1845.5,    20100716, -0.2,  2.7,   -2.0
1807.99,   20100719, -2.0,  -0.4,  -2.7
1789.93,   20100720, -1.0,  -2.5,  -2.7
1859.6,    20100721, 3.9,   0.6,   3.4
1842.38,   20100722, -0.9,  -0.4,  2.0
1851.81,   20100723, 0.5,   0.3,   2.8
1875.77,   20100726, 1.3,   3.7,   3.4
1899.13,   20100727, 1.2,   6.1,   2.0
1887.46,   20100728, -0.6,  1.5,   1.7
1884.07,   20100729, -0.2,  2.3,   -0.4
1842.33,   20100730, -2.2,  -0.5,  -3.8
1886.61,   20100802, 2.4,   0.6,   -1.3
1898.46,   20100803, 0.6,   -0.0,  -2.1
1898.81,   20100804, 0.0,   0.6,   -0.2
1900.07,   20100805, 0.1,   0.8,   0.9
1883.32,   20100806, -0.9,  2.2,   0.8
1911.38,   20100809, 1.5,   1.3,   3.4
1896.71,   20100810, -0.8,  -0.1,  2.9
1866.0,    20100811, -1.6,  -1.7,  3.3
1808.63,   20100812, -3.1,  -4.8,  2.6
1825.29,   20100813, 0.9,   -3.1,  4.8
1806.97,   20100816, -1.0,  -5.5,  4.1
1833.15,   20100817, 1.4,   -3.4,  4.6
1841.42,   20100818, 0.5,   -1.3,  5.9
1842.79,   20100819, 0.1,   1.9,   2.3
1818.42,   20100820, -1.3,  -0.4,  1.2
1833.33,   20100823, 0.8,   1.5,   1.0
1783.2,    20100824, -2.7,  -2.7,  -2.8
1762.68,   20100825, -1.2,  -4.3,  -4.6
1797.35,   20100826, 2.0,   -2.5,  -2.8
1780.11,   20100827, -1.0,  -2.1,  -3.5
1785.98,   20100830, 0.3,   -2.6,  -1.2
1762.54,   20100831, -1.3,  -1.2,  -1.5
1791.91,   20100901, 1.7,   1.7,   -3.6
1822.83,   20100902, 1.7,   1.4,   -1.1
1864.03,   20100903, 2.3,   4.7,   0.7
1862.88,   20100907, -0.1,  4.3,   -0.7
1863.71,   20100908, 0.0,   5.7,   -1.9
1897.01,   20100909, 1.8,   5.9,   0.5
1887.83,   20100910, -0.5,  3.6,   0.2
1908.75,   20100913, 1.1,   2.4,   3.6
1917.83,   20100914, 0.5,   2.9,   1.7
1923.97,   20100915, 0.3,   3.2,   1.3
1937.18,   20100916, 0.7,   2.1,   2.0
1959.26,   20100917, 1.1,   3.8,   3.1
1962.15,   20100920, 0.1,   2.8,   4.2
1990.19,   20100921, 1.4,   3.8,   4.1
1979.9,    20100922, -0.5,  2.9,   4.4
1968.03,   20100923, -0.6,  1.6,   5.5
2006.53,   20100924, 2.0,   2.4,   10.9
2021.68,   20100927, 0.8,   3.0,   10.8
2013.57,   20100928, -0.4,  1.2,   11.4
2008.14,   20100929, -0.3,  1.4,   9.5
2021.49,   20100930, 0.7,   2.7,   9.8
2014.47,   20101001, -0.3,  0.4,   9.3
1990.13,   20101004, -1.2,  -1.6,  9.4
1996.38,   20101005, 0.3,   -0.9,  8.9
2021.21,   20101006, 1.2,   0.7,   13.3
2016.08,   20101007, -0.3,  -0.3,  14.4
2014.99,   20101008, -0.1,  0.0,   12.1
2028.53,   20101011, 0.7,   1.9,   14.0
2023.75,   20101012, -0.2,  1.4,   13.3
2052.5,    20101013, 1.4,   1.5,   16.5
2058.35,   20101014, 0.3,   2.1,   14.9
2078.63,   20101015, 1.0,   3.2,   14.0
2100.21,   20101018, 1.0,   3.5,   12.7
2063.96,   20101019, -1.7,  2.0,   10.8
2075.47,   20101020, 0.6,   1.1,   11.4
2097.17,   20101021, 1.0,   1.9,   10.6
2089.22,   20101022, -0.4,  0.5,   10.7
2113.23,   20101025, 1.1,   0.6,   10.7
2100.53,   20101026, -0.6,  1.8,   9.5
2108.11,   20101027, 0.4,   1.6,   9.6
2135.14,   20101028, 1.3,   1.8,   10.2
2126.76,   20101029, -0.4,  1.8,   8.5
2135.57,   20101101, 0.4,   1.1,   8.8
2146.98,   20101102, 0.5,   2.2,   7.9
2151.19,   20101103, 0.2,   2.0,   8.7
2183.1,    20101104, 1.5,   2.2,   10.9
2188.32,   20101105, 0.2,   2.9,   9.1
2179.7,    20101108, -0.4,  2.1,   7.8
2195.78,   20101109, 0.7,   2.3,   9.0
2178.36,   20101110, -0.8,  1.3,   8.5
2153.82,   20101111, -1.1,  -1.3,  6.5
2161.66,   20101112, 0.4,   -1.2,  7.3
2147.06,   20101115, -0.7,  -1.5,  7.9
2114.43,   20101116, -1.5,  -3.7,  5.9
2093.72,   20101117, -1.0,  -3.9,  3.6
2124.79,   20101118, 1.5,   -1.3,  5.4
2129.61,   20101119, 0.2,   -1.5,  5.7
2126.4,    20101122, -0.2,  -1.0,  4.8
2128.19,   20101123, 0.1,   0.7,   5.2
2138.24,   20101124, 0.5,   2.1,   4.2
2146.38,   20101126, 0.4,   1.0,   4.3
2144.04,   20101129, -0.1,  0.7,   3.1
2119.47,   20101130, -1.1,  -0.3,  0.9
2150.64,   20101201, 1.5,   1.1,   4.2
2166.57,   20101202, 0.7,   1.3,   4.4
2177.11,   20101203, 0.5,   1.4,   3.8
2192.12,   20101206, 0.7,   2.2,   4.9
2214.99,   20101207, 1.0,   4.5,   4.8
2195.33,   20101208, -0.9,  2.1,   4.5
2210.73,   20101209, 0.7,   2.0,   4.9
2206.47,   20101210, -0.2,  1.3,   3.3
2223.89,   20101213, 0.8,   1.4,   4.6
2213.23,   20101214, -0.5,  -0.1,  3.6
2210.51,   20101215, -0.1,  0.7,   3.0
2205.27,   20101216, -0.2,  -0.2,  2.5
2222.01,   20101217, 0.8,   0.7,   1.8
2225.41,   20101220, 0.2,   0.1,   1.7
2230.61,   20101221, 0.2,   0.8,   2.3
2234.6,    20101222, 0.2,   1.1,   1.8
2232.84,   20101223, -0.1,  1.3,   2.5
2223.76,   20101227, -0.4,  0.1,   3.2
2234.56,   20101228, 0.5,   0.4,   3.4
2232.33,   20101229, -0.1,  0.1,   4.0
2230.39,   20101230, -0.1,  -0.2,  5.5
2223.28,   20101231, -0.3,  -0.4,  6.2
2238.66,   20110103, 0.7,   0.7,   5.4
2261.65,   20110104, 1.0,   1.2,   6.2
2245.4,    20110105, -0.7,  0.6,   5.6
2272.33,   20110106, 1.2,   1.9,   6.8
2280.13,   20110107, 0.3,   2.6,   6.6
2270.65,   20110110, -0.4,  1.4,   5.8
2294.66,   20110111, 1.1,   1.5,   7.0
2298.63,   20110112, 0.2,   2.4,   8.5
2303.6,    20110113, 0.2,   1.4,   7.1
2304.37,   20110114, 0.0,   1.1,   6.4
2300.93,   20110118, -0.1,  1.3,   5.7
2329.93,   20110119, 1.3,   1.5,   6.3
2292.17,   20110120, -1.6,  -0.3,  3.5
2295.15,   20110121, 0.1,   -0.4,  4.5
2271.73,   20110124, -1.0,  -1.4,  2.8
2288.5,    20110125, 0.7,   -0.5,  3.7
2306.99,   20110126, 0.8,   -1.0,  3.7
2322.33,   20110127, 0.7,   1.3,   4.9
2330.07,   20110128, 0.3,   1.5,   5.4
2274.22,   20110131, -2.4,  0.1,   3.1
2298.37,   20110201, 1.1,   0.4,   3.4
2317.89,   20110202, 0.8,   0.5,   4.2
2316.67,   20110203, -0.1,  -0.2,  3.9
2324.18,   20110204, 0.3,   -0.3,  4.0
2340.44,   20110207, 0.7,   2.9,   4.8
2348.53,   20110208, 0.3,   2.2,   5.6
2359.81,   20110209, 0.5,   1.8,   5.6
2343.79,   20110210, -0.7,  1.2,   5.0
2357.77,   20110211, 0.6,   1.4,   5.7
2379.52,   20110214, 0.9,   1.7,   7.0
2380.16,   20110215, 0.0,   1.3,   6.3
2389.51,   20110216, 0.4,   1.3,   5.7
2384.69,   20110217, -0.2,  1.7,   6.2
2396.75,   20110218, 0.5,   1.7,   5.5
2353.81,   20110222, -1.8,  -1.1,  3.2
2321.44,   20110223, -1.4,  -2.5,  2.2
2306.94,   20110224, -0.6,  -3.5,  0.5
2326.13,   20110225, 0.8,   -2.5,  1.2
2354.87,   20110228, 1.2,   -1.7,  2.2
2359.78,   20110301, 0.2,   0.3,   2.4
2314.28,   20110302, -1.9,  -0.3,  0.6
2350.52,   20110303, 1.6,   1.9,   0.9
2370.34,   20110304, 0.8,   1.9,   3.4
2367.92,   20110307, -0.1,  0.6,   3.2
2325.13,   20110308, -1.8,  -1.5,  2.4
2328.8,    20110309, 0.2,   0.6,   1.8
2295.09,   20110310, -1.4,  -2.4,  -0.5
2275.29,   20110311, -0.9,  -4.0,  -2.0
2286.77,   20110314, 0.5,   -3.4,  -1.9
2218.3,    20110315, -3.0,  -4.6,  -2.5
2240.83,   20110316, 1.0,   -3.8,  -2.5
2238.85,   20110317, -0.1,  -2.5,  -3.4
2249.18,   20110318, 0.5,   -1.1,  -2.9
2250.89,   20110321, 0.1,   -1.6,  -3.2
2264.07,   20110322, 0.6,   2.1,   -3.3
2251.49,   20110323, -0.6,  0.5,   -4.1
2287.01,   20110324, 1.6,   2.2,   -3.1
2319.07,   20110325, 1.4,   3.1,   -1.1
2325.13,   20110328, 0.3,   3.3,   -1.4
2299.27,   20110329, -1.1,  1.6,   -3.4
2338.41,   20110330, 1.7,   3.9,   -1.8
2332.74,   20110331, -0.2,  2.0,   -2.4
2352.47,   20110401, 0.8,   1.4,   -1.4
2347.71,   20110404, -0.2,  1.0,   -2.0
2330.12,   20110405, -0.7,  1.3,   -1.0
2343.37,   20110406, 0.6,   0.2,   0.9
2331.74,   20110407, -0.5,  -0.0,  1.1
2342.74,   20110408, 0.5,   -0.4,  0.7
2330.26,   20110411, -0.5,  -0.7,  -1.0
2302.89,   20110412, -1.2,  -1.2,  -2.4
2312.8,    20110413, 0.4,   -1.3,  -0.1
2301.27,   20110414, -0.5,  -1.3,  -2.1
2304.46,   20110415, 0.1,   -1.6,  -2.8
2281.92,   20110418, -1.0,  -2.1,  -3.6
2297.0,    20110419, 0.7,   -0.3,  -1.2
2344.21,   20110420, 2.1,   1.4,   0.7
2381.2,    20110421, 1.6,   3.5,   3.8
2377.91,   20110425, -0.1,  3.2,   4.5
2389.24,   20110426, 0.5,   4.7,   4.5
2402.45,   20110427, 0.6,   4.6,   8.3
2404.96,   20110428, 0.1,   2.6,   7.3
2405.05,   20110429, 0.0,   1.0,   7.4
2410.24,   20110502, 0.2,   1.4,   7.2
2401.8,    20110503, -0.4,  0.5,   6.7
2392.16,   20110504, -0.4,  -0.4,  5.7
2376.46,   20110505, -0.7,  -1.2,  5.6
2395.92,   20110506, 0.8,   -0.4,  4.8
2384.2,    20110509, -0.5,  -1.1,  2.8
2397.2,    20110510, 0.5,   -0.2,  3.1
2408.15,   20110511, 0.5,   0.7,   4.7
2384.14,   20110512, -1.0,  0.3,   2.0
2403.06,   20110513, 0.8,   0.3,   3.0
2368.47,   20110516, -1.4,  -0.7,  0.7
2325.86,   20110517, -1.8,  -3.0,  -0.9
2340.69,   20110518, 0.6,   -2.8,  0.5
2369.06,   20110519, 1.2,   -0.6,  1.1
2365.49,   20110520, -0.2,  -1.6,  1.4
2318.16,   20110523, -2.0,  -2.1,  -1.0
2321.59,   20110524, 0.1,   -0.2,  -0.4
2299.49,   20110525, -1.0,  -1.8,  -0.1
2306.44,   20110526, 0.3,   -2.6,  -0.3
2328.65,   20110527, 1.0,   -1.6,  1.2
2359.05,   20110531, 1.3,   1.8,   2.4
2368.29,   20110601, 0.4,   2.0,   3.8
2325.56,   20110602, -1.8,  1.1,   1.2
2301.4,    20110603, -1.0,  -0.2,  -1.8
2288.61,   20110606, -0.6,  -1.7,  -3.9
2279.55,   20110607, -0.4,  -3.4,  -4.1
2264.4,    20110608, -0.7,  -4.4,  -5.2
2253.23,   20110609, -0.5,  -3.1,  -6.2
2249.4,    20110610, -0.2,  -2.3,  -6.5
2225.21,   20110613, -1.1,  -2.8,  -7.5
2240.39,   20110614, 0.7,   -1.7,  -7.0
2229.81,   20110615, -0.5,  -1.5,  -7.2
2209.75,   20110616, -0.9,  -1.9,  -7.6
2220.04,   20110617, 0.5,   -1.3,  -6.6
2187.21,   20110620, -1.5,  -1.7,  -8.7
2210.97,   20110621, 1.1,   -1.3,  -7.3
2243.21,   20110622, 1.5,   0.6,   -6.4
2210.49,   20110623, -1.5,  0.0,   -8.2
2245.49,   20110624, 1.6,   1.1,   -5.8
2217.63,   20110627, -1.2,  1.4,   -7.7
2256.52,   20110628, 1.8,   2.1,   -4.7
2290.5,    20110629, 1.5,   2.1,   -1.5
2303.0,    20110630, 0.5,   4.2,   -1.6
2326.16,   20110701, 1.0,   3.6,   -1.8
2363.08,   20110705, 1.6,   6.6,   -0.1
2368.75,   20110706, 0.2,   5.0,   2.2
2396.64,   20110707, 1.2,   4.6,   3.2
2390.16,   20110708, -0.3,  3.8,   3.9
2380.56,   20110711, -0.4,  2.3,   3.2
2362.19,   20110712, -0.8,  -0.0,  1.4
2360.43,   20110713, -0.1,  -0.4,  0.1
2358.89,   20110714, -0.1,  -1.6,  -0.4
2351.81,   20110715, -0.3,  -1.6,  1.1
2346.53,   20110718, -0.2,  -1.4,  2.0
2367.81,   20110719, 0.9,   0.2,   3.5
2411.71,   20110720, 1.9,   2.2,   5.8
2389.56,   20110721, -0.9,  1.3,   5.5
2403.2,    20110722, 0.6,   2.2,   6.7
2408.65,   20110725, 0.2,   2.6,   7.1
2423.16,   20110726, 0.6,   2.3,   8.9
2414.36,   20110727, -0.4,  0.1,   7.8
2367.44,   20110728, -1.9,  -0.9,  6.2
2349.3,    20110729, -0.8,  -2.2,  6.3
2391.42,   20110801, 1.8,   -0.7,  7.7
2340.73,   20110802, -2.1,  -3.4,  7.0
2294.94,   20110803, -2.0,  -4.9,  3.8
2275.3,    20110804, -0.9,  -3.9,  1.4
2227.49,   20110805, -2.1,  -5.2,  0.8
2127.87,   20110808, -4.5,  -11.0, -5.2
2095.99,   20110809, -1.5,  -10.5, -5.5
2110.14,   20110810, 0.7,   -8.1,  -6.5
2108.92,   20110811, -0.1,  -7.3,  -7.9
2176.54,   20110812, 3.2,   -2.3,  -5.5
2189.49,   20110815, 0.6,   2.9,   -5.9
2190.14,   20110816, 0.0,   4.5,   -7.3
2193.3,    20110817, 0.1,   3.9,   -7.4
2116.79,   20110818, -3.5,  0.4,   -11.7
2053.01,   20110819, -3.0,  -5.7,  -14.1
2084.27,   20110822, 1.5,   -4.8,  -12.4
2059.03,   20110823, -1.2,  -6.0,  -12.8
2121.52,   20110824, 3.0,   -3.3,  -10.1
2141.86,   20110825, 1.0,   1.2,   -9.2
2101.91,   20110826, -1.9,  2.4,   -10.6
2188.67,   20110829, 4.1,   5.0,   -6.7
2212.07,   20110830, 1.1,   7.4,   -6.6
2248.19,   20110831, 1.6,   6.0,   -6.8
2245.01,   20110901, -0.1,  4.8,   -6.0
2178.11,   20110902, -3.0,  3.6,   -9.4
2116.48,   20110906, -2.8,  -3.3,  -12.1
2198.61,   20110907, 3.9,   -0.6,  -9.3
2212.71,   20110908, 0.6,   -1.6,  -8.4
2197.72,   20110909, -0.7,  -2.1,  -7.2
2145.78,   20110912, -2.4,  -1.5,  -8.7
2197.66,   20110913, 2.4,   3.8,   -8.1
2233.66,   20110914, 1.6,   1.6,   -4.6
2273.22,   20110915, 1.8,   2.7,   -0.9
2284.28,   20110916, 0.5,   3.9,   0.4
2277.64,   20110919, -0.3,  6.1,   2.3
2318.55,   20110920, 1.8,   5.5,   9.0
2308.41,   20110921, -0.4,  3.3,   10.1
2196.63,   20110922, -4.8,  -3.4,  4.1
2165.75,   20110923, -1.4,  -5.2,  2.7
2216.66,   20110926, 2.4,   -2.7,  1.8
2270.69,   20110927, 2.4,   -2.1,  3.7
2266.29,   20110928, -0.2,  -1.8,  3.5
2255.55,   20110929, -0.5,  2.7,   2.8
2167.24,   20110930, -3.9,  0.1,   2.4
2128.02,   20111003, -1.8,  -4.0,  3.7
2064.01,   20111004, -3.0,  -9.1,  -1.0
2121.03,   20111005, 2.8,   -6.4,  3.0
2181.58,   20111006, 2.9,   -3.3,  2.8
2217.51,   20111007, 1.6,   2.3,   3.5
2241.17,   20111010, 1.1,   5.3,   6.6
2272.64,   20111011, 1.4,   10.1,  3.8
2316.76,   20111012, 1.9,   9.2,   4.7
2300.86,   20111013, -0.7,  5.5,   2.3
2360.0,    20111014, 2.6,   6.4,   5.1
2359.53,   20111017, -0.0,  5.3,   8.3
2331.98,   20111018, -1.2,  2.6,   10.2
2348.52,   20111019, 0.7,   1.4,   6.8
2317.34,   20111020, -1.3,  0.7,   4.7
2332.21,   20111021, 0.6,   -1.2,  6.1
2341.71,   20111024, 0.4,   -0.8,  9.1
2375.49,   20111025, 1.4,   1.9,   8.1
2349.85,   20111026, -1.1,  0.1,   5.2
2390.69,   20111027, 1.7,   3.2,   5.2
2386.02,   20111028, -0.2,  2.3,   4.5
2376.99,   20111031, -0.4,  1.5,   4.4
2301.11,   20111101, -3.2,  -3.1,  -0.8
2321.9,    20111102, 0.9,   -1.2,  0.6
2340.14,   20111103, 0.8,   -2.1,  6.5
2353.51,   20111104, 0.6,   -1.4,  8.7
2353.82,   20111107, 0.0,   -1.0,  6.2
2386.19,   20111108, 1.4,   3.7,   5.1
2346.74,   20111109, -1.7,  1.1,   3.5
2338.6,    20111110, -0.3,  -0.1,  3.7
2336.44,   20111111, -0.1,  -0.7,  7.8
2350.91,   20111114, 0.6,   -0.1,  10.5
2334.24,   20111115, -0.7,  -2.2,  13.1
2345.27,   20111116, 0.5,   -0.1,  10.6
2320.64,   20111117, -1.1,  -0.8,  6.4
2273.64,   20111118, -2.0,  -2.7,  2.5
2219.88,   20111121, -2.4,  -5.6,  -0.9
2205.31,   20111122, -0.7,  -5.5,  -3.0
2199.46,   20111123, -0.3,  -6.2,  -5.1
2159.94,   20111125, -1.8,  -6.9,  -6.1
2207.68,   20111128, 2.2,   -2.9,  -6.5
2225.12,   20111129, 0.8,   0.2,   -5.7
2270.28,   20111130, 2.0,   2.9,   -2.6
2294.27,   20111201, 1.1,   4.3,   -2.3
2327.87,   20111202, 1.5,   7.8,   0.5
2336.55,   20111205, 0.4,   5.8,   0.2
2327.65,   20111206, -0.4,  4.6,   -0.6
2312.55,   20111207, -0.6,  1.9,   -2.6
2309.53,   20111208, -0.1,  0.7,   -1.7
2287.27,   20111209, -1.0,  -1.7,  -4.3
2295.43,   20111212, 0.4,   -1.8,  -3.8
2308.29,   20111213, 0.6,   -0.8,  -2.9
2259.84,   20111214, -2.1,  -2.3,  -1.8
2253.14,   20111215, -0.3,  -2.4,  -3.0
2238.59,   20111216, -0.6,  -2.1,  -4.3
2244.37,   20111219, 0.3,   -2.2,  -4.6
2253.19,   20111220, 0.4,   -2.4,  -4.3
2265.9,    20111221, 0.6,   0.3,   -5.0
2254.68,   20111222, -0.5,  0.1,   -3.9
2274.35,   20111223, 0.9,   1.6,   -2.7
2284.29,   20111227, 0.4,   1.8,   -2.2
2294.97,   20111228, 0.5,   1.9,   -2.4
2272.56,   20111229, -1.0,  0.3,   -2.6
2281.91,   20111230, 0.4,   1.2,   -2.7
2323.16,   20120103, 1.8,   2.1,   0.1
2316.53,   20120104, -0.3,  1.4,   1.9
2326.58,   20120105, 0.4,   1.4,   4.8
2350.68,   20120106, 1.0,   3.4,   6.6
2362.53,   20120109, 0.5,   3.5,   7.4
2374.26,   20120110, 0.5,   2.2,   9.9
2363.69,   20120111, -0.4,  2.0,   7.1
2377.34,   20120112, 0.6,   2.2,   6.8
2371.42,   20120113, -0.2,  0.9,   4.5
2396.14,   20120117, 1.0,   1.4,   4.4
2396.63,   20120118, 0.0,   0.9,   3.0
2435.14,   20120119, 1.6,   3.0,   4.2
2428.66,   20120120, -0.3,  2.2,   4.3
2437.06,   20120123, 0.3,   2.8,   5.4
2426.86,   20120124, -0.4,  1.3,   5.1
2456.55,   20120125, 1.2,   2.5,   7.4
2474.05,   20120126, 0.7,   1.6,   7.8
2450.38,   20120127, -1.0,  0.9,   6.2
2438.52,   20120130, -0.5,  0.1,   7.9
2476.65,   20120131, 1.6,   2.1,   9.9
2481.62,   20120201, 0.2,   1.0,   10.9
2495.08,   20120202, 0.5,   0.9,   11.2
2518.86,   20120203, 1.0,   2.8,   11.8
2519.33,   20120206, 0.0,   3.3,   11.2
2522.79,   20120207, 0.1,   1.9,   11.9
2533.48,   20120208, 0.4,   2.1,   11.4
2553.63,   20120209, 0.8,   2.3,   11.8
2543.86,   20120210, -0.4,  1.0,   10.8
2564.93,   20120213, 0.8,   1.8,   12.9
2561.6,    20120214, -0.1,  1.5,   12.3
2584.95,   20120215, 0.9,   2.0,   11.3
2555.55,   20120216, -1.1,  0.1,   10.3
2587.22,   20120217, 1.2,   1.7,   11.2
2589.21,   20120221, 0.1,   0.9,   10.1
2586.32,   20120222, -0.1,  1.0,   9.5
2578.91,   20120223, -0.3,  -0.2,  8.6
2602.66,   20120224, 0.9,   1.8,   10.1
2589.44,   20120227, -0.5,  0.1,   8.9
2610.04,   20120228, 0.8,   0.8,   10.1
2638.97,   20120229, 1.1,   2.0,   10.1
2634.2,    20120301, -0.2,  2.1,   9.9
2640.43,   20120302, 0.2,   1.5,   8.4
2636.6,    20120305, -0.1,  1.8,   8.6
2586.31,   20120306, -1.9,  -0.9,  6.1
2599.85,   20120307, 0.5,   -1.5,  7.1
2623.45,   20120308, 0.9,   -0.4,  6.8
2641.15,   20120309, 0.7,   0.0,   6.8
2646.98,   20120312, 0.2,   0.4,   8.0
2665.3,    20120313, 0.7,   3.1,   9.3
2701.08,   20120314, 1.3,   3.9,   9.1
2716.19,   20120315, 0.6,   3.5,   9.5
2715.71,   20120316, -0.0,  2.8,   8.8
2716.98,   20120319, 0.0,   2.6,   7.9
2719.4,    20120320, 0.1,   2.0,   7.9
2739.4,    20120321, 0.7,   1.4,   8.6
2720.66,   20120322, -0.7,  0.2,   7.4
2734.68,   20120323, 0.5,   0.7,   7.1
2746.98,   20120326, 0.4,   1.1,   8.0
2779.67,   20120327, 1.2,   2.2,   8.4
2785.98,   20120328, 0.2,   1.7,   8.8
2756.44,   20120329, -1.1,  1.3,   6.6
2773.93,   20120330, 0.6,   1.4,   8.5
2752.25,   20120402, -0.8,  0.2,   6.4
2786.14,   20120403, 1.2,   0.2,   7.6
2759.33,   20120404, -1.0,  -1.0,  6.7
2740.7,    20120405, -0.7,  -0.6,  6.3
2726.08,   20120409, -0.5,  -1.7,  4.7
2738.44,   20120410, 0.5,   -0.5,  5.8
2719.48,   20120411, -0.7,  -2.4,  4.2
2715.04,   20120412, -0.2,  -1.6,  2.9
2730.74,   20120413, 0.6,   -0.4,  3.7
2713.89,   20120416, -0.6,  -0.4,  2.8
2678.58,   20120417, -1.3,  -2.2,  1.6
2714.66,   20120418, 1.3,   -0.2,  5.0
2707.89,   20120419, -0.2,  -0.3,  4.2
2699.55,   20120420, -0.3,  -1.1,  2.9
2651.13,   20120423, -1.8,  -2.3,  0.4
2647.91,   20120424, -0.1,  -1.1,  0.0
2694.86,   20120425, 1.8,   -0.7,  1.1
2709.88,   20120426, 0.6,   0.1,   0.3
2737.57,   20120427, 1.0,   1.4,   0.8
2731.26,   20120430, -0.2,  3.0,   0.6
2719.25,   20120501, -0.4,  2.7,   0.1
2713.18,   20120502, -0.2,  0.7,   -0.2
2737.04,   20120503, 0.9,   1.0,   -0.1
2682.37,   20120504, -2.0,  -2.0,  -1.4
2622.67,   20120507, -2.2,  -4.0,  -4.1
2624.35,   20120508, 0.1,   -3.5,  -4.5
2600.19,   20120509, -0.9,  -4.2,  -6.5
2631.97,   20120510, 1.2,   -3.8,  -5.5
2606.01,   20120511, -1.0,  -2.8,  -5.5
2593.75,   20120514, -0.5,  -1.1,  -6.5
2589.96,   20120515, -0.1,  -1.3,  -5.9
2589.91,   20120516, -0.0,  -0.4,  -7.0
2562.13,   20120517, -1.1,  -2.7,  -7.1
2509.9,    20120518, -2.0,  -3.7,  -8.4
2482.31,   20120521, -1.1,  -4.3,  -8.9
2553.58,   20120522, 2.9,   -1.4,  -6.8
2534.66,   20120523, -0.7,  -2.1,  -6.8
2553.12,   20120524, 0.7,   -0.4,  -6.0
2532.32,   20120525, -0.8,  0.9,   -7.3
2542.66,   20120529, 0.4,   2.4,   -6.3
2538.58,   20120530, -0.2,  -0.6,  -5.2
2535.87,   20120531, -0.1,  0.0,   -6.6
2510.82,   20120601, -1.0,  -1.7,  -7.3
2458.97,   20120604, -2.1,  -2.9,  -8.9
2467.81,   20120605, 0.4,   -2.9,  -6.9
2504.08,   20120606, 1.5,   -1.4,  -5.4
2569.24,   20120607, 2.6,   1.3,   -4.7
2529.42,   20120608, -1.5,  0.7,   -6.7
2578.73,   20120611, 1.9,   4.9,   -5.8
2523.01,   20120612, -2.2,  2.2,   -7.6
2541.72,   20120613, 0.7,   1.5,   -6.5
2529.28,   20120614, -0.5,  -1.6,  -6.8
2543.85,   20120615, 0.6,   0.6,   -7.1
2570.98,   20120618, 1.1,   -0.3,  -4.2
2606.43,   20120619, 1.4,   3.3,   -0.6
2625.0,    20120620, 0.7,   3.3,   0.0
2619.98,   20120621, -0.2,  3.6,   0.8
2562.28,   20120622, -2.2,  0.7,   -2.6
2562.98,   20120625, 0.0,   -0.3,  -1.7
2541.21,   20120626, -0.8,  -2.5,  -2.0
2558.67,   20120627, 0.7,   -2.5,  -1.2
2565.53,   20120628, 0.3,   -2.1,  -0.9
2566.84,   20120629, 0.1,   0.2,   0.2
2616.52,   20120702, 1.9,   2.1,   4.2
2623.46,   20120703, 0.3,   3.2,   5.7
2643.63,   20120705, 0.8,   3.3,   3.5
2635.65,   20120706, -0.3,  2.7,   4.0
2610.38,   20120709, -1.0,  1.7,   2.2
2620.33,   20120710, 0.4,   0.1,   3.5
2580.07,   20120711, -1.5,  -1.7,  1.5
2561.68,   20120712, -0.7,  -3.1,  0.9
2545.98,   20120713, -0.6,  -3.4,  0.4
2579.96,   20120716, 1.3,   -1.2,  2.8
2591.78,   20120717, 0.5,   -1.1,  5.4
2586.86,   20120718, -0.2,  0.3,   4.8
2645.34,   20120719, 2.3,   3.3,   5.6
2654.6,    20120720, 0.4,   4.3,   3.3
2575.29,   20120723, -3.0,  -0.2,  1.8
2593.39,   20120724, 0.7,   0.1,   0.6
2551.7,    20120725, -1.6,  -1.4,  1.1
2588.2,    20120726, 1.4,   -2.2,  1.8
2600.03,   20120727, 0.5,   -2.1,  2.8
2649.78,   20120730, 1.9,   2.9,   4.2
2643.28,   20120731, -0.2,  1.9,   2.8
2660.49,   20120801, 0.7,   4.3,   2.1
2616.04,   20120802, -1.7,  1.1,   -0.3
2664.69,   20120803, 1.9,   2.5,   1.7
2686.38,   20120806, 0.8,   1.4,   4.8
2705.97,   20120807, 0.7,   2.4,   5.6
2707.29,   20120808, 0.0,   1.8,   6.5
2712.97,   20120809, 0.2,   3.7,   6.0
2710.55,   20120810, -0.1,  1.7,   5.7
2721.49,   20120813, 0.4,   1.3,   6.0
2737.85,   20120814, 0.6,   1.2,   4.6
2724.71,   20120815, -0.5,  0.6,   3.9
2743.88,   20120816, 0.7,   1.1,   3.8
2775.53,   20120817, 1.2,   2.4,   5.3
2777.71,   20120820, 0.1,   2.1,   6.4
2791.74,   20120821, 0.5,   2.0,   6.5
2765.1,    20120822, -1.0,  1.5,   7.2
2773.98,   20120823, 0.3,   1.1,   8.3
2754.43,   20120824, -0.7,  -0.8,  8.2
2792.0,    20120827, 1.4,   0.5,   8.2
2779.17,   20120828, -0.5,  -0.5,  7.2
2782.98,   20120829, 0.1,   0.6,   7.6
2771.44,   20120830, -0.4,  -0.1,  4.8
2773.24,   20120831, 0.1,   0.7,   4.5
2766.93,   20120904, -0.2,  -0.9,  7.4
2769.19,   20120905, 0.1,   -0.4,  6.8
2785.01,   20120906, 0.6,   0.1,   9.1
2823.22,   20120907, 1.4,   1.9,   9.1
2821.03,   20120910, -0.1,  1.7,   8.5
2789.09,   20120911, -1.1,  0.8,   5.3
2794.38,   20120912, 0.2,   0.9,   5.7
2796.72,   20120913, 0.1,   0.4,   5.1
2841.82,   20120914, 1.6,   0.7,   8.6
2857.52,   20120917, 0.6,   1.3,   7.2
2852.42,   20120918, -0.2,  2.3,   6.2
2858.54,   20120919, 0.2,   2.3,   5.6
2850.44,   20120920, -0.3,  1.9,   5.3
2874.53,   20120921, 0.8,   1.2,   6.0
2836.35,   20120924, -1.3,  -0.7,  4.6
2851.65,   20120925, 0.5,   -0.0,  4.8
2798.93,   20120926, -1.8,  -2.1,  2.2
2790.78,   20120927, -0.3,  -2.1,  2.4
2812.82,   20120928, 0.8,   -2.1,  2.5
2811.45,   20121001, -0.0,  -0.9,  1.3
2807.08,   20121002, -0.2,  -1.6,  1.1
2810.16,   20121003, 0.1,   0.4,   0.7
2824.89,   20121004, 0.5,   1.2,   2.2
2840.19,   20121005, 0.5,   1.0,   2.4
2797.33,   20121008, -1.5,  -0.5,  1.6
2781.81,   20121009, -0.6,  -0.9,  -0.4
2743.58,   20121010, -1.4,  -2.4,  -1.3
2750.81,   20121011, 0.3,   -2.6,  -1.2
2719.69,   20121012, -1.1,  -4.2,  -1.9
2729.2,    20121015, 0.3,   -2.4,  -1.6
2746.14,   20121016, 0.6,   -1.3,  -0.8
2763.7,    20121017, 0.6,   0.7,   -0.2
2770.05,   20121018, 0.2,   0.7,   -0.5
2741.58,   20121019, -1.0,  0.8,   -2.9
2678.89,   20121022, -2.3,  -1.8,  -5.0
2670.94,   20121023, -0.3,  -2.7,  -4.2
2685.55,   20121024, 0.5,   -2.8,  -3.9
2677.13,   20121025, -0.3,  -3.4,  -4.3
2657.91,   20121026, -0.7,  -3.1,  -6.5
2663.56,   20121031, 0.2,   -0.6,  -6.8
2661.05,   20121101, -0.1,  -0.4,  -6.7
2701.91,   20121102, 1.5,   0.6,   -5.5
2657.73,   20121105, -1.6,  -0.7,  -6.8
2676.15,   20121106, 0.7,   0.7,   -6.9
2647.51,   20121107, -1.1,  -0.6,  -6.7
2620.25,   20121108, -1.0,  -1.5,  -8.1
2574.87,   20121109, -1.7,  -4.7,  -8.0
2596.57,   20121112, 0.8,   -2.3,  -7.0
2558.86,   20121113, -1.5,  -4.4,  -9.0
2574.62,   20121114, 0.6,   -2.8,  -8.4
2534.24,   20121115, -1.6,  -3.3,  -9.7
2525.89,   20121116, -0.3,  -1.9,  -10.1
2564.23,   20121119, 1.5,   -1.2,  -9.2
2592.27,   20121120, 1.1,   1.3,   -8.7
2596.02,   20121121, 0.1,   0.8,   -7.2
2617.87,   20121123, 0.8,   3.3,   -5.9
2634.43,   20121126, 0.6,   4.3,   -4.0
2649.94,   20121127, 0.6,   3.3,   -3.7
2627.57,   20121128, -0.8,  1.4,   -3.4
2677.63,   20121129, 1.9,   3.1,   -1.9
2680.55,   20121130, 0.1,   2.4,   -2.4
2697.44,   20121203, 0.6,   2.4,   -2.4
2671.12,   20121204, -1.0,  0.8,   -3.6
2660.68,   20121205, -0.4,  1.3,   -3.0
2631.16,   20121206, -1.1,  -1.7,  -1.8
2667.44,   20121207, 1.4,   -0.5,  -0.1
2634.54,   20121210, -1.2,  -2.3,  -1.9
2665.98,   20121211, 1.2,   -0.2,  -0.4
2694.4,    20121212, 1.1,   1.3,   1.4
2667.36,   20121213, -1.0,  1.4,   0.1
2635.91,   20121214, -1.2,  -1.2,  -0.9
2629.76,   20121217, -0.2,  -0.2,  -2.7
2675.44,   20121218, 1.7,   0.4,   0.7
2709.61,   20121219, 1.3,   0.6,   1.3
2697.6,    20121220, -0.4,  1.1,   1.9
2641.96,   20121221, -2.1,  0.2,   0.8
2658.65,   20121224, 0.6,   1.1,   3.3
2657.41,   20121226, -0.0,  -0.7,  2.3
2636.35,   20121227, -0.8,  -2.7,  3.0
2612.19,   20121228, -0.9,  -3.2,  1.5
2600.92,   20121231, -0.4,  -1.6,  2.6
2727.67,   20130102, 4.9,   2.6,   8.0
2742.13,   20130103, 0.5,   3.2,   6.9
2730.53,   20130104, -0.4,  3.6,   5.3
2713.44,   20130107, -0.6,  3.9,   4.5
2724.76,   20130108, 0.4,   4.8,   4.1
2724.17,   20130109, -0.0,  -0.1,  3.4
2746.68,   20130110, 0.8,   0.2,   3.7
2743.57,   20130111, -0.1,  0.5,   4.4
2733.46,   20130114, -0.4,  0.7,   2.1
2721.29,   20130115, -0.4,  -0.1,  1.5
2725.63,   20130116, 0.2,   0.1,   1.0
2746.19,   20130117, 0.8,   -0.0,  2.8
2735.9,    20130118, -0.4,  -0.3,  2.8
2744.27,   20130122, 0.3,   0.4,   4.3
2761.98,   20130123, 0.6,   1.5,   3.5
2724.2,    20130124, -1.4,  -0.1,  3.4
2731.33,   20130125, 0.3,   -0.5,  2.5
2737.62,   20130128, 0.2,   0.1,   1.6
2739.39,   20130129, 0.1,   -0.2,  2.7
2748.63,   20130130, 0.3,   -0.5,  4.3
2736.13,   20130131, -0.5,  0.4,   4.0
2751.19,   20130201, 0.6,   0.7,   2.8
2749.4,    20130204, -0.1,  0.4,   1.5
2727.82,   20130205, -0.8,  -0.4,  1.1
2745.38,   20130206, 0.6,   -0.1,  3.9
2746.02,   20130207, 0.0,   0.4,   3.3
2761.05,   20130208, 0.5,   0.4,   3.9
2774.44,   20130211, 0.5,   0.9,   5.2
2771.16,   20130212, -0.1,  1.6,   6.1
2770.7,    20130213, -0.0,  0.9,   6.5
2760.12,   20130214, -0.4,  0.5,   1.2
2772.56,   20130215, 0.5,   0.4,   1.1
2770.7,    20130219, -0.1,  -0.1,  1.5
2781.0,    20130220, 0.4,   0.4,   2.5
2730.1,    20130221, -1.8,  -1.5,  0.2
2726.27,   20130222, -0.1,  -1.2,  0.1
2756.19,   20130225, 1.1,   -0.6,  0.3
2708.8,    20130226, -1.7,  -2.2,  -1.3
2714.45,   20130227, 0.2,   -2.4,  -0.7
2742.38,   20130228, 1.0,   0.4,   0.8
2725.38,   20130301, -0.6,  -0.0,  -0.0
2738.21,   20130304, 0.5,   -0.7,  -0.3
2776.28,   20130305, 1.4,   2.5,   1.5
2808.11,   20130306, 1.1,   3.5,   2.3
2794.69,   20130307, -0.5,  1.9,   1.2
2809.33,   20130308, 0.5,   3.1,   3.1
2798.13,   20130311, -0.4,  2.2,   2.4
2803.47,   20130312, 0.2,   1.0,   2.4
2801.41,   20130313, -0.1,  -0.2,  2.3
2806.41,   20130314, 0.2,   0.4,   2.1
2810.04,   20130315, 0.1,   0.0,   2.7
2771.53,   20130318, -1.4,  -1.0,  0.7
2801.1,    20130319, 1.1,   -0.1,  1.9
2808.93,   20130320, 0.3,   0.3,   3.0
2779.57,   20130321, -1.0,  -1.0,  1.2
2787.27,   20130322, 0.3,   -0.8,  1.5
2810.75,   20130325, 0.8,   1.4,   1.8
2803.23,   20130326, -0.3,  0.1,   1.0
2786.66,   20130327, -0.6,  -0.8,  0.6
2809.49,   20130328, 0.8,   1.1,   1.4
2819.78,   20130401, 0.4,   1.2,   2.2
2809.0,    20130402, -0.4,  -0.1,  1.3
2821.97,   20130403, 0.5,   0.7,   1.9
2794.41,   20130404, -1.0,  0.3,   0.5
2749.64,   20130405, -1.6,  -2.1,  0.7
2772.3,    20130408, 0.8,   -1.7,  1.7
2793.86,   20130409, 0.8,   -0.5,  1.4
2812.51,   20130410, 0.7,   -0.3,  3.8
2850.39,   20130411, 1.3,   2.0,   5.0
2854.47,   20130412, 0.1,   3.8,   4.1
2841.26,   20130415, -0.5,  2.5,   4.3
2815.65,   20130416, -0.9,  0.8,   2.8
2811.33,   20130417, -0.2,  -0.0,  1.3
2787.97,   20130418, -0.8,  -2.2,  -0.7
2743.4,    20130419, -1.6,  -3.9,  -1.8
2791.77,   20130422, 1.8,   -1.7,  -0.6
2824.97,   20130423, 1.2,   0.3,   1.0
2827.35,   20130424, 0.1,   0.6,   0.9
2841.98,   20130425, 0.5,   1.9,   1.4
2842.21,   20130426, 0.0,   3.6,   1.3
2849.54,   20130429, 0.3,   2.1,   1.4
2869.13,   20130430, 0.7,   1.6,   3.5
2885.35,   20130501, 0.6,   2.1,   3.0
2877.56,   20130502, -0.3,  1.3,   2.4
2938.74,   20130503, 2.1,   3.4,   5.7
2948.9,    20130506, 0.3,   3.5,   5.8
2960.08,   20130507, 0.4,   3.2,   5.3
2952.31,   20130508, -0.3,  2.3,   5.3
2960.55,   20130509, 0.3,   2.9,   6.2
2967.07,   20130510, 0.2,   1.0,   5.6
2972.96,   20130513, 0.2,   0.8,   5.4
2981.89,   20130514, 0.3,   0.7,   6.2
2990.39,   20130515, 0.3,   1.3,   6.0
3005.05,   20130516, 0.5,   1.5,   7.5
3017.79,   20130517, 0.4,   1.7,   9.8
3019.28,   20130520, 0.0,   1.6,   8.9
3019.9,    20130521, 0.0,   1.3,   8.1
3027.45,   20130522, 0.3,   1.2,   7.6
2967.93,   20130523, -2.0,  -1.2,  4.1
2971.36,   20130524, 0.1,   -1.5,  4.1
3024.38,   20130528, 1.8,   0.2,   6.4
2996.98,   20130529, -0.9,  -0.8,  6.4
2998.99,   20130530, 0.1,   -0.9,  6.7
3001.69,   20130531, 0.1,   1.1,   7.7
2984.59,   20130603, -0.6,  0.4,   8.8
2993.06,   20130604, 0.3,   -1.0,  7.2
2961.67,   20130605, -1.0,  -1.2,  4.8
2940.87,   20130606, -0.7,  -1.9,  4.0
2960.9,    20130607, 0.7,   -1.4,  4.2
2995.03,   20130610, 1.2,   0.3,   5.4
2959.44,   20130611, -1.2,  -1.1,  3.9
2977.68,   20130612, 0.6,   0.5,   3.8
2924.89,   20130613, -1.8,  -0.5,  1.4
2958.87,   20130614, 1.2,   -0.1,  2.8
2967.33,   20130617, 0.3,   -0.9,  1.0
2974.92,   20130618, 0.3,   0.5,   0.9
2998.09,   20130619, 0.8,   0.7,   1.3
2927.63,   20130620, -2.4,  0.1,   -0.8
2887.07,   20130621, -1.4,  -2.4,  -2.5
2852.65,   20130624, -1.2,  -3.9,  -3.9
2874.66,   20130625, 0.8,   -3.4,  -3.3
2892.43,   20130626, 0.6,   -3.5,  -3.0
2909.88,   20130627, 0.6,   -0.6,  -2.7
2896.7,    20130628, -0.5,  0.3,   -3.6
2933.48,   20130701, 1.3,   2.8,   -2.8
2924.79,   20130702, -0.3,  1.7,   -3.1
2918.95,   20130703, -0.2,  0.9,   -3.3
2958.26,   20130705, 1.3,   1.7,   -2.3
2976.61,   20130708, 0.6,   2.8,   0.3
2981.1,    20130709, 0.2,   1.6,   0.3
2981.22,   20130710, 0.0,   1.9,   -1.4
3032.09,   20130711, 1.7,   3.9,   1.2
3061.89,   20130712, 1.0,   3.5,   2.1
3077.02,   20130715, 0.5,   3.4,   2.5
3083.57,   20130716, 0.2,   3.4,   3.3
3084.94,   20130717, 0.0,   3.5,   3.1
3081.34,   20130718, -0.1,  1.6,   4.0
3043.06,   20130719, -1.2,  -0.6,  3.5
3061.5,    20130722, 0.6,   -0.5,  3.4
3059.15,   20130723, -0.1,  -0.8,  2.1
3060.48,   20130724, 0.0,   -0.8,  3.4
3054.17,   20130725, -0.2,  -0.9,  2.6
3046.12,   20130726, -0.3,  0.1,   4.1
3067.48,   20130729, 0.7,   0.2,   3.7
3080.2,    20130730, 0.4,   0.7,   3.8
3094.82,   20130731, 0.5,   1.1,   4.0
3113.22,   20130801, 0.6,   1.9,   3.8
3125.49,   20130802, 0.4,   2.6,   6.8
3136.77,   20130805, 0.4,   2.3,   8.6
3136.15,   20130806, -0.0,  1.8,   9.9
3117.13,   20130807, -0.6,  0.7,   8.4
3134.06,   20130808, 0.5,   0.7,   8.4
3124.74,   20130809, -0.3,  -0.0,  7.4
3107.06,   20130812, -0.6,  -0.9,  7.3
3130.08,   20130813, 0.7,   -0.2,  6.7
3141.97,   20130814, 0.4,   0.8,   7.4
3089.34,   20130815, -1.7,  -1.4,  5.8
3076.83,   20130816, -0.4,  -1.5,  4.0
3074.38,   20130819, -0.1,  -1.1,  3.3
3077.15,   20130820, 0.1,   -1.7,  3.2
3073.24,   20130821, -0.1,  -2.2,  3.1
3085.61,   20130822, 0.4,   -0.1,  1.8
3126.25,   20130823, 1.3,   1.6,   2.1
3127.01,   20130826, 0.0,   1.7,   1.6
3086.17,   20130827, -1.3,  0.3,   0.1
3060.96,   20130828, -0.8,  -0.4,  -0.8
3065.87,   20130829, 0.2,   -0.6,  -0.5
3095.17,   20130830, 1.0,   -1.0,  1.7
3097.65,   20130903, 0.1,   -0.9,  1.2
3100.28,   20130904, 0.1,   0.5,   1.3
3127.07,   20130905, 0.9,   2.2,   2.2
3140.98,   20130906, 0.4,   2.4,   2.8
3146.02,   20130909, 0.2,   1.6,   3.3
3186.53,   20130910, 1.3,   2.9,   3.9
3163.77,   20130911, -0.7,  2.0,   2.7
3179.6,    20130912, 0.5,   1.7,   2.7
3180.45,   20130913, 0.0,   1.3,   2.2
3203.19,   20130916, 0.7,   1.8,   2.5
3177.78,   20130917, -0.8,  -0.3,  1.3
3198.45,   20130918, 0.7,   1.1,   2.0
3240.93,   20130919, 1.3,   1.9,   4.0
3244.43,   20130920, 0.1,   2.0,   3.5
3242.32,   20130923, -0.1,  1.2,   3.8
3226.23,   20130924, -0.5,  1.5,   3.8
3221.2,    20130925, -0.2,  0.7,   2.9
3222.98,   20130926, 0.1,   -0.6,  2.6
3217.44,   20130927, -0.2,  -0.8,  4.1
3190.4,    20130930, -0.8,  -1.6,  3.7
3220.92,   20131001, 1.0,   -0.2,  4.8
3231.57,   20131002, 0.3,   0.3,   5.0
3247.85,   20131003, 0.5,   0.8,   5.7
3214.05,   20131004, -1.0,  -0.1,  4.2
3215.77,   20131007, 0.1,   0.8,   2.9
3217.15,   20131008, 0.0,   -0.1,  2.9
3161.35,   20131009, -1.7,  -2.2,  2.4
3179.27,   20131010, 0.6,   -2.1,  3.9
3203.49,   20131011, 0.8,   -0.3,  4.5
3213.38,   20131014, 0.3,   -0.1,  3.8
3253.96,   20131015, 1.3,   1.1,   5.0
3261.48,   20131016, 0.2,   3.2,   5.2
3265.91,   20131017, 0.1,   2.7,   4.4
3330.61,   20131018, 2.0,   4.0,   6.0
3361.76,   20131021, 0.9,   4.6,   6.9
3374.17,   20131022, 0.4,   3.7,   5.9
3348.6,    20131023, -0.8,  2.7,   5.8
3347.97,   20131024, -0.0,  2.5,   5.3
3394.16,   20131025, 1.4,   1.9,   6.7
3382.18,   20131028, -0.4,  0.6,   5.6
3397.88,   20131029, 0.5,   0.7,   6.9
3402.49,   20131030, 0.1,   1.6,   6.4
3378.51,   20131031, -0.7,  0.9,   4.2
3392.32,   20131101, 0.4,   -0.1,  4.6
3388.7,    20131104, -0.1,  0.2,   4.5
3372.95,   20131105, -0.5,  -0.7,  4.5
3396.48,   20131106, 0.7,   -0.2,  5.4
3386.36,   20131107, -0.3,  0.2,   5.1
3333.92,   20131108, -1.5,  -1.7,  3.6
3359.83,   20131111, 0.8,   -0.9,  5.3
3351.25,   20131112, -0.3,  -0.6,  4.0
3346.45,   20131113, -0.1,  -1.5,  3.6
3393.25,   20131114, 1.4,   0.2,   4.5
3419.77,   20131115, 0.8,   2.6,   6.4
3423.47,   20131118, 0.1,   1.9,   6.5
3385.82,   20131119, -1.1,  1.0,   5.2
3386.06,   20131120, 0.0,   1.2,   7.1
3382.5,    20131121, -0.1,  -0.3,  6.4
3409.52,   20131122, 0.8,   -0.3,  6.4
3433.92,   20131125, 0.7,   0.3,   6.9
3429.64,   20131126, -0.1,  1.3,   5.4
3454.81,   20131127, 0.7,   2.0,   5.9
3481.31,   20131129, 0.8,   2.9,   6.6
3496.19,   20131202, 0.4,   2.5,   5.0
3478.99,   20131203, -0.5,  1.3,   3.5
3463.72,   20131204, -0.4,  1.0,   2.7
3484.27,   20131205, 0.6,   0.9,   4.1
3511.06,   20131206, 0.8,   0.9,   4.9
3515.67,   20131209, 0.1,   0.6,   3.6
3510.71,   20131210, -0.1,  0.9,   3.8
3514.24,   20131211, 0.1,   1.5,   3.4
3471.9,    20131212, -1.2,  -0.4,  2.0
3476.88,   20131213, 0.1,   -1.0,  2.9
3470.43,   20131216, -0.2,  -1.3,  2.3
3474.73,   20131217, 0.1,   -1.0,  2.5
3468.15,   20131218, -0.2,  -1.3,  2.8
3499.81,   20131219, 0.9,   0.8,   3.0
3502.46,   20131220, 0.1,   0.7,   3.4
3563.19,   20131223, 1.7,   2.7,   6.9
3570.52,   20131224, 0.2,   2.8,   6.3
3579.62,   20131226, 0.3,   3.2,   6.8
3589.12,   20131227, 0.3,   2.6,   7.3
3570.86,   20131230, -0.5,  2.0,   5.2
3576.24,   20131231, 0.2,   0.4,   4.6
3575.6,    20140102, -0.0,  0.1,   4.4
3564.94,   20140103, -0.3,  -0.4,  5.3
3539.02,   20140106, -0.7,  -1.4,  4.5
3539.29,   20140107, 0.0,   -0.9,  4.6
3558.3,    20140108, 0.5,   -0.5,  4.4
3576.33,   20140109, 0.5,   0.0,   4.1
3565.68,   20140110, -0.3,  0.0,   4.0
3559.39,   20140113, -0.2,  0.6,   3.0
3526.2,    20140114, -0.9,  -0.4,  1.3
3593.81,   20140115, 1.9,   1.0,   2.8
3605.43,   20140116, 0.3,   0.8,   3.6
3597.41,   20140117, -0.2,  0.9,   3.9
3611.63,   20140121, 0.4,   1.5,   3.7
3623.31,   20140122, 0.3,   2.8,   3.2
3612.53,   20140123, -0.3,  0.5,   2.8
3596.93,   20140124, -0.4,  -0.2,  2.5
3543.5,    20140127, -1.5,  -1.5,  0.8
3484.39,   20140128, -1.7,  -3.5,  0.4
3472.42,   20140129, -0.3,  -4.2,  -0.1
3514.25,   20140130, 1.2,   -2.7,  1.3
3488.37,   20140131, -0.7,  -3.0,  0.4
3524.23,   20140203, 1.0,   -0.5,  1.6
3460.13,   20140204, -1.8,  -0.7,  -1.1
3457.04,   20140205, -0.1,  -0.4,  -1.3
3465.83,   20140206, 0.3,   -1.4,  -2.7
3522.33,   20140207, 1.6,   1.0,   -1.3
3558.59,   20140210, 1.0,   1.0,   -0.6
3587.58,   20140211, 0.8,   3.7,   -0.0
3626.14,   20140212, 1.1,   4.9,   1.5
3601.75,   20140213, -0.7,  3.9,   0.7
3652.88,   20140214, 1.4,   3.7,   2.2
3668.99,   20140218, 0.4,   3.1,   2.9
3668.72,   20140219, -0.0,  2.3,   3.7
3654.85,   20140220, -0.4,  0.8,   3.3
3683.42,   20140221, 0.8,   2.3,   3.5
3670.07,   20140224, -0.4,  0.5,   2.6
3689.68,   20140225, 0.5,   0.6,   3.5
3691.84,   20140226, 0.1,   0.6,   3.7
3678.31,   20140227, -0.4,  0.6,   4.3
3703.48,   20140228, 0.7,   0.5,   3.1
3657.32,   20140303, -1.2,  -0.3,  1.4
3710.19,   20140304, 1.4,   0.6,   3.1
3721.53,   20140305, 0.3,   0.8,   3.0
3735.85,   20140306, 0.4,   1.6,   3.1
3736.93,   20140307, 0.0,   0.9,   3.4
3702.14,   20140310, -0.9,  1.2,   2.9
3715.89,   20140311, 0.4,   0.2,   4.9
3678.36,   20140312, -1.0,  -1.2,  5.6
3719.36,   20140313, 1.1,   -0.4,  7.1
3643.21,   20140314, -2.0,  -2.5,  3.7
3653.19,   20140317, 0.3,   -1.3,  4.7
3667.4,    20140318, 0.4,   -1.3,  4.1
3705.51,   20140319, 1.0,   0.7,   7.1
3675.62,   20140320, -0.8,  -1.2,  6.3
3711.47,   20140321, 1.0,   1.9,   7.1
3665.38,   20140324, -1.2,  0.3,   4.1
3640.26,   20140325, -0.7,  -0.7,  2.3
3645.73,   20140326, 0.2,   -1.6,  1.6
3578.54,   20140327, -1.8,  -2.6,  -1.3
3576.18,   20140328, -0.1,  -3.6,  -0.7
3598.08,   20140331, 0.6,   -1.8,  -1.5
3615.91,   20140401, 0.5,   -0.7,  -1.4
3670.33,   20140402, 1.5,   0.7,   0.0
3670.43,   20140403, 0.0,   2.6,   0.4
3659.92,   20140404, -0.3,  2.3,   -0.6
3522.21,   20140407, -3.8,  -2.1,  -4.0
3511.82,   20140408, -0.3,  -2.9,  -4.8
3552.07,   20140409, 1.1,   -3.2,  -3.8
3597.58,   20140410, 1.3,   -2.0,  -2.2
3453.24,   20140411, -4.0,  -5.6,  -6.8
3479.66,   20140414, 0.8,   -1.2,  -4.9
3484.29,   20140415, 0.1,   -0.8,  -6.1
3517.5,    20140416, 1.0,   -1.0,  -5.5
3526.57,   20140417, 0.3,   -2.0,  -5.6
3543.65,   20140421, 0.5,   2.6,   -5.2
3570.51,   20140422, 0.8,   2.6,   -3.6
3587.65,   20140423, 0.5,   3.0,   -3.5
3609.83,   20140424, 0.6,   2.6,   -1.9
3569.92,   20140425, -1.1,  1.2,   -4.0
3546.96,   20140428, -0.6,  0.1,   -2.6
3557.77,   20140429, 0.3,   -0.4,  -2.6
3556.0,    20140430, -0.0,  -0.9,  -3.0
3589.66,   20140501, 0.9,   -0.6,  -3.1
3608.84,   20140502, 0.5,   1.1,   -1.8
3566.26,   20140505, -1.2,  0.5,   -3.9
3598.27,   20140506, 0.9,   1.1,   -1.8
3561.47,   20140507, -1.0,  0.2,   -2.2
3533.39,   20140508, -0.8,  -1.6,  -3.1
3537.68,   20140509, 0.1,   -2.0,  -1.1
3574.24,   20140512, 1.0,   0.2,   -0.1
3614.91,   20140513, 1.1,   0.5,   0.5
3603.58,   20140514, -0.3,  1.2,   -0.3
3594.59,   20140515, -0.2,  1.7,   -2.1
3568.2,    20140516, -0.7,  0.9,   -2.8
3577.85,   20140519, 0.3,   0.1,   -2.2
3612.86,   20140520, 1.0,   -0.1,  2.6
3605.79,   20140521, -0.2,  0.1,   2.7
3639.49,   20140522, 0.9,   1.2,   2.5
3656.27,   20140523, 0.5,   2.5,   1.6
3693.18,   20140527, 1.0,   3.2,   6.9
3720.89,   20140528, 0.8,   3.0,   6.9
3723.27,   20140529, 0.1,   3.3,   6.9
3738.71,   20140530, 0.4,   2.7,   6.3
3740.71,   20140602, 0.1,   2.3,   6.1
3719.17,   20140603, -0.6,  0.7,   5.0
3720.0,    20140604, 0.0,   -0.0,  4.2
3748.36,   20140605, 0.8,   0.7,   4.5
3792.42,   20140606, 1.2,   1.4,   5.1
3795.85,   20140609, 0.1,   1.5,   6.3
3790.48,   20140610, -0.1,  1.9,   6.9
3789.41,   20140611, -0.0,  1.9,   6.5
3790.59,   20140612, 0.0,   1.1,   6.6
3782.84,   20140613, -0.2,  -0.3,  5.4
3768.37,   20140616, -0.4,  -0.7,  4.4
3775.73,   20140617, 0.2,   -0.4,  5.9
3786.19,   20140618, 0.3,   -0.1,  5.2
3808.76,   20140619, 0.6,   0.5,   6.9
3803.1,    20140620, -0.1,  0.5,   7.6
3802.25,   20140623, -0.0,  0.9,   7.5
3807.49,   20140624, 0.1,   0.8,   6.5
3794.84,   20140625, -0.3,  0.2,   5.0
3826.7,    20140626, 0.8,   0.5,   6.2
3825.02,   20140627, -0.0,  0.6,   6.4
3844.45,   20140630, 0.5,   1.1,   7.7
3864.14,   20140701, 0.5,   1.5,   8.0
3895.18,   20140702, 0.8,   2.6,   7.8
3911.11,   20140703, 0.4,   2.2,   8.5
3917.89,   20140707, 0.2,   2.4,   7.6
3903.37,   20140708, -0.4,  1.5,   6.8
3873.17,   20140709, -0.8,  0.2,   4.9
3837.29,   20140710, -0.9,  -1.5,  3.1
3888.76,   20140711, 1.3,   -0.6,  4.4
3923.6,    20140714, 0.9,   0.1,   4.9
3934.79,   20140715, 0.3,   0.8,   5.2
3943.6,    20140716, 0.2,   1.8,   6.0
3921.87,   20140717, -0.6,  2.2,   5.4
3899.38,   20140718, -0.6,  0.3,   4.0
3933.11,   20140721, 0.9,   0.2,   3.7
3950.83,   20140722, 0.5,   0.4,   4.1
3974.66,   20140723, 0.6,   0.8,   4.9
3994.18,   20140724, 0.5,   1.8,   5.4
3959.35,   20140725, -0.9,  1.5,   4.5
3966.31,   20140728, 0.2,   0.8,   4.9
3974.06,   20140729, 0.2,   0.6,   5.5
3982.1,    20140730, 0.2,   0.2,   5.5
3938.31,   20140731, -1.1,  -1.4,  4.0
3886.1,    20140801, -1.3,  -1.9,  2.0
3890.63,   20140804, 0.1,   -1.9,  2.3
3891.13,   20140805, 0.0,   -2.1,  2.3
3851.44,   20140806, -1.0,  -3.3,  1.2
3889.69,   20140807, 1.0,   -1.2,  2.5
3864.09,   20140808, -0.7,  -0.6,  1.0
3899.98,   20140811, 0.9,   0.2,   2.0
3904.45,   20140812, 0.1,   0.3,   1.6
3924.47,   20140813, 0.5,   1.9,   1.6
3953.27,   20140814, 0.7,   1.6,   1.5
3993.0,    20140815, 1.0,   3.3,   2.1
4007.19,   20140818, 0.4,   2.7,   2.3
4025.51,   20140819, 0.5,   3.1,   3.1
4032.91,   20140820, 0.2,   2.8,   4.1
4041.0,    20140821, 0.2,   2.2,   5.3
4052.14,   20140822, 0.3,   1.5,   4.2
4073.48,   20140825, 0.5,   1.7,   3.8
4073.05,   20140826, -0.0,  1.2,   3.5
4073.4,    20140827, 0.0,   1.0,   3.3
4059.37,   20140828, -0.3,  0.5,   3.5
4081.02,   20140829, 0.5,   0.7,   4.7
4093.12,   20140902, 0.3,   0.5,   4.1
4103.97,   20140903, 0.3,   0.8,   3.9
4078.82,   20140904, -0.6,  0.1,   2.6
4067.37,   20140905, -0.3,  0.2,   1.8
4086.52,   20140908, 0.5,   0.1,   3.2
4094.02,   20140909, 0.2,   0.0,   3.2
4063.85,   20140910, -0.7,  -1.0,  2.3
4077.69,   20140911, 0.3,   -0.0,  2.4
4087.17,   20140912, 0.2,   0.5,   3.8
4070.2,    20140915, -0.4,  -0.4,  4.7
4012.53,   20140916, -1.4,  -2.0,  3.1
4066.76,   20140917, 1.4,   0.1,   4.5
4084.51,   20140918, 0.4,   0.2,   6.1
4113.02,   20140919, 0.7,   0.6,   5.7
4091.87,   20140922, -0.5,  0.5,   5.9
4045.67,   20140923, -1.1,  0.8,   3.7
4055.42,   20140924, 0.2,   -0.3,  3.9
4079.37,   20140925, 0.6,   -0.1,  3.9
4016.97,   20140926, -1.5,  -2.3,  1.6
4009.66,   20140929, -0.2,  -2.0,  0.4
4054.86,   20140930, 1.1,   0.2,   1.2
4043.33,   20141001, -0.3,  -0.3,  0.4
3984.8,    20141002, -1.4,  -2.3,  -1.2
4004.75,   20141003, 0.5,   -0.3,  -0.9
4041.4,    20141006, 0.9,   0.8,   -0.3
3999.25,   20141007, -1.0,  -1.4,  -1.8
3960.79,   20141008, -1.0,  -2.0,  -2.8
4031.62,   20141009, 1.8,   1.2,   -1.0
3946.32,   20141010, -2.1,  -1.5,  -2.8
3866.89,   20141013, -2.0,  -4.3,  -5.2
3837.22,   20141014, -0.8,  -4.1,  -6.3
3738.72,   20141015, -2.6,  -5.6,  -8.9
3706.7,    20141016, -0.9,  -8.1,  -9.1
3815.72,   20141017, 2.9,   -3.3,  -6.2
3813.6,    20141020, -0.1,  -1.4,  -6.7
3916.65,   20141021, 2.7,   2.1,   -4.3
3980.49,   20141022, 1.6,   6.5,   -2.1
3989.86,   20141023, 0.2,   7.6,   -2.2
4019.84,   20141024, 0.8,   5.3,   -1.6
4031.88,   20141027, 0.3,   5.7,   -0.9
4063.07,   20141028, 0.8,   3.7,   1.3
4091.62,   20141029, 0.7,   2.8,   0.6
4074.26,   20141030, -0.4,  2.1,   -0.3
4168.5,    20141031, 2.3,   3.7,   1.3
4160.6,    20141103, -0.2,  3.2,   1.7
4154.88,   20141104, -0.1,  2.3,   2.7
4179.23,   20141105, 0.6,   2.1,   3.1
4147.31,   20141106, -0.8,  1.8,   1.7
4170.46,   20141107, 0.6,   0.0,   3.8
4162.59,   20141110, -0.2,  0.0,   3.8
4173.61,   20141111, 0.3,   0.5,   2.9
4174.47,   20141112, 0.0,   -0.1,  3.2
4202.77,   20141113, 0.7,   1.3,   5.5
4214.97,   20141114, 0.3,   1.1,   5.2
4215.29,   20141117, 0.0,   1.3,   4.3
4215.18,   20141118, -0.0,  1.0,   5.4
4233.71,   20141119, 0.4,   1.4,   6.9
4204.54,   20141120, -0.7,  0.0,   4.3
4284.41,   20141121, 1.9,   1.6,   8.6
4262.09,   20141124, -0.5,  1.1,   10.2
4290.68,   20141125, 0.7,   1.8,   11.8
4291.87,   20141126, 0.0,   1.4,   14.8
4330.96,   20141128, 0.9,   3.0,   16.8
4323.95,   20141201, -0.2,  0.9,   13.3
4291.33,   20141202, -0.8,  0.7,   12.5
4312.17,   20141203, 0.5,   0.5,   10.1
4309.48,   20141204, -0.1,  0.4,   8.3
4318.28,   20141205, 0.2,   -0.3,  8.2
4301.27,   20141208, -0.4,  -0.5,  7.0
4228.21,   20141209, -1.7,  -1.5,  4.9
4284.5,    20141210, 1.3,   -0.6,  5.4
4241.69,   20141211, -1.0,  -1.6,  3.7
4207.61,   20141212, -0.8,  -2.6,  3.3
4224.17,   20141215, 0.4,   -1.8,  1.3
4122.23,   20141216, -2.4,  -2.5,  -0.9
4095.75,   20141217, -0.6,  -4.4,  -1.4
4230.35,   20141218, 3.3,   -0.3,  1.2
4272.75,   20141219, 1.0,   1.5,   3.0
4271.87,   20141222, -0.0,  1.1,   2.4
4308.95,   20141223, 0.9,   4.5,   3.5
4283.19,   20141224, -0.6,  4.6,   2.6
4295.72,   20141226, 0.3,   1.5,   2.9
4309.7,    20141229, 0.3,   0.9,   2.5
4301.14,   20141230, -0.2,  0.7,   2.0
4294.88,   20141231, -0.1,  -0.3,  1.9
4258.6,    20150102, -0.8,  -0.6,  1.0
4206.55,   20150105, -1.2,  -2.1,  -0.6
4174.78,   20150106, -0.8,  -3.1,  -0.7
4139.85,   20150107, -0.8,  -3.7,  -3.4
4195.49,   20150108, 1.3,   -2.3,  -1.6
4250.98,   20150109, 1.3,   -0.2,  -0.9
4221.52,   20150112, -0.7,  0.4,   -1.6
4212.99,   20150113, -0.2,  0.9,   -2.7
4122.08,   20150114, -2.2,  -0.4,  -4.7
4162.11,   20150115, 1.0,   -0.8,  -3.0
4087.88,   20150116, -1.8,  -3.8,  -5.2
4164.43,   20150120, 1.9,   -1.4,  -3.4
4158.13,   20150121, -0.2,  -1.3,  -3.7
4215.0,    20150122, 1.4,   2.3,   -2.0
4268.53,   20150123, 1.3,   2.6,   1.0
4271.1,    20150126, 0.1,   4.5,   -0.3
4200.88,   20150127, -1.6,  0.9,   -1.0
4233.74,   20150128, 0.8,   1.8,   0.6
4134.52,   20150129, -2.3,  -1.9,  -2.1
4178.62,   20150130, 1.1,   -2.1,  1.4
4162.2,    20150202, -0.4,  -2.5,  1.6
4201.94,   20150203, 1.0,   0.0,   -0.7
4202.43,   20150204, 0.0,   -0.7,  -1.6
4229.62,   20150205, 0.6,   2.3,   -1.0
4259.98,   20150206, 0.7,   1.9,   -1.1
4209.81,   20150209, -1.2,  1.1,   -1.7
4240.04,   20150210, 0.7,   0.9,   -1.3
4284.76,   20150211, 1.1,   2.0,   -0.6
4321.41,   20150212, 0.9,   2.2,   0.5
4359.78,   20150213, 0.9,   2.3,   1.5
4379.32,   20150217, 0.4,   4.0,   2.8
4378.73,   20150218, -0.0,  3.3,   4.1
4388.92,   20150219, 0.2,   2.4,   5.1
4406.75,   20150220, 0.4,   2.0,   6.4
4441.26,   20150223, 0.8,   1.9,   5.9
4442.13,   20150224, 0.0,   1.4,   4.5
4441.08,   20150225, -0.0,  1.4,   5.2
4443.55,   20150226, 0.1,   1.2,   5.5
4459.86,   20150227, 0.4,   1.2,   8.2
4452.8,    20150302, -0.2,  0.3,   7.0
4466.53,   20150303, 0.3,   0.5,   9.3
4442.41,   20150304, -0.5,  0.0,   6.7
4454.89,   20150305, 0.3,   0.3,   7.1
4442.19,   20150306, -0.3,  -0.4,  5.4
4406.87,   20150309, -0.8,  -1.0,  3.2
4376.03,   20150310, -0.7,  -2.0,  2.5
4336.05,   20150311, -0.9,  -2.4,  3.2
4302.73,   20150312, -0.8,  -3.4,  1.6
4328.09,   20150313, 0.6,   -2.6,  4.7
4338.29,   20150316, 0.2,   -1.6,  3.8
4355.83,   20150317, 0.4,   -0.5,  4.7
4366.8,    20150318, 0.3,   0.7,   3.9
4424.79,   20150319, 1.3,   2.8,   5.3
4468.29,   20150320, 1.0,   3.2,   5.6
4454.15,   20150323, -0.3,  2.7,   4.6
4444.39,   20150324, -0.2,  2.0,   5.6
4437.38,   20150325, -0.2,  1.6,   4.7
4288.16,   20150326, -3.4,  -3.1,  0.1
4314.6,    20150327, 0.6,   -3.4,  -0.2
4359.78,   20150330, 1.0,   -2.1,  0.0
4364.14,   20150331, 0.1,   -1.8,  -0.3
4328.17,   20150401, -0.8,  -2.5,  -1.2
4316.24,   20150402, -0.3,  0.7,   -1.7
4286.16,   20150406, -0.7,  -0.7,  -2.7
4349.3,    20150407, 1.5,   -0.2,  -2.1
4345.23,   20150408, -0.1,  -0.4,  -2.2
4375.78,   20150409, 0.7,   1.1,   -1.5
4402.02,   20150410, 0.6,   2.0,   -0.9
4428.13,   20150413, 0.6,   3.3,   -0.7
4409.29,   20150414, -0.4,  1.4,   -1.0
4409.74,   20150415, 0.0,   1.5,   -1.3
4412.15,   20150416, 0.1,   0.8,   -0.7
4379.63,   20150417, -0.7,  -0.5,  -1.7
4373.65,   20150420, -0.1,  -1.2,  -1.5
4444.5,    20150421, 1.6,   0.8,   0.9
4448.58,   20150422, 0.1,   0.9,   1.7
4445.17,   20150423, -0.1,  0.7,   2.5
4529.73,   20150424, 1.9,   3.4,   5.3
4548.51,   20150427, 0.4,   4.0,   5.1
4529.41,   20150428, -0.4,  1.9,   4.4
4490.57,   20150429, -0.9,  0.9,   3.1
4459.23,   20150430, -0.7,  0.3,   2.1
4437.7,    20150501, -0.5,  -2.0,  0.3
4491.19,   20150504, 1.2,   -1.3,  0.5
4464.21,   20150505, -0.6,  -1.4,  0.2
4420.3,    20150506, -1.0,  -1.6,  -0.5
4377.81,   20150507, -1.0,  -1.8,  -1.3
4445.0,    20150508, 1.5,   0.2,   3.7
4457.62,   20150511, 0.3,   -0.7,  3.3
4412.82,   20150512, -1.0,  -1.2,  1.2
4436.08,   20150513, 0.5,   0.4,   1.6
4461.07,   20150514, 0.6,   1.9,   3.1
4505.39,   20150515, 1.0,   1.4,   4.4
4487.14,   20150518, -0.4,  0.7,   4.7
4514.67,   20150519, 0.6,   2.3,   3.8
4505.59,   20150520, -0.2,  1.6,   3.7
4499.42,   20150521, -0.1,  0.9,   2.8
4522.86,   20150522, 0.5,   0.4,   2.7
4516.63,   20150526, -0.1,  0.7,   2.0
4488.05,   20150527, -0.6,  -0.6,  1.8
4537.48,   20150528, 1.1,   0.7,   2.9
4532.15,   20150529, -0.1,  0.7,   2.7
4530.03,   20150601, -0.0,  0.2,   3.4
4503.76,   20150602, -0.6,  -0.3,  3.0
4531.04,   20150603, 0.6,   1.0,   1.9
4501.73,   20150604, -0.6,  -0.8,  1.2
4483.43,   20150605, -0.4,  -1.1,  0.9
4476.69,   20150608, -0.2,  -1.2,  -1.2
4421.02,   20150609, -1.2,  -1.8,  -2.8
4440.54,   20150610, 0.4,   -2.0,  -2.0
4498.49,   20150611, 1.3,   -0.1,  0.2
4467.38,   20150612, -0.7,  -0.4,  0.2
4416.14,   20150615, -1.1,  -1.4,  -0.5
4428.3,    20150616, 0.3,   0.2,   -1.4
4465.17,   20150617, 0.8,   0.6,   0.0
4483.61,   20150618, 0.4,   -0.3,  1.4
4538.71,   20150619, 1.2,   1.6,   3.7
4541.85,   20150622, 0.1,   2.8,   2.2
4549.52,   20150623, 0.2,   2.7,   2.1
4542.07,   20150624, -0.2,  1.7,   2.9
4540.4,    20150625, -0.0,  1.3,   2.4
4514.37,   20150626, -0.6,  -0.5,  1.2
4433.11,   20150629, -1.8,  -2.4,  -1.6
4414.86,   20150630, -0.4,  -3.0,  -1.6
4433.79,   20150701, 0.4,   -2.4,  -1.8
4440.17,   20150702, 0.1,   -2.2,  -1.5
4394.44,   20150706, -1.0,  -2.7,  -2.3
4422.75,   20150707, 0.6,   -0.2,  -2.2
4392.51,   20150708, -0.7,  -0.5,  -2.7
4410.27,   20150709, 0.4,   -0.5,  -1.7
4405.99,   20150710, -0.1,  -0.8,  -2.9
4457.76,   20150713, 1.2,   1.4,   -1.6
4503.2,    20150714, 1.0,   1.8,   -0.6
4531.32,   20150715, 0.6,   3.2,   0.6
4567.06,   20150716, 0.8,   3.6,   0.8
4635.48,   20150717, 1.5,   5.2,   3.0
4670.23,   20150720, 0.7,   4.8,   4.2
4679.54,   20150721, 0.2,   3.9,   4.5
4604.97,   20150722, -1.6,  1.6,   4.2
4627.56,   20150723, 0.5,   1.3,   4.2
4630.95,   20150724, 0.1,   -0.1,  2.9
4530.37,   20150727, -2.2,  -3.0,  1.4
4539.47,   20150728, 0.2,   -3.0,  2.8
4570.5,    20150729, 0.7,   -0.7,  3.2
4570.77,   20150730, 0.0,   -1.2,  2.4
4616.48,   20150731, 1.0,   -0.3,  3.0
4595.26,   20150803, -0.5,  1.4,   1.2
4573.25,   20150804, -0.5,  0.7,   0.7
4592.81,   20150805, 0.4,   0.5,   1.0
4608.69,   20150806, 0.3,   0.8,   1.5
4519.36,   20150807, -1.9,  -2.1,  -0.5
4558.68,   20150810, 0.9,   -0.8,  1.0
4548.75,   20150811, -0.2,  -0.5,  2.6
4475.91,   20150812, -1.6,  -2.5,  1.4
4536.96,   20150813, 1.4,   -1.6,  2.3
4512.88,   20150814, -0.5,  -0.1,  1.6
4516.88,   20150817, 0.1,   -0.9,  2.8
4556.23,   20150818, 0.9,   0.2,   3.0
4525.32,   20150819, -0.7,  1.1,   3.0
4467.79,   20150820, -1.3,  -1.5,  1.3
4313.19,   20150821, -3.5,  -4.4,  -2.1
3847.12,   20150824, -10.8, -14.8, -13.7
4187.28,   20150825, 8.8,   -8.1,  -7.0
4141.24,   20150826, -1.1,  -8.5,  -8.6
4281.63,   20150827, 3.4,   -4.2,  -6.2
4307.05,   20150828, 0.6,   -0.1,  -7.1
4299.88,   20150831, -0.2,  11.8,  -7.9
4177.94,   20150901, -2.8,  -0.2,  -10.7
4207.68,   20150902, 0.7,   1.6,   -8.6
4267.34,   20150903, 1.4,   -0.3,  -7.8
4175.62,   20150904, -2.1,  -3.1,  -9.8
4267.4,    20150908, 2.2,   -0.8,  -5.8
4346.36,   20150909, 1.9,   4.0,   -4.3
4248.48,   20150910, -2.3,  1.0,   -7.0
4273.62,   20150911, 0.6,   0.1,   -6.5
4334.86,   20150914, 1.4,   3.8,   -6.1
4322.41,   20150915, -0.3,  1.3,   -5.9
4357.36,   20150916, 0.8,   0.3,   -4.7
4378.99,   20150917, 0.5,   3.1,   -4.7
4325.29,   20150918, -1.2,  1.2,   -6.1
4343.77,   20150921, 0.4,   0.2,   -3.9
4273.3,    20150922, -1.6,  -1.1,  -6.3
4280.01,   20150923, 0.2,   -1.8,  -5.9
4239.85,   20150924, -0.9,  -3.2,  -5.3
4305.39,   20150925, 1.5,   -0.5,  -5.1
4206.68,   20150928, -2.3,  -3.2,  -6.8
4110.26,   20150929, -2.3,  -3.8,  -9.0
4137.18,   20150930, 0.7,   -3.3,  -9.2
4185.28,   20151001, 1.2,   -1.3,  -7.5
4136.96,   20151002, -1.2,  -3.9,  -7.4
4296.54,   20151005, 3.9,   2.1,   -0.4
4315.32,   20151006, 0.4,   5.0,   12.2
4331.87,   20151007, 0.4,   4.7,   3.5
4316.18,   20151008, -0.4,  3.1,   4.2
4355.24,   20151009, 0.9,   5.3,   1.7
4378.64,   20151012, 0.5,   1.9,   1.7
4354.06,   20151013, -0.6,  0.9,   1.3
4356.82,   20151014, 0.1,   0.6,   4.3
4361.98,   20151015, 0.1,   1.1,   3.7
4419.95,   20151016, 1.3,   1.5,   3.6
4428.06,   20151019, 0.2,   1.1,   6.0
4454.62,   20151020, 0.6,   2.3,   4.4
4462.22,   20151021, 0.2,   2.4,   2.7
4451.32,   20151022, -0.2,  2.0,   4.8
4623.72,   20151023, 3.9,   4.6,   8.2
4621.14,   20151026, -0.1,  4.4,   6.6
4617.6,    20151027, -0.1,  3.7,   6.8
4649.1,    20151028, 0.7,   4.2,   6.7
4657.78,   20151029, 0.2,   4.6,   6.4
4676.3,    20151030, 0.4,   1.1,   8.1
4658.79,   20151102, -0.4,  0.8,   7.3
4692.03,   20151103, 0.7,   1.6,   9.8
4729.07,   20151104, 0.8,   1.7,   10.5
4718.51,   20151105, -0.2,  1.3,   11.3
4695.88,   20151106, -0.5,  0.4,   9.1
4687.35,   20151109, -0.2,  0.6,   11.4
4628.83,   20151110, -1.2,  -1.3,  12.6
4657.19,   20151111, 0.6,   -1.5,  12.6
4617.78,   20151112, -0.8,  -2.1,  10.3
4566.12,   20151113, -1.1,  -2.8,  10.4
4494.56,   20151116, -1.6,  -4.1,  4.6
4571.55,   20151117, 1.7,   -1.2,  5.9
4586.24,   20151118, 0.3,   -1.5,  5.9
4657.61,   20151119, 1.6,   0.9,   7.9
4680.51,   20151120, 0.5,   2.5,   7.5
4689.98,   20151123, 0.2,   4.3,   7.1
4645.52,   20151124, -0.9,  1.6,   6.7
4674.23,   20151125, 0.6,   1.9,   7.3
4680.44,   20151127, 0.1,   0.5,   7.3
4692.57,   20151130, 0.3,   0.3,   6.2
4685.74,   20151201, -0.1,  -0.1,  5.8
4720.27,   20151202, 0.7,   1.6,   6.0
4704.2,    20151203, -0.3,  0.6,   5.4
4621.48,   20151204, -1.8,  -1.3,  3.8
4718.55,   20151207, 2.1,   0.6,   2.1
4646.43,   20151208, -1.5,  -0.8,  0.5
4674.8,    20151209, 0.6,   -1.0,  1.2
4627.77,   20151210, -1.0,  -1.6,  -0.5
4583.08,   20151211, -1.0,  -0.8,  -1.6
4535.56,   20151214, -1.0,  -3.9,  -3.0
4607.2,    20151215, 1.6,   -0.8,  -1.1
4633.85,   20151216, 0.6,   -0.9,  -1.2
4680.63,   20151217, 1.0,   1.1,   -1.0
4578.69,   20151218, -2.2,  -0.1,  -3.0
4547.92,   20151221, -0.7,  0.3,   -3.2
4580.61,   20151222, 0.7,   -0.6,  -2.3
4612.16,   20151223, 0.7,   -0.5,  -0.4
4626.32,   20151224, 0.3,   -1.2,  -0.7
4610.41,   20151228, -0.3,  0.7,   -0.2
4645.08,   20151229, 0.8,   2.1,   1.7
4687.16,   20151230, 0.9,   2.3,   4.3
4634.16,   20151231, -1.1,  0.5,   1.4
4485.06,   20160104, -3.2,  -3.1,  -2.2
4512.04,   20160105, 0.6,   -2.1,  -3.1
4409.78,   20160106, -2.3,  -5.1,  -5.8
4349.14,   20160107, -1.4,  -7.2,  -7.3
4336.12,   20160108, -0.3,  -6.4,  -6.7
4300.97,   20160111, -0.8,  -4.1,  -8.0
4322.77,   20160112, 0.5,   -4.2,  -7.6
4352.64,   20160113, 0.7,   -1.3,  -7.2
4201.23,   20160114, -3.5,  -3.4,  -10.3
4123.87,   20160115, -1.8,  -4.9,  -12.6
4199.56,   20160119, 1.8,   -2.4,  -10.7
4078.23,   20160120, -2.9,  -5.7,  -11.8
4146.45,   20160121, 1.7,   -4.7,  -12.1
4226.44,   20160122, 1.9,   0.6,   -9.0
4245.47,   20160125, 0.5,   2.9,   -9.2
4211.92,   20160126, -0.8,  0.3,   -9.0
4214.94,   20160127, 0.1,   3.4,   -8.0
4197.2,    20160128, -0.4,  1.2,   -7.5
4186.35,   20160129, -0.3,  -0.9,  -9.1
4254.41,   20160201, 1.6,   0.2,   -8.2
4262.45,   20160202, 0.2,   1.2,   -8.9
4213.67,   20160203, -1.1,  -0.0,  -8.0
4158.05,   20160204, -1.3,  -0.9,  -8.6
4150.35,   20160205, -0.2,  -0.9,  -9.4
3947.99,   20160208, -4.9,  -7.2,  -14.4
3903.93,   20160209, -1.1,  -8.4,  -15.6
3996.76,   20160210, 2.4,   -5.1,  -13.3
3910.57,   20160211, -2.2,  -6.0,  -15.8
3996.64,   20160212, 2.2,   -3.7,  -14.7
4077.33,   20160216, 2.0,   3.3,   -12.0
4138.43,   20160217, 1.5,   6.0,   -7.7
4212.22,   20160218, 1.8,   5.4,   -6.6
4129.75,   20160219, -2.0,  5.6,   -6.4
4203.6,    20160222, 1.8,   5.2,   -3.3
4209.32,   20160223, 0.1,   3.2,   -2.9
4116.02,   20160224, -2.2,  -0.5,  -4.3
4212.48,   20160225, 2.3,   0.0,   -2.6
4273.59,   20160226, 1.5,   3.5,   -1.8
4228.57,   20160229, -1.1,  0.6,   0.7
4238.08,   20160301, 0.2,   0.7,   2.8
4328.72,   20160302, 2.1,   5.2,   3.1
4330.4,    20160303, 0.0,   2.8,   6.2
4334.03,   20160304, 0.1,   1.4,   4.5
4303.81,   20160307, -0.7,  1.8,   1.8
4272.72,   20160308, -0.7,  0.8,   0.6
4281.1,    20160309, 0.2,   -1.1,  1.6
4307.87,   20160310, 0.6,   -0.5,  2.2
4337.12,   20160311, 0.7,   0.1,   3.3
4347.94,   20160314, 0.2,   1.0,   3.9
4354.38,   20160315, 0.1,   1.9,   2.3
4359.67,   20160316, 0.1,   1.8,   2.3
4390.06,   20160317, 0.7,   1.9,   4.2
4408.82,   20160318, 0.4,   1.7,   6.0
4403.09,   20160321, -0.1,  1.3,   6.1
4403.47,   20160322, 0.0,   1.1,   11.5
4431.82,   20160323, 0.6,   1.7,   13.5
4380.23,   20160324, -1.2,  -0.2,  9.6
4415.04,   20160328, 0.8,   0.1,   12.9
4391.19,   20160329, -0.5,  -0.3,  9.9
4494.7,    20160330, 2.4,   2.1,   10.2
4491.01,   20160331, -0.1,  1.3,   8.5
4461.77,   20160401, -0.7,  1.9,   5.9
4526.39,   20160404, 1.4,   2.5,   9.6
4479.11,   20160405, -1.0,  2.0,   6.6
4476.35,   20160406, -0.1,  -0.4,  6.3
4519.02,   20160407, 1.0,   0.6,   9.8
4506.97,   20160408, -0.3,  1.0,   7.0
4496.26,   20160411, -0.2,  -0.7,  5.2
4464.07,   20160412, -0.7,  -0.3,  5.6
4526.92,   20160413, 1.4,   1.1,   6.8
4554.66,   20160414, 0.6,   0.8,   5.2
4550.31,   20160415, -0.1,  1.0,   5.1
4535.21,   20160418, -0.3,  0.9,   4.6
4573.58,   20160419, 0.8,   2.5,   6.3
4539.2,    20160420, -0.8,  0.3,   6.2
4540.71,   20160421, 0.0,   -0.3,  6.1
4478.45,   20160422, -1.4,  -1.6,  4.0
4459.67,   20160425, -0.4,  -1.7,  2.8
4480.6,    20160426, 0.5,   -2.0,  3.1
4412.82,   20160427, -1.5,  -2.8,  1.3
4419.12,   20160428, 0.1,   -2.7,  1.4
4367.59,   20160429, -1.2,  -2.5,  -0.5
4350.83,   20160502, -0.4,  -2.4,  -1.3
4349.08,   20160503, -0.0,  -2.9,  -1.2
4314.5,    20160504, -0.8,  -2.2,  -2.0
4326.91,   20160505, 0.3,   -2.1,  -2.4
4290.36,   20160506, -0.8,  -1.8,  -2.1
4331.66,   20160509, 1.0,   -0.4,  -1.9
4360.73,   20160510, 0.7,   0.3,   -0.7
4395.88,   20160511, 0.8,   1.9,   -2.2
4376.09,   20160512, -0.5,  1.1,   -2.6
4337.83,   20160513, -0.9,  1.1,   -2.8
4335.33,   20160516, -0.1,  0.1,   -4.2
4376.55,   20160517, 1.0,   0.4,   -2.3
4314.34,   20160518, -1.4,  -1.9,  -3.6
4319.86,   20160519, 0.1,   -1.3,  -4.4
4330.07,   20160520, 0.2,   -0.2,  -3.9
4364.73,   20160523, 0.8,   0.7,   -2.9
4381.34,   20160524, 0.4,   0.1,   -1.9
4459.26,   20160525, 1.8,   3.4,   -1.5
4478.57,   20160526, 0.4,   3.7,   -1.7
4490.96,   20160527, 0.3,   3.7,   -1.3
4513.85,   20160531, 0.5,   3.4,   -0.5
4507.18,   20160601, -0.1,  2.9,   -1.5
4510.81,   20160602, 0.1,   1.2,   -0.6
4520.98,   20160603, 0.2,   0.9,   -0.4
4517.88,   20160606, -0.1,  0.6,   0.9
4527.86,   20160607, 0.2,   0.3,   1.5
4521.1,    20160608, -0.1,  0.3,   0.9
4501.15,   20160609, -0.4,  -0.2,  2.0
4473.08,   20160610, -0.6,  -1.1,  1.2
4436.12,   20160613, -0.8,  -1.8,  1.6
4413.71,   20160614, -0.5,  -2.5,  1.4
4433.68,   20160615, 0.5,   -1.9,  1.9
4387.9,    20160616, -1.0,  -2.5,  1.7
4409.75,   20160617, 0.5,   -1.4,  1.9
4424.11,   20160620, 0.3,   -0.3,  3.1
4408.03,   20160621, -0.4,  -0.1,  1.8
4415.46,   20160622, 0.2,   -0.4,  1.3
4437.61,   20160623, 0.5,   1.1,   0.9
4294.8,    20160624, -3.2,  -2.6,  -1.9
4247.71,   20160627, -1.1,  -4.0,  -2.1
4245.32,   20160628, -0.1,  -3.7,  -2.1
4323.33,   20160629, 1.8,   -2.1,  -1.2
4378.75,   20160630, 1.3,   -1.3,  1.5
4411.77,   20160701, 0.8,   2.7,   2.1
4414.35,   20160705, 0.1,   3.9,   1.9
4388.43,   20160706, -0.6,  3.4,   0.5
4451.15,   20160707, 1.4,   3.0,   1.6
4484.92,   20160708, 0.8,   2.4,   0.6
4544.33,   20160711, 1.3,   3.0,   1.5
4579.85,   20160712, 0.8,   3.7,   2.0
4589.13,   20160713, 0.2,   4.6,   1.7
4597.19,   20160714, 0.2,   3.3,   2.0
4602.0,    20160715, 0.1,   2.6,   2.0
4595.06,   20160718, -0.2,  1.1,   1.6
4601.68,   20160719, 0.1,   0.5,   1.9
4630.85,   20160720, 0.6,   0.9,   2.3
4662.95,   20160721, 0.7,   1.4,   3.1
4650.98,   20160722, -0.3,  1.1,   3.3
4662.9,    20160725, 0.3,   1.5,   4.2
4664.1,    20160726, 0.0,   1.4,   5.1
4711.11,   20160727, 1.0,   1.7,   6.7
4710.23,   20160728, -0.0,  1.0,   6.2
4734.7,    20160729, 0.5,   1.8,   7.9
4734.44,   20160801, -0.0,  1.5,   7.4
4748.42,   20160802, 0.3,   1.8,   7.3
4716.58,   20160803, -0.7,  0.1,   7.0
4731.54,   20160804, 0.3,   0.5,   7.2
4769.17,   20160805, 0.8,   0.7,   7.5
4791.1,    20160808, 0.5,   1.2,   11.6
4787.92,   20160809, -0.1,  0.8,   12.7
4799.83,   20160810, 0.2,   1.8,   13.1
4800.59,   20160811, 0.0,   1.5,   11.0
4794.35,   20160812, -0.1,  0.5,   9.5
4814.77,   20160815, 0.4,   0.5,   9.1
4814.21,   20160816, -0.0,  0.5,   9.1
4799.03,   20160817, -0.3,  -0.0,  9.4
4802.33,   20160818, 0.1,   0.0,   7.9
4800.2,    20160819, -0.0,  0.1,   7.0
4797.95,   20160822, -0.0,  -0.3,  5.6
4828.79,   20160823, 0.6,   0.3,   5.4
4812.49,   20160824, -0.3,  0.3,   4.9
4773.68,   20160825, -0.8,  -0.6,  3.8
4782.46,   20160826, 0.2,   -0.4,  3.9
4787.93,   20160829, 0.1,   -0.2,  4.2
4787.77,   20160830, -0.0,  -0.8,  4.0
4770.23,   20160831, -0.4,  -0.9,  3.0
4774.81,   20160901, 0.1,   0.0,   2.4
4806.14,   20160902, 0.7,   0.5,   3.3
4806.66,   20160906, 0.0,   0.4,   3.1
4829.91,   20160907, 0.5,   0.9,   3.6
4816.48,   20160908, -0.3,  1.0,   2.2
4763.04,   20160909, -1.1,  -0.2,  1.1
4656.59,   20160912, -2.2,  -3.1,  -1.6
4740.0,    20160913, 1.8,   -1.4,  0.1
4726.75,   20160914, -0.3,  -2.1,  -0.5
4749.71,   20160915, 0.5,   -1.4,  0.7
4811.79,   20160916, 1.3,   1.0,   1.7
4833.84,   20160919, 0.5,   3.8,   1.4
4813.78,   20160920, -0.4,  1.6,   0.5
4826.58,   20160921, 0.3,   2.1,   0.8
4879.28,   20160922, 1.1,   2.7,   1.7
4881.45,   20160923, 0.0,   1.4,   1.7
4830.17,   20160926, -1.1,  -0.1,  0.7
4813.89,   20160927, -0.3,  0.0,   -0.0
4871.84,   20160928, 1.2,   0.9,   1.2
4868.46,   20160929, -0.1,  -0.2,  1.4
4856.1,    20160930, -0.3,  -0.5,  1.1
4864.63,   20161003, 0.2,   0.7,   1.3
4880.57,   20161004, 0.3,   1.4,   1.7
4872.98,   20161005, -0.2,  0.0,   0.9
4871.95,   20161006, -0.0,  0.1,   1.2
4883.94,   20161007, 0.2,   0.6,   2.3
4887.73,   20161010, 0.1,   0.5,   2.2
4890.17,   20161011, 0.0,   0.2,   2.1
4824.27,   20161012, -1.3,  -1.0,  0.8
4785.59,   20161013, -0.8,  -1.8,  0.3
4828.9,    20161014, 0.9,   -1.1,  1.1
4808.47,   20161017, -0.4,  -1.6,  0.0
4849.91,   20161018, 0.9,   -0.8,  0.9
4833.81,   20161019, -0.3,  0.2,   0.1
4831.31,   20161020, -0.1,  1.0,   0.3
4836.54,   20161021, 0.1,   0.2,   1.5
4884.16,   20161024, 1.0,   1.6,   4.9
4908.18,   20161025, 0.5,   1.2,   3.5
4863.52,   20161026, -0.9,  0.6,   2.9
4883.07,   20161027, 0.4,   1.1,   2.8
4820.5,    20161028, -1.3,  -0.3,  0.2
4821.31,   20161031, 0.0,   -1.3,  -0.3
4810.3,    20161101, -0.2,  -2.0,  -0.1
4764.83,   20161102, -0.9,  -2.0,  -1.3
4718.53,   20161103, -1.0,  -3.4,  -3.3
4647.59,   20161104, -1.5,  -3.6,  -4.8
4737.98,   20161107, 1.9,   -1.7,  -1.9
4766.48,   20161108, 0.6,   -0.9,  -1.0
4748.91,   20161109, -0.4,  -0.3,  -2.5
4849.71,   20161110, 2.1,   2.8,   -0.4
4729.14,   20161111, -2.5,  1.8,   -2.6
4752.61,   20161114, 0.5,   0.3,   -2.3
4733.57,   20161115, -0.4,  -0.7,  -3.0
4745.03,   20161116, 0.2,   -0.1,  -2.6
4789.79,   20161117, 0.9,   -1.2,  -1.7
4832.81,   20161118, 0.9,   2.2,   -1.0
4822.03,   20161121, -0.2,  1.5,   -1.3
4876.92,   20161122, 1.1,   3.0,   -0.3
4856.72,   20161123, -0.4,  2.4,   0.7
4861.47,   20161125, 0.1,   1.5,   1.6
4861.73,   20161128, 0.0,   0.6,   0.7
4859.85,   20161129, -0.0,  0.8,   1.1
4876.3,    20161130, 0.3,   -0.0,  0.5
4806.77,   20161201, -1.4,  -1.0,  -0.6
4734.38,   20161202, -1.5,  -2.6,  -2.0
4762.34,   20161205, 0.6,   -2.0,  -1.5
4786.06,   20161206, 0.5,   -1.5,  -2.0
4778.86,   20161207, -0.2,  -2.0,  -2.6
4849.75,   20161208, 1.5,   0.9,   -0.3
4878.54,   20161209, 0.6,   3.0,   -0.1
4878.04,   20161212, -0.0,  2.4,   1.2
4893.65,   20161213, 0.3,   2.2,   1.5
4943.64,   20161214, 1.0,   3.4,   2.8
4925.65,   20161215, -0.4,  1.6,   3.4
4945.04,   20161216, 0.4,   1.4,   4.8
4919.16,   20161219, -0.5,  0.8,   5.8
4948.34,   20161220, 0.6,   1.1,   4.4
4952.07,   20161221, 0.1,   0.2,   3.9
4948.36,   20161222, -0.1,  0.5,   4.2
4927.53,   20161223, -0.4,  -0.4,  1.6
4948.92,   20161227, 0.4,   0.6,   4.6
4976.42,   20161228, 0.6,   0.6,   4.7
4923.93,   20161229, -1.1,  -0.6,  4.0
4925.36,   20161230, 0.0,   -0.5,  3.8
4900.85,   20170103, -0.5,  -0.5,  2.3
4920.79,   20170104, 0.4,   -0.6,  1.8
4936.35,   20170105, 0.3,   -0.8,  2.4
4973.87,   20170106, 0.8,   1.0,   2.0
5013.82,   20170109, 0.8,   1.8,   3.2
5027.5,    20170110, 0.3,   2.6,   3.4
5033.88,   20170111, 0.1,   2.3,   3.5
5029.28,   20170112, -0.1,  1.9,   3.5
5047.56,   20170113, 0.4,   1.5,   3.5
5043.07,   20170117, -0.1,  0.6,   4.9
5050.26,   20170118, 0.1,   0.5,   6.7
5061.1,    20170119, 0.2,   0.5,   6.3
5070.15,   20170120, 0.2,   0.8,   5.9
5055.97,   20170123, -0.3,  0.2,   5.8
5079.61,   20170124, 0.5,   0.7,   4.7
5132.87,   20170125, 1.0,   1.6,   5.2
5164.12,   20170126, 0.6,   2.0,   5.9
5169.49,   20170127, 0.1,   2.0,   5.6
5146.41,   20170130, -0.4,  1.8,   4.1
5107.47,   20170131, -0.8,  0.5,   3.7
5157.83,   20170201, 1.0,   0.5,   4.3
5141.27,   20170202, -0.3,  -0.4,  4.5
5153.61,   20170203, 0.2,   -0.3,  4.1
5154.18,   20170206, 0.0,   0.2,   4.1
5178.85,   20170207, 0.5,   1.4,   4.7
5176.5,    20170208, -0.0,  0.4,   5.1
5200.17,   20170209, 0.5,   1.1,   5.1
5219.81,   20170210, 0.4,   1.3,   4.9
5242.49,   20170213, 0.4,   1.7,   6.5
5251.36,   20170214, 0.2,   1.4,   6.6
5266.12,   20170215, 0.3,   1.7,   7.5
5306.38,   20170216, 0.8,   2.0,   7.8
5295.49,   20170217, -0.2,  1.4,   7.3
5334.57,   20170221, 0.7,   1.8,   7.3
5345.18,   20170222, 0.2,   1.8,   6.6
5358.03,   20170223, 0.2,   1.7,   6.6
5305.23,   20170224, -1.0,  -0.0,  5.4
5330.56,   20170227, 0.5,   0.7,   6.0
5344.18,   20170228, 0.3,   0.2,   5.9
5368.65,   20170301, 0.5,   0.4,   6.5
5386.34,   20170302, 0.3,   0.5,   6.7
5355.21,   20170303, -0.6,  0.9,   5.8
5352.61,   20170306, -0.0,  0.4,   5.6
5348.2,    20170307, -0.1,  0.1,   5.8
5351.35,   20170308, 0.1,   -0.3,  5.3
5355.78,   20170309, 0.1,   -0.6,  4.3
5390.46,   20170310, 0.6,   0.7,   4.4
5387.73,   20170313, -0.1,  0.7,   4.2
5382.79,   20170314, -0.1,  0.6,   4.6
5392.48,   20170315, 0.2,   0.8,   5.6
5421.46,   20170316, 0.5,   1.2,   5.1
5413.47,   20170317, -0.1,  0.4,   5.3
5406.02,   20170320, -0.1,  0.3,   4.9
5433.28,   20170321, 0.5,   0.9,   5.4
5332.64,   20170322, -1.9,  -1.1,  3.0
5356.71,   20170323, 0.5,   -1.2,  3.5
5376.74,   20170324, 0.4,   -0.7,  3.4
5320.96,   20170327, -1.0,  -1.6,  1.9
5371.9,    20170328, 1.0,   -1.1,  2.5
5410.14,   20170329, 0.7,   1.5,   3.0
5430.31,   20170330, 0.4,   1.4,   3.1
5430.99,   20170331, 0.0,   1.0,   2.3
5440.2,    20170403, 0.2,   2.2,   2.7
5415.53,   20170404, -0.5,  0.8,   1.5
5447.27,   20170405, 0.6,   0.7,   1.9
5423.65,   20170406, -0.4,  -0.1,  1.2
5420.94,   20170407, -0.0,  -0.2,  2.2
5423.57,   20170410, 0.0,   -0.3,  1.7
5413.91,   20170411, -0.2,  -0.0,  1.3
5394.61,   20170412, -0.4,  -1.0,  0.5
5370.69,   20170413, -0.4,  -1.0,  -0.3
5369.21,   20170417, -0.0,  -1.0,  0.3
5383.74,   20170418, 0.3,   -0.7,  0.6
5416.3,    20170419, 0.6,   0.0,   1.3
5422.47,   20170420, 0.1,   0.5,   1.3
5447.28,   20170421, 0.5,   1.4,   1.7
5503.27,   20170424, 1.0,   2.5,   2.1
5523.48,   20170425, 0.4,   2.6,   2.5
5553.6,    20170426, 0.5,   2.5,   3.2
5555.27,   20170427, 0.0,   2.4,   3.0
5598.47,   20170428, 0.8,   2.8,   3.3
5602.44,   20170501, 0.1,   1.8,   3.5
5642.41,   20170502, 0.7,   2.2,   4.4
5624.88,   20170503, -0.3,  1.3,   3.5
5623.57,   20170504, -0.0,  1.2,   5.5
5640.78,   20170505, 0.3,   0.8,   5.3
5649.18,   20170508, 0.1,   0.8,   5.1
5667.98,   20170509, 0.3,   0.5,   6.5
5681.33,   20170510, 0.2,   1.0,   5.8
5662.59,   20170511, -0.3,  0.7,   4.7
5683.58,   20170512, 0.4,   0.8,   4.7
5687.97,   20170515, 0.1,   0.7,   4.7
5715.15,   20170516, 0.5,   0.8,   5.1
5673.62,   20170517, -0.7,  -0.1,  4.8
5569.81,   20170518, -1.8,  -1.6,  2.2
5640.71,   20170519, 1.3,   -0.8,  4.0
5662.95,   20170522, 0.4,   -0.4,  4.5
5714.94,   20170523, 0.9,   -0.0,  5.4
5720.83,   20170524, 0.1,   0.8,   5.7
5748.29,   20170525, 0.5,   3.2,   6.6
5782.17,   20170526, 0.6,   2.5,   7.7
5785.38,   20170530, 0.1,   2.2,   7.8
5812.97,   20170531, 0.5,   1.7,   8.0
5804.17,   20170601, -0.2,  1.5,   7.2
5834.41,   20170602, 0.5,   1.5,   7.6
5881.22,   20170605, 0.8,   1.7,   8.0
5869.48,   20170606, -0.2,  1.5,   6.7
5871.77,   20170607, 0.0,   1.0,   6.3
5894.75,   20170608, 0.4,   1.6,   6.1
5891.67,   20170609, -0.1,  1.0,   6.1
5677.16,   20170612, -3.6,  -3.5,  1.4
5740.21,   20170613, 1.1,   -2.2,  2.5
5774.12,   20170614, 0.6,   -1.7,  2.3
5661.36,   20170615, -2.0,  -4.0,  0.6
5694.21,   20170616, 0.6,   -3.4,  1.3
5729.54,   20170619, 0.6,   0.9,   1.6
5763.0,    20170620, 0.6,   0.4,   2.0
5740.58,   20170621, -0.4,  -0.6,  1.3
5787.44,   20170622, 0.8,   2.2,   1.9
5775.03,   20170623, -0.2,  1.4,   2.0
5832.31,   20170626, 1.0,   1.8,   2.6
5752.72,   20170627, -1.4,  -0.2,  1.1
5693.48,   20170628, -1.0,  -0.8,  -0.4
5723.31,   20170629, 0.5,   -1.1,  0.9
5676.4,    20170630, -0.8,  -1.7,  1.9
5680.36,   20170703, 0.1,   -2.6,  0.7
5611.0,    20170705, -1.2,  -2.5,  -0.9
5606.42,   20170706, -0.1,  -1.5,  -1.9
5618.85,   20170707, 0.2,   -1.8,  -1.8
5662.71,   20170710, 0.8,   -0.2,  -1.5
5687.03,   20170711, 0.4,   0.1,   -1.6
5757.2,    20170712, 1.2,   2.6,   -0.5
5785.32,   20170713, 0.5,   3.2,   -0.5
5815.04,   20170714, 0.5,   3.5,   0.2
5845.82,   20170717, 0.5,   3.2,   0.2
5832.92,   20170718, -0.2,  2.6,   -0.8
5898.4,    20170719, 1.1,   2.5,   0.5
5929.58,   20170720, 0.5,   2.5,   1.0
5905.6,    20170721, -0.4,  1.6,   0.2
5920.93,   20170724, 0.3,   1.3,   0.5
5927.1,    20170725, 0.1,   1.6,   4.4
5944.16,   20170726, 0.3,   0.8,   3.6
5994.02,   20170727, 0.8,   1.1,   3.8
5883.7,    20170728, -1.8,  -0.4,  3.9
5928.68,   20170731, 0.8,   0.1,   4.1
5900.64,   20170801, -0.5,  -0.4,  3.0
5934.07,   20170802, 0.6,   -0.2,  3.0
5915.73,   20170803, -0.3,  -1.3,  3.1
5899.69,   20170804, -0.3,  0.3,   1.9
5909.45,   20170807, 0.2,   -0.3,  2.3
5922.64,   20170808, 0.2,   0.4,   1.5
5879.55,   20170809, -0.7,  -0.9,  2.2
5880.14,   20170810, 0.0,   -0.6,  3.3
5792.32,   20170811, -1.5,  -1.8,  1.2
5878.64,   20170814, 1.5,   -0.5,  3.6
5917.29,   20170815, 0.7,   -0.1,  4.2
5920.68,   20170816, 0.1,   0.7,   5.5
5896.32,   20170817, -0.4,  0.3,   5.2
5803.9,    20170818, -1.6,  0.2,   3.3
5791.04,   20170821, -0.2,  -1.5,  2.3
5817.29,   20170822, 0.5,   -1.7,  2.3
5842.16,   20170823, 0.4,   -1.3,  1.5
5863.99,   20170824, 0.4,   -0.5,  1.4
5854.31,   20170825, -0.2,  0.9,   0.7
5839.27,   20170828, -0.3,  0.8,   -0.1
5785.29,   20170829, -0.9,  -0.6,  -0.8
5870.88,   20170830, 1.5,   0.5,   -0.5
5948.74,   20170831, 1.3,   1.4,   0.3
6001.82,   20170901, 0.9,   2.5,   1.6
5966.64,   20170905, -0.6,  2.2,   0.8
5949.54,   20170906, -0.3,  2.8,   0.4
5960.44,   20170907, 0.2,   1.5,   0.3
5957.19,   20170908, -0.1,  0.1,   -0.6
5962.35,   20170911, 0.1,   -0.7,  1.3
5998.75,   20170912, 0.6,   0.5,   1.2
5982.85,   20170913, -0.3,  0.6,   1.4
5981.85,   20170914, -0.0,  0.4,   0.8
5965.66,   20170915, -0.3,  0.1,   0.8
5997.51,   20170918, 0.5,   0.6,   1.7
5991.93,   20170919, -0.1,  -0.1,  1.4
5987.72,   20170920, -0.1,  0.1,   1.1
5965.6,    20170921, -0.4,  -0.3,  1.5
5912.2,    20170922, -0.9,  -0.9,  0.5
5904.06,   20170925, -0.1,  -1.6,  1.9
5893.18,   20170926, -0.2,  -1.6,  0.2
5912.96,   20170927, 0.3,   -1.2,  -0.1
5920.65,   20170928, 0.1,   -0.8,  -0.0
5941.78,   20170929, 0.4,   0.5,   0.8
5988.96,   20171002, 0.8,   1.4,   3.2
5986.85,   20171003, -0.0,  1.6,   3.4
5983.31,   20171004, -0.1,  1.2,   2.9
6018.27,   20171005, 0.6,   1.6,   3.0
6037.44,   20171006, 0.3,   1.6,   3.0
6071.96,   20171009, 0.6,   1.4,   3.7
6078.27,   20171010, 0.1,   1.5,   4.1
6062.73,   20171011, -0.3,  1.3,   4.8
6072.21,   20171012, 0.2,   0.9,   3.4
6094.72,   20171013, 0.4,   0.9,   2.5
6108.66,   20171016, 0.2,   0.6,   1.8
6111.32,   20171017, 0.0,   0.5,   2.4
6128.91,   20171018, 0.3,   1.1,   3.0
6074.28,   20171019, -0.9,  0.0,   1.9
6114.46,   20171020, 0.7,   0.3,   2.6
6122.24,   20171023, 0.1,   0.2,   2.7
6078.02,   20171024, -0.7,  -0.5,  1.3
6069.35,   20171025, -0.1,  -1.0,  1.4
6056.05,   20171026, -0.2,  -0.3,  1.2
6138.23,   20171027, 1.4,   0.4,   2.9
6209.8,    20171030, 1.2,   1.4,   3.5
6241.88,   20171031, 0.5,   2.7,   4.2
6274.26,   20171101, 0.5,   3.4,   4.8
6238.29,   20171102, -0.6,  3.0,   4.6
6266.19,   20171103, 0.4,   2.1,   6.0
6292.15,   20171106, 0.4,   1.3,   6.6
6314.69,   20171107, 0.4,   1.2,   7.2
6319.03,   20171108, 0.1,   0.7,   6.9
6295.29,   20171109, -0.4,  0.9,   6.3
6297.15,   20171110, 0.0,   0.5,   6.0
6286.01,   20171113, -0.2,  -0.1,  5.0
6293.33,   20171114, 0.1,   -0.3,  5.1
6261.27,   20171115, -0.5,  -0.9,  4.6
6293.46,   20171116, 0.5,   -0.0,  4.6
6343.64,   20171117, 0.8,   0.7,   5.1
6319.57,   20171120, -0.4,  0.5,   4.1
6336.91,   20171121, 0.3,   0.7,   4.3
6384.13,   20171122, 0.7,   2.0,   5.3
6393.33,   20171124, 0.1,   1.6,   5.3
6409.52,   20171127, 0.3,   1.0,   5.2
6419.48,   20171128, 0.2,   1.6,   5.1
6412.69,   20171129, -0.1,  1.2,   4.9
6337.34,   20171130, -1.2,  -0.7,  3.4
6325.61,   20171201, -0.2,  -1.1,  4.1
6380.68,   20171204, 0.9,   -0.4,  4.4
6242.47,   20171205, -2.2,  -2.8,  2.0
6243.72,   20171206, 0.0,   -2.6,  2.7
6300.64,   20171207, 0.9,   -0.6,  3.8
6364.6,    20171208, 1.0,   0.6,   5.1
6351.24,   20171211, -0.2,  -0.5,  3.5
6389.47,   20171212, 0.6,   2.4,   2.9
6405.41,   20171213, 0.2,   2.6,   2.6
6407.26,   20171214, 0.0,   1.7,   2.1
6405.15,   20171215, -0.0,  0.6,   2.7
6504.89,   20171218, 1.6,   2.4,   3.8
6505.36,   20171219, 0.0,   1.8,   3.4
6504.55,   20171220, -0.0,  1.5,   3.0
6481.08,   20171221, -0.4,  1.2,   2.6
6462.55,   20171222, -0.3,  0.9,   2.7
6427.32,   20171226, -0.5,  -1.2,  2.1
6437.06,   20171227, 0.2,   -1.0,  2.4
6449.53,   20171228, 0.2,   -0.8,  2.5
6439.91,   20171229, -0.1,  -0.6,  2.9
6431.59,   20180102, -0.1,  -0.5,  2.2
6520.03,   20180103, 1.4,   1.4,   2.8
6595.75,   20180104, 1.2,   2.5,   4.4
6613.13,   20180105, 0.3,   2.5,   4.4
6651.68,   20180108, 0.6,   3.3,   4.2
6692.87,   20180109, 0.6,   4.1,   4.7
6640.66,   20180110, -0.8,  1.9,   3.6
6675.69,   20180111, 0.5,   1.2,   4.0
6699.32,   20180112, 0.4,   1.3,   4.5
6807.85,   20180116, 1.6,   2.3,   7.4
6767.85,   20180117, -0.6,  1.1,   7.0
6804.59,   20180118, 0.5,   2.5,   6.6
6829.04,   20180119, 0.4,   2.3,   9.4
6829.55,   20180122, 0.0,   1.9,   9.4
6928.27,   20180123, 1.4,   1.8,   10.0
6975.38,   20180124, 0.7,   3.1,   9.6
6965.01,   20180125, -0.1,  2.4,   9.7
6956.66,   20180126, -0.1,  1.9,   8.9
7000.68,   20180129, 0.6,   2.5,   9.3
6910.39,   20180130, -1.3,  -0.3,  7.9
6972.81,   20180131, 0.9,   -0.0,  8.9
6910.57,   20180201, -0.9,  -0.8,  6.2
6866.38,   20180202, -0.6,  -1.3,  5.5
6687.47,   20180205, -2.6,  -4.5,  2.8
6378.6,    20180206, -4.6,  -7.7,  -1.6
6632.98,   20180207, 4.0,   -4.9,  2.6
6595.81,   20180208, -0.6,  -4.6,  2.6
6395.16,   20180209, -3.0,  -6.9,  -0.7
6477.04,   20180212, 1.3,   -3.1,  0.4
6482.77,   20180213, 0.1,   1.6,   0.7
6523.26,   20180214, 0.6,   -1.7,  1.4
6734.34,   20180215, 3.2,   2.1,   3.3
6778.28,   20180216, 0.7,   6.0,   2.8
6742.15,   20180220, -0.5,  4.1,   2.0
6808.6,    20180221, 1.0,   5.0,   2.4
6793.08,   20180222, -0.2,  4.1,   1.5
6816.43,   20180223, 0.3,   1.2,   2.6
6933.82,   20180226, 1.7,   2.3,   3.9
6984.83,   20180227, 0.7,   3.6,   4.3
6945.2,    20180228, -0.6,  2.0,   2.0
6862.94,   20180301, -1.2,  1.0,   1.4
6661.91,   20180302, -2.9,  -2.3,  -2.1
6777.63,   20180305, 1.7,   -2.3,  -0.8
6919.79,   20180306, 2.1,   -0.9,  1.3
6855.57,   20180307, -0.9,  -1.3,  -1.0
6955.85,   20180308, 1.5,   1.4,   -0.3
7014.84,   20180309, 0.8,   5.3,   0.7
7122.47,   20180312, 1.5,   5.1,   2.4
7172.25,   20180313, 0.7,   3.6,   2.5
7077.85,   20180314, -1.3,  3.2,   2.4
7050.66,   20180315, -0.4,  1.4,   1.1
7058.12,   20180316, 0.1,   0.6,   2.1
6945.97,   20180319, -1.6,  -2.5,  1.2
6871.09,   20180320, -1.1,  -4.2,  2.7
6863.29,   20180321, -0.1,  -3.0,  7.6
6763.94,   20180322, -1.4,  -4.1,  2.0
6683.19,   20180323, -1.2,  -5.3,  1.3
6644.4,    20180326, -0.6,  -4.3,  3.9
6791.39,   20180327, 2.2,   -1.2,  4.9
6488.02,   20180328, -4.5,  -5.5,  0.1
6493.51,   20180329, 0.1,   -4.0,  -0.5
6528.23,   20180402, 0.5,   -2.3,  -3.1
6448.05,   20180403, -1.2,  -3.0,  -4.9
6326.54,   20180404, -1.9,  -6.8,  -6.2
6619.22,   20180405, 4.6,   2.0,   -2.8
6514.48,   20180406, -1.6,  0.3,   -4.1
6488.48,   20180409, -0.4,  -0.6,  -4.8
6585.53,   20180410, 1.5,   2.1,   -5.0
6578.84,   20180411, -0.1,  4.0,   -5.8
6627.32,   20180412, 0.7,   0.1,   -4.6
6696.02,   20180413, 1.0,   2.8,   -2.4
6675.92,   20180416, -0.3,  2.9,   0.2
6738.65,   20180417, 0.9,   2.3,   -0.6
6824.58,   20180418, 1.3,   3.7,   -1.4
6793.08,   20180419, -0.5,  2.5,   -0.9
6754.68,   20180420, -0.6,  0.9,   -2.9
6699.16,   20180423, -0.8,  0.3,   -4.5
6680.88,   20180424, -0.3,  -0.9,  -6.2
6514.33,   20180425, -2.5,  -4.5,  -9.2
6606.61,   20180426, 1.4,   -2.7,  -6.7
6748.59,   20180427, 2.1,   -0.1,  -4.3
6669.44,   20180430, -1.2,  -0.4,  -5.5
6592.93,   20180501, -1.1,  -1.3,  -5.1
6694.52,   20180502, 1.5,   2.8,   -2.6
6608.7,    20180503, -1.3,  0.0,   -3.7
6618.7,    20180504, 0.2,   -1.9,  -2.1
6803.45,   20180507, 2.8,   2.0,   1.8
6813.91,   20180508, 0.2,   3.4,   2.6
6830.55,   20180509, 0.2,   2.0,   0.6
6909.18,   20180510, 1.2,   4.5,   6.5
6948.87,   20180511, 0.6,   5.0,   7.0
6977.35,   20180514, 0.4,   2.6,   6.9
6909.32,   20180515, -1.0,  1.4,   7.2
6892.89,   20180516, -0.2,  0.9,   9.0
6905.75,   20180517, 0.2,   -0.0,  4.3
6876.65,   20180518, -0.4,  -1.0,  5.6
6920.32,   20180521, 0.6,   -0.8,  6.7
6934.7,    20180522, 0.2,   0.4,   5.3
6847.27,   20180523, -1.3,  -0.7,  4.1
6951.52,   20180524, 1.5,   0.7,   4.9
6951.47,   20180525, -0.0,  1.1,   3.8
6928.5,    20180529, -0.3,  0.1,   3.8
6955.59,   20180530, 0.4,   0.3,   3.2
6969.93,   20180531, 0.2,   1.8,   2.1
7010.08,   20180601, 0.6,   0.8,   3.2
7099.5,    20180604, 1.3,   2.1,   5.1
7162.41,   20180605, 0.9,   3.4,   6.9
7180.24,   20180606, 0.2,   3.2,   7.5
7212.43,   20180607, 0.4,   3.5,   10.7
7121.76,   20180608, -1.3,  1.6,   7.8
7149.65,   20180611, 0.4,   0.7,   5.9
7181.67,   20180612, 0.4,   0.3,   7.7
7219.58,   20180613, 0.5,   0.5,   9.5
7233.7,    20180614, 0.2,   0.3,   8.1
7245.16,   20180615, 0.2,   1.7,   9.6
7201.18,   20180618, -0.6,  0.7,   8.8
7158.8,    20180619, -0.6,  -0.3,  5.2
7264.85,   20180620, 1.5,   0.6,   6.6
7304.05,   20180621, 0.5,   1.0,   6.9
7239.72,   20180622, -0.9,  -0.1,  4.8
7131.05,   20180625, -1.5,  -1.0,  2.6
7061.23,   20180626, -1.0,  -1.4,  1.2
7096.6,    20180627, 0.5,   -2.3,  2.7
6963.37,   20180628, -1.9,  -4.7,  1.0
7071.49,   20180629, 1.6,   -2.3,  2.4
6979.54,   20180702, -1.3,  -2.1,  1.5
7121.71,   20180703, 2.0,   0.9,   2.9
7064.0,    20180705, -0.8,  -0.5,  1.9
7114.17,   20180706, 0.7,   2.2,   3.9
7251.18,   20180709, 1.9,   2.5,   4.3
7287.05,   20180710, 0.5,   4.4,   4.8
7221.66,   20180711, -0.9,  1.4,   4.2
7276.38,   20180712, 0.8,   3.0,   4.6
7372.28,   20180713, 1.3,   3.6,   5.8
7381.03,   20180716, 0.1,   1.8,   5.3
7291.48,   20180717, -1.2,  0.1,   2.7
7407.25,   20180718, 1.6,   2.6,   3.4
7364.66,   20180719, -0.6,  1.2,   2.6
7379.13,   20180720, 0.2,   0.1,   2.3
7335.42,   20180723, -0.6,  -0.6,  3.0
7455.51,   20180724, 1.6,   2.2,   4.3
7406.1,    20180725, -0.7,  -0.0,  3.1
7404.56,   20180726, -0.0,  0.5,   2.6
7443.24,   20180727, 0.5,   0.9,   2.9
7292.72,   20180730, -2.0,  -0.6,  0.7
7219.67,   20180731, -1.0,  -3.2,  0.3
7269.2,    20180801, 0.7,   -1.8,  1.5
7221.35,   20180802, -0.7,  -2.5,  -0.6
7389.36,   20180803, 2.3,   -0.7,  1.2
7390.82,   20180806, 0.0,   1.3,   2.1
7459.32,   20180807, 0.9,   3.3,   4.6
7454.42,   20180808, -0.1,  2.5,   5.6
7467.51,   20180809, 0.2,   3.4,   5.2
7409.05,   20180810, -0.8,  0.3,   6.4
7420.18,   20180813, 0.2,   0.4,   4.9
7430.45,   20180814, 0.1,   -0.4,  6.5
7385.67,   20180815, -0.6,  -0.9,  3.7
7409.61,   20180816, 0.3,   -0.8,  4.9
7352.63,   20180817, -0.8,  -0.8,  3.4
7393.58,   20180820, 0.6,   -0.4,  2.0
7393.46,   20180821, -0.0,  -0.5,  1.5
7379.84,   20180822, -0.2,  -0.1,  2.2
7420.31,   20180823, 0.5,   0.1,   2.0
7443.71,   20180824, 0.3,   1.2,   1.0
7527.37,   20180827, 1.1,   1.8,   2.0
7580.85,   20180828, 0.7,   2.5,   4.0
7585.13,   20180829, 0.1,   2.8,   2.4
7645.11,   20180830, 0.8,   3.0,   3.8
7634.44,   20180831, -0.1,  2.6,   3.5
7629.34,   20180904, -0.1,  1.4,   4.0
7600.95,   20180905, -0.4,  0.3,   2.0
7527.22,   20180906, -1.0,  -0.8,  1.6
7402.19,   20180907, -1.7,  -3.2,  -0.0
7466.76,   20180910, 0.9,   -2.2,  0.3
7415.09,   20180911, -0.7,  -2.8,  1.7
7493.38,   20180912, 1.1,   -1.4,  3.8
7535.23,   20180913, 0.6,   0.1,   3.7
7575.38,   20180914, 0.5,   2.3,   4.9
7522.59,   20180917, -0.7,  0.7,   1.8
7440.86,   20180918, -1.1,  0.3,   0.7
7498.24,   20180919, 0.8,   0.1,   0.5
7534.02,   20180920, 0.5,   -0.0,  1.1
7587.39,   20180921, 0.7,   0.2,   1.6
7477.45,   20180924, -1.4,  -0.6,  0.9
7552.02,   20180925, 1.0,   1.5,   1.8
7563.96,   20180926, 0.2,   0.9,   1.8
7600.35,   20180927, 0.5,   0.9,   2.9
7612.65,   20180928, 0.2,   0.3,   2.7
7673.0,    20181001, 0.8,   2.6,   4.4
7632.37,   20181002, -0.5,  1.1,   3.2
7663.09,   20181003, 0.4,   1.3,   3.6
7603.01,   20181004, -0.8,  0.0,   3.0
7482.04,   20181005, -1.6,  -1.7,  0.8
7356.54,   20181008, -1.7,  -4.1,  -1.2
7352.44,   20181009, -0.1,  -3.7,  -2.3
7319.81,   20181010, -0.4,  -4.5,  -3.4
7013.48,   20181011, -4.2,  -7.8,  -7.5
7150.21,   20181012, 1.9,   -4.4,  -6.5
7129.31,   20181015, -0.3,  -3.1,  -6.6
7141.67,   20181016, 0.2,   -2.9,  -6.4
7310.0,    20181017, 2.4,   -0.1,  -3.8
7250.92,   20181018, -0.8,  3.4,   -3.7
7169.45,   20181019, -1.1,  0.3,   -3.1
7146.03,   20181022, -0.3,  0.2,   -4.3
7002.69,   20181023, -2.0,  -1.9,  -5.6
7101.85,   20181024, 1.4,   -2.8,  -5.2
6891.27,   20181025, -3.0,  -5.0,  -8.5
6804.69,   20181026, -1.3,  -5.1,  -10.2
6954.69,   20181029, 2.2,   -2.7,  -7.5
6673.84,   20181030, -4.0,  -4.7,  -10.3
6933.11,   20181031, 3.9,   -2.4,  -7.5
6980.79,   20181101, 0.7,   1.3,   -7.3
7042.3,    20181102, 0.9,   3.5,   -7.2
6946.15,   20181105, -1.4,  -0.1,  -7.1
6939.13,   20181106, -0.1,  4.0,   -8.1
7068.5,    20181107, 1.9,   2.0,   -6.6
7176.19,   20181108, 1.5,   2.8,   -5.6
7095.45,   20181109, -1.1,  0.8,   -6.8
6990.37,   20181112, -1.5,  0.6,   -8.9
6858.59,   20181113, -1.9,  -1.2,  -10.1
6896.2,    20181114, 0.5,   -2.4,  -10.0
6750.63,   20181115, -2.1,  -5.9,  -11.2
6823.03,   20181116, 1.1,   -3.8,  -8.8
6832.25,   20181119, 0.1,   -2.3,  -7.1
6475.68,   20181120, -5.2,  -5.6,  -11.9
6608.12,   20181121, 2.0,   -4.2,  -9.7
6525.02,   20181123, -1.3,  -3.3,  -7.0
6616.79,   20181126, 1.4,   -3.0,  -7.5
6639.39,   20181127, 0.3,   -2.8,  -6.9
6756.13,   20181128, 1.8,   4.3,   -5.4
6890.84,   20181129, 2.0,   4.3,   -5.7
6902.24,   20181130, 0.2,   5.8,   -4.8
7106.84,   20181203, 3.0,   7.4,   -0.9
7026.81,   20181204, -1.1,  5.8,   -1.7
6652.13,   20181206, -5.3,  -1.5,  -5.0
6806.63,   20181207, 2.3,   -1.2,  -4.2
6603.36,   20181210, -3.0,  -4.3,  -4.2
6782.41,   20181211, 2.7,   -4.6,  -0.3
6800.55,   20181212, 0.3,   -3.2,  -2.2
6805.7,    20181213, 0.1,   2.3,   2.0
6679.91,   20181214, -1.8,  -1.9,  -3.7
6571.0,    20181217, -1.6,  -0.5,  -5.9
6503.12,   20181218, -1.0,  -4.1,  -7.7
6480.31,   20181219, -0.4,  -4.7,  -6.7
6312.72,   20181220, -2.6,  -7.2,  -9.0
6293.05,   20181221, -0.3,  -5.8,  -11.0
5994.08,   20181224, -4.8,  -8.8,  -16.5
5969.08,   20181226, -0.4,  -8.2,  -15.9
6168.6,    20181227, 3.3,   -4.8,  -11.8
6326.62,   20181228, 2.6,   0.2,   -7.8
6354.85,   20181231, 0.4,   1.0,   -7.8
6198.68,   20190102, -2.5,  3.4,   -8.2
6274.76,   20190103, 1.2,   5.1,   -8.0
6250.05,   20190104, -0.4,  1.3,   -8.5
6434.55,   20190107, 3.0,   1.7,   -0.6
6555.91,   20190108, 1.9,   3.2,   -0.8
6576.54,   20190109, 0.3,   6.1,   0.8
6551.05,   20190110, -0.4,  4.4,   -1.0
6579.89,   20190111, 0.4,   5.3,   -0.9
6536.37,   20190114, -0.7,  1.6,   -3.3
6571.15,   20190115, 0.5,   0.2,   -4.6
6677.38,   20190116, 1.6,   1.5,   -3.3
6646.19,   20190117, -0.5,  1.5,   -6.5
6771.52,   20190118, 1.9,   2.9,   -3.6
6736.65,   20190122, -0.5,  3.1,   1.3
6689.08,   20190123, -0.7,  1.8,   -1.7
6680.34,   20190124, -0.1,  0.0,   1.2
6755.67,   20190125, 1.1,   1.6,   -0.4
6691.84,   20190128, -0.9,  -1.2,  -1.6
6695.71,   20190129, 0.1,   -0.6,  -1.6
6708.56,   20190130, 0.2,   0.3,   0.4
6840.93,   20190131, 2.0,   2.4,   4.1
6872.73,   20190201, 0.5,   1.7,   5.7
6876.56,   20190204, 0.1,   2.8,   6.1
6969.37,   20190205, 1.3,   4.1,   10.4
7023.89,   20190206, 0.8,   4.7,   11.6
6934.33,   20190207, -1.3,  1.4,   15.7
6843.33,   20190208, -1.3,  -0.4,  14.6
6941.26,   20190211, 1.4,   0.9,   12.5
6960.55,   20190212, 0.3,   -0.1,  10.0
7038.32,   20190213, 1.1,   0.2,   10.8
6986.51,   20190214, -0.7,  0.8,   12.7
7066.38,   20190215, 1.1,   3.3,   12.6
7033.37,   20190219, -0.5,  1.3,   12.5
7070.24,   20190220, 0.5,   1.6,   9.9
7047.08,   20190221, -0.3,  0.1,   7.5
7055.2,    20190222, 0.1,   1.0,   7.3
7144.34,   20190225, 1.3,   1.1,   9.1
7098.97,   20190226, -0.6,  0.9,   7.9
7095.75,   20190227, -0.0,  0.4,   8.6
7093.2,    20190228, -0.0,  0.7,   7.9
7152.08,   20190301, 0.8,   1.4,   7.1
7193.88,   20190304, 0.6,   0.7,   8.2
7156.66,   20190305, -0.5,  0.8,   5.7
7156.24,   20190306, -0.0,  0.9,   6.2
7089.63,   20190307, -0.9,  -0.1,  6.0
6940.9,    20190308, -2.1,  -3.0,  3.9
7050.05,   20190311, 1.6,   -2.0,  4.4
7176.23,   20190312, 1.8,   0.3,   7.2
7231.05,   20190313, 0.8,   1.0,   8.0
7257.31,   20190314, 0.4,   2.4,   8.2
7274.14,   20190315, 0.2,   4.8,   6.3
7311.63,   20190318, 0.5,   3.7,   6.4
7357.58,   20190319, 0.6,   2.5,   7.0
7348.99,   20190320, -0.1,  1.6,   5.4
7356.94,   20190321, 0.1,   1.4,   4.7
7458.44,   20190322, 1.4,   2.5,   7.6
7299.93,   20190325, -2.1,  -0.2,  6.7
7378.23,   20190326, 1.1,   0.3,   6.3
7364.47,   20190327, -0.2,  0.2,   5.8
7325.06,   20190328, -0.5,  -0.4,  4.1
7377.77,   20190329, 0.7,   -1.1,  5.6
7450.81,   20190401, 1.0,   2.1,   5.4
7473.51,   20190402, 0.3,   1.3,   6.3
7538.17,   20190403, 0.9,   2.4,   6.6
7542.16,   20190404, 0.1,   3.0,   7.0
7564.27,   20190405, 0.3,   2.5,   7.2
7565.06,   20190408, 0.0,   1.5,   5.9
7571.88,   20190409, 0.1,   1.3,   6.7
7581.39,   20190410, 0.1,   0.6,   6.8
7621.4,    20190411, 0.5,   1.1,   7.4
7624.15,   20190412, 0.0,   0.8,   6.6
7630.6,    20190415, 0.1,   0.9,   6.1
7651.19,   20190416, 0.3,   1.0,   6.9
7705.32,   20190417, 0.7,   1.6,   7.7
7688.93,   20190418, -0.2,  0.9,   8.5
7658.37,   20190422, -0.4,  0.4,   10.3
7724.17,   20190423, 0.9,   1.2,   9.6
7813.02,   20190424, 1.2,   2.1,   8.9
7850.71,   20190425, 0.5,   1.9,   8.6
7791.15,   20190426, -0.8,  1.3,   7.4
7825.64,   20190429, 0.4,   2.2,   7.6
7769.3,    20190430, -0.7,  0.6,   6.3
7828.14,   20190501, 0.8,   0.2,   6.4
7751.64,   20190502, -1.0,  -1.3,  5.5
7784.29,   20190503, 0.4,   -0.1,  5.8
7662.92,   20190506, -1.6,  -2.1,  2.7
7713.49,   20190507, 0.7,   -0.7,  5.7
7623.32,   20190508, -1.2,  -2.6,  3.3
7525.81,   20190509, -1.3,  -2.9,  2.2
7556.04,   20190510, 0.4,   -2.9,  3.2
7385.55,   20190513, -2.3,  -3.6,  0.1
7366.64,   20190514, -0.3,  -4.5,  -1.1
7354.82,   20190515, -0.2,  -3.5,  -1.6
7509.91,   20190516, 2.1,   -0.2,  -0.4
7513.15,   20190517, 0.0,   -0.6,  -0.4
7391.73,   20190520, -1.6,  0.1,   -2.3
7442.31,   20190521, 0.7,   1.0,   -1.6
7413.84,   20190522, -0.4,  0.8,   -2.1
7331.67,   20190523, -1.1,  -2.4,  -3.3
7353.97,   20190524, 0.3,   -2.1,  -3.5
7321.27,   20190528, -0.4,  -1.0,  -4.0
7222.55,   20190529, -1.3,  -3.0,  -5.3
7234.83,   20190530, 0.2,   -2.4,  -5.4
7148.71,   20190531, -1.2,  -2.5,  -7.2
7110.34,   20190603, -0.5,  -3.3,  -7.5
7056.18,   20190604, -0.8,  -3.6,  -7.9
7230.81,   20190605, 2.5,   0.1,   -6.4
7229.86,   20190606, -0.0,  -0.1,  -7.5
7315.33,   20190607, 1.2,   2.3,   -6.8
7472.6,    20190610, 2.1,   5.1,   -4.1
7584.75,   20190611, 1.5,   7.5,   -3.1
7493.55,   20190612, -1.2,  3.6,   -3.5
7500.85,   20190613, 0.1,   3.7,   -4.2
7476.47,   20190614, -0.3,  2.2,   -3.5
7500.28,   20190617, 0.3,   0.4,   -3.6
7606.61,   20190618, 1.4,   0.3,   -0.7
7652.52,   20190619, 0.6,   2.1,   -0.8
7770.17,   20190620, 1.5,   3.6,   1.9
7719.42,   20190621, -0.7,  3.2,   2.6
7737.0,    20190624, 0.2,   3.2,   2.4
7720.91,   20190625, -0.2,  1.5,   4.5
7642.92,   20190626, -1.0,  -0.1,  3.8
7656.68,   20190627, 0.2,   -1.5,  4.1
7674.52,   20190628, 0.2,   -0.6,  2.2
7816.56,   20190701, 1.9,   1.0,   4.0
7763.03,   20190702, -0.7,  0.5,   5.0
7819.06,   20190703, 0.7,   2.3,   5.1
7806.07,   20190705, -0.2,  2.0,   5.3
7790.46,   20190708, -0.2,  1.5,   6.3
7748.29,   20190709, -0.5,  -0.9,  5.4
7869.73,   20190710, 1.6,   1.4,   7.5
7918.65,   20190711, 0.6,   1.3,   9.6
7908.58,   20190712, -0.1,  1.3,   9.3
7961.07,   20190715, 0.7,   2.2,   11.4
7958.44,   20190716, -0.0,  2.7,   11.9
7927.91,   20190717, -0.4,  0.7,   12.4
7850.12,   20190718, -1.0,  -0.9,  8.6
7944.86,   20190719, 1.2,   0.5,   9.9
7862.38,   20190722, -1.0,  -1.2,  7.5
7946.05,   20190723, 1.1,   -0.2,  6.3
7928.78,   20190724, -0.2,  0.0,   4.5
7979.14,   20190725, 0.6,   1.6,   6.5
7992.13,   20190726, 0.2,   0.6,   6.5
8010.05,   20190729, 0.2,   1.9,   7.1
7927.56,   20190730, -1.0,  -0.2,  5.7
7972.76,   20190731, 0.6,   0.6,   4.8
7866.6,    20190801, -1.3,  -1.4,  2.8
7740.91,   20190802, -1.6,  -3.1,  -0.4
7510.02,   20190805, -3.0,  -6.2,  -2.7
7492.02,   20190806, -0.2,  -5.5,  -3.2
7436.14,   20190807, -0.7,  -6.7,  -3.7
7608.03,   20190808, 2.3,   -3.3,  -0.5
7684.56,   20190809, 1.0,   -0.7,  0.4
7597.19,   20190812, -1.1,  1.2,   -1.0
7553.43,   20190813, -0.6,  0.8,   -3.4
7594.64,   20190814, 0.5,   2.1,   -2.2
7503.14,   20190815, -1.2,  -1.4,  -4.0
7548.9,    20190816, 0.6,   -1.8,  -3.3
7715.41,   20190819, 2.2,   1.6,   -1.0
7708.31,   20190820, -0.1,  2.1,   -0.5
7731.75,   20190821, 0.3,   1.8,   -1.8
7750.98,   20190822, 0.2,   3.3,   -2.1
7658.48,   20190823, -1.2,  1.5,   -3.2
7540.69,   20190826, -1.5,  -2.3,  -5.3
7630.46,   20190827, 1.2,   -1.0,  -4.1
7536.7,    20190828, -1.2,  -2.5,  -4.9
7678.69,   20190829, 1.9,   -0.9,  -2.2
7743.23,   20190830, 0.8,   1.1,   -2.5
7634.03,   20190903, -1.4,  1.2,   -2.9
7683.78,   20190904, 0.7,   0.7,   -3.3
7803.45,   20190905, 1.6,   3.5,   -1.6
7868.87,   20190906, 0.8,   2.5,   -1.4
7876.4,    20190909, 0.1,   1.7,   -1.4
7793.28,   20190910, -1.1,  2.1,   -2.7
7820.71,   20190911, 0.4,   1.8,   -1.3
7931.96,   20190912, 1.4,   1.6,   -0.5
7903.73,   20190913, -0.4,  0.4,   0.5
7833.73,   20190916, -0.9,  -0.5,  1.2
7849.88,   20190917, 0.2,   0.7,   4.5
7877.58,   20190918, 0.4,   0.7,   5.1
7904.39,   20190919, 0.3,   -0.3,  6.3
7901.46,   20190920, -0.0,  -0.0,  3.9
7815.23,   20190923, -1.1,  -0.2,  1.7
7855.97,   20190924, 0.5,   0.1,   3.4
7706.5,    20190925, -1.9,  -2.2,  2.0
7794.28,   20190926, 1.1,   -1.4,  2.6
7786.03,   20190927, -0.1,  -1.5,  3.8
7705.97,   20190930, -1.0,  -1.4,  2.1
7782.82,   20191001, 1.0,   -0.9,  0.9
7627.59,   20191002, -2.0,  -1.0,  -1.0
7557.19,   20191003, -0.9,  -3.0,  -2.3
7677.33,   20191004, 1.6,   -1.4,  -1.0
7725.68,   20191007, 0.6,   0.3,   0.9
7671.38,   20191008, -0.7,  -1.4,  1.7
7678.66,   20191009, 0.1,   0.7,   0.6
7688.42,   20191010, 0.1,   1.7,   2.0
7835.93,   20191011, 1.9,   2.1,   2.0
7834.74,   20191014, -0.0,  1.4,   1.2
7867.96,   20191015, 0.4,   2.6,   3.1
7911.99,   20191016, 0.6,   3.0,   3.0
7976.64,   20191017, 0.8,   3.7,   2.2
7938.3,    20191018, -0.5,  1.3,   0.9
7911.58,   20191021, -0.3,  1.0,   0.4
7968.25,   20191022, 0.7,   1.3,   2.2
7858.48,   20191023, -1.4,  -0.7,  0.5
7956.23,   20191024, 1.2,   -0.3,  0.3
7926.6,    20191025, -0.4,  -0.1,  0.3
8071.39,   20191028, 1.8,   2.0,   3.0
8101.55,   20191029, 0.4,   1.7,   3.2
8056.9,    20191030, -0.6,  2.5,   2.3
8100.62,   20191031, 0.5,   1.8,   2.5
8121.95,   20191101, 0.3,   2.5,   2.8
8218.93,   20191104, 1.2,   1.8,   5.2
8225.35,   20191105, 0.1,   1.5,   4.7
8204.34,   20191106, -0.3,  1.8,   6.5
8237.68,   20191107, 0.4,   1.7,   5.7
8208.37,   20191108, -0.4,  1.1,   5.4
8212.37,   20191111, 0.0,   -0.1,  6.6
8249.62,   20191112, 0.5,   0.3,   6.0
8235.68,   20191113, -0.2,  0.4,   8.0
8238.85,   20191114, 0.0,   0.0,   9.0
8303.84,   20191115, 0.8,   1.2,   8.2
8303.22,   20191118, -0.0,  1.1,   7.5
8357.43,   20191119, 0.7,   1.3,   8.9
8315.55,   20191120, -0.5,  1.0,   8.3
8273.95,   20191121, -0.5,  0.4,   7.6
8287.25,   20191122, 0.2,   -0.2,  5.8
8309.55,   20191125, 0.3,   0.1,   6.1
8374.26,   20191126, 0.8,   0.2,   6.4
8407.23,   20191127, 0.4,   1.1,   6.3
8422.02,   20191129, 0.2,   1.8,   5.6
8409.87,   20191202, -0.1,  1.5,   5.9
8201.63,   20191203, -2.5,  -1.3,  3.7
8291.86,   20191204, 1.1,   -1.0,  4.1
8321.19,   20191205, 0.4,   -1.0,  5.9
8370.23,   20191206, 0.6,   -0.6,  5.2
8388.75,   20191209, 0.2,   -0.3,  5.8
8364.55,   20191210, -0.3,  2.0,   3.6
8371.65,   20191211, 0.1,   1.0,   3.3
8391.92,   20191212, 0.2,   0.8,   4.2
8461.42,   20191213, 0.8,   1.1,   4.5
8544.27,   20191216, 1.0,   1.9,   5.2
8586.33,   20191217, 0.5,   2.7,   4.5
8587.2,    20191218, 0.0,   2.6,   4.4
8592.68,   20191219, 0.1,   2.4,   4.7
8666.57,   20191220, 0.9,   2.4,   5.2
8703.06,   20191223, 0.4,   1.9,   6.0
8705.3,    20191224, 0.0,   1.4,   6.0
8717.95,   20191226, 0.1,   1.5,   5.7
8805.86,   20191227, 1.0,   2.5,   6.9
8766.39,   20191230, -0.4,  1.2,   6.4
8681.33,   20191231, -1.0,  -0.2,  4.5
8802.22,   20200102, 1.4,   1.1,   6.0
8755.17,   20200103, -0.5,  0.4,   4.8
8713.89,   20200106, -0.5,  -1.0,  4.8
8857.14,   20200107, 1.6,   1.0,   7.0
8845.45,   20200108, -0.1,  1.9,   6.7
8990.04,   20200109, 1.6,   2.1,   8.2
9021.88,   20200110, 0.4,   3.0,   7.7
9007.94,   20200113, -0.2,  3.4,   7.1
9068.02,   20200114, 0.7,   2.4,   7.7
9039.31,   20200115, -0.3,  2.2,   7.5
9088.08,   20200116, 0.5,   1.1,   10.8
9159.48,   20200117, 0.8,   1.5,   10.5
9145.64,   20200121, -0.2,  1.5,   9.9
9212.35,   20200122, 0.7,   1.6,   10.1
9186.14,   20200123, -0.3,  1.6,   9.5
9266.35,   20200124, 0.9,   2.0,   10.8
8913.16,   20200127, -3.8,  -2.7,  6.5
9013.23,   20200128, 1.1,   -1.4,  7.4
9141.52,   20200129, 1.4,   -0.8,  8.0
9038.92,   20200130, -1.1,  -1.6,  5.8
9169.91,   20200131, 1.4,   -1.0,  6.8
9033.52,   20200203, -1.5,  1.4,   5.2
9256.94,   20200204, 2.5,   2.7,   7.7
9441.34,   20200205, 2.0,   3.3,   8.9
9395.69,   20200206, -0.5,  3.9,   8.0
9397.77,   20200207, 0.0,   2.5,   8.0
9373.2,    20200210, -0.3,  3.8,   7.5
9567.81,   20200211, 2.1,   3.4,   8.7
9569.82,   20200212, 0.0,   1.4,   9.2
9540.67,   20200213, -0.3,  1.5,   9.9
9613.88,   20200214, 0.8,   2.3,   9.2
9567.28,   20200218, -0.5,  2.1,   9.3
9683.94,   20200219, 1.2,   1.2,   11.1
9696.66,   20200220, 0.1,   1.3,   9.5
9582.54,   20200221, -1.2,  0.4,   8.3
9055.3,    20200224, -5.5,  -5.8,  0.7
9167.33,   20200225, 1.2,   -4.2,  1.6
8883.01,   20200226, -3.1,  -8.3,  -1.4
8632.54,   20200227, -2.8,  -11.0, -4.8
8133.85,   20200228, -5.8,  -15.1, -10.0
8569.91,   20200302, 5.4,   -5.4,  -5.7
8892.68,   20200303, 3.8,   -3.0,  -2.9
8745.64,   20200304, -1.7,  -1.5,  -4.4
8713.19,   20200305, -0.4,  0.9,   -5.4
8395.95,   20200306, -3.6,  3.2,   -8.6
7912.14,   20200309, -5.8,  -7.7,  -14.6
8213.97,   20200310, 3.8,   -7.6,  -7.8
8166.26,   20200311, -0.6,  -6.6,  -9.4
7452.76,   20200312, -8.7,  -14.5, -18.5
7683.04,   20200313, 3.1,   -8.5,  -15.0
7502.26,   20200316, -2.4,  -5.2,  -18.2
7198.53,   20200317, -4.0,  -12.4, -20.3
7023.86,   20200318, -2.4,  -14.0, -24.1
7190.21,   20200319, 2.4,   -3.5,  -23.8
7391.22,   20200320, 2.8,   -3.8,  -21.3
6952.71,   20200323, -5.9,  -7.3,  -26.0
7349.0,    20200324, 5.7,   2.1,   -21.6
7543.96,   20200325, 2.7,   7.4,   -21.2
7549.83,   20200326, 0.1,   5.0,   -21.1
7657.44,   20200327, 1.4,   3.6,   -19.7
7678.83,   20200330, 0.3,   10.4,  -20.1
7855.74,   20200331, 2.3,   6.9,   -17.9
7582.78,   20200401, -3.5,  0.5,   -21.7
7445.88,   20200402, -1.8,  -1.4,  -23.2
7631.57,   20200403, 2.5,   -0.3,  -20.4
7819.06,   20200406, 2.5,   1.8,   -13.7
8287.48,   20200407, 6.0,   5.5,   -9.6
8134.3,    20200408, -1.8,  7.3,   -8.4
8284.31,   20200409, 1.8,   11.3,  -4.0
8209.68,   20200413, -0.9,  7.6,   0.9
8489.27,   20200414, 3.4,   8.6,   -0.9
8548.47,   20200415, 0.7,   3.1,   -3.9
8689.09,   20200416, 1.6,   6.8,   -0.6
8876.66,   20200417, 2.2,   7.2,   1.9
8742.54,   20200420, -1.5,  6.5,   4.1
8639.74,   20200421, -1.2,  1.8,   9.2
8575.57,   20200422, -0.7,  0.3,   4.4
8693.73,   20200423, 1.4,   0.1,   6.5
8666.57,   20200424, -0.3,  -2.4,  16.3
8865.58,   20200427, 2.3,   1.4,   15.4
8913.28,   20200428, 0.5,   3.2,   18.8
8871.04,   20200429, -0.5,  3.4,   23.2
9005.24,   20200430, 1.5,   3.6,   28.2
8791.88,   20200501, -2.4,  1.4,   22.3
8683.94,   20200504, -1.2,  -2.0,  17.5
8922.39,   20200505, 2.7,   0.1,   28.3
8997.02,   20200506, 0.8,   1.4,   22.4
9104.67,   20200507, 1.2,   1.1,   20.7
9165.7,    20200508, 0.7,   4.3,   21.4
9155.21,   20200511, -0.1,  5.4,   19.6
9326.06,   20200512, 1.9,   4.5,   21.5
9123.69,   20200513, -2.2,  1.4,   16.1
8946.45,   20200514, -1.9,  -1.7,  18.0
8976.29,   20200515, 0.3,   -2.1,  20.6
9281.36,   20200518, 3.4,   1.4,   21.6
9326.28,   20200519, 0.5,   0.0,   19.3
9415.25,   20200520, 1.0,   3.2,   13.6
9487.63,   20200521, 0.8,   6.0,   16.6
9363.67,   20200522, -1.3,  4.3,   13.0
9570.53,   20200526, 2.2,   3.1,   16.6
9366.63,   20200527, -2.1,  0.4,   10.3
9404.83,   20200528, 0.4,   -0.1,  10.0
9440.64,   20200529, 0.4,   -0.5,  8.6
9526.43,   20200601, 0.9,   1.7,   7.3
9603.18,   20200602, 0.8,   0.3,   9.8
9689.72,   20200603, 0.9,   3.4,   12.2
9672.17,   20200604, -0.2,  2.8,   12.8
9673.09,   20200605, 0.0,   2.5,   11.3
9805.69,   20200608, 1.4,   2.9,   13.1
9861.18,   20200609, 0.6,   2.7,   11.2
10037.98,  20200610, 1.8,   3.6,   12.6
9907.56,   20200611, -1.3,  2.4,   11.7
9785.58,   20200612, -1.2,  1.2,   8.7
9517.14,   20200615, -2.7,  -2.9,  8.2
9983.65,   20200616, 4.9,   1.2,   15.0
9998.86,   20200617, 0.2,   -0.4,  12.1
9972.35,   20200618, -0.3,  0.7,   10.8
10104.63,  20200619, 1.3,   3.3,   11.0
10009.0,   20200622, -0.9,  5.2,   9.2
10192.42,  20200623, 1.8,   2.1,   11.3
10183.77,  20200624, -0.1,  1.8,   9.2
10003.5,   20200625, -1.8,  0.3,   9.6
10087.08,  20200626, 0.8,   -0.2,  12.7
9850.55,   20200629, -2.3,  -1.6,  9.7
9970.21,   20200630, 1.2,   -2.2,  7.4
10160.41,  20200701, 1.9,   -0.2,  8.9
10386.38,  20200702, 2.2,   3.8,   10.3
10487.7,   20200706, 1.0,   4.0,   10.5
10595.94,  20200707, 1.0,   7.6,   13.2
10594.41,  20200708, -0.0,  6.3,   10.7
10745.87,  20200709, 1.4,   5.8,   14.7
10751.77,  20200710, 0.1,   3.5,   14.3
10951.54,  20200713, 1.9,   4.4,   16.0
10502.85,  20200714, -4.1,  -0.9,  10.2
10752.11,  20200715, 2.4,   1.5,   12.0
10589.86,  20200716, -1.5,  -1.5,  9.3
10651.6,   20200717, 0.6,   -0.9,  10.1
10672.96,  20200720, 0.2,   -2.5,  10.3
11015.54,  20200721, 3.2,   4.9,   12.3
10850.92,  20200722, -1.5,  0.9,   10.0
10853.16,  20200723, 0.0,   2.5,   8.1
10389.54,  20200724, -4.3,  -2.5,  4.9
10547.11,  20200727, 1.5,   -1.2,  7.8
10644.34,  20200728, 0.9,   -3.4,  11.8
10602.4,   20200729, -0.4,  -2.3,  6.2
10578.63,  20200730, -0.2,  -2.5,  5.8
10886.36,  20200731, 2.9,   4.8,   9.2
11018.02,  20200803, 1.2,   4.5,   9.0
11048.5,   20200804, 0.3,   3.8,   10.4
11107.24,  20200805, 0.5,   4.8,   9.0
11116.49,  20200806, 0.1,   5.1,   9.2
11235.24,  20200807, 1.1,   3.2,   12.3
11153.51,  20200810, -0.7,  1.2,   10.6
11039.68,  20200811, -1.0,  -0.1,  12.1
10972.07,  20200812, -0.6,  -1.2,  10.0
11174.64,  20200813, 1.8,   0.5,   10.0
11187.82,  20200814, 0.1,   -0.4,  7.7
11231.97,  20200817, 0.4,   0.7,   7.1
11339.76,  20200818, 1.0,   2.7,   7.0
11397.23,  20200819, 0.5,   3.9,   7.6
11277.05,  20200820, -1.1,  0.9,   4.9
11476.85,  20200821, 1.8,   2.6,   6.7
11703.34,  20200824, 2.0,   4.2,   6.9
11610.58,  20200825, -0.8,  2.4,   10.5
11779.35,  20200826, 1.5,   3.4,   9.6
11997.89,  20200827, 1.9,   6.4,   13.3
11992.68,  20200828, -0.0,  4.5,   12.6
12017.46,  20200831, 0.2,   2.7,   12.6
12203.36,  20200901, 1.5,   5.1,   10.8
12417.45,  20200902, 1.8,   5.4,   14.4
12195.75,  20200903, -1.8,  1.6,   12.4
11686.24,  20200904, -4.2,  -2.6,  12.5
11143.91,  20200908, -4.6,  -7.3,  5.7
11308.25,  20200909, 1.5,   -7.3,  6.2
11501.43,  20200910, 1.7,   -7.4,  8.5
11251.19,  20200911, -2.2,  -7.7,  6.4
11250.8,   20200914, -0.0,  -3.7,  3.3
11432.94,  20200915, 1.6,   2.6,   3.8
11467.9,   20200916, 0.3,   1.4,   3.8
10965.74,  20200917, -4.4,  -4.7,  -1.3
11147.8,   20200918, 1.7,   -0.9,  0.3
10768.01,  20200921, -3.4,  -4.3,  -4.2
11082.09,  20200922, 2.9,   -3.1,  -0.6
11167.22,  20200923, 0.8,   -2.6,  1.2
10746.35,  20200924, -3.8,  -2.0,  -2.1
10910.5,   20200925, 1.5,   -2.1,  -2.4
11332.32,  20200928, 3.9,   5.2,   1.3
11354.7,   20200929, 0.2,   2.5,   1.1
11328.55,  20200930, -0.2,  1.4,   -0.1
11554.96,  20201001, 2.0,   7.5,   1.4
11328.85,  20201002, -2.0,  3.8,   0.5
11345.24,  20201005, 0.1,   0.1,   -1.1
11476.68,  20201006, 1.2,   1.1,   -1.9
11412.65,  20201007, -0.6,  0.7,   -1.7
11576.44,  20201008, 1.4,   0.2,   -1.7
11615.57,  20201009, 0.3,   2.5,   -3.2
11900.12,  20201012, 2.4,   4.9,   -0.8
12131.07,  20201013, 1.9,   5.7,   0.9
12105.1,   20201014, -0.2,  6.1,   -0.8
11767.27,  20201015, -2.8,  1.6,   -5.2
11952.12,  20201016, 1.6,   2.9,   -2.0
11913.01,  20201019, -0.3,  0.1,   1.9
11679.08,  20201020, -2.0,  -3.7,  4.8
11686.19,  20201021, 0.1,   -3.5,  3.3
11712.64,  20201022, 0.2,   -0.5,  1.8
11680.73,  20201023, -0.3,  -2.3,  3.8
11584.17,  20201026, -0.8,  -2.8,  3.0
11560.86,  20201027, -0.2,  -1.0,  1.1
11400.48,  20201028, -1.4,  -2.4,  -0.6
11214.33,  20201029, -1.6,  -4.3,  2.3
11265.7,   20201030, 0.5,   -3.6,  1.1
11152.48,  20201102, -1.0,  -3.7,  3.6
11155.85,  20201103, 0.0,   -3.5,  0.7
11627.76,  20201104, 4.2,   2.0,   4.1
12021.77,  20201105, 3.4,   7.2,   11.9
12044.29,  20201106, 0.2,   6.9,   10.4
12194.71,  20201109, 1.2,   9.3,   7.6
11704.46,  20201110, -4.0,  4.9,   3.1
11734.81,  20201111, 0.3,   0.9,   3.6
11924.15,  20201112, 1.6,   -0.8,  3.2
11910.62,  20201113, -0.1,  -1.1,  5.1
11925.36,  20201116, 0.1,   -2.2,  5.1
12030.27,  20201117, 0.9,   2.8,   4.8
11963.36,  20201118, -0.6,  1.9,   4.8
11863.12,  20201119, -0.8,  -0.5,  2.5
11976.0,   20201120, 1.0,   0.5,   3.1
11959.89,  20201123, -0.1,  0.3,   0.5
11952.7,   20201124, -0.1,  -0.6,  -1.5
12112.11,  20201125, 1.3,   1.2,   0.1
12220.37,  20201127, 0.9,   3.0,   3.9
12278.78,  20201130, 0.5,   2.5,   2.7
12387.62,  20201201, 0.9,   3.6,   4.0
12390.58,  20201202, 0.0,   3.7,   6.1
12469.16,  20201203, 0.6,   2.9,   6.7
12479.91,  20201204, 0.1,   2.1,   6.6
12523.57,  20201207, 0.3,   2.0,   7.2
12574.84,  20201208, 0.4,   1.5,   8.6
12626.97,  20201209, 0.4,   1.9,   9.2
12268.47,  20201210, -2.8,  -1.6,  7.6
12327.07,  20201211, 0.5,   -1.2,  9.9
12427.86,  20201214, 0.8,   -0.8,  10.3
12565.28,  20201215, 1.1,   -0.1,  12.7
12607.87,  20201216, 0.3,   -0.2,  13.0
12741.64,  20201217, 1.1,   3.9,   9.6
12786.92,  20201218, 0.4,   3.7,   6.4
12575.6,   20201221, -1.7,  1.2,   4.4
12715.48,  20201222, 1.1,   1.2,   4.3
12733.75,  20201223, 0.1,   1.0,   8.8
12668.2,   20201224, -0.5,  -0.6,  8.0
12813.97,  20201228, 1.2,   0.2,   7.5
12909.86,  20201229, 0.7,   2.7,   8.4
12900.03,  20201230, -0.1,  1.5,   8.2
12852.96,  20201231, -0.4,  0.9,   6.8
12950.22,  20210104, 0.8,   2.2,   8.2
12663.13,  20210105, -2.2,  -1.2,  6.7
12601.26,  20210106, -0.5,  -2.4,  5.2
12745.41,  20210107, 1.1,   -1.2,  6.6
13037.24,  20210108, 2.3,   1.4,   9.1
12961.5,   20210111, -0.6,  0.1,   7.0
12916.1,   20210112, -0.4,  2.0,   5.7
12911.87,  20210113, -0.0,  2.5,   5.2
13010.94,  20210114, 0.8,   2.1,   5.0
12898.09,  20210115, -0.9,  -1.1,  4.1
12921.61,  20210119, 0.2,   -0.3,  3.6
13155.12,  20210120, 1.8,   1.9,   5.4
13356.7,   20210121, 1.5,   3.4,   6.7
13356.18,  20210122, -0.0,  2.7,   6.2
13515.63,  20210125, 1.2,   4.8,   7.0
13507.47,  20210126, -0.1,  4.5,   10.1
13371.16,  20210127, -1.0,  1.6,   8.5
13163.32,  20210128, -1.6,  -1.4,  5.9
13124.7,   20210129, -0.3,  -1.7,  4.5
13067.65,  20210201, -0.4,  -3.3,  3.6
13371.37,  20210202, 2.3,   -1.0,  4.9
13555.03,  20210203, 1.4,   1.4,   6.0
13454.85,  20210204, -0.7,  2.2,   7.0
13592.77,  20210205, 1.0,   3.6,   6.9
13672.33,  20210208, 0.6,   4.6,   7.4
13667.14,  20210209, -0.0,  2.2,   7.9
13751.42,  20210210, 0.6,   1.4,   7.3
13723.63,  20210211, -0.2,  2.0,   6.3
13695.9,   20210212, -0.2,  0.8,   6.2
13841.03,  20210216, 1.1,   1.2,   7.7
13636.15,  20210217, -1.5,  -0.2,  5.3
13556.01,  20210218, -0.6,  -1.4,  7.1
13686.23,  20210219, 1.0,   -0.3,  8.6
13414.04,  20210222, -2.0,  -2.1,  5.2
12982.36,  20210223, -3.2,  -6.2,  -0.4
13095.86,  20210224, 0.9,   -4.0,  1.0
13197.75,  20210225, 0.8,   -2.6,  2.2
12958.96,  20210226, -1.8,  -5.3,  0.4
13106.02,  20210301, 1.1,   -2.3,  0.7
13297.76,  20210302, 1.5,   2.4,   3.1
13020.1,   20210303, -2.1,  -0.6,  0.8
12655.92,  20210304, -2.8,  -4.1,  -3.8
12594.16,  20210305, -0.5,  -2.8,  -5.7
12629.56,  20210308, 0.3,   -3.6,  -5.4
12635.45,  20210309, 0.0,   -5.0,  -6.5
12950.25,  20210310, 2.5,   -0.5,  -4.1
12960.53,  20210311, 0.1,   2.4,   -3.1
12873.39,  20210312, -0.7,  2.2,   -2.2
12936.13,  20210315, 0.5,   2.4,   -1.4
13156.65,  20210316, 1.7,   4.1,   0.7
13024.83,  20210317, -1.0,  0.6,   -2.6
13006.29,  20210318, -0.1,  0.4,   -4.0
12792.67,  20210319, -1.6,  -0.6,  -4.9
12943.14,  20210322, 1.2,   0.1,   -4.8
13127.17,  20210323, 1.4,   -0.2,  -4.0
13072.82,  20210324, -0.4,  0.4,   -4.3
12707.58,  20210325, -2.8,  -2.3,  -7.6
12777.27,  20210326, 0.5,   -0.1,  -6.9
12950.51,  20210329, 1.4,   0.1,   -5.4
12904.01,  20210330, -0.4,  -1.7,  -6.8
12970.33,  20210331, 0.5,   -0.8,  -4.9
13268.88,  20210401, 2.3,   4.4,   -2.1
13434.04,  20210405, 1.2,   5.1,   -1.8
13567.2,   20210406, 1.0,   4.8,   1.1
13553.9,   20210407, -0.1,  5.0,   4.4
13730.73,  20210408, 1.3,   5.9,   4.8
13709.67,  20210409, -0.2,  3.3,   3.9
13792.74,  20210412, 0.6,   2.7,   6.4
13874.58,  20210413, 0.6,   2.3,   5.9
13988.86,  20210414, 0.8,   3.2,   5.2
13934.87,  20210415, -0.4,  1.5,   7.0
14035.96,  20210416, 0.7,   2.4,   10.9
13970.83,  20210419, -0.5,  1.3,   10.9
13891.96,  20210420, -0.6,  0.1,   10.0
13768.92,  20210421, -0.9,  -1.6,  9.0
13920.88,  20210422, 1.1,   -0.1,  7.5
13794.49,  20210423, -0.9,  -1.7,  6.4
13954.94,  20210426, 1.2,   -0.1,  8.4
14048.13,  20210427, 0.7,   1.1,   8.6
13953.61,  20210428, -0.7,  1.3,   6.1
14063.94,  20210429, 0.8,   1.0,   8.0
13857.79,  20210430, -1.5,  0.5,   6.5
13923.04,  20210503, 0.5,   -0.2,  8.8
13680.63,  20210504, -1.7,  -2.6,  5.7
13649.12,  20210505, -0.2,  -2.2,  4.0
13490.94,  20210506, -1.2,  -4.1,  3.2
13715.56,  20210507, 1.7,   -1.0,  7.9
13647.73,  20210510, -0.5,  -2.0,  6.8
13102.64,  20210511, -4.0,  -4.2,  1.2
13155.07,  20210512, 0.4,   -3.6,  1.9
13133.73,  20210513, -0.2,  -2.6,  1.3
13238.99,  20210514, 0.8,   -3.5,  -0.2
13329.86,  20210517, 0.7,   -2.3,  -0.8
13352.26,  20210518, 0.2,   1.9,   -1.6
12998.53,  20210519, -2.6,  -1.2,  -4.1
13294.59,  20210520, 2.3,   1.2,   -3.2
13573.22,  20210521, 2.1,   2.5,   -1.0
13504.39,  20210524, -0.5,  1.3,   -2.1
13701.3,   20210525, 1.5,   2.6,   -1.2
13687.71,  20210526, -0.1,  5.3,   -2.2
13686.86,  20210527, -0.0,  3.0,   -1.8
13717.0,   20210528, 0.2,   1.1,   -2.3
13764.27,  20210601, 0.3,   1.9,   -1.5
13655.71,  20210602, -0.8,  -0.3,  -1.7
13568.91,  20210603, -0.6,  -0.9,  -1.5
13619.79,  20210604, 0.4,   -0.5,  -2.2
13744.15,  20210607, 0.9,   0.2,   -0.4
13873.46,  20210608, 0.9,   0.8,   -0.6
13865.51,  20210609, -0.1,  1.5,   -1.3
13834.26,  20210610, -0.2,  2.0,   -0.9
13959.47,  20210611, 0.9,   2.5,   -0.7
14002.14,  20210614, 0.3,   1.9,   1.0
14120.81,  20210615, 0.8,   1.8,   1.4
14053.12,  20210616, -0.5,  1.4,   2.7
13946.7,   20210617, -0.8,  0.8,   2.2
14125.12,  20210618, 1.3,   1.2,   4.7
14055.04,  20210621, -0.5,  0.4,   2.5
14140.3,   20210622, 0.6,   0.1,   3.6
14275.09,  20210623, 1.0,   1.6,   8.9
14358.3,   20210624, 0.6,   3.0,   9.1
14396.63,  20210625, 0.3,   1.9,   9.6
14401.63,  20210628, 0.0,   2.5,   8.8
14523.63,  20210629, 0.8,   2.7,   9.0
14560.07,  20210630, 0.3,   2.0,   9.0
14530.63,  20210701, -0.2,  1.2,   11.8
14633.51,  20210702, 0.7,   1.6,   10.1
14755.15,  20210706, 0.8,   2.5,   8.7
14889.76,  20210707, 0.9,   2.5,   10.3
14577.67,  20210708, -2.1,  0.1,   6.4
14709.32,  20210709, 0.9,   1.2,   7.5
14878.71,  20210712, 1.2,   1.7,   8.7
14871.04,  20210713, -0.1,  0.8,   8.4
14984.86,  20210714, 0.8,   0.6,   8.9
14903.49,  20210715, -0.5,  2.2,   9.1
14842.08,  20210716, -0.4,  0.9,   9.4
14517.96,  20210719, -2.2,  -2.4,  6.6
14601.34,  20210720, 0.6,   -1.8,  6.2
14723.67,  20210721, 0.8,   -1.7,  6.1
14865.47,  20210722, 1.0,   -0.3,  7.2
15004.43,  20210723, 0.9,   1.1,   8.5
15088.28,  20210726, 0.6,   3.9,   8.1
15103.01,  20210727, 0.1,   3.4,   7.9
15005.17,  20210728, -0.6,  1.9,   6.3
15015.3,   20210729, 0.1,   1.0,   6.8
14882.39,  20210730, -0.9,  -0.8,  6.7
15046.1,   20210802, 1.1,   -0.3,  6.5
14997.2,   20210803, -0.3,  -0.7,  6.7
15059.16,  20210804, 0.4,   0.4,   6.5
15105.4,   20210805, 0.3,   0.6,   5.8
15125.74,  20210806, 0.1,   1.6,   5.3
15136.14,  20210809, 0.1,   0.6,   5.1
15157.37,  20210810, 0.1,   1.1,   5.2
15101.82,  20210811, -0.4,  0.3,   4.0
15012.6,   20210812, -0.6,  -0.6,  3.1
15101.84,  20210813, 0.6,   -0.2,  3.9
15090.05,  20210816, -0.1,  -0.3,  3.1
15018.94,  20210817, -0.5,  -0.9,  1.8
14975.02,  20210818, -0.3,  -0.8,  0.6
14773.19,  20210819, -1.3,  -1.6,  1.3
14974.06,  20210820, 1.4,   -0.8,  1.8
15140.14,  20210823, 1.1,   0.3,   1.8
15338.92,  20210824, 1.3,   2.1,   3.1
15384.83,  20210825, 0.3,   2.7,   2.7
15348.98,  20210826, -0.2,  3.9,   3.0
15305.75,  20210827, -0.3,  2.2,   3.1
15460.93,  20210830, 1.0,   2.1,   6.5
15601.34,  20210831, 0.9,   1.7,   6.8
15637.13,  20210901, 0.2,   1.6,   6.2
15656.28,  20210902, 0.1,   2.0,   5.3
15578.96,  20210903, -0.5,  1.8,   3.8
15662.3,   20210907, 0.5,   1.3,   3.8
15669.01,  20210908, 0.0,   0.4,   3.7
15639.44,  20210909, -0.2,  0.0,   4.2
15648.05,  20210910, 0.1,   -0.1,  4.2
15545.66,  20210913, -0.7,  -0.2,  4.5
15505.78,  20210914, -0.3,  -1.0,  3.1
15419.95,  20210915, -0.6,  -1.6,  2.8
15452.67,  20210916, 0.2,   -1.2,  2.6
15481.43,  20210917, 0.2,   -1.1,  2.5
15068.65,  20210920, -2.7,  -3.1,  -0.4
15096.97,  20210921, 0.2,   -2.6,  -0.3
15073.96,  20210922, -0.2,  -2.2,  -0.6
15231.2,   20210923, 1.0,   -1.4,  0.9
15222.97,  20210924, -0.1,  -1.7,  1.4
15205.23,  20210927, -0.1,  0.9,   0.7
15001.98,  20210928, -1.3,  -0.6,  -0.6
14835.51,  20210929, -1.1,  -1.6,  -1.2
14830.3,   20210930, -0.0,  -2.6,  -1.0
14731.38,  20211001, -0.7,  -3.2,  -0.3
14713.6,   20211004, -0.1,  -3.2,  -1.7
14533.43,  20211005, -1.2,  -3.1,  -4.0
14529.89,  20211006, -0.0,  -2.1,  -5.3
14899.07,  20211007, 2.5,   0.5,   -3.2
14942.36,  20211008, 0.3,   1.4,   -2.6
14765.69,  20211011, -1.2,  0.4,   -3.5
14772.29,  20211012, 0.0,   1.6,   -4.5
14751.8,   20211013, -0.1,  1.5,   -5.4
14925.58,  20211014, 1.2,   0.2,   -4.6
15106.97,  20211015, 1.2,   1.1,   -3.5
15091.58,  20211018, -0.1,  2.2,   -3.1
15349.45,  20211019, 1.7,   3.9,   -2.0
15448.0,   20211020, 0.6,   4.7,   -1.4
15365.81,  20211021, -0.5,  2.9,   -1.7
15422.07,  20211022, 0.4,   2.1,   -1.4
15417.25,  20211025, -0.0,  2.2,   -0.8
15615.36,  20211026, 1.3,   1.7,   0.7
15603.16,  20211027, -0.1,  1.0,   1.2
15674.39,  20211028, 0.5,   2.0,   1.4
15638.74,  20211029, -0.2,  1.4,   1.0
15879.61,  20211101, 1.5,   3.0,   5.4
15885.34,  20211102, 0.0,   1.7,   5.2
16000.6,   20211103, 0.7,   2.5,   6.1
16193.22,  20211104, 1.2,   3.3,   6.3
16397.59,  20211105, 1.3,   4.9,   7.7
16351.49,  20211108, -0.3,  3.0,   7.5
16389.99,  20211109, 0.2,   3.2,   9.3
16087.02,  20211110, -1.8,  0.5,   8.4
16114.05,  20211111, 0.2,   -0.5,  8.7
16078.13,  20211112, -0.2,  -1.9,  9.1
16227.01,  20211115, 0.9,   -0.8,  10.3
16170.29,  20211116, -0.3,  -1.3,  11.3
16329.07,  20211117, 1.0,   1.5,   12.4
16398.76,  20211118, 0.4,   1.8,   10.1
16558.561, 20211119, 1.0,   3.0,   10.8
16644.77,  20211122, 0.5,   2.6,   12.7
16330.18,  20211123, -1.9,  1.0,   10.5
16202.26,  20211124, -0.8,  -0.8,  9.8
16237.41,  20211126, 0.2,   -1.0,  8.8
16263.43,  20211129, 0.2,   -1.8,  7.7
16333.95,  20211130, 0.4,   -1.9,  8.2
16347.96,  20211201, 0.1,   0.1,   6.5
15791.3,   20211202, -3.4,  -2.5,  2.2
16039.41,  20211203, 1.6,   -1.2,  4.4
15744.91,  20211206, -1.8,  -3.2,  2.1
16139.56,  20211207, 2.5,   -1.2,  4.7
16313.88,  20211208, 1.1,   -0.2,  4.5
16337.81,  20211209, 0.1,   3.5,   4.7
16278.3,   20211210, -0.4,  1.5,   3.9
16317.9,   20211213, 0.2,   3.6,   4.3
15877.65,  20211214, -2.7,  -1.6,  -0.0
15908.45,  20211215, 0.2,   -2.5,  0.1
16335.03,  20211216, 2.7,   -0.0,  2.1
15714.54,  20211217, -3.8,  -3.5,  -3.0
15566.83,  20211220, -0.9,  -4.6,  -5.1
15794.38,  20211221, 1.5,   -0.5,  -3.4
15963.54,  20211222, 1.1,   0.3,   -2.6
16203.76,  20211223, 1.5,   -0.8,  0.7
16361.07,  20211227, 1.0,   4.1,   1.5
16602.92,  20211228, 1.5,   6.7,   3.3
16510.631, 20211229, -0.6,  4.5,   1.7
16485.4,   20211230, -0.2,  3.3,   1.9
16411.26,  20211231, -0.4,  1.3,   0.5
16395.51,  20220103, -0.1,  0.2,   -0.0
16513.869, 20220104, 0.7,   -0.5,  -0.3
16190.55,  20220105, -2.0,  -1.9,  -2.7
15675.22,  20220106, -3.2,  -4.9,  -4.0
15774.13,  20220107, 0.6,   -3.9,  -2.6
15383.18,  20220110, -2.5,  -6.2,  -5.3
15582.72,  20220111, 1.3,   -5.6,  -4.2
15955.48,  20220112, 2.4,   -1.5,  -2.3
15970.09,  20220113, 0.1,   1.9,   -2.3
15396.47,  20220114, -3.6,  -2.4,  -2.5
15378.06,  20220118, -0.1,  -0.0,  -4.1
15287.25,  20220119, -0.6,  -1.9,  -2.9
15176.38,  20220120, -0.7,  -4.9,  -6.0
14728.66,  20220121, -3.0,  -7.8,  -9.7
14160.91,  20220124, -3.9,  -8.0,  -13.3
14237.6,   20220125, 0.5,   -7.4,  -12.5
14492.52,  20220126, 1.8,   -5.2,  -11.2
14346.46,  20220127, -1.0,  -5.5,  -9.6
14091.31,  20220128, -1.8,  -4.3,  -11.4
14505.07,  20220131, 2.9,   2.4,   -11.2
14966.6,   20220201, 3.2,   5.1,   -4.8
15173.03,  20220202, 1.4,   4.7,   -2.5
14693.85,  20220203, -3.2,  2.4,   -7.0
14566.28,  20220204, -0.9,  3.4,   -8.8
14716.43,  20220207, 1.0,   1.5,   -9.2
14533.25,  20220208, -1.2,  -2.9,  -11.2
14930.99,  20220209, 2.7,   -1.6,  -10.1
14770.68,  20220210, -1.1,  0.5,   -10.5
14739.46,  20220211, -0.2,  1.2,   -10.6
14233.4,   20220214, -3.4,  -3.3,  -13.3
14482.11,  20220215, 1.7,   -0.4,  -11.7
14514.84,  20220216, 0.2,   -2.8,  -12.1
14485.08,  20220217, -0.2,  -1.9,  -10.5
14207.55,  20220218, -1.9,  -3.6,  -9.4
13892.81,  20220222, -2.2,  -2.4,  -11.9
14016.94,  20220223, 0.9,   -3.2,  -8.9
13065.44,  20220224, -6.8,  -10.0, -16.2
13973.9,   20220225, 7.0,   -3.5,  -12.4
14058.61,  20220228, 0.6,   -1.0,  -12.0
14197.66,  20220301, 1.0,   2.2,   -7.8
14075.11,  20220302, -0.9,  0.4,   -8.5
14339.7,   20220303, 1.9,   9.8,   -6.2
13957.6,   20220304, -2.7,  -0.1,  -8.0
13853.53,  20220307, -0.7,  -1.5,  -5.9
13275.23,  20220308, -4.2,  -6.5,  -6.3
13610.48,  20220309, 2.5,   -3.3,  -4.4
13577.84,  20220310, -0.2,  -5.3,  -6.3
13701.8,   20220311, 0.9,   -1.8,  -4.5
13248.09,  20220314, -3.3,  -4.4,  -6.0
13174.97,  20220315, -0.6,  -0.8,  -9.2
13633.12,  20220316, 3.5,   0.2,   -8.9
13878.99,  20220317, 1.8,   2.2,   -8.5
14061.29,  20220318, 1.3,   2.6,   -4.3
14377.15,  20220321, 2.2,   8.5,   -1.3
14396.03,  20220322, 0.1,   9.3,   -2.2
14525.22,  20220323, 0.9,   6.5,   -0.1
14529.72,  20220324, 0.0,   4.7,   -2.7
14766.9,   20220325, 1.6,   5.0,   -0.0
14763.16,  20220328, -0.0,  2.7,   0.2
15142.93,  20220329, 2.6,   5.2,   6.4
15177.15,  20220330, 0.2,   4.5,   4.8
15074.23,  20220331, -0.7,  3.7,   3.9
14886.91,  20220401, -1.2,  0.8,   2.8
14903.38,  20220404, 0.1,   0.9,   4.9
15109.27,  20220405, 1.4,   -0.2,  8.8
14600.3,   20220406, -3.4,  -3.8,  4.2
14469.5,   20220407, -0.9,  -4.0,  10.7
14450.53,  20220408, -0.1,  -2.9,  3.4
14149.6,   20220411, -2.1,  -5.1,  0.6
14175.66,  20220412, 0.2,   -6.2,  -0.2
13939.61,  20220413, -1.7,  -4.5,  -1.0
14225.47,  20220414, 2.1,   -1.7,  -0.8
13859.47,  20220418, -2.6,  -4.1,  -0.7
13882.75,  20220419, 0.2,   -1.9,  0.2
14246.2,   20220420, 2.6,   0.5,   7.3
14174.92,  20220421, -0.5,  1.7,   4.1
13714.63,  20220422, -3.2,  -3.6,  1.0
13275.41,  20220425, -3.2,  -4.2,  -3.1
13447.66,  20220426, 1.3,   -3.1,  1.5
13009.4,   20220427, -3.3,  -8.7,  -1.3
13254.96,  20220428, 1.9,   -6.5,  -2.8
13274.94,  20220429, 0.2,   -3.2,  -4.4
12864.83,  20220502, -3.1,  -3.1,  -8.5
13045.35,  20220503, 1.4,   -3.0,  -9.3
13110.3,   20220504, 0.5,   0.8,   -8.9
13344.79,  20220505, 1.8,   0.7,   -8.1
12776.86,  20220506, -4.3,  -3.8,  -12.1
12465.91,  20220509, -2.4,  -3.1,  -15.6
12496.84,  20220510, 0.2,   -4.2,  -15.4
12240.21,  20220511, -2.1,  -6.6,  -19.2
11790.13,  20220512, -3.7,  -11.6, -22.3
12125.93,  20220513, 2.8,   -5.1,  -19.6
12297.07,  20220516, 1.4,   -1.4,  -17.4
12496.63,  20220517, 1.6,   -0.0,  -16.1
12352.92,  20220518, -1.1,  0.9,   -18.2
11872.56,  20220519, -3.9,  0.7,   -18.7
12037.03,  20220520, 1.4,   -0.7,  -16.8
11867.08,  20220523, -1.4,  -3.5,  -17.9
11807.78,  20220524, -0.5,  -5.5,  -16.6
11732.45,  20220525, -0.6,  -5.0,  -17.2
11906.83,  20220526, 1.5,   0.3,   -14.6
12421.8,   20220527, 4.3,   3.2,   -12.7
12688.95,  20220531, 2.2,   6.9,   -8.4
12750.31,  20220601, 0.5,   8.0,   -8.2
12498.63,  20220602, -2.0,  6.5,   -12.3
12650.38,  20220603, 1.2,   6.2,   -10.8
12745.89,  20220606, 0.8,   2.6,   -7.1
12456.49,  20220607, -2.3,  -1.8,  -6.2
12682.81,  20220608, 1.8,   -0.5,  -5.7
12543.37,  20220609, -1.1,  0.4,   -3.6
12052.59,  20220610, -3.9,  -4.7,  -9.1
11472.19,  20220613, -4.8,  -10.0, -13.6
11385.18,  20220614, -0.8,  -8.6,  -11.5
11468.13,  20220615, 0.7,   -9.6,  -12.1
11286.87,  20220616, -1.6,  -10.0, -13.9
11172.65,  20220617, -1.0,  -7.3,  -16.3
11446.98,  20220621, 2.5,   -0.2,  -10.4
11427.74,  20220622, -0.2,  0.4,   -8.3
11622.09,  20220623, 1.7,   1.3,   -7.0
11826.95,  20220624, 1.8,   4.8,   -3.4
12157.93,  20220627, 2.8,   8.8,   3.1
12021.34,  20220628, -1.1,  5.0,   -0.9
11619.01,  20220629, -3.3,  1.7,   -5.5
11532.32,  20220630, -0.7,  -0.8,  -7.7
11472.63,  20220701, -0.5,  -3.0,  -7.1
11419.34,  20220705, -0.5,  -6.1,  -3.8
11807.08,  20220706, 3.4,   -1.8,  -1.9
11913.73,  20220707, 0.9,   2.5,   0.4
11977.95,  20220708, 0.5,   3.9,   1.4
12008.76,  20220711, 0.3,   4.7,   2.4
11926.84,  20220712, -0.7,  4.4,   0.2
11523.91,  20220713, -3.4,  -2.4,  -7.2
11637.18,  20220714, 1.0,   -2.3,  -8.3
11896.15,  20220715, 2.2,   -0.7,  -6.7
12089.68,  20220718, 1.6,   0.7,   -3.3
12046.69,  20220719, -0.4,  1.0,   -4.8
12269.07,  20220720, 1.8,   6.5,   -3.7
12466.13,  20220721, 1.6,   7.1,   0.1
12575.76,  20220722, 0.9,   5.7,   -0.8
12391.21,  20220725, -1.5,  2.5,   -1.2
12248.21,  20220726, -1.2,  1.7,   1.6
12299.34,  20220727, 0.4,   0.2,   7.2
12580.61,  20220728, 2.3,   0.9,   10.5
12784.2,   20220729, 1.6,   1.7,   11.5
12877.04,  20220801, 0.7,   3.9,   14.1
12853.04,  20220802, -0.2,  4.9,   15.0
12983.58,  20220803, 1.0,   5.6,   13.4
13253.77,  20220804, 2.1,   5.4,   16.0
13107.45,  20220805, -1.1,  2.5,   12.8
13227.09,  20220808, 0.9,   2.7,   11.8
13064.24,  20220809, -1.2,  1.6,   7.5
13330.66,  20220810, 2.0,   2.7,   10.9
13471.03,  20220811, 1.1,   1.6,   15.9
13384.65,  20220812, -0.6,  2.1,   16.1
13529.66,  20220815, 1.1,   2.3,   17.9
13617.66,  20220816, 0.7,   4.2,   19.3
13499.24,  20220817, -0.9,  1.3,   14.3
13474.24,  20220818, -0.2,  0.0,   13.1
13370.25,  20220819, -0.8,  -0.1,  11.6
13056.39,  20220822, -2.3,  -3.5,  8.7
12885.28,  20220823, -1.3,  -5.4,  8.0
12876.23,  20220824, -0.1,  -4.6,  11.7
12984.74,  20220825, 0.8,   -3.6,  11.6
13129.91,  20220826, 1.1,   -1.8,  10.4
12488.46,  20220829, -4.9,  -4.3,  3.3
12562.4,   20220830, 0.6,   -2.5,  4.3
12448.07,  20220831, -0.9,  -3.3,  1.5
12158.88,  20220901, -2.3,  -6.4,  -2.5
12392.46,  20220902, 1.9,   -5.6,  -1.5
12106.57,  20220906, -2.3,  -3.1,  -2.3
12038.18,  20220907, -0.6,  -4.2,  -1.7
12139.57,  20220908, 0.8,   -2.5,  -1.3
12424.15,  20220909, 2.3,   2.2,   -1.2
12649.23,  20220912, 1.8,   2.1,   -1.1
12364.83,  20220913, -2.2,  2.1,   -4.0
12092.03,  20220914, -2.2,  0.4,   -5.9
12034.43,  20220915, -0.5,  -0.9,  -7.3
11775.94,  20220916, -2.1,  -5.2,  -11.2
11753.6,   20220919, -0.2,  -7.1,  -10.3
11855.84,  20220920, 0.9,   -4.1,  -10.4
11892.72,  20220921, 0.3,   -1.6,  -9.0
11574.32,  20220922, -2.7,  -3.8,  -13.2
11398.75,  20220923, -1.5,  -3.2,  -15.4
11283.14,  20220926, -1.0,  -4.0,  -15.7
11411.34,  20220927, 1.1,   -3.7,  -15.7
11258.74,  20220928, -1.3,  -5.3,  -17.3
11334.57,  20220929, 0.7,   -2.1,  -16.0
11123.11,  20220930, -1.9,  -2.4,  -17.4
11059.17,  20221003, -0.6,  -2.0,  -17.3
11477.85,  20221004, 3.8,   0.6,   -12.1
11429.47,  20221005, -0.4,  1.5,   -11.3
11556.4,   20221006, 1.1,   2.0,   -10.3
11272.46,  20221007, -2.5,  1.3,   -13.2
11048.51,  20221010, -2.0,  -0.1,  -15.9
10865.32,  20221011, -1.7,  -5.3,  -13.0
10810.3,   20221012, -0.5,  -5.4,  -13.9
10481.58,  20221013, -3.0,  -9.3,  -15.8
11130.08,  20221014, 6.2,   -1.3,  -8.5
10967.25,  20221017, -1.5,  -0.7,  -11.5
11368.76,  20221018, 3.7,   4.6,   -6.1
11081.82,  20221019, -2.5,  2.5,   -7.9
11081.22,  20221020, -0.0,  5.7,   -8.7
10998.17,  20221021, -0.7,  -1.2,  -11.5
11321.11,  20221024, 2.9,   3.2,   -10.5
11482.87,  20221025, 1.4,   1.0,   -7.1
11409.79,  20221026, -0.6,  3.0,   -5.6
11387.71,  20221027, -0.2,  2.8,   -5.4
11176.99,  20221028, -1.9,  1.6,   -5.1
11465.21,  20221031, 2.6,   1.3,   -2.5
11571.53,  20221101, 0.9,   0.8,   -2.4
11300.27,  20221102, -2.3,  -1.0,  -5.0
10769.43,  20221103, -4.7,  -5.4,  -7.0
10911.98,  20221104, 1.3,   -2.4,  -4.3
10900.83,  20221107, -0.1,  -4.9,  -3.4
11039.58,  20221108, 1.3,   -4.6,  -3.3
10971.43,  20221109, -0.6,  -2.9,  -2.6
11350.42,  20221110, 3.5,   5.4,   0.1
11604.36,  20221111, 2.2,   6.3,   4.3
11728.11,  20221114, 1.1,   7.6,   6.0
12006.45,  20221115, 2.4,   8.8,   4.6
11767.42,  20221116, -2.0,  7.3,   3.0
11521.64,  20221117, -2.1,  1.5,   -0.3
11791.85,  20221118, 2.3,   1.6,   4.6
11623.35,  20221121, -1.4,  -0.9,  5.2
11590.83,  20221122, -0.3,  -3.5,  6.7
11725.08,  20221123, 1.2,   -0.4,  8.5
11777.33,  20221125, 0.4,   2.2,   12.4
11684.07,  20221128, -0.8,  -0.9,  5.0
11590.3,   20221129, -0.8,  -0.3,  5.7
11505.74,  20221130, -0.7,  -0.7,  1.2
12030.24,  20221201, 4.6,   2.6,   8.6
11845.53,  20221202, -1.5,  0.6,   6.9
11899.54,  20221205, 0.5,   1.8,   8.2
11775.2,   20221206, -1.0,  1.6,   4.0
11490.99,  20221207, -2.4,  -0.1,  0.1
11543.68,  20221208, 0.5,   -4.0,  1.2
11594.38,  20221209, 0.4,   -2.1,  1.8
11572.26,  20221212, -0.2,  -2.8,  3.5
12141.24,  20221213, 4.9,   3.1,   5.9
11824.54,  20221214, -2.6,  2.9,   2.2
11573.38,  20221215, -2.1,  0.3,   2.4
11313.64,  20221216, -2.2,  -2.4,  5.1
11243.0,   20221219, -0.6,  -2.8,  3.0
11023.47,  20221220, -2.0,  -9.2,  1.1
11110.65,  20221221, 0.8,   -6.0,  0.6
11099.36,  20221222, -0.1,  -4.1,  1.2
10911.04,  20221223, -1.7,  -3.6,  -3.9
10944.3,   20221227, 0.3,   -2.7,  -5.7
10807.12,  20221228, -1.3,  -2.0,  -7.9
10800.8,   20221229, -0.1,  -2.8,  -10.0
10835.59,  20221230, 0.3,   -2.4,  -7.9
11038.42,  20230103, 1.9,   1.2,   -4.2
10949.34,  20230104, -0.8,  0.0,   -7.1
10837.25,  20230105, -1.0,  0.3,   -6.8
10807.46,  20230106, -0.3,  0.1,   -6.8
11133.07,  20230109, 3.0,   2.7,   -5.0
11069.05,  20230110, -0.6,  0.3,   -6.0
11259.16,  20230111, 1.7,   2.8,   -3.6
11441.45,  20230112, 1.6,   5.6,   -1.3
11362.13,  20230113, -0.7,  5.1,   -1.2
11531.54,  20230117, 1.5,   3.6,   -4.1
11636.61,  20230118, 0.9,   5.1,   -1.8
11345.19,  20230119, -2.5,  0.8,   -4.7
11363.85,  20230120, 0.2,   -0.7,  -3.5
11652.0,   20230123, 2.5,   2.6,   1.4
11802.95,  20230124, 1.3,   2.4,   2.2
11636.01,  20230125, -1.4,  -0.0,  0.4
11972.57,  20230126, 2.9,   5.5,   3.5
12000.21,  20230127, 0.2,   5.6,   -1.2
12047.16,  20230130, 0.4,   3.4,   1.9
11915.94,  20230131, -1.1,  1.0,   3.0
12087.92,  20230201, 1.4,   3.9,   6.8
12655.18,  20230202, 4.7,   5.7,   12.6
12520.95,  20230203, -1.1,  4.3,   13.6
12468.8,   20230206, -0.4,  3.5,   12.2
12474.95,  20230207, 0.0,   4.7,   12.4
12680.03,  20230208, 1.6,   4.9,   16.2
12677.54,  20230209, -0.0,  0.2,   15.8
12306.59,  20230210, -2.9,  -1.7,  13.9
12356.5,   20230213, 0.4,   -0.9,  14.4
12413.1,   20230214, 0.5,   -0.5,  14.6
12531.6,   20230215, 1.0,   -1.2,  13.5
12495.07,  20230216, -0.3,  -1.4,  14.1
12352.3,   20230217, -1.1,  0.4,   14.0
12205.21,  20230221, -1.2,  -1.2,  12.9
12085.67,  20230222, -1.0,  -2.6,  8.6
12224.44,  20230223, 1.1,   -2.5,  10.4
11979.8,   20230224, -2.0,  -4.1,  6.4
12106.79,  20230227, 1.1,   -2.0,  5.8
12041.75,  20230228, -0.5,  -1.3,  6.0
12026.72,  20230301, -0.1,  -0.5,  4.3
11830.09,  20230302, -1.6,  -3.2,  1.7
12110.71,  20230303, 2.4,   1.1,   6.7
12344.65,  20230306, 1.9,   2.0,   8.6
12303.19,  20230307, -0.3,  2.2,   5.6
12181.06,  20230308, -1.0,  1.3,   3.2
12230.84,  20230309, 0.4,   3.4,   5.1
12001.7,   20230310, -1.9,  -0.9,  0.2
11752.1,   20230313, -2.1,  -4.8,  -2.1
12078.46,  20230314, 2.8,   -1.8,  0.3
12083.24,  20230315, 0.0,   -0.8,  1.4
12211.87,  20230316, 1.1,   -0.2,  1.0
12570.16,  20230317, 2.9,   4.7,   -0.7
12487.79,  20230320, -0.7,  6.3,   -0.3
12643.35,  20230321, 1.2,   4.7,   1.4
12742.41,  20230322, 0.8,   5.5,   2.1
12735.6,   20230323, -0.1,  4.3,   0.4
12700.51,  20230324, -0.3,  1.0,   0.2
12797.9,   20230327, 0.8,   2.5,   4.0
12654.91,  20230328, -1.1,  0.1,   2.4
12768.14,  20230329, 0.9,   0.2,   2.9
12934.99,  20230330, 1.3,   1.6,   3.2
12968.96,  20230331, 0.3,   2.1,   3.8
13084.1,   20230403, 0.9,   2.2,   5.9
13165.32,  20230404, 0.6,   4.0,   7.9
13056.79,  20230405, -0.8,  2.3,   8.0
12892.9,   20230406, -1.3,  -0.3,  5.5
12936.52,  20230410, 0.3,   -0.3,  8.0
13040.49,  20230411, 0.8,   -0.3,  7.7
13043.97,  20230412, 0.0,   -0.9,  8.3
12922.9,   20230413, -0.9,  -1.0,  7.5
13044.69,  20230414, 0.9,   1.2,   10.3
13053.26,  20230417, 0.1,   0.9,   7.8
13180.31,  20230418, 1.0,   1.1,   6.8
12992.51,  20230419, -1.4,  -0.4,  5.6
12962.29,  20230420, -0.2,  0.3,   6.4
12970.32,  20230421, 0.1,   -0.6,  6.0
12981.71,  20230424, 0.1,   -0.5,  8.2
12905.09,  20230425, -0.6,  -2.1,  9.8
12866.64,  20230426, -0.3,  -1.0,  6.5
12963.21,  20230427, 0.8,   0.0,   7.3
13139.35,  20230428, 1.4,   1.3,   7.6
13229.11,  20230501, 0.7,   1.9,   5.2
13225.48,  20230502, -0.0,  2.5,   5.9
13123.1,   20230503, -0.8,  2.0,   3.8
13014.07,  20230504, -0.8,  0.4,   2.1
13070.7,   20230505, 0.4,   -0.5,  2.6
13247.09,  20230508, 1.3,   0.1,   4.3
13228.64,  20230509, -0.1,  0.0,   3.4
13308.41,  20230510, 0.6,   1.4,   5.2
13376.85,  20230511, 0.5,   2.8,   4.8
13407.02,  20230512, 0.2,   2.6,   3.6
13352.26,  20230515, -0.4,  0.8,   3.0
13385.37,  20230516, 0.2,   1.2,   2.3
13474.52,  20230517, 0.7,   1.2,   2.3
13608.95,  20230518, 1.0,   1.7,   4.2
13842.69,  20230519, 1.7,   3.2,   7.4
13805.33,  20230522, -0.3,  3.4,   6.7
13773.68,  20230523, -0.2,  2.9,   5.6
13589.02,  20230524, -1.3,  0.8,   4.2
13920.89,  20230525, 2.4,   2.3,   7.7
13990.55,  20230526, 0.5,   1.1,   7.3
14465.59,  20230530, 3.4,   4.8,   10.8
14293.56,  20230531, -1.2,  3.8,   8.4
14269.09,  20230601, -0.2,  5.0,   9.8
14517.08,  20230602, 1.7,   4.3,   12.0
14550.13,  20230605, 0.2,   4.0,   12.2
14526.1,   20230606, -0.2,  0.4,   11.9
14573.27,  20230607, 0.3,   2.0,   12.9
14319.24,  20230608, -1.7,  0.4,   11.3
14574.24,  20230609, 1.8,   0.4,   12.4
14617.35,  20230612, 0.3,   0.5,   11.2
14911.26,  20230613, 2.0,   2.7,   12.7
14894.1,   20230614, -0.1,  2.2,   12.6
14949.7,   20230615, 0.4,   4.4,   13.9
15272.65,  20230616, 2.2,   4.8,   17.4
15036.48,  20230620, -1.5,  2.9,   15.0
15019.01,  20230621, -0.1,  0.7,   13.4
14797.89,  20230622, -1.5,  -0.6,  11.9
14879.23,  20230623, 0.5,   -0.5,  11.8
14866.66,  20230626, -0.1,  -2.7,  11.1
14751.57,  20230627, -0.8,  -1.9,  10.0
14876.25,  20230628, 0.8,   -1.0,  11.4
14965.55,  20230629, 0.6,   1.1,   11.8
15085.24,  20230630, 0.8,   1.4,   12.0
15190.54,  20230703, 0.7,   2.2,   11.6
15165.36,  20230705, -0.2,  2.8,   9.6
15054.1,   20230706, -0.7,  1.2,   9.0
15070.5,   20230707, 0.1,   0.7,   9.4
15031.95,  20230710, -0.3,  -0.4,  10.6
15068.13,  20230711, 0.2,   -0.8,  8.2
15279.35,  20230712, 1.4,   0.8,   9.2
15432.48,  20230713, 1.0,   2.5,   6.7
15610.0,   20230714, 1.2,   3.6,   9.2
15608.28,  20230717, -0.0,  3.8,   9.4
15671.72,  20230718, 0.4,   4.0,   8.0
15888.16,  20230719, 1.4,   4.0,   9.2
15717.67,  20230720, -1.1,  1.8,   8.2
15554.95,  20230721, -1.0,  -0.4,  6.7
15475.15,  20230724, -0.5,  -0.9,  8.1
15483.06,  20230725, 0.1,   -1.2,  6.2
15514.04,  20230726, 0.2,   -2.4,  6.1
15734.03,  20230727, 1.4,   0.1,   5.5
15630.17,  20230728, -0.7,  0.5,   4.9
15774.3,   20230731, 0.9,   1.9,   5.5
15696.98,  20230801, -0.5,  1.4,   2.8
15561.15,  20230802, -0.9,  0.3,   3.5
15287.77,  20230803, -1.8,  -2.8,  1.8
15420.74,  20230804, 0.9,   -1.3,  4.2
15355.62,  20230807, -0.4,  -2.7,  3.2
15279.41,  20230808, -0.5,  -2.7,  2.8
15286.99,  20230809, 0.0,   -1.8,  3.6
15217.29,  20230810, -0.5,  -0.5,  2.3
15010.52,  20230811, -1.4,  -2.7,  0.3
14988.42,  20230814, -0.1,  -2.4,  -0.6
15172.08,  20230815, 1.2,   -0.7,  -0.1
15021.63,  20230816, -1.0,  -1.7,  -0.9
14943.34,  20230817, -0.5,  -1.8,  -0.7
14589.71,  20230818, -2.4,  -2.8,  -3.2
14762.01,  20230821, 1.2,   -1.5,  -1.8
15035.07,  20230822, 1.8,   -0.9,  -0.2
14947.58,  20230823, -0.6,  -0.5,  -2.2
15278.93,  20230824, 2.2,   2.2,   -1.0
14858.93,  20230825, -2.7,  1.8,   -4.8
15035.48,  20230828, 1.2,   1.9,   -3.7
15043.02,  20230829, 0.1,   0.1,   -4.0
15386.67,  20230830, 2.3,   2.9,   -3.2
15475.43,  20230831, 0.6,   1.3,   -1.5
15600.95,  20230901, 0.8,   5.0,   0.3
15460.52,  20230905, -0.9,  2.8,   -0.1
15467.32,  20230906, 0.0,   2.8,   -0.1
15174.22,  20230907, -1.9,  -1.4,  -2.2
15265.57,  20230908, 0.6,   -1.4,  -3.0
15409.77,  20230911, 0.9,   -1.2,  -1.4
15390.59,  20230912, -0.1,  -0.5,  -2.4
15293.27,  20230913, -0.6,  -1.1,  -2.6
15433.01,  20230914, 0.9,   1.7,   -0.8
15425.95,  20230915, -0.0,  1.1,   0.9
15161.17,  20230918, -1.7,  -1.6,  -1.7
15152.53,  20230919, -0.1,  -1.5,  -1.3
15225.34,  20230920, 0.5,   -0.4,  -0.4
14813.49,  20230921, -2.7,  -4.0,  -3.1
14756.62,  20230922, -0.4,  -4.3,  -3.0
14662.69,  20230925, -0.6,  -3.3,  -2.3
14668.45,  20230926, 0.0,   -3.2,  -2.1
14600.14,  20230927, -0.5,  -4.1,  -3.8
14530.96,  20230928, -0.5,  -1.9,  -3.3
14853.35,  20230929, 2.2,   0.7,   -0.6
14717.9,   20231002, -0.9,  0.4,   0.9
14744.05,  20231003, 0.2,   0.5,   -0.1
14613.23,  20231004, -0.9,  0.1,   -2.8
14769.55,  20231005, 1.1,   1.6,   -1.2
14622.23,  20231006, -1.0,  -1.6,  -4.3
14871.57,  20231009, 1.7,   1.0,   0.1
15074.04,  20231010, 1.4,   2.2,   0.3
15194.53,  20231011, 0.8,   4.0,   1.0
15264.11,  20231012, 0.5,   3.3,   -0.8
15225.73,  20231013, -0.3,  4.1,   -1.6
15054.48,  20231016, -1.1,  1.2,   -3.5
15009.26,  20231017, -0.3,  -0.4,  -2.9
15022.28,  20231018, 0.1,   -1.1,  -2.9
14981.13,  20231019, -0.3,  -1.9,  -1.3
14752.59,  20231020, -1.5,  -3.1,  -3.4
14514.11,  20231023, -1.6,  -3.6,  -5.8
14678.41,  20231024, 1.1,   -2.2,  -4.6
14642.08,  20231025, -0.2,  -2.5,  -4.3
14314.01,  20231026, -2.2,  -4.5,  -7.3
14245.75,  20231027, -0.5,  -3.4,  -7.7
14283.98,  20231030, 0.3,   -1.6,  -5.8
14340.86,  20231031, 0.4,   -2.3,  -5.4
14453.68,  20231101, 0.8,   -1.3,  -5.1
14856.95,  20231102, 2.8,   3.8,   0.3
14985.12,  20231103, 0.9,   5.2,   1.5
15137.16,  20231106, 1.0,   6.0,   3.2
15201.77,  20231107, 0.4,   6.0,   3.6
15313.9,   20231108, 0.7,   6.0,   4.9
15351.12,  20231109, 0.2,   3.3,   5.6
15252.36,  20231110, -0.6,  1.8,   2.7
15469.21,  20231113, 1.4,   2.2,   5.1
15744.36,  20231114, 1.8,   3.6,   6.8
15881.72,  20231115, 0.9,   3.7,   8.7
15765.2,   20231116, -0.7,  2.7,   6.7
15803.98,  20231117, 0.2,   3.6,   8.1
15845.2,   20231120, 0.3,   2.4,   6.5
15951.55,  20231121, 0.7,   1.3,   5.8
16025.74,  20231122, 0.5,   0.9,   5.5
15974.85,  20231124, -0.3,  1.3,   4.7
15969.72,  20231127, -0.0,  1.0,   4.9
15940.29,  20231128, -0.2,  0.6,   5.9
16111.36,  20231129, 1.1,   1.0,   7.3
15987.66,  20231130, -0.8,  -0.2,  6.4
15892.59,  20231201, -0.6,  -0.5,  6.1
15837.12,  20231204, -0.3,  -0.8,  7.4
15763.65,  20231205, -0.5,  -1.1,  8.6
15990.2,   20231206, 1.4,   -0.8,  8.9
15888.23,  20231207, -0.6,  -0.6,  8.5
15952.85,  20231208, 0.4,   0.4,   11.4
16069.62,  20231211, 0.7,   1.5,   12.8
16209.61,  20231212, 0.9,   2.8,   13.5
16392.18,  20231213, 1.1,   2.5,   14.3
16603.539, 20231214, 1.3,   4.5,   14.9
16587.0,   20231215, -0.1,  4.0,   11.6
16631.109, 20231218, 0.3,   3.5,   11.0
16734.25,  20231219, 0.6,   3.2,   10.6
16763.641, 20231220, 0.2,   2.3,   10.3
16719.609, 20231221, -0.3,  0.7,   9.2
16799.02,  20231222, 0.5,   1.3,   9.4
16816.779, 20231226, 0.1,   1.1,   10.3
16896.141, 20231227, 0.5,   1.0,   9.2
16963.52,  20231228, 0.4,   1.2,   7.7
16902.439, 20231229, -0.4,  1.1,   6.4
16667.301, 20240102, -1.4,  -0.8,  5.7
16413.26,  20240103, -1.5,  -2.4,  3.9
16290.78,  20240104, -0.7,  -3.6,  2.8
16281.74,  20240105, -0.1,  -4.0,  2.1
16354.22,  20240108, 0.4,   -3.2,  2.0
16531.961, 20240109, 1.1,   -0.8,  3.5
16702.551, 20240110, 1.0,   1.8,   4.6
16852.189, 20240111, 0.9,   3.4,   5.7
16855.0,   20240112, 0.0,   3.5,   4.6
16775.1,   20240116, -0.5,  2.6,   4.9
16705.51,  20240117, -0.4,  1.0,   5.1
16894.33,  20240118, 1.1,   1.1,   6.7
17060.141, 20240119, 1.0,   1.2,   8.2
17403.619, 20240122, 2.0,   3.3,   8.8
17346.949, 20240123, -0.3,  3.4,   9.2
17562.32,  20240124, 1.2,   5.1,   10.1
17589.27,  20240125, 0.2,   4.1,   9.5
17432.869, 20240126, -0.9,  2.2,   7.5
17438.029, 20240129, 0.0,   0.2,   6.4
17556.49,  20240130, 0.7,   1.2,   5.7
17269.98,  20240131, -1.6,  -1.7,  4.1
17216.99,  20240201, -0.3,  -2.1,  3.5
17415.939, 20240202, 1.2,   -0.1,  4.1
17634.689, 20240205, 1.3,   1.1,   5.2
17664.439, 20240206, 0.2,   0.6,   5.7
17674.59,  20240207, 0.1,   2.3,   5.2
17757.6,   20240208, 0.5,   3.1,   5.6
17820.98,  20240209, 0.4,   2.3,   5.5
17942.279, 20240212, 0.7,   1.7,   5.8
17519.801, 20240213, -2.4,  -0.8,  3.7
17728.9,   20240214, 1.2,   0.3,   6.4
17815.32,  20240215, 0.5,   0.3,   8.5
17853.59,  20240216, 0.2,   0.2,   9.6
17591.17,  20240220, -1.5,  -2.0,  8.0
17412.789, 20240221, -1.0,  -0.6,  6.5
17857.76,  20240222, 2.6,   0.7,   8.0
18052.59,  20240223, 1.1,   1.3,   8.1
17970.211, 20240226, -0.5,  0.7,   6.6
17962.58,  20240227, -0.0,  2.1,   6.6
17895.029, 20240228, -0.4,  2.8,   6.7
17991.67,  20240229, 0.5,   0.7,   7.7
18069.289, 20240301, 0.4,   0.1,   7.0
18290.1,   20240304, 1.2,   1.8,   7.2
18088.711, 20240305, -1.1,  0.7,   3.9
18083.51,  20240306, -0.0,  1.1,   4.2
18161.73,  20240307, 0.4,   0.9,   3.4
18312.789, 20240308, 0.8,   1.3,   4.1
17959.68,  20240311, -1.9,  -1.8,  3.0
18063.02,  20240312, 0.6,   -0.1,  3.6
18151.76,  20240313, 0.5,   0.4,   3.4
18114.689, 20240314, -0.2,  -0.3,  4.9
17926.92,  20240315, -1.0,  -2.1,  4.1
18024.24,  20240318, 0.5,   0.4,   3.5
17902.52,  20240319, -0.7,  -0.9,  1.5
18067.699, 20240320, 0.9,   -0.5,  2.3
18438.24,  20240321, 2.1,   1.8,   4.3
18293.15,  20240322, -0.8,  2.0,   3.0
18215.539, 20240325, -0.4,  1.1,   2.2
18329.289, 20240326, 0.6,   2.4,   2.2
18338.189, 20240327, 0.0,   1.5,   4.7
18263.41,  20240328, -0.4,  -0.9,  3.0
18280.82,  20240401, 0.1,   -0.1,  2.6
18076.43,  20240402, -1.1,  -0.8,  1.2
18054.439, 20240403, -0.1,  -1.5,  2.6
18331.02,  20240404, 1.5,   -0.0,  5.3
17940.279, 20240405, -2.1,  -1.8,  0.5
18130.439, 20240408, 1.1,   -0.8,  0.4
18188.869, 20240409, 0.3,   0.6,   1.2
17957.961, 20240410, -1.3,  -0.5,  -0.0
18085.109, 20240411, 0.7,   -1.3,  1.1
18117.67,  20240412, 0.2,   1.0,   0.7
18143.25,  20240415, 0.1,   0.1,   0.4
17691.449, 20240416, -2.5,  -2.7,  -3.3
17785.82,  20240417, 0.5,   -1.0,  -1.7
17514.77,  20240418, -1.5,  -3.2,  -3.1
17334.711, 20240419, -1.0,  -4.3,  -4.6
17161.109, 20240422, -1.0,  -5.4,  -6.3
17297.24,  20240423, 0.8,   -2.2,  -3.7
17613.66,  20240424, 1.8,   -1.0,  -2.5
17205.279, 20240425, -2.3,  -1.8,  -5.2
17566.221, 20240426, 2.1,   1.3,   -3.0
17801.141, 20240429, 1.3,   3.7,   -0.7
17720.35,  20240430, -0.5,  2.4,   -1.7
17382.779, 20240501, -1.9,  -1.3,  -2.9
17474.92,  20240502, 0.5,   1.6,   -3.3
17848.66,  20240503, 2.1,   1.6,   -3.2
17950.98,  20240506, 0.6,   0.8,   -1.9
18106.33,  20240507, 0.9,   2.2,   -0.6
17987.699, 20240508, -0.7,  3.5,   -1.9
18095.801, 20240509, 0.6,   3.6,   -1.3
18171.15,  20240510, 0.4,   1.8,   -0.5
18228.4,   20240513, 0.3,   1.5,   -0.3
18187.51,  20240514, -0.2,  0.4,   0.6
18412.811, 20240515, 1.2,   2.4,   2.0
18589.98,  20240516, 1.0,   2.7,   1.4
18571.82,  20240517, -0.1,  2.2,   3.5
18559.221, 20240520, -0.1,  1.8,   2.4
18603.99,  20240521, 0.2,   2.3,   2.3
18733.539, 20240522, 0.7,   1.7,   4.3
18907.141, 20240523, 0.9,   1.7,   4.5
18669.359, 20240524, -1.3,  0.5,   3.0
18855.15,  20240528, 1.0,   1.6,   3.9
18708.07,  20240529, -0.8,  0.6,   5.7
18683.25,  20240530, -0.1,  -0.3,  5.0
18550.801, 20240531, -0.7,  -1.9,  5.9
18666.721, 20240603, 0.6,   -0.0,  7.7
18598.0,   20240604, -0.4,  -1.4,  8.4
18791.279, 20240605, 1.0,   0.4,   8.6
19053.01,  20240606, 1.4,   2.0,   8.2
18992.58,  20240607, -0.3,  2.4,   10.4
18945.311, 20240610, -0.2,  1.5,   7.9`;
