import _TQQQ from './QQQ';

const toObject = csv => {
    const lines = csv.trim().split('\n');
    const headers = ['open', 'date', '_1dChange', '_5dChange', '_30dChange'];

    return lines.slice(1).map(line => {
        const values = line.split(',');
        return headers.reduce((obj, header, index) => {
            obj[header.trim()] = Number(values[index].trim());
            return obj;
        }, {});
    }) as {
        open: number;
        date: number;
        _1dChange: number;
        _5dChange: number;
        _30dChange: number;
    }[];
}

const TQQQ = toObject(_TQQQ);

export const SymbolData = {
    TQQQ: [ TQQQ, 'TQQQ 3X'  ],
    QQQ:  [ TQQQ, 'QQQ' ],
    SQQQ: [ TQQQ, 'SQQQ -3X' ],
};

export const GameSets = {
    QQQ:  [ 'TQQQ', 'SQQQ', 'QQQ' ],
};

export const CustomLeverage = {
    TQQQ: 5,
    SQQQ: -5,
    QQQ: 1,
};
