import ApexCharts from 'apexcharts';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useGlobalState } from "./State";
import { Box, SxProps, ToggleButton } from "@mui/material";
import { JSON_Import_T } from "./Interfaces";
import { SsidChart } from '@mui/icons-material';

const NormalizedButton = ({sx={} as SxProps}) => {
    const [flags, setFlags] = useGlobalState(`flags`);
    const selected = (flags && flags.normalized || false);
    return <ToggleButton
        value='normalized'
        selected={selected}
        onChange={() => setFlags(i => ({...i, normalized: !selected}))}
        sx={{height: `1.5em`, width: `1.5em`, ...sx}}
    >
        <SsidChart />
    </ToggleButton>
};

export const ViewChart = ({id='chart', data={} as JSON_Import_T, sx={} as SxProps, height=.9}) => {
    const [chart, setChart] = useState<ApexCharts>();
    const toSeries = useCallback((_data: JSON_Import_T) => {
        const colors = [
            `#b60000`,
            `#770000`,
            `#00b600`,
            `#007700`,
        ];
        const nextSeries = _data.data_sets.map((_set, i) => ({
            name: _set.name,
            color: _set.color || colors[i],
            data: _set.data_points.map(d => d.value),
        }));
        return nextSeries;
    }, []);

    useEffect(() => {
        if (chart) {
            chart.updateSeries(toSeries(data));
        }
    }, [chart, data]);

    useEffect(() => {
        if (chart) {
            return;
        }
        const nextSeries = toSeries(data);
        const stroke: ApexStroke = {
            curve: 'smooth',
            width: 5,
        };
        const yaxis: ApexYAxis = {
            labels: {
                style: {
                    fontSize: `1.5em`,
                }
            }
        };
        const legend: ApexLegend = {
            floating: true,
            fontSize: `20`,
        };
        const options = {
            series: nextSeries,
            chart: {
                type: 'line',
                id: `mainchart`,
                toolbar: {
                    show: false,
                },
                height: window.innerHeight * height,
                offsetY: 0,
            } as ApexChart,
            xaxis: {
                labels: {show: false},
            },
            legend: legend,
            yaxis: yaxis,
            tooltip: {
                enabled: false,
            },
            stroke: stroke,
        };
        const nextChart = new ApexCharts(document.querySelector(`#${id}`), options);
        nextChart.render();
        setChart(nextChart);
        return () => {
            nextChart.destroy();
        };
    }, []);

    return <Box sx={{
        width: `100%`,
        userSelect: `none`,
        position: `relative`,
        ...sx,
    }}>
        <Box id={id} sx={{
            width: `100%`,
            userSelect: `none`,
            zIndex: 1,
        }} />
    </Box>;
};

export default () => {
    const [chart, setChart] = useState<ApexCharts>();
    const [series, setSeries] = useState<ApexAxisChartSeries>();
    const [game] = useGlobalState(`game`);
    const [symbolData] = useGlobalState(`symbolData`);
    const [flags, setFlags] = useGlobalState(`flags`);
    const alwaysNormalized = (flags && flags.normalized || false);

    useEffect(() => {
        if (!symbolData) {
            return;
        }
        const symbols = game.current_game_symbols.sort();
        const nextSeries = [];
        symbols.forEach((symbol, i) => {
            const index = game.day;
            const vals = symbolData[symbol];
            const ticks = vals.windows[index].map(i => i.toFixed(2));
            const _100Ticks = vals.windowsFrom100[index].map(i => i.toFixed(2));
            nextSeries.push({
                name: `${symbol}`,
                color: game.view_symbolColor[symbol],
                data: alwaysNormalized ? _100Ticks : ticks,
            });
        })
        setSeries(nextSeries);
    }, [game, flags, symbolData]);

    useEffect(() => {
        if (chart) {
            chart.updateSeries(series);
        }
    }, [chart, series]);

    useEffect(() => {
        if (chart) {
            return;
        }
        const options = {
            series: [],
            chart: {
                type: 'line',
                id: `mainchart`,
                toolbar: {
                    show: false,
                },
                height: window.innerHeight * .5,
                offsetY: 0,
            } as ApexChart,
            xaxis: {
                labels: {show: false},
            },
            tooltip: {
                enabled: false,
            },
            stroke: {
                width: 2,
            },
        };
        const nextChart = new ApexCharts(document.querySelector('#chart'), options);
        nextChart.render();
        setChart(nextChart);
    }, []);

    const windowSize = useMemo(() => <Box sx={{
        position: `absolute`, top: 10, right: 10,
    }}>{game.windowLength} Days</Box>, []);

    return <Box sx={{
        width: `100%`,
        userSelect: `none`,
        position: `relative`,
    }}>

        <Box id='chart' sx={{
            width: `100%`,
            userSelect: `none`,
            zIndex: 1,
        }} />
        <Box id='finger-guard' sx={{
            position: `absolute`,
            top: 0, bottom: 0, right: 0, left: 0,
            userSelect: `none`,
            p: 5,
            zIndex: 10,
            display: `flex`,
            alignItems: `flex-end`,
            justifyContent: `flex-end`,
        }}>
            {windowSize}
            <NormalizedButton sx={{background: `white`, outline: `solid 1px green`}} />
        </Box>
    </Box>;
};
