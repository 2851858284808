import { Box, TextField, ToggleButton } from "@mui/material";
import { useEffect, useState } from "react";
import { useGlobalState } from "./State";
import { Check } from "@mui/icons-material";
import { Span } from "./Theme";

const Restricted = () => {
    const [flags, setFlags] = useGlobalState('flags');
    const [toggled, setToggled] = useState<boolean>(flags.restricted);

    const onChange = () => {
        setToggled(!toggled);
        setFlags(f => ({...f, restricted: !toggled}))
    };

    useEffect(() => {
    }, []);

    return <ToggleButton
        value="restricted"
        selected={toggled}
        onChange={onChange}
        sx={{
            display: `flex`,
            gap: 0.5,
    }}>
        <Span>Restricted</Span>
        <Check />
  </ToggleButton>
}

const DateSelector = () => {
    const [ flags, setFlags ] = useGlobalState('flags');
    const [ val, setVal ] = useState('');

    const toDate = str => {
        const year = parseInt(str.slice(0, 4), 10);
        const half = parseInt(str[4], 10);
        let startMonth, endMonth;
        if (half === 1) {
            startMonth = 0;
            endMonth = 5;
        } else {
            startMonth = 6;
            endMonth = 11;
        }
        const startDate = new Date(year, startMonth, 1);
        const endDate = new Date(year, endMonth + 1, 0);
        const randomDate = new Date(startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime()));
        const yyyy = randomDate.getFullYear();
        const mm = String(randomDate.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const dd = String(randomDate.getDate()).padStart(2, '0');
        return parseInt(`${yyyy}${mm}${dd}`, 10);
    }

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const next = event.target.value.toLowerCase();
        setVal(next);
        const isDate = /^\d{4}[12]h$/.test(next);
        if (!isDate) {
            return;
        }
        const nextDate = toDate(next);
        console.log(`Setting Custom Date: ${nextDate}`);
        setFlags(f => ({...f, customDate: nextDate}));
    };

    return <TextField id="date-selector" label="YYYY1h/2h" variant="outlined" value={val} onChange={onChange} />
}

export default () => {
    return <Box sx={{
        display: `flex`,
        gap: 1,
        flexWrap: 'wrap',
    }}>
        <Restricted />
        <DateSelector />
    </Box>
}
