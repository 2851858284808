import { useEffect } from "react";
import { createGlobalState } from "react-hooks-global-state";
import { CustomLeverage, GameSets, SymbolData } from './GameData/GameData';
import { assert, assert_eq, assert_is_num } from "./Theme";
import { DataVisColors, formatDate, getLocalItem, setLocalItem } from "./Utils";
import { DEFAULT_FLAGS, Flags_T, GAME_LEN, Game_T, LLM_Game_T, SymbolsData, WINDOW_LEN, Website_Flags_T, getDefaultGame } from "./Interfaces";

const firstValue = d => d[Object.keys(d)[0]];

interface SymbolData_T {
    open: number;
    date: number;
    _1dChange: number;
    _5dChange: number;
    _30dChange: number;
}

const getCommonValues = (symbols: string[], _startDate?) => {
    const ret = {} as Record<string, SymbolData_T[]>;
    const seenDates = {};
    for (const symbol of symbols) {
        const gameData = SymbolData[symbol][0];
        seenDates[symbol] = new Set();
        for (const data of gameData) {
            seenDates[symbol].add(data.date);
        }
    }

    const commonDates: any = Object.values(seenDates).reduce((intersectionSet: any, currentSet: any) => {
        return intersectionSet.size === 0 ? new Set(currentSet) : new Set([...currentSet].filter(value => intersectionSet.has(value)));
    }, new Set());

    let commonLen = 0;
    let earliestDate = 99999999;
    let latestDate = 0;
    for (const symbol of symbols) {
        const gameData = SymbolData[symbol][0];
        ret[symbol] = [];
        for (const data of gameData) {
            if (commonDates.has(data.date)) {
                ret[symbol].push(data);
                earliestDate = Math.min(earliestDate, data.date);
                latestDate = Math.max(latestDate, data.date);
            }
        }
        commonLen = ret[symbol].length;
    }

    assert(commonLen > 0);
    assert(Object.keys(ret).length > 0);
    for (const [k, v] of Object.entries(ret)) {
        assert(v.length == commonLen);
        let prevDate = 0;
        for (const i of v) {
            assert(i.date > prevDate);
            prevDate = i.date;
        }
    }

    let startIndex = Math.floor(Math.random() * (commonLen - (GAME_LEN + WINDOW_LEN)));
    if (_startDate) {
        assert_is_num(_startDate);
        let index = ret[Object.keys(ret)[0]].findIndex(obj => obj.date === _startDate);
        if (index > 0) {
            startIndex = index;
        }
    }

    let startDate = 0;
    let endDate = 99999999;
    for (const symbol of Object.keys(ret)) {
        ret[symbol] = ret[symbol].slice(startIndex, startIndex + GAME_LEN + WINDOW_LEN);
        assert(startDate === ret[symbol][0].date || startDate === 0);
        startDate = ret[symbol][0].date;
        assert(endDate === ret[symbol].slice(-1)[0].date || endDate === 99999999);
        endDate = ret[symbol].slice(-1)[0].date;
    }
    assert_is_num(startDate)

    console.log(`Dates: ${earliestDate} - ${latestDate}`)
    return [ret, startDate] as [Record<string, SymbolData_T[]>, number];
}

const marketTicksLeveraged = (_1dChanges: number[], lev: number, trail=10) => {
    const inner = (_1dChanges: number[], lev: number, trail) => {
        const original = [100];
        const leveraged = [100];
        for (let i = 1; i < _1dChanges.length; i++) {
            const cur_o = original[i - 1];
            const cur_l = leveraged[i - 1];
            const pct_o = (_1dChanges[i] / 100) + 1
            const pct_l = ((_1dChanges[i] * lev) / 100) + 1;
            const toPush_o = Number((cur_o * pct_o).toFixed(trail))
            const toPush_l = Number((cur_l * pct_l).toFixed(trail))
            original.push(toPush_o);
            leveraged.push(toPush_l);
        }
        return [ original, leveraged ];    
    }
    assert_eq(inner([2.5, 3.5, -1.5], 2, 3)[0], [100, 103.5, 101.948]);
    assert_eq(inner([2.5, 3.5, -1.5], 2, 3)[1], [100, 107, 103.79]);
    return inner(_1dChanges, lev, trail);
}

const normalizeMarketTicks = (
    symbolData: Record<string, SymbolData_T[]>,
    windowLen: number,
    gameLen: number
) => {

    const to100 = (ticks: number[]) => ticks.map(i => (i * (100 / ticks[0])));

    const periodChange = (vals: number[], N) => {
        const result = new Array(N - 1).fill(null);
        for (let i = N; i <= vals.length; i++) {
            const previousValue = vals[i - N];
            const currentValue = vals[i - 1];
            const percentChange = ((currentValue - previousValue) / previousValue) * 100;
            result.push(percentChange);
        }
        return result;
    }

    const YTD = (vals: number[]) => {
        const result = [0];
        const firstValue = vals[0];
        for (let i = 1; i < vals.length; i++) {
            const currentValue = vals[i];
            const percentChange = ((currentValue - firstValue) / firstValue) * 100;
            result.push(percentChange);
        }
        return result;    
    }
    assert_eq(YTD([100, 110, 120, 130]), [0, 10, 20, 30])

    const getWindows = (vals: number[], N, normal=false) => {
        const L = vals.length;
        const result = [];
        for (let i = 0; i <= L - N; i++) {
            let sublist = vals.slice(i, i + N);
            if (normal) {
                sublist = to100(sublist);
            }
            result.push(sublist);
        }
        return result;
    };
    assert_eq(getWindows([1, 2, 3, 2, 1], 3), [[1,2,3],[2,3,2],[3,2,1]])
    assert_eq(getWindows([1, 2, 4, 2, 1], 3, true), [[100,200,400],[100,200,100],[100,50,25]])

    const makeLeveraged = (val: number, lev: number) => (((val / 100) + 1) ** lev - 1) * 100;
    assert_eq(makeLeveraged(1.51, 3).toFixed(1), '4.6');
    assert_eq(makeLeveraged(-1.51, 3).toFixed(1), '-4.5');

    const ret = {} as SymbolsData;
    for (const [symbol, data] of Object.entries(symbolData)) {
        const leverage = CustomLeverage[symbol] || 2;
        const [ marketTicks, leveragedTicks ] = marketTicksLeveraged(data.map(d => d._1dChange), leverage);
        const daily100Windows = getWindows(leveragedTicks, windowLen, true);
        const dailyWindows = getWindows(leveragedTicks, windowLen);
        assert_eq(daily100Windows.length, gameLen + 1);
        assert_eq(daily100Windows[0].length, windowLen);

        const tmp = {
            desc: SymbolData[symbol][1],
            marketTicks: leveragedTicks.slice(windowLen - 1),
            windows: dailyWindows,
            dateRanges: getWindows(data.map(d => d.date), windowLen),
            windowsFrom100: daily100Windows,
            _30dayChange: periodChange(leveragedTicks, 30).slice(windowLen - 1),
            _ytdChange: YTD(leveragedTicks.slice(windowLen - 1)),
        };
        ret[symbol] = tmp;
    };
    return ret;
};

const getAllSymbolData = (symbols: string[], windowLen: number, gameLen: number, _startDate?) => {
    const allSymbols = symbols; // || Object.keys(SymbolData);
    const [ allSymbolCommonTicks, startDate ] = getCommonValues(allSymbols, _startDate);
    const allSymbolData = normalizeMarketTicks(allSymbolCommonTicks, windowLen, gameLen);
    return [ allSymbolData, startDate ] as [ typeof allSymbolData, number ];
};

export const newGame = (flags: Flags_T) => {
    assert(flags != null);
    const nextGameSymbols = Object.values(GameSets)[0];
    const [allSymbolData, startDate] = getAllSymbolData(nextGameSymbols, WINDOW_LEN, GAME_LEN, flags.customDate);
    assert_is_num(startDate);
    if (flags.customDate) {
        console.log(`Using custom date: ${flags.customDate}`)
        assert_eq(startDate.toString().substring(0, 4), flags.customDate.toString().substring(0, 4))
    }
    const nextGame = getDefaultGame(startDate);
    nextGame.current_game_symbols = nextGameSymbols;
    nextGameSymbols.forEach((symbol, i) => nextGame.view_symbolColor[symbol] = DataVisColors[i]);
    nextGame.logs.push({type: 'date', data: {value: startDate}});
    nextGame.flags = flags || nextGame.flags;
    nextGame.currentDate = startDate;
    return nextGame;
};

export const useGlobalState = createGlobalState({
    game: null as Game_T | null,
    flags: null as Flags_T | null,
    symbolData: null as SymbolsData | null,
    llmGame: null as LLM_Game_T | null,
    currentDate: null as number,
    WebsiteFlags: {
        version: 1,
        local: 'en',
    } as Website_Flags_T,
}).useGlobalState;

export const Persistence = () => {
    const [game, setGame] = useGlobalState(`game`);
    const [currentDate, setCurrentDate] = useGlobalState(`currentDate`);
    const [flags, setFlags] = useGlobalState(`flags`);
    const [symbolData, setSymbolData] = useGlobalState(`symbolData`);

    useEffect(() => {
        if (game === undefined) {
            localStorage.removeItem(`savedGame`);
        }
        if (!game) {
            const savedGame = getLocalItem(`savedGame`);
            if (!savedGame) {
                setTimeout(() => {
                    const nextGame = newGame(flags || {} as any);
                    setGame(nextGame);
                    setSymbolData(null);
                    setLocalItem(`previousGame`, nextGame);
                }, 500);
                return;
            }
            if (savedGame) {
                setGame(savedGame.value);
            }
        }
        if (game) {
            setLocalItem(`savedGame`, game);
        }
    }, [game]);

    useEffect(() => {
        if (symbolData || !game) {
            return;
        }
        const [allSymbolData, startDate] = getAllSymbolData(game.current_game_symbols, WINDOW_LEN, GAME_LEN, game.startDate);
        setSymbolData(allSymbolData);
        const allVals = Object.values(allSymbolData)[0].dateRanges[0];
        const [ WindowStartDate, windowEndDate ] = [allVals[0], allVals[allVals.length - 1]];
        console.log(`Window dates: ${formatDate(WindowStartDate)} - ${formatDate(windowEndDate)}`);
        setCurrentDate(windowEndDate);
    }, [symbolData, game]);

    useEffect(() => {
        if (!flags) {
            const savedFlags = getLocalItem(`flags`);
            if (savedFlags) {
                setFlags(savedFlags.value);
                return
            }
            setFlags(DEFAULT_FLAGS);
            return;
        }
        if (!game) {
            return
        }
        setLocalItem(`flags`, flags);
        var setNewGame = false;
        assert(game.flags != null);
        [ 'restricted', 'customDate', ].forEach(s => {
            const cur = flags[s];
            console.log(game);
            const expect = game.flags[s];
            if (cur != expect) {
                setNewGame = true;
            }
        });
        if (setNewGame) {
            setGame(undefined);
        }
    }, [flags]);

    return <></>;
}
