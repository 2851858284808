import { Box, Typography } from "@mui/material"
import { CURSIVE, Footer, _Logo, assert, PARAGRAPH, PAGE_HEADER, Translated } from "./Theme";
import Metadata from "./Metadata";
import { Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useGlobalState } from "./State";
import _Letters from "./_Letters";
import { Translate } from "@mui/icons-material";

const RenderLine = ({line, letter_index, line_index, mt, li=false}) => {
    const Date = ({text}) => <Typography component='time' sx={{
        textAlign: `right`,
        textDecoration: `underlined`,
    }}>{text}</Typography>;
    
    let element;
    if (line.startsWith('<') && line.endsWith('>')) {
        if (line.startsWith('<date \'')) {
            const elem = line.slice(7, -2);
            return <Date key={line_index} text={elem} />;
        }
        const elem = line.slice(1, -1);
        element = _Letters[letter_index][elem];
        return <Fragment>{element}</Fragment>;
    }

    const isBold = line.startsWith('**') && line.endsWith('**');
    if (isBold) {
        line = line.slice(2, -2);
    }

    const parts = line.split(/(_[^_]*_)/).map((part, idx) => {
        if (part.startsWith('_') && part.endsWith('_')) {
            return <Box component='span' key={`ital-${idx}`} style={{textDecoration: 'underline'}}>{part.slice(1, -1)}</Box>;
        }
        return part;
    });

    return <Typography key={line_index} component={isBold ? 'h2' : li ? 'li' : undefined} sx={{
        mt: mt,
        ml: li ? 3 : 0,
        pl: li ? 1 : 0,
        fontWeight: isBold ? 'bold' : undefined,
        fontFamily: isBold ? undefined : PARAGRAPH,
    }}>
        {parts}
    </Typography>;
};

const elementsForPage = (letter_index, lines: string[]) => {
    var trailingMt = 0;
    const nextElements: JSX.Element[] = [];
    var listStartsAt = 0;
    for (var i = 0; i < lines.length; i++) {
        assert(!"")
        if (!lines[i].trim()) {
            trailingMt = 2;
            console.log('adding trailingMt');
            continue;
        }
        var listEndsAt = i;
        var isList = false;
        while (lines[listEndsAt].trim().startsWith("* ")) {
            listEndsAt += 1;
            isList = true;
        }
        if (!isList) {
            const line = lines[i].trim();
            nextElements.push(<Fragment key={i}>
                <RenderLine line={line} letter_index={letter_index} line_index={i} mt={trailingMt} />
            </Fragment>);
        }
        else {
            console.log('trailingMt', trailingMt);
            nextElements.push(<Box component='ul' key={i}>
                {lines.slice(i, listEndsAt).map((l, x) => <Fragment key={x}>
                    <RenderLine key={`${i}-ul`} line={l.trim().slice(2)} li letter_index={letter_index} line_index={i} mt={trailingMt} />
                </Fragment>)}
            </Box>);
            i = listEndsAt - 1;
        }
        trailingMt = 0;
    }
    return nextElements;
};

const Title = () => <Typography component='h1' sx={{
    fontFamily: 'cursive',
    fontWeight: 400,
    fontSize: {xs: `min(12ch, 12svw)`, sm: `min(12ch, 12svw)`},
    textAlign: `center`,
    lineHeight: 1,
}}>
    <Translated en='Dear Clients' zh='尊敬的客户们' />
</Typography>;

const LetterViewer = ({letter_index, lang}) => {
    const [ elements, setElements ] = useState([]);
    useEffect(() => {
        const letter = _Letters[letter_index];
        if (elements.length) {
            window.print();
            console.log(`Will print!`);
            return;
        }
        if (!letter) {
            return;
        }
        if (!letter[lang]) {
            lang = 'en';
        }
        const pages: string[] = letter[lang].trim().split('<page />');
        var elements2 = [];
        pages.forEach((page, i) => {
            const lines: string[] = page.trim().split('\n');
            const elements = elementsForPage(letter_index, lines);
            elements2.push(<Box sx={{
                pageBreakBefore: i > 0 && 'always',
                '@media print': {
                    height: `99svh`,
                    overflow: `hidden`,
                },
                display: `flex`,
                flexDirection: `column`,
                mt: 0, pt: 0,
            }}>
                <PAGE_HEADER center />
                {i == 0 && <Title />}
                {elements}
                <Footer idx={i+1} onlyOnPrint confidential />
            </Box>)
        });
        const lines: string[] = letter[lang].trim().split('\n');
        const elements1 = elementsForPage(letter_index, lines);
        setElements(elements2);
    }, [elements, letter_index]);
    return <Fragment>{elements}</Fragment>;
};

const HR = () => <Box component='hr' sx={{width: `80%`, borderTop: `solid 1px #dadada`, '@media print': {display: `none`}}} />;

const _DearClientsV1 = () => {
    const [ WebsiteFlags, setWebsiteFlags ] = useGlobalState(`WebsiteFlags`);
    const Allocation = () => {
        return <Box component='main' sx={{
            display: `flex`,
            flexDirection: `column`,
            alignItems: `center`,
            gap: 2,
            my: 2, mx: 1,
            '@media print': {m: 0,},
        }}>
            <HR />
            <Box component='article' sx={{
                display: `flex`,
                flexDirection: `column`,
                justifyContent: `center`,
                gap: 1,
            }}>
                <LetterViewer letter_index={0} lang={WebsiteFlags.local} />
            </Box>
            <HR />
        </Box>
    };

    return <Box sx={{
        minHeight: `100svh`,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
        justifyContent: `center`,
        boxSizing: 'border-box',
        p: 3,
        '@media print': {p: 0,},
        mx: 'auto',
        width: 'min(100%, 900px)',
    }}>
        <_Logo />
        <Allocation />
        <Footer neverOnPrint />
    </Box>;
}

export default () => <>
    <Metadata title={{en: 'Dear Clients', zh: '尊敬的客户们'}} />
    <_DearClientsV1 />
</>;
