import { Box, Button, Typography } from "@mui/material";
import { _Logo, Span, toGridArea, Footer, FENG } from "./Theme";
import { useNavigate } from "react-router";
import { DataObject, MoreHoriz } from "@mui/icons-material";
import { onEmail } from "./Utils";
import { useGlobalState } from "./State";

const SkillGrid = () => {
    const navigate = useNavigate();
    const [ WebsiteFlags, setWebsiteFlags ] = useGlobalState(`WebsiteFlags`);

    const texts = {
        Design: {
            en: [
                `Oberon Research is an Investment Management and Economic Research Firm`,
                `Mathematical and Statistical methods are Employed in the Design and Execution of Investment Programs`,
            ],
            zh: [
                `奥布朗研究是一家投资管理和经济研究公司。`,
                `在设计和执行投资方案中，我们采用数学和统计方法。`,
            ],
        },
        Services: {
            en: [
                `We Provide:`,
                `Investment Management,`,
                `Investment Research and Advice,`,
                `US Limited Liability Companies for Foreign Individuals.`,
            ],
            zh: [
                `我们提供：`,
                `投资管理、`,
                `投资研究和建议、`,
                `面向外国个人的美国有限责任公司。`,
            ]
        }
    };

    const DearClientsButton = () => {
        const onClick = e => { e.preventDefault(); navigate(`/dear-clients`); };
        return <Button onClick={onClick} href='/dear-clients' sx={{
            width: `100%`,
            backgroundColor: `#009688`,
            color: `white`,
            display: `flex`,
            fontWeight: `bold`,
            alignItems: `flex-end`,
            '&:hover': {
                backgroundColor: `#00968896`,
            },
            position: `relative`,
        }}>
            <Box sx={{
                position: `absolute`,
                top: `10%`, left: `30%`, right: `30%`, height: `50%`,
                backgroundColor: `white`,
                clipPath: FENG,
            }}/>
            <Span sx={{whiteSpace: `nowrap`}}>Dear Clients</Span>
        </Button>
    };

    const _Design = () => <Typography sx={{
        border: `solid 2px #009688`,
        p: 2,
        boxSizing: `border-box`,
        display: `flex`,
        justifyContent: `center`,
        flexDirection: `column`,
        width: `100%`,
    }}>
        {texts.Design[WebsiteFlags.local].map((l, i) => <Span key={i} sx={{mt: i == 0 ? 0 : 1}}>{l}</Span>)}
    </Typography>;

    const _Markets = () => <Typography sx={{
        border: `solid 2px #009688`,
        p: 2,
        boxSizing: `border-box`,
        display: `flex`,
        justifyContent: `center`,
        flexDirection: `column`,
        width: `100%`,
    }}>
        <Span sx={{fontWeight: `bold`, display: `block`}}>{texts.Services[WebsiteFlags.local][0]}</Span>
        <Box component='ul' sx={{
            ml: 1,
        }}>
            {texts.Services[WebsiteFlags.local].slice(1).map((l, i) => <Typography key={i} component='li' sx={{}}>{l}</Typography>)}
        </Box>
    </Typography>;

    const _Address = () => <Typography sx={{
        border: `solid 2px #9c27b0`,
        p: 2,
        boxSizing: `border-box`,
        display: `flex`,
        justifyContent: `center`,
        flexDirection: `column`,
        width: `100%`,
    }}>
        <Span sx={{fontWeight: `bold`}}>Oberon Research &</Span>
        <Span sx={{fontWeight: `bold`, mb: 1,}}>Investment, LLC</Span>
        <Span>1309 Coffeen Avenue STE 10017</Span>
        <Span>Sheridan Wyoming 82801</Span>
    </Typography>;

    const XFB = ({url, color, children}) => {
        const onClick = e => { e.preventDefault(); navigate(url); };
        return <Button onClick={onClick} href={url} sx={{
            width: `100%`,
            backgroundColor: color,
            color: `white`,
            display: `flex`,
            flexDirection: `column`,
            fontWeight: `bold`,
            alignItems: `center`,
            justifyContent: `flex-end`,
            '&:hover': { backgroundColor: `${color}96`, },
        }}>{children}</Button>
    };

    const Services = () => <XFB url={`/strategy`} color='#009688'>
        <MoreHoriz sx={{height: `4rem`, width: `4rem`, m: -2}} />
        <Span>Services</Span>
    </XFB>;

    const Simulator = () => <XFB url={`/simulator`} color='#009688'>
        <DataObject sx={{height: `3rem`, width: `3rem`, my: -1}} />
        <Span>Simulator</Span>
    </XFB>;

    const ContactUs = () => {
        const size = 8;
        return <Button onClick={onEmail} sx={{
            width: `100%`,
            backgroundColor: `#9c27b0`,
            color: `white`,
            clipPath: `polygon(0 0, 0% 100%, 100% 100%, 100% ${10 + size}%, 50% ${50 + (size / 2)}%, 0 ${10 + size}%, 0 10%, 50% ${50 - (size / 2)}%, 100% 10%, 100% 0%)`,
            display: `flex`,
            fontWeight: `bold`,
            alignItems: `flex-end`,
            '&:hover': {
                backgroundColor: `#9c27b096`,
            },
        }}>
            <Span>Email Us</Span>
        </Button>
    };

    const skillBoxes = [
        { content: <_Logo />,            gridArea: {xs: toGridArea(1, 1, 6, 1), sm: toGridArea(1, 1, 4, 1), md: toGridArea(1, 1, 3, 1)} },
        { content: <_Design />,          gridArea: {xs: toGridArea(1, 3, 6, 3), sm: toGridArea(1, 2, 3, 3), md: toGridArea(2, 2, 2, 3)} },
        { content: <_Markets />,         gridArea: {xs: toGridArea(2, 6, 5, 2), sm: toGridArea(4, 4, 3, 2), md: toGridArea(4, 4, 2, 2)} },
        { content: <_Address />,         gridArea: {xs: toGridArea(1, 8, 5, 2), sm: toGridArea(3, 6, 3, 2), md: toGridArea(3, 6, 2, 2)} },
        { content: <Services />,         gridArea: {xs: toGridArea(3, 2, 2, 1), sm: toGridArea(5, 2)      , md: toGridArea(5, 2)      } },
        { content: <ContactUs />,        gridArea: {xs: toGridArea(4,10, 2, 1), sm: toGridArea(5, 8),       md: toGridArea(5, 7)      } },
        { content: '', color: "#009688", gridArea: {xs: toGridArea(1, 7)      , sm: toGridArea(2, 5)      , md: toGridArea(2, 5)      } },
        { content: '', color: "#009688", gridArea: {xs: toGridArea(5, 2, 2, 1), sm: toGridArea(4, 3)      , md: toGridArea(4, 3)      } },
        { content: '', color: "#009688", gridArea: {xs: toGridArea(1, 7)      , sm: toGridArea(2, 6)      , md: toGridArea(2, 6)      } },
        { content: '', color: "#9c27b0", gridArea: {xs: toGridArea(5,10)      , sm: toGridArea(5, 9)      , md: toGridArea(5, 6)      } },
        { content: '', color: "#9c27b0", gridArea: {xs: toGridArea(6, 8)      , sm: toGridArea(6, 6)      , md: toGridArea(6, 6)      } },
        { content: '', color: "#9c27b0", gridArea: {xs: toGridArea(4,10)      , sm: toGridArea(4, 8)      , md: toGridArea(4, 8)      } },
        { content: '', color: "#009688", gridArea: {xs: toGridArea(1, 2, 2, 1), sm: toGridArea(5, 3)      , md: toGridArea(5, 3)      } },
        { content: '', color: "#009688", gridArea: {xs: toGridArea(1, 6)      , sm: toGridArea(3, 5)      , md: toGridArea(3, 5)      } },
    ];

    return <Box sx={{
        minHeight: `100svh`,
        width: `100%`,
        display: `flex`,
        flexDirection: `column`,
        alignItems: `center`,
        justifyContent: `center`,
        boxSizing: 'border-box',
        p: 3,
    }}>
        <Box sx={{
            display: 'grid',
            gap: 1,
            gridTemplateColumns: { xs: `repeat(6, 1fr)` },
            gridTemplateRows:    { xs: `repeat(10, 1fr)`, sm: `repeat(9, 1fr)`, md: 'repeat(8, 1fr)' },
            width:               'min(100%, 900px)',
            height: '100%'
        }}>
            {skillBoxes.map((box, i) => <Box key={i} id={`box-${151 + i}`} sx={{
                    backgroundColor: box.color || `white`,
                    gridArea: box.gridArea,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'stretch',
                    boxSizing: 'border-box',
                    zIndex: 100 - i,
                }}>
                    {box.content}
                </Box>)}
        </Box>
        <Footer />
    </Box>;
}

export default () => {
    return <Box sx={{
        minHeight: `100svh`,
    }}>
        <SkillGrid />
    </Box>;
};
