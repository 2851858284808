import { Box, SxProps } from "@mui/material";
import { Game_T, Log_Row_Transaction_T } from "./Interfaces";
import { Span, assert } from "./Theme";

const toTransactLog = (data: Log_Row_Transaction_T) => {
    return <Box sx={{
        display: `flex`,
        gap: 2,
    }}>
        <Span sx={{
            color: data.isBuy ? 'red' : 'green',
        }}>
            {data.isBuy ? 'BUY' : 'SELL'}
        </Span>
        <Span sx={{
            fontWeight: `bold`,
            color: data.symbolColor,
        }}>
            {data.symbol}
        </Span>
        <Span sx={{
        }}>
            Day: {data.day}
        </Span>
        <Span sx={{
        }}>
            {data.qty} @ ${data.cost}
        </Span>
    </Box>;
}

export const downloadLog = (game: Game_T) => {
    const jsonData = JSON.stringify(game.logs, null, 4);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${game.startDate}.json`;
    a.click();
    URL.revokeObjectURL(url);
}

export default ({game=undefined as Game_T, sx={} as SxProps}) => {
    assert(game);
    const paths = {
        'transaction': toTransactLog,
        'date': (d) => <Box>Start Date: {d.value}</Box>
    };
    return <Box component='section' sx={{
        ...sx
    }}>
        {game.logs.reverse().map((row, i) => <Box key={i}>{(paths[row.type] || (() => {}))(row.data)}</Box>)}
    </Box>;
};
