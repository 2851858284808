import { useEffect } from "react";
import { Box } from "@mui/material";
import { Logo } from "./Theme";

export default () => {
    useEffect(() => {
        document.body.style.paddingTop = '2em';
        return () => {
            document.body.style.paddingTop = '0';
        };
    }, []);
    return <Box sx={{
        position: `fixed`, top: 0, left: 0,
        p: 2,
        display: `flex`,
        gap: 1,
        background: `white`,
        width: `100%`,
        boxSizing: `border-box`,
        zIndex: 999,
    }}>
        <Logo />
    </Box>
};
